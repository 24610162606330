import axios from '../utils/axios';
import { API_URL_CLIENTS } from '../constants';

/**
 * Function service to request customer data by firebase uid account
 * @param {string} uid firebase uid
 * @returns Promise<object>
 */
export const getCustomerData = async (uid) => {
  const response = await axios.get(`${API_URL_CLIENTS}/client/auth/${uid}`);
  return response.data;
};

/**
 * Update customer data
 * @param {object} params customer data to update
 * @returns Promise<object>
 */
export const updateCustomer = async ({ params }) => {
  const response = await axios.put(`${API_URL_CLIENTS}/client`, params);
  return response.data;
};
