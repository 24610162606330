/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes, { any } from 'prop-types';
import {
  Button, ButtonGroup, Container, Grid, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import styles from './styles';
import images from '../../assets/images';
import {
  deleteItem, updateQuantity
} from '../../reducers/shoppingCart/actions';
import ModalDelete from '../../components/ModalDelete';
import useToggle from '../../customHooks/useToggle';

const ShoppingCart = ({
  orderList, deleteItem, updateQuantity
}) => {
  const [prouctModal, setProductModal] = useState({ name: '', id: '' });
  const {
    isToggleActive,
    handleToggle
  } = useToggle();
  const classes = styles();

  const handleDelete = (id, name) => {
    setProductModal({ name, id });
    handleToggle();
  };
  const handleDecrease = (operation, id, numberOfItems, name) => {
    if (numberOfItems === 1) {
      handleDelete(id, name);
      return;
    }
    updateQuantity(operation, id);
  };
  return (
    <Container maxWidth="xl">
      <Grid item xs={12} elevation={1}>
        <Grid className={classes.titleBox}>
          <Typography className={classes.title}>Tu carrito de compras</Typography>
        </Grid>
        <Paper elevation={0} className={classes.contentBox}>
          <div>
            <TableContainer className={classes.TableContainer}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cellTitle} align="center">Producto</TableCell>
                    <TableCell className={classes.cellTitle} align="center">Cantidad</TableCell>
                    <TableCell className={classes.cellTitle} align="center">Precio</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderList.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <div className={classes.cell}>
                          <img className={classes.image} src={images.medicinale} alt="" />
                          <div>
                            <Typography className={classes.nameProduct}>
                              {row.product.nameProduct}
                            </Typography>
                            <Typography className={classes.subName}>Activo</Typography>
                            <div className={classes.row}>
                              <Typography className={classes.contentText}>6 mg</Typography>
                              <Typography className={classes.separation}>|</Typography>
                              <Typography className={classes.contentText}>4 tabletas</Typography>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={classes.buttonsCell}>
                          <ButtonGroup
                            className={classes.buttonGroup}
                            variant="contained"
                            size="small"
                            color="default"
                            aria-label="contained primary button group"
                          >
                            <Button onClick={() => handleDecrease('Decrease', row.id, row.quantity, row.product.nameProduct)}>
                              -
                            </Button>
                            <input
                              defaultValue={row.quantity}
                              className={classes.buttonItems}
                              value={row.quantity}
                            />
                            <Button onClick={() => updateQuantity('Increase', row.id)}>+</Button>
                          </ButtonGroup>
                          <Button
                            onClick={() => handleDelete(row.id, row.product.nameProduct)}
                            color="default"
                            size="small"
                            className={classes.deleteButton}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M4 8h16v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8zm2
                            2v10h12V10H6zm3 2h2v6H9v-6zm4 0h2v6h-2v-6zM7 5V3a1 1 0 0 1
                            1-1h8a1 1 0 0 1 1 1v2h5v2H2V5h5zm2-1v1h6V4H9z"
                              />
                            </svg>
                          </Button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Typography className={classes.programingDayText}>$130.00</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer className={classes.TableContainerSmall}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cellTitle} align="center">Productos</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderList.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <div className={classes.cell}>
                          <img className={classes.image} src={images.medicinale} alt="" />
                          <div>
                            <Typography className={classes.nameProduct}>
                              {row.product.nameProduct}
                            </Typography>
                            <Typography className={classes.subName}>Activo</Typography>
                            <div className={classes.row}>
                              <Typography className={classes.contentText}>6 mg</Typography>
                              <Typography className={classes.separation}>|</Typography>
                              <Typography className={classes.contentText}>4 tabletas</Typography>
                            </div>
                          </div>
                        </div>
                        <div className={classes.buttonsCell}>
                          <ButtonGroup className={classes.buttonGroup} variant="contained" size="small" color="default" aria-label="contained primary button group">
                            <Button onClick={() => handleDecrease('Decrease', row.id, row.quantity, row.nameProduct)}>-</Button>
                            <input
                              defaultValue={row.quantity}
                              className={classes.buttonItems}
                              value={row.quantity}
                            />
                            <Button onClick={() => updateQuantity('Increase', row.id)}>+</Button>
                          </ButtonGroup>
                          <Button onClick={() => handleDelete(row.id, row.product.nameProduct)} color="default" size="small" className={classes.deleteButton}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M4 8h16v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8zm2 2v10h12V10H6zm3 2h2v6H9v-6zm4 0h2v6h-2v-6zM7 5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v2h5v2H2V5h5zm2-1v1h6V4H9z" />
                            </svg>
                          </Button>
                        </div>
                        <div className={classes.boxTextSmall}>
                          <Typography className={classes.sameDayText}>Precio</Typography>
                          <Typography className={classes.programingDayText}>$130.00</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            { orderList.length < 1 && (
            <div className={classes.notFound}>
              <Typography className={classes.notFoundText}>
                🛒 No haz agregado productos a tu carrito de compras
              </Typography>
            </div>
            )}
          </div>
          <div className={classes.infoBox}>
            <div className={classes.pricesContainer}>
              <div className={classes.programmedBox}>
                <Typography className={classes.priceTitle}>
                  Total:
                </Typography>
                <Typography className={classes.priceProgrammedDay}> $ 175.00 MXN </Typography>
              </div>
            </div>
            <div className={classes.shoppingButtonBox}>
              <Button className={classes.shoppingButton}>Continuar con la compra</Button>
            </div>
            <div>
              <div>
                <Typography className={classes.methodsTitle}>Métodos de pago</Typography>
              </div>
              <div className={classes.methodsBox}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="21" height="21">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 9H4v7h16v-7zm0-4V5H4v3h16z" />
                </svg>
                <Typography className={classes.methodsSubtitle}>Pago en linea</Typography>
              </div>
              <div className={classes.boxPayment}>
                <img className={classes.paymentsImage} src={images.visa} alt="" />
                <img className={classes.paymentsImageMasterCard} src={images.masterCard} alt="" />
              </div>
            </div>
          </div>
        </Paper>
        <ModalDelete
          open={isToggleActive}
          image={images.medicinale}
          name={prouctModal.name}
          onAction={() => deleteItem(prouctModal.id)}
          onClose={handleToggle}
        />
      </Grid>
    </Container>
  );
};

ShoppingCart.propTypes = {
  orderList: PropTypes.arrayOf(any),
  deleteItem: PropTypes.func,
  updateQuantity: PropTypes.func
};

ShoppingCart.defaultProps = {
  orderList: [],
  deleteItem: () => {},
  updateQuantity: () => {}
};

const mapStateToProps = (state) => {
  const { orderList, shoppingCartData } = state.shoppingCart;

  return { orderList, shoppingCartData };
};

export default connect(mapStateToProps, {
  deleteItem,
  updateQuantity
})(ShoppingCart);
