import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../theme';

const styles = makeStyles((theme) => ({
  container: {
    marginTop: '3rem',
    padding: '2rem',
    backgroundColor: Colors.simpleWhite,
    minHeight: '40rem'
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem'
  },
  image: {
    maxHeight: '21rem',
    marginTop: '2rem'
  },
  info: {
    padding: '0.4rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  infoCdso: {
    padding: '0.4rem',
    marginTop: '1.3rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
     marginTop: '0.3rem'
    }
  },
  benefits: {
    marginTop: '1rem',
    padding: '1rem',
    fontWeight: 'bold',
    color: Colors.blueHepatica,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  benefitsBox: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  item: {
    margin: '1.4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  textBenefit: {
    fontWeight: 'Bold'
  },
  icon: {
    fontSize: '2rem'
  },
  button: {
    width: '100%',
    maxWidth: '20rem',
    margin: 'auto',
    borderRadius: '1.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '18rem',
      fontSize: '1rem'
    },
    color: Colors.simpleWhite,
    background: Colors.electricViolet,
    '&:hover': {
      background: Colors.honeyFlower
    }
  },
  link: {
    textDecoration: 'none',
    width: '100%',
    maxWidth: '20rem',
    margin: 'auto',
    textAlign: 'center'
  },
  fixText: {
    marginTop: '0.4rem'
  },
  containerInfo: {
    maxWidth: '60rem',
    margin: 'auto'
  },
  logos : {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  logoCdso: {
    margin: 'auto',
    width: '8rem'
  },
  linkCdso: {
    textDecoration: 'none'
  }
}));

export default styles;
