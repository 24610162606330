/*  eslint-disable prefer-regex-literals  */

import { useState } from 'react';

const useValidField = ({ type, skip = false, phone = false, noErrorEmpty = false }) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const emailValidator = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const phoneValidator = new RegExp(/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?\d{10}$/i);
  const handleEmail = (event) => {
    if (noErrorEmpty) {
      setValue(event.target.value);
    }

    if (event.target.value !== '') {
      setValue(event.target.value);
      setError(false);
      if (!emailValidator.test(event.target.value)) {
        setError(true);
        setHelperText('Introduce un email válido.');
        setValue(event.target.value);
      } else {
        setHelperText('');
        setError(false);
      }
    } else {
      setError(false);
      setHelperText('El email es obligatorio.');
    }
    if (noErrorEmpty && event.target.value === '') {
      setError(false);
      setHelperText('');
    }
  };

  const handlePassword = (event) => {
    if (event.target.value !== '') {
      setError(false);
      if (event.target.value.length < 7) {
        setError(true);
        setHelperText('La contraseña debe tener al menos 7 caracteres');
      } else {
        setHelperText('');
        setError(false);
        setValue(event.target.value);
      }
    } else {
      setError(true);
      setHelperText('Escribe una contraseña');
    }
  };

  const handleNumber = (event) => {
    setValue(event.target.value);
    if (phone) {
      if (event.target.value !== '' && phoneValidator.test(event.target.value)) {
        setHelperText('');
        setError(false);
      } else {
        setError(true);
        setHelperText('Introduce un número de teléfono válido.');
      }
    } else if (event.target.value !== '' && event.target.value.length >= 5) {
      setError(false);
      setHelperText('');
    } else {
      setError(true);
      setHelperText('Debe tener almenos 5 digitos');
    }
    if (noErrorEmpty && event.target.value === '') {
      setError(false);
      setHelperText('');
    }
  };

  const handleText = (event) => {
    const deleteNumericValues = event.target.value.replace(/[0-9]/g, '');
    const deleteSpecialCharacters = deleteNumericValues.replace(/[^a-zA-Z ]/g, '');
    if (deleteSpecialCharacters !== '') {
      setValue(deleteSpecialCharacters);
      if (deleteSpecialCharacters.length >= 3) {
        setError(false);
        setHelperText('');
      } else {
        setError(true);
        setHelperText('El minimo es 3 caracteres');
      }
    } else {
      setValue('');
      setError(true);
      setHelperText('Este campo es obligatorio');
    }
  };

  const handleDate = (event) => {
    if (event.target.value !== '') {
      setValue(event.target.value);
    } else {
      setValue('');
      setError(true);
      setHelperText('Este campo es obligatorio');
    }
  };

  const validationType = {
    email: handleEmail,
    password: handlePassword,
    number: handleNumber,
    text: handleText,
    date: handleDate
  };

  const onChange = (event) => {
    if (!skip) {
      const validate = validationType[type];
      validate(event);
    } else {
      setValue(event.target.value);
    }
  };

  return {
    value,
    onChange,
    type,
    error,
    helperText,
    setValue
  };
};

export default useValidField;
