import {
  FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const PasswordInput = ({
  handlerField, name, error, helperText
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <FormControl error={error} size="small" style={{ marginTop: '1rem', width: '100%' }} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">{name}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...handlerField}
        label={name}
      />
      <FormHelperText id="component-error-text">{helperText}</FormHelperText>
    </FormControl>
  );
};

PasswordInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  handlerField: PropTypes.any,
  name: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string

};

PasswordInput.defaultProps = {
  handlerField: {},
  name: 'Contraseña',
  error: false,
  helperText: ''
};

export default PasswordInput;
