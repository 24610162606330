/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable import/no-useless-path-segments */

import iSanaLogo from './images/isana-logo.svg';
import iSanaMonochrome from './images/logo-monochrome.png';
import caps from './images/doc-caps.png';
import circleDetail from './images/circle-detail.png';
import phoneIcon from './images/phone-icon.svg';
import searchIcon from './images/search-icon.svg';
import packageIcon from './images/package-icon.svg';
import isanaWomen from './images/isana-women.png';
import moto from './images/moto.png';
import moto4040 from './images/moto4040.png';
import bolsas from './images/bolsas.png';
import phoneApp from './images/phones-app.png';
import phonesAppBanner from './images/phones.png';
import googlePlay from './images/google-play.png';
import appStore from './images/app-store.png';
import family from './images/family.png';
import maleDoc from './images/male-doc.png';
import mariana from './images/Mariana.webp';
import marilu from './images/Marilu.webp';
import alejandro from './images/Alejandro.webp';
import decoration from './images/decoration.png';
import bigDoctor from './images/big-doc.png';
import bag from './images/bag.png';
import docHeart from './images/doc-heart.png';
import facebookIcon from './images/facebook-circle-fill.png';
import linkedinIcon from './images/linicon.png';
import instagramIcon from './images/instagram-fill.png';
import arrowDown from './images/arrow-down.png';
import facebookSmallIcon from './images/fb-sm-icon.png';
import instagramSmallIcon from './images/in-sm-icon.png';
import QuestionsAnsawersbanner from './images/medi-isana.png';
import iconhservices from './images/icon-h-serivices.png';
import iconPhoneServices from './images/icon-phone-services.png';
import iconRewardsServices from './images/icon-rewards-services.png';
import team from './images/team.png';
import bagServices from './images/bolsa-banner.png';
import medicinale from './images/farm.png';
import womenPromo from './images/women-promo.png';
import avatarChat from './images/avatar-chat.png';
import inconTypingMessage from './images/icon-typing-message.gif';
import payPal from './images/PAYPAL.png';
import visa from './images/Visa_Inc._logo.svg';
import masterCard from './images/Mastercard-logo.svg';
import bannerOk from './images/Imagen-banner-ok.png';
import quotationMarks from './images/comillas.svg';
import bannerWoman from './images/banner-woman.png';
import iconPay from './images/icon-pay.png';
import iconApp from './images/icon-app.png';
import iconBag from './images/icon-bag.png';
import iconShipping from './images/icon-shipping.png';
import iconBetterPrice from './images/icon-betterPrice.png';
import iconMedicines from './images/icon-medicines.png';
import whatsAppIcon from './images/whatsapp.svg';
import productDefault from './images/product-default.png';
import iconRecipe from './images/icon-recipe.svg';
import amex from './images/amex.png';
import cdsoLogo from './images/cdso-logo.png';
import google from './images/google-icon.svg';
import facebook from './images/facebook.svg';
import womenWithMask from './images/womenwithmask.png';
import cdsoCard from './images/tarjeta-cdso.png';
import isanaPlus from './images/isanaplus.png';
import mercadoPagoIcon from './images/mercado-icon.png';
import isanaPlusSmall from './images/isanaplusfix.png';
import plusIcon from './images/isanaplusjusticon.png';



const images = {
  iSanaLogo,
  iSanaMonochrome,
  caps,
  circleDetail,
  phoneIcon,
  searchIcon,
  packageIcon,
  isanaWomen,
  moto,
  moto4040,
  phoneApp,
  appStore,
  googlePlay,
  family,
  maleDoc,
  mariana,
  marilu,
  alejandro,
  decoration,
  phonesAppBanner,
  bigDoctor,
  bag,
  docHeart,
  facebookIcon,
  instagramIcon,
  facebookSmallIcon,
  instagramSmallIcon,
  QuestionsAnsawersbanner,
  arrowDown,
  iconhservices,
  iconPhoneServices,
  iconRewardsServices,
  team,
  bagServices,
  medicinale,
  womenPromo,
  avatarChat,
  inconTypingMessage,
  payPal,
  masterCard,
  visa,
  bannerOk,
  quotationMarks,
  bannerWoman,
  iconPay,
  iconApp,
  iconBag,
  iconShipping,
  iconBetterPrice,
  iconMedicines,
  bolsas,
  whatsAppIcon,
  productDefault,
  iconRecipe,
  amex,
  cdsoLogo,
  google,
  facebook,
  womenWithMask,
  cdsoCard,
  isanaPlus,
  mercadoPagoIcon,
  linkedinIcon,
  isanaPlusSmall,
  plusIcon
};

export default images;
