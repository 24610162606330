/* eslint-disable import/prefer-default-export */
export const getPaymentStatus = (status) => {
  switch (status) {
    case 'approved':
      return 'Autorizado';
    default:
      return 'Pendiente';
  }
};

export const getPaymentStatusColor = (status, classes) => {
  switch (status) {
    case 'approved':
      return classes.paid;
    default:
      return classes.pending;
  }
};
