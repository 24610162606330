import { createTheme } from '@material-ui/core/styles';

/*
Name colors taken from:
https://chir.ag/projects/name-that-color/
*/
const Colors = {
  black: '#000000',
  blueViolet: '#754DFC',
  balticSea: '#262529',
  cornflowerBlue: '#7F77F7',
  electricViolet: '#AD01D5',
  gray: '#707070',
  grayViolet: '#F4F1F1',
  heliotrope: '#CC6DEF',
  honeyFlower: '#691E77',
  ilusion: '#F19BC3',
  lavender: '#C965EC',
  lowGray: '#FAFAFA',
  lowPurple: '#F7F7F7',
  martinique: '#2F2748',
  pastelPink: '#FFD1D8',
  purpleBlue: '#7754FF',
  purpleHeart: '#B226D7',
  radicalRed: '#FF4568',
  scarpaFlow: '#504B5F',
  simpleWhite: '#FFFFFF',
  snuff: '#EEE3F1',
  softBlue: '#DBE9FF',
  softPurlple: '#EFEBFF',
  softViolet: '#C453E2',
  wewak: '#F4A1B4',
  wildSand: '#F4F4F4',
  transparentBlue: '#D9D8FF',
  mintZest: '#C3FFE3',
  fancyFlamingo: '#FFB1B1',
  iceLemon: '#FFFDC1',
  goodLuck: '#8BCE90',
  carteBlanche: '#EDEDFF',
  blueHepatica: '#6A60ED',
  redIsana: '#FE0029',
  redBackIsana: '#FE516B',
  facebookColor: '#3A56A1',
  googleColor: '#EB4530',
  affair: '#723B79',
  wildBlueYonder: '#888CB4',
  goldCdso: '#B8771C',
  goldGradiantCdso: '#E9D983',
  athensGray: '#E5E8EB',
  doveGray: '#707070',
  solidGray: '#616161'
};
const fontQuicksand = {
  fontFamily: 'quicksand',
  fontWeight: 'bold'
};
const theme = createTheme({
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    h1: fontQuicksand,
    h2: fontQuicksand,
    h3: fontQuicksand,
    h4: fontQuicksand,
    h5: fontQuicksand,
    h6: fontQuicksand,
    p: {
      fontFamily: 'roboto'
    }
  },
  palette: {
    primary: {
      main: Colors.electricViolet
    },
    secondary: {
      main: Colors.martinique
    }
  }
});

export { Colors, theme };
