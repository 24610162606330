import {
  Breadcrumbs,
  Button,
  Typography,
  Grid,
  Container,
  CircularProgress,
  useMediaQuery,
  Box
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import styles from './styles';
import images from '../../assets/images';
import ProductDescription from '../../components/ProductDescription';
import PaymentMethodsBanner from '../../components/PaymentMethodsBanner';
import { details } from '../../services/products';
import DetailNumberItems from './DetailNumberItems';
import { STOCK_PARAMS } from '../../constants';
import SnackbarNote from '../../components/SnackbarNote';
import SimilarProducts from './SimilarProducts';
import WeGotItForYouForm from './WeGotItForYouForm';
import PublicPrice from '../../components/PublicPrice';
import { scrollTop, handleTypeOfPromotion } from '../../helper';
import getCurrencyFormat from '../../utils/getCurrencyFormat';
import AddToShoppingCartButton from '../../components/AddToShoppingCartButton';

const ProductDetail = () => {
  const classes = styles();
  const [productStock, setProductStock] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { _id } = useParams();
  const [open, setOpen] = useState(false);
  const [success, setsuccess] = useState(true);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const selector = useSelector((state) => state.customer);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getProductById = async (productId) => {
    setLoading(true);
    try {
      const {customer} = selector
      let referralCode = ''
      if (customer && customer.referralCode) {
        referralCode = customer.referralCode
      }
      const response = await details(productId, referralCode);
      setProduct(response);
      setProductStock(response.stock);
      setLoading(false);
    } catch (error) {
      // TODO error notification
    }
  };

  useEffect(() => {
    getProductById(_id);
    scrollTop();
  }, [_id]);

  const handleOnAddItem = () => {
    if (quantity < productStock) {
      setQuantity(quantity + 1);
    }
  };

  const handleOnSubstractItem = () => {
    if (quantity !== 1) {
      setQuantity(quantity - 1);
    }
  };

  const backToProducts = () => {
    navigate('/productos');
  };

  /**
   * Get discount price with public price
   * @param {number} publicPrice Public price
   * @param {number} price Price
   * @returns number
   */
  const getDiscountPublicPrice = (publicPrice, price) => {
    if (!publicPrice || !price) {
      return '$0.00';
    }
    const result = parseFloat(publicPrice) - parseFloat(price);
    return getCurrencyFormat(result);
  };

  const getPercent = (publicPrice, price) => {
    const resultValue = parseFloat(publicPrice) - parseFloat(price);
    const resulPercent = parseInt((resultValue * 100) / publicPrice, 10);
    return resulPercent;
  };

  /**
   * Validate correct discount to show
   * @param {number} publicPrice Public price
   * @param {number} price Price
   * @returns number
   */
  const isValidPublicPrice = (publicPrice, price) => {
    if (!publicPrice) {
      return false;
    }

    if (publicPrice < price) {
      return false;
    }

    const resulPercent = getPercent(publicPrice, price);

    if (resulPercent < 1) return false;

    return true;
  };


  const getDiscountPublicPercentage = (publicPrice, price) => {
    if (!publicPrice || !price) {
      return '%';
    }

    const resulPercent = getPercent(publicPrice, price);

    return `- ${resulPercent} %`;
  };

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12} elevation={1} className={classes.bar}>
          <Button
            className={classes.backButton}
            color="inherit"
            startIcon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"
                  fill="rgba(199,85,218,1)"
                />
              </svg>
            }
            onClick={backToProducts}
          >
            Regresar
          </Button>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className={classes.link} to="/productos">
              Medicamentos
            </Link>
            <Link className={classes.link} to={`/productos/${_id}`}>
              Detalle
            </Link>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} className={classes.boxCart} elevation={1}>
          {loading ? (
            <div className={classes.progress}>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <div className={classes.detail}>
              <div className={classes.imageBox}>
                <img
                  className={classes.imageProduct}
                  src={product.imageUrl || images.productDefault}
                  alt=""
                />
              </div>
              <div className={classes.informationBox}>
                <Box className={classes.nameBox}>
                  <Typography className={classes.productName}>{product.name}</Typography>
                  {isValidPublicPrice(product.publicPrice, product.price) && (
                    <div className={classes.promotion}>
                      {getDiscountPublicPercentage(product.publicPrice, product.price)}
                    </div>
                  )}
                </Box>
                <div className={classes.subtitleBox}>
                  <ProductDescription productData={product} />
                </div>
                {Boolean(product.offers.length) && (
                  <div className={classes.subtitleBox}>
                    <Typography  className={classes.plusText}>
                      Con &nbsp;
                    </Typography>
                    <Typography className={classes.isanaText}>iSana</Typography> 
                    <Typography className={classes.plusText}>+</Typography>
                    <Typography  className={classes.promo}>: &nbsp;</Typography>

                    <Typography className={classes.promo}>
                    {handleTypeOfPromotion(product.offers[product.offers.length -1].name).toLowerCase()} 
                    </Typography>
                  </div>
                )}
                <div>
                  <div>
                    {Boolean(product.price !== 0) && (
                      <>
                        <Grid container alignItems="center">
                          <PublicPrice publicPrice={product.publicPrice} price={product.price} />
                          <Typography className={classes.programmedPrice}>
                            {getCurrencyFormat(product.price)}
                          </Typography>
                        </Grid>
                        {isValidPublicPrice(product.publicPrice, product.price) && (
                          <Typography className={classes.textDiscountPrice}>
                            Ahorro {getDiscountPublicPrice(product.publicPrice, product.price)}{' '}
                          </Typography>
                        )}
                      </>
                    )}
                  </div>
                  {product.requireRecipe && (
                    <div className={classes.requireInstructionBox}>
                      <Typography className={classes.requireInstructionText}>
                        Este medicamento requiere receta médica. Para que podamos realizar tu compra
                        tendrás que enviarnos tu receta médica por WhatsApp al (55) 1329 9099 junto
                        con el número de orden.
                      </Typography>
                    </div>
                  )}
                  {Boolean(productStock > STOCK_PARAMS.LOW_STOCK) && (
                    <div className={classes.productOnStock}>
                      <Typography className={classes.onStockText}>Producto disponible</Typography>
                    </div>
                  )}

                  {Boolean(
                    productStock < STOCK_PARAMS.LOW_STOCK && productStock > STOCK_PARAMS.EMPTY
                  ) && (
                    <div className={classes.fewProductOnStock}>
                      <Typography className={classes.onStockText}>
                        Poca o nula disponibilidad
                      </Typography>
                    </div>
                  )}
                  {Boolean(productStock <= STOCK_PARAMS.EMPTY) && (
                    <WeGotItForYouForm productId={_id} />
                  )}
                </div>
                {Boolean(productStock > STOCK_PARAMS.EMPTY) && (
                  <div className={classes.boxPoint}>
                    <DetailNumberItems
                      quantity={quantity}
                      handleOnAddItem={handleOnAddItem}
                      handleOnSubstractItem={handleOnSubstractItem}
                    />
                    <AddToShoppingCartButton
                      product={product}
                      setsuccess={setsuccess}
                      onClickButton={handleClick}
                      text={matches ? 'Agregar' : 'Agregar al carrito'}
                      detail
                      quantity={quantity}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={classes.boxInfo}>
            <PaymentMethodsBanner />
          </div>
        </Grid>
        {Boolean(product.categoryId) && <SimilarProducts categoryId={product.categoryId._id} />}
        <Grid item xs={12} className={classes.iSanaWorkContainer}>
          <div className={classes.boxIconTitle}>
            <Typography className={classes.iconTitle}>¿Como funciona iSana?</Typography>
          </div>
          <div className={classes.iconContainer}>
            <div className={classes.boxIcon}>
              <img className={classes.icon} src={images.phoneIcon} alt="phone" />
              <Typography className={classes.textIcon}>
                Agrega a tu carrito los medicamentos que necesitas desde nuestro sitio o desde la
                app
              </Typography>
            </div>
            <div className={classes.boxIcon}>
              <img className={classes.icon} src={images.searchIcon} alt="phone" />
              <Typography className={classes.textIcon}>
                Nosotros los buscamos con los mejores proveedores para darte excelentes precios
              </Typography>
            </div>
            <div className={classes.boxIcon}>
              <img className={classes.icon} src={images.packageIcon} alt="phone" />
              <Typography className={classes.textIcon}>
                Programa tu entrega para el día siguiete y tu envío será GRATIS
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <SnackbarNote onOpen={open} onClose={handleClose} success={success} />
    </Container>
  );
};

export default ProductDetail;
