import React, { useEffect } from 'react';
import { Grid, Typography, Box, Button, Container } from '@material-ui/core';
import { CardGiftcard, AttachMoney } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Title from '../../../../components/Title';
import styles from './styles';
import { scrollTop } from '../../../../helper';
import images from '../../../../assets/images';

const CdsoAboutUs = ({ customer }) => {
  const classes = styles();
  const benefits = [
    {
      benefit: 'Piezas de regalo',
      icon: <CardGiftcard />
    },
    {
      benefit: 'Precios Irresistibles',
      icon: <AttachMoney />
    },
    {
      benefit: 'Hasta 25% de descuento directo',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.51em"
          height="1.5rem"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m18.5 3.5l-15 15l2 2l15-15M7 4a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m10 10a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
          />
        </svg>
      )
    }
  ];

  const redirectPerCase = () => {
    if (customer.cdsoCardNumber) {
      return '/programas-de-lealtad/circulo-salud-oro/perfil';
    }
    if (!customer.name) {
      return '/signup';
    }
    return '/programas-de-lealtad/circulo-salud-oro/registrar-tarjeta';
  };

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Title text="iSana+" />
          <Box className={classes.containerInfo}>
            <Typography className={classes.info}>
              iSana+ es la tarjeta de lealtad potenciada por el programa de beneficios del{' '}
              <a
                target="_blank"
                href="https://www.circulodelasalud.mx/farmacia/circulo-oro/"
                rel="noreferrer"
                className={classes.linkCdso}
              >
                Círculo de la Salud Oro
              </a>
              , la única multitarjeta que ayuda a los pacientes a cumplir con el tratamiento
              prescrito por el médico; recibiendo beneficios disponibles en las farmacias afiliadas
              en todo el país.
            </Typography>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography className={classes.infoCdso}>
                  Círculo de la Salud Oro cuenta con más de 700 medicamentos con piezas gratis y
                  beneficios exclusivos.
                </Typography>
              </Grid>
              <Grid item  xs={12} md={4}>
                <Box className={classes.logos}>
                  <img className={classes.logoCdso} src={images.cdsoLogo} alt="cdso" />
                </Box>
              </Grid>
            </Grid>

            <Typography className={classes.benefits}>Beneficios para ti</Typography>
            <Box className={classes.benefitsBox}>
              {benefits &&
                benefits.map((benefit) => (
                  <Box className={classes.item} key={benefit.benefit}>
                    <Typography className={classes.textBenefit}>{benefit.benefit}</Typography>
                    <Typography className={classes.icon}>{benefit.icon}</Typography>
                  </Box>
                ))}
            </Box>
          </Box>
            <Box className={classes.benefitsBox}>
              <Link className={classes.link} to={redirectPerCase()}>
                <Button className={classes.button}>¡Adquiere tu tarjeta gratis aquí!</Button>
                <Typography className={classes.fixText}>
                  ( Te toma menos de un minuto 😉 )
                </Typography>
              </Link>
            </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

CdsoAboutUs.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    cdsoCardNumber: PropTypes.string
  })
};

CdsoAboutUs.defaultProps = {
  customer: {
    name: '',
    lastName: '',
    cdsoCardNumber: ''
  }
};

const mapsStateToProps = (state) => {
  const { customer } = state.customer;
  return { customer };
};

export default connect(mapsStateToProps, {})(CdsoAboutUs);
