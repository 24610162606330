/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Typography,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { EditSharp, Mail, Phone } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AvatarUser from '../../components/AvatarUser';
import Title from '../../components/Title';
import styles from './styles';
import { resendVerificationEmail } from '../../services/auth';
import { updateCustumerData } from '../../reducers/customer/actions';
import SnackbarNote from '../../components/SnackbarNote';
import Crumbs from '../../components/Crumbs';
import EditPofile from './EditPofile';
import MyAddresses from './MyAddresses';
import MyLoyaltyPrograms from './MyLoyaltyPrograms';
import ReferralCode from './ReferralCode';

const MyProfile = ({ customer, updateCustumerData, account }) => {
  const classes = styles();
  const navigate = useNavigate();
  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openResend, setOpenResend] = useState(false);
  const [success, setsuccess] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);
  const [openModalEmailNotVerified, setOpenModalEmailNotVerified] = useState(false);

  const routes = [
    {
      link: 'account',
      name: 'Mi Cuenta'
    },
    {
      link: 'perfil',
      name: 'Mi Perfil'
    }
  ];

  /**
   * handleClickUpdate
   *  open the snackbar note when user is updated
   * @returns {boolean} true
   */
  const handleClickUpdate = () => {
    setOpen(true);
  };

  /**
   * handleCloseUpdate
   *  close the snackbar note when user is updated
   * @returns {boolean} false
   */
  const handleCloseUpdate = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  /**
   * getUpdatedCustomer
   * update custumer data and get the new info
   * @param {Array}
   * @returns {Array}
   */
  const getUpdatedCustomer = async (infoUpdated) => {
    updateCustumerData(infoUpdated);
  };  

  useEffect(() => {
    setOpenModalEmailNotVerified(!customer.emailVerified);
  }, [customer]);

  const onResend = () => {
    setDisabledButton(true);
    const params = {
      email: account.email
    };
    resendVerificationEmail(params)
      .then(() => {
        setsuccess(true);
        setOpenResend(true);
      })
      .catch(() => {
        setsuccess(false);
        setOpenResend(true);
        setDisabledButton(false);
      });
  };

  /**
   * onEditProfile
   * handles if the edit profile modal opens or closes
   */
  const onEditProfile = () => {
    setEditProfileOpen(!editProfileOpen);
  };

  /**
   * put a new value to success state
   * @param {boolean} value 
   */
  const handleSuccess = (value) => {
    setsuccess(value);
  };

  return (
    <Container maxWidth="xl">
      {!Object.keys(customer).length && navigate('/')}
      <Modal open={openModalEmailNotVerified}>
        <>
          <Box className={classes.modal}>
            <Title text="Verifica tu email 😬" />
            <Typography className={classes.textModal}>
              Para poder editar tu perfil, agregar direcciones y modificarlas debes verificar tu
              email.
            </Typography>
            <Typography className={classes.textModal}>
              {' '}
              En tu bandeja de entrada encontrarás un mensaje nuestro que te ayudará a hacerlo.
            </Typography>
            <div className={classes.divModal}>
              <Link to="/home">
                <Button color="primary" variant="contained">
                  Ok
                </Button>
              </Link>
              <Button
                onClick={onResend}
                disabled={disabledButton}
                color="primary"
                variant="contained"
              >
                Necesito un nuevo email
              </Button>
            </div>
          </Box>
          <SnackbarNote
            successMessage="Te enviamos un correo de verificación, revisalo. 📨"
            onOpen={openResend}
            onClose={handleCloseUpdate}
            success={success}
          />
        </>
      </Modal>
      <Grid container className={classes.container}>
        <Box className={classes.breadcrumbBox}>
          <Crumbs routes={routes} />
        </Box>
      </Grid>
      {customer ? (
        <Grid container>
          <SnackbarNote
            successMessage="Datos actualizados correctamente 👍"
            onOpen={open}
            onClose={handleCloseUpdate}
            success={success}
          />
          <Grid item xs={12} className={classes.profileContainer}>
            <Box className={classes.profileFlex}>
              <Box className={classes.profileBanner}>
                <AvatarUser lg />
                <Typography className={classes.nameUser}>
                  {`${customer.name} ${customer.lastName} ${customer.mothersLastName}`}
                </Typography>
              </Box>
              <Tooltip title="Editar">
                <IconButton onClick={onEditProfile}>
                  <EditSharp fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className={classes.directonRow}>
              <Typography className={classes.textProfile}>
                <Mail fontSize="small" /> {account.email}
              </Typography>
              {customer.phoneNumber && (
                <Typography className={classes.textProfile}>
                  <Phone fontSize="small" /> {customer.phoneNumber}
                </Typography>
              )}
            </Box>
            <EditPofile
              getUpdatedCustomer={getUpdatedCustomer}
              open={editProfileOpen}
              onClose={onEditProfile}
              customer={customer}
              handleSuccess={handleSuccess}
              handleClickUpdate={handleClickUpdate}
            />
          </Grid>
          <Grid className={classes.form} container spacing={1}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
                <MyAddresses getUpdatedCustomer={getUpdatedCustomer} customer={customer}/>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
                <MyLoyaltyPrograms customer={customer}/>
            </Grid>
          </Grid>
          <Grid className={classes.form} container spacing={1} justifyContent='flex-start'>
            <Grid item  lg={6} md={12} sm={12} xs={12}>
                <ReferralCode />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Box className={classes.circularBox}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

MyProfile.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    mothersLastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    _id: PropTypes.string,
    emailVerified: PropTypes.bool,
    cdsoCardNumber: PropTypes.string
  }),
  updateCustumerData: PropTypes.func,
  account: PropTypes.shape({
    email: PropTypes.string
  })
};

MyProfile.defaultProps = {
  customer: {
    name: '',
    lastName: '',
    mothersLastName: '',
    phoneNumber: '',
    _id: '',
    emailVerified: false,
    cdsoCardNumber: ''
  },
  updateCustumerData: () => {},
  account: {
    email: ''
  }
};

const mapsStateToProps = (state) => {
  const { account } = state.auth;
  const { customer } = state.customer;
  return { customer, account };
};
export default connect(mapsStateToProps, { updateCustumerData })(MyProfile);
