import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles(() => ({
  boxFlex: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    textAlign: 'center'
  },
  cardFix: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '.5rem',
    maxWidth: '23rem',
    padding: '1rem',
    minHeight: '25rem'
  },
  title: {
    color: Colors.balticSea,
    fontSize: '1.7rem',
    textAlign: 'center',
    margin: '1rem'
  },
  subTitle: {
    textAlign: 'center',
    fontSize: '.9rem'
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  decoration: {
    position: 'absolute',
    top: '-.5rem',
    width: '9rem',
    paddingRight: '6rem'
  },
  circleImg: {
    borderRadius: '50%'
  }
}));

export default styles;
