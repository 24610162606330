/* eslint-disable  no-console */
import {
  DELETE_ITEM_SHOPPING_CART,
  SET_COUPON_SHOPPING_CART,
  SET_ITEM_SHOPPING_CART,
  UPDATE_QUANTITY_SHOPPING_CART,
  UPDATE_SHOPPING_CART_DATA,
  GET_SHOPPING_CART_DATA,
  DELIVERY_DATA_WEBSITE_SET,
  SHOPPING_CART_UPDATE,
  DELETE_SHOPPING_CART_DATA,
  SET_CLIENT_SHOPPING_CART,
  GET_BONUS_PRODUCTS_START,
  GET_BONUS_PRODUCTS_SUCCESS,
  GET_BONUS_PRODUCTS_FAIL,
  UPDATE_METADATA_ITEMS,
  SET_SHOPPINGCART_ERROR,
  SET_BONUS_RECENTLY_UPDATED,
  CHANGES_DISABLED,
  SET_CDSO_BONUS,
  REMOVE_SHOPPING_CART_DATA
} from './types';
import {
  updateShoppingCart,
  getShoppingCartById,
  createShoppingCart,
  destroyShoppingCart,
  getShoppingCartBySomeIntegration
} from '../../services/shoppingCart';
import { getBonusProducts } from '../../services/cdso';
import { SHOPPING_CART_ACTIONS, SHOP_ID_STORAGE } from '../../constants';

const { ITEM_SET, ITEM_DELETE, SET_CLIENT } = SHOPPING_CART_ACTIONS;

export const addItem = (product) => (dispatch) => {
  dispatch({ type: SET_ITEM_SHOPPING_CART, payload: product });
};

export const deleteItem = (id) => (dispatch) => {
  dispatch({ type: DELETE_ITEM_SHOPPING_CART, payload: id });
};

export const updateQuantity = (operation, id) => (dispatch) => {
  dispatch({ type: UPDATE_QUANTITY_SHOPPING_CART, payload: { id, operation } });
};

// TODO: Validate if cupon is valid
export const setCoupon = (coupon) => (dispatch) => {
  dispatch({ type: SET_COUPON_SHOPPING_CART, payload: coupon });
};

export const setErrorShoppingCart = (value) => (dispatch) => {
  dispatch({ type: SET_SHOPPINGCART_ERROR, payload: value });
};

const getBodyOnItemSet = (data, shoppingCartData) => {
  const { items } = shoppingCartData;
  let body = {};
  if (shoppingCartData._id) {
    const itemExits = items.find((item) => item.productId === data.productId);
    if (itemExits) {
      const itemsList = items.map((item) => {
        if (item.productId === data.productId) {
          // eslint-disable-next-line no-unused-vars
          const { quantity } = item;
          const itemUpdated = {
            ...item,
            quantity: data.quantity,
            discount: data.discount || 0,
            subtotal: (data.price - data.discount) * quantity
          };
          return itemUpdated;
        }
        return item;
      });
      body = {
        ...shoppingCartData
      };
      body.items = [...itemsList];
    } else {
      const newItem = {
        product: {
          ...data,
          offers: [],
          _id: data.productId
        },
        unitPrice: data.price,
        quantity: data.quantity,
        productId: data.productId,
        discount: data.discount || 0
      };
      items.push(newItem);
      body = {
        ...shoppingCartData
      };
      body.items = [...items];
    }
  } else {
    const itemsBody = items.map((item) => {
      const newItem = {};
      newItem.productId = item.productId || item._id;
      newItem.quantity = item.quantity || 1;
      newItem.discount = item.discount || 0;
      newItem.price = item.unitPrice;
      newItem.priceHistoryId = item.product.priceHistoryId;
      newItem.metadata = item.product.metadata || {};
      return newItem;
    });
    body = {
      ...shoppingCartData
    };
    body.items = [...itemsBody];
  }
  return body;
};

const setBodyAddressData = (data) => {
  const body = {
    deliveryAddress: {},
    deliveryContact: {}
  };

  body.deliveryAddress.street = data.street;
  body.deliveryAddress.exteriorNumber = data.exteriorNumber;
  body.deliveryAddress.interiorNumber = data.interiorNumber;
  body.deliveryAddress.postalCode = data.postalCode;
  body.deliveryAddress.city = data.city;
  body.deliveryAddress.state = data.state;
  body.deliveryAddress.neighborhood = data.neighborhood;
  body.deliveryAddress.addressNote = data.addressNote;

  body.deliveryContact.name = data.name;
  body.deliveryContact.phoneNumber = data.phoneNumber;
  body.deliveryContact.email = data.email;

  return body;
};

/**
 * Function to update shopping cart data
 * @param {object} data shopping cart data
 * @param {string} action action to describe data to update
 * @returns dispatch function
 */
export const updateShoppingCartData = () => async (dispatch, getState) => {
  try {
    const { metadata } = getState().shoppingCart;
    let body = {};
    const { itemsToUpdate, action } = metadata;
    switch (action) {
      case ITEM_SET:
        body = {
          items: itemsToUpdate
        };
        break;
      case ITEM_DELETE:
        // eslint-disable-next-line prefer-destructuring
        body = itemsToUpdate[0];
        break;
      default:
        break;
    }
    const shoppingCartId = sessionStorage.getItem(SHOP_ID_STORAGE);
    const response = await updateShoppingCart(shoppingCartId, body, metadata.action);
    if(!response.data.metadata) {
      response.data.metadata = {}
    }
    dispatch({ type: UPDATE_SHOPPING_CART_DATA, payload: response.data });
    dispatch({
      type: UPDATE_METADATA_ITEMS,
      payload: {
        bonusRecentlyUpdated: metadata.bonusRecentlyUpdated,
        itemsToUpdate: [],
        action: null
      }
    });

    return true;
  } catch (error) {
    console.error('[upadteShoppingCartData]: ', error);
    dispatch({ type: SET_SHOPPINGCART_ERROR, payload: true });
    throw error;
  }
};

/**
 * Function to update shopping cart data on redux
 * @param {object} data shopping cart data
 * @param {string} action action to describe data to update
 * @returns dispatch function
 */
export const updateCheckoutShoppingCart = (data, action) => async (dispatch, getState) => {
  try {
    const { shoppingCartData } = getState().shoppingCart;
    let body = {};
    switch (action) {
      case SHOPPING_CART_UPDATE:
        body = data;
        break;
      case DELIVERY_DATA_WEBSITE_SET:
        body = setBodyAddressData(data);
        break;
      case SET_CDSO_BONUS: {
        body = {
          action: SET_CDSO_BONUS,
          items: data
        }
        break;
      }
      default:
        break;
    }
    let shoppingCartId = shoppingCartData._id;
    if (!shoppingCartId) {
      shoppingCartId = sessionStorage.getItem(SHOP_ID_STORAGE);
    }
    const response = await updateShoppingCart(shoppingCartId, body, action);
    console.log('response', response)
    if(!response.data.metadata) {
      response.data.metadata = {}
    }
    dispatch({ type: UPDATE_SHOPPING_CART_DATA, payload: response.data });
    return true;
  } catch (error) {
    console.error('[upadteShoppingCartData]: ', error);
    dispatch({ type: SET_SHOPPINGCART_ERROR, payload: true });
    throw error;
  }
};

/**
 * Function to update shopping cart data on redux
 * @param {object} data shopping cart data
 * @param {string} action action to describe data to update
 * @returns dispatch function
 */
export const updateItemsInReduxState = (data, action) => async (dispatch, getState) => {
  try {
    const { shoppingCartData, metadata } = getState().shoppingCart;
    let body = {};
    switch (action) {
      case ITEM_SET:
        body = getBodyOnItemSet(data, shoppingCartData);
        body.changes = true;
        console.log('bodyOnitem', body)
        if (body) {
          metadata.itemsToUpdate.push(data);
          metadata.action = action;
        }
        break;
      case ITEM_DELETE:
        // eslint-disable-next-line no-case-declarations
        const { items } = shoppingCartData;
        // eslint-disable-next-line no-case-declarations
        const itemsUpdated = items.filter((item) => item.productId !== data.productId);
        body = {
          ...shoppingCartData,
          items: itemsUpdated
        };
        body.changes = true;
        if (body) {
          metadata.itemsToUpdate.push(data);
          metadata.action = action;
        }
        break;
      default:
        break;
    }
    console.log('body', body)
    dispatch({ type: UPDATE_SHOPPING_CART_DATA, payload: body });
    dispatch({ type: UPDATE_METADATA_ITEMS, payload: metadata});
    return true;
  } catch (error) {
    dispatch({ type: SET_SHOPPINGCART_ERROR, payload: true });
    throw error;
  }
};

/**
 * Function to create shopping cart data
 * @param {object} data shopping cart data
 * @param {string} action action to describe data to update
 * @returns dispatch function
 */
export const createShoppingCartData =
  (cdsoCard = null) =>
  async (dispatch, getState) => {
    try {
      const { shoppingCartData } = getState().shoppingCart;
      const body = getBodyOnItemSet({}, shoppingCartData);
      if (cdsoCard) {
        body.metadata = {
          cdsoCard
        };
      }
      const response = await createShoppingCart(body);
      if(!response.data.metadata) {
        response.data.metadata = {}
      }
      dispatch({ type: UPDATE_SHOPPING_CART_DATA, payload: response.data });
      if (response.data._id) {
        sessionStorage.setItem(SHOP_ID_STORAGE, response.data._id);
      } else {
        sessionStorage.removeItem(SHOP_ID_STORAGE);
      }
      return true;
    } catch (error) {
      console.error('[createShoppingCartData]: ', error);
      dispatch({ type: SET_SHOPPINGCART_ERROR, payload: true });
      throw error;
    }
  };

/**
 * Function to create shopping cart data on redux
 * @param {object} data shopping cart data
 * @param {string} action action to describe data to update
 * @returns dispatch function
 */
export const setItemsInReduxState = (data) => async (dispatch, getState) => {
  try {
    const { shoppingCartData } = getState().shoppingCart;
    const { productId, price, quantity, discount } = data;
    const items = shoppingCartData.items.filter((item) => item.productId !== productId);
    const newBody = {
      items: [
        ...items,
        {
          product: {
            ...data,
            offers: [],
            _id: productId
          },
          unitPrice: price,
          quantity,
          productId,
          discount: discount || 0,
          subtotal: price * quantity
        }
      ]
    };
    newBody.changes = true;
    dispatch({ type: UPDATE_SHOPPING_CART_DATA, payload: newBody });
    return true;
  } catch (error) {
    console.error('[createShoppingCartData]: ', error);
    dispatch({ type: SET_SHOPPINGCART_ERROR, payload: true });
    throw error;
  }
};

/**
 * Function to request shopping cart data and set in redux state
 */
export const getShoppingCartData = (id) => async (dispatch) => {
  try {
    const response = await getShoppingCartById(id);
    dispatch({ type: GET_SHOPPING_CART_DATA, payload: response.data });
    return response.data;
  } catch (error) {
    sessionStorage.removeItem(SHOP_ID_STORAGE);
    throw error;
  }
};

/**
 * Function to get shopping cart data from some integration
 * @param {string} id shopping cart id
 * @returns Promise
 */
export const getShoppingCartBySomeIntegrationAction = (id) => async (dispatch) => {
  const response = await getShoppingCartBySomeIntegration(id);
  dispatch({ type: GET_SHOPPING_CART_DATA, payload: response.data });
};

/**
 * Function to set client in shopping cart selected
 * @param {string} clientId client id
 * @returns object
 */
export const setClientShoppingCart = (shoppingCartId, clientId) => async (dispatch) => {
  const response = await updateShoppingCart(shoppingCartId, { clientId }, SET_CLIENT);
  dispatch({ type: SET_CLIENT_SHOPPING_CART, payload: response.data });
  return true;
};

/**
 * Function to create Order data
 * @param {string} id shopping Cart Id
 * @returns dispatch function and data
 */
export const deleteShoppingCartData = (id) => async (dispatch) => {
  await destroyShoppingCart(id);
  sessionStorage.removeItem(SHOP_ID_STORAGE);
  dispatch({ type: DELETE_SHOPPING_CART_DATA });
  return true;
};

/**
 * Function to create Order data
 * @param {string} id shopping Cart Id
 * @returns dispatch function and data
 */
export const removeShoppingCartData = () => async (dispatch) => {
  sessionStorage.removeItem(SHOP_ID_STORAGE);
  dispatch({ type: REMOVE_SHOPPING_CART_DATA });
};


/**
 * Get bonus in products with offers by cdso card number
 * @param {string} cardNumber CDSO card number
 * @param {array} products Products list
 * @returns Promise
 */
export const getBonusInProducts = (cardNumber, products) => async (dispatch) => {
  try {
    dispatch({ type: GET_BONUS_PRODUCTS_START });
    const response = await getBonusProducts(cardNumber, products);
    dispatch({ type: GET_BONUS_PRODUCTS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: GET_BONUS_PRODUCTS_FAIL });
  }
};

export const setBonusRecentlyUpdatedValidation = (data) => async (dispatch) => {
  dispatch({ type: SET_BONUS_RECENTLY_UPDATED, payload: data });
};

export const setChangesDisabled = (data) => async (dispatch) => {
  dispatch({ type: CHANGES_DISABLED, payload: data });
};

export const createShoppingCartByInsurancesData = (data) => async (dispatch) =>  {
  try {
    const { products, IndicadorDePago, NombrePaciente } = data;
    const body = getBodyOnItemSet({}, {items: products});
    body.metadata = {
      originServices: 'medikit',
      paymentIndicator: IndicadorDePago,
      axaUser: NombrePaciente
    }
    const response = await createShoppingCart(body);
    console.log('createShoppingCartByInsurancesData', response)
    dispatch({ type: GET_SHOPPING_CART_DATA, payload: response.data });
  } catch (error) {
    console.log(error)
  }
}
