import React, { useEffect, useState } from 'react';
import {
  Badge, Box, Button, Drawer, Grid, IconButton, Paper, Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { ShoppingCart } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import styles from './styles';
import CartList from './CartList';
import { OAUTH_CODE } from '../../../../constants';
import SubtotalPrice from '../../../../components/SubtotalPrice';

const CartWindow = ({ handler, modalHanlder, shoppingCartData }) => {
  const classes = styles();
  const [isActiveButton, setIsActiveButton] = useState(true);
  const location = useLocation();

  // useEffect(() => {
  //   if (shoppingCartData.oauthId) {
  //     setIsActiveButton(shoppingCartData.oauthId.internalCode === OAUTH_CODE);
  //   }
    
  // }, [shoppingCartData.oauthId]);

  useEffect(() => {
    if (!location.pathname.includes('checkout') && !isActiveButton) {
      setIsActiveButton(true);
      return;
    }

    if (location.pathname.includes('checkout') && isActiveButton) {
      setIsActiveButton(false);
    }

  }, [location]);
  /**
   * Get number of items selected
   * @returns number
   */
  const getNumItems = () => shoppingCartData.items ? shoppingCartData.items.length : 0;

  return (
    isActiveButton && (
      <>
        <IconButton
          onClick={handler}
          aria-label="cart"
          className={`${classes.fixLinkDrawer} ${classes.buttonCar}`}
        >
          <Badge overlap='rectangular' badgeContent={getNumItems()} className={classes.badgeItems}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="25" height="25">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M15.366 3.438L18.577 9H22v2h-1.167l-.757 9.083a1 1 0 0 1-.996.917H4.92a1 1 0 0 1-.996-.917L3.166 11H2V9h3.422l3.212-5.562 1.732 1L7.732 9h8.535l-2.633-4.562 1.732-1zM18.826 11H5.173l.667 8h12.319l.667-8zM13 13v4h-2v-4h2zm-4 0v4H7v-4h2zm8 0v4h-2v-4h2z" />
            </svg>
          </Badge>
        </IconButton>
        <Drawer anchor='right' open={modalHanlder} onClose={handler} >
          <div className={classes.cart}>
            <Paper className={classes.cart}>
              {!getNumItems() && (
                <Grid className={classes.empty}>
                  <ShoppingCart className={classes.icon} />
                  <Typography className={classes.emptyText}>Tú carrito está vacío 😟</Typography>
                </Grid>
              )}

              {getNumItems() ? (
                <Box className={classes.cart}>
                  <Grid className={classes.cardBox}>
                    <CartList items={shoppingCartData.items} />
                  </Grid>
                  <Box className={classes.infoTotalBox}>
                  <Typography variant="subtitle2">
                    Envío: <strong>Por calcular</strong>
                  </Typography>
                  <Typography variant="h6">
                    Subtotal: <SubtotalPrice />
                  </Typography>
                  <Link className={classes.buttonLinkCheckout} to="/checkout" onClick={handler}>
                    <Button
                      className={classes.button}
                      fullWidth
                      color="primary"
                      variant="contained"
                      disabled={shoppingCartData.changes}
                    >
                      Proceder a comprar
                    </Button>
                  </Link>
                  </Box>
                </Box>
              ) : null}
            </Paper>
          </div>
        </Drawer>
      </>
    )
  );
};

const mapStateToProps = (state) => {
  const { shoppingCartData } = state.shoppingCart;
  return { shoppingCartData };
};

CartWindow.propTypes = {
  handler: PropTypes.func,
  modalHanlder: PropTypes.bool,
  shoppingCartData: PropTypes.shape({
    oauthId: PropTypes.shape({ internalCode: PropTypes.string }),
    subTotal: PropTypes.number,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        quantity: PropTypes.number
      })
    ),
    changes: PropTypes.bool
  })
};

CartWindow.defaultProps = {
  handler: () => {},
  modalHanlder: false,
  shoppingCartData: { items: [], subTotal: 0, oauthId: { internalCode: OAUTH_CODE }, changes: false }
};

export default connect(mapStateToProps)(CartWindow);
