import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../theme';

const styles = makeStyles(() => ({
  textfield: {
    marginBottom: '1rem'
  },
  title: {
    fontWeight: 'bold',
    color: Colors.blueHepatica,
    marginTop: '1rem',
    marginBottom: '0.4rem'
  },
  image: {
    width: '40%',
    marginLeft: '10rem'
  },
  warning: {
    display: 'flex',
    alignContent: 'center',
    marginTop: '1rem',
    color: Colors.radicalRed,
    fontWeight: 'bold'
  }
}));

export default styles;
