import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../theme';

const styles = makeStyles((theme) => ({
  card: {
    maxWidth: '43rem',
    display: 'flex',
    padding: '2rem',
    flexDirection: 'column',
    margin: '1rem',
    height: '16rem',
    [theme.breakpoints.down('md')]: {
      padding: '1rem'
    }
  },
  link: {
    textDecoration: 'none'
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  orderText: {
    color: Colors.blueHepatica,
    fontWeight: 'bold'
  },
  dataText: {
    fontWeight: 'bold'
  },
  address: {
    marginTop: '1rem',
    height: '5.2rem'
  },
  delivery: {
    color: Colors.goodLuck,
    fontWeight: 'bold'
  },
  canceled: {
    color: Colors.radicalRed,
    fontWeight: 'bold'
  },
  defaultStatus: {
    color: Colors.purpleHeart,
    fontWeight: 'bold'
  }
}));
export default styles;
