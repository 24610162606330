import { SET_ACCOUNT_DATA } from './types';

const INITIAL_STATE = {
  account: {},
  isGettingAccount: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACCOUNT_DATA:
      return { ...state, account: action.payload };
    default:
      return state;
  }
};
