import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

const PromoCard = ({
  image, promotion, name, validity, exception, key
}) => {
  const classes = styles();
  return (
    <div className={classes.boxFlex} key={key}>
      <Card className={classes.cardFix}>
        <CardContent>
          <div className={classes.promoBox}>
            <Typography className={classes.promoText}>{promotion}</Typography>
          </div>
          <img className={classes.image} src={image} alt="product" />
          <div>
            <Typography className={classes.title}>
              {name}
            </Typography>
            <Typography className={classes.subTitle}>
              {validity}
            </Typography>
            <Typography className={classes.subText}>
              {exception}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

PromoCard.propTypes = {
  image: PropTypes.string,
  promotion: PropTypes.string,
  name: PropTypes.string,
  validity: PropTypes.string,
  exception: PropTypes.string,
  key: PropTypes.string
};

PromoCard.defaultProps = {
  image: '',
  promotion: '',
  name: '',
  validity: '',
  exception: '',
  key: ''
};

export default PromoCard;
