import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: Colors.blueHepatica,
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5rem'
    }
  }
}));
export default styles;
