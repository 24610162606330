
import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow, CircularProgress
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ItemCartTable from './ItemCartTable';
import styles from './styles';
import FreePiece from './FreePiece';
import useAgreementCondition from '../../customHooks/useEgreementCondition';

const CartTable = ({ isEditable, shoppingCartData, customer }) => {
  const classes = styles();
  const [promotion, setPromotion] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const isAgreement  = useAgreementCondition({shoppingCartData})
 

  /**
   * onPromotionDetected
   * function that adds the number of items with promotions in the cart
   * @param {number} item 
   */
  const onPromotionDetected = (item) => {
    if (item >= 1) {
      setPromotion(promotion + 1);
    }
  }

  /**
   * handleOpenCloseModal
   * show and close modal cdso warning
   */
  const handleOpenCloseModal = () => {
    setShowModal(!showModal);
  }


  useEffect(() => {
    if (!customer.cdsoCardNumber && !(shoppingCartData.metadata && shoppingCartData.metadata.originServices)) {
      if (shoppingCartData.items.length !== 0) {
        shoppingCartData.items.forEach(item => {
          onPromotionDetected(item.product.offers.length);
        });
      }
    }
  }, [shoppingCartData, customer]);

  useEffect(() => {
    if (promotion >= 1) {
      setTimeout(() => {
        handleOpenCloseModal()
      }, 2000);
    }
  }, [promotion]);
  return (
    <>
      {shoppingCartData.items.length === 0 && (
        <div className={classes.box}>
          <CircularProgress />
        </div>
      )}
      {shoppingCartData.items && (
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellTitle} align="center">
                Producto
              </TableCell>
              <TableCell className={`${classes.cellTitle} ${classes.tableCell}`} align="center">
                Cantidad
              </TableCell>
              <TableCell className={`${classes.cellTitle} ${classes.tableCell}`} align="center">
               {shoppingCartData && isAgreement ? 'Precio unitario' : ''}
              </TableCell>
              <TableCell className={`${classes.cellTitle} ${classes.tableCell}`} align="center">
              {shoppingCartData && isAgreement ? 'Subtotal' : ''}
              </TableCell>
              <TableCell className={`${classes.cellTitle} ${classes.tableCell}`} align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {shoppingCartData.items.map((item) => (
              <>
              <ItemCartTable itemLength={shoppingCartData.items.length} key={item._id} item={item} isEditable={isEditable} showPromotion={!(shoppingCartData.metadata && shoppingCartData.metadata.originServices)} showPrices={shoppingCartData && isAgreement}/>
               { item.metadata && item.metadata.bonusDetails && item.metadata.bonusDetails.PiezasGratis > 0 ?  (
                 <FreePiece itemLength={shoppingCartData.items.length} key={item._id} item={item} bonus={item.metadata.bonusDetails} /> 
               ) : null }
                { item.metadata && item.metadata.bonusDetails && item.metadata.giftAplusB  ?  (
                 <FreePiece itemLength={shoppingCartData.items.length} key={item._id} item={{product: item.metadata.giftAplusB}} bonus={item.metadata.giftAplusB.metadata.bonusDetails} /> 
               ) : null }
              </>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { bonusProducts, shoppingCartData } = state.shoppingCart;
  const { customer } = state.customer;
  return { shoppingCartData, customer, bonusProducts };
};

CartTable.propTypes = {
  isEditable: PropTypes.bool,
  shoppingCartData: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number
      })
    ),
    metadata: {}
  }),
  customer: PropTypes.shape({
    name: PropTypes.string,
    cdsoCardNumber: PropTypes.string
  })
};

CartTable.defaultProps = {
  isEditable: false,
  shoppingCartData: { items: [], metadata: {} },
  customer: {
    name: '',
    cdsoCardNumber: ''
  }
};
export default connect(mapStateToProps, {})(CartTable);
