import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../theme';

const styles = makeStyles(() => ({
  image: {
    width: '10rem',
    height: '8rem'
  },
  card: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '15rem',
    height: '20rem',
    transform: 'translateZ(0)',
    margin: '1rem'
  },
  list: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center'
  },
  imageBox: {
    height: '9rem'
  },
  descriptionBox: {
    height: '3.4rem'
  },
  price: {
    fontWeight: 'bold'
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'hidden',
    overflowX: 'scroll',
    maxWidth: '60rem',
    height: '25rem'
  },
  listItems: {
    whiteSpace: 'nowrap',
    transform: 'translateZ(0)'
  },
  fabButton: {
    boxShadow: 'none',
    width: '2.2rem',
    height: '1.7rem'
  },
  fabIcon: {
    fontSize: '1rem'
  },
  descriptionProduct: {
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: '.5rem',
    width: '15rem'
  },
  iSanaWorkContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '4rem',
    background: Colors.simpleWhite,
    marginTop: '.5rem'
  },
  iconTitle: {
    fontWeight: 'bold'
  },
  link: {
    textDecoration: 'none'
  },
  boxStock: {
    height: '3rem'
  },
  noProductOnStock: {
    backgroundColor: Colors.lowPurple,
    padding: '0.2rem',
    borderRadius: '0.5rem'
  },
  textNoStock: {
    fontWeight: 'bold'
  }
}));
export default styles;
