/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Modal,
  TextField,
  Typography
} from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import React, { useState, useEffect, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import images from '../../assets/images';
import PasswordInput from '../../components/PasswordInput';
import useValidField from '../../customHooks/useValidField';
import styles from './styles';
import { FirebaseContext } from '../../customContext/FirebaseContext';
import noAuth from '../../customHocs/noAuth';

const Login = () => {
  const classes = styles();
  const [loginError, setLoginError] = useState(false);
  const [errorSocialEmail, setErrorSocialEmail] = useState(false);
  const [open, setOpen] = useState(false);
  const userEmail = useValidField({ type: 'email' });
  const { helperText, error, ...userPassword } = useValidField({ type: 'password' });
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });
  const { loginFirebase, loginWithGoogle, loginWithFacebook } = useContext(FirebaseContext);
  const [isValidRecaptcha, setIsValidRecaptcha] = useState(false);
  const navigate = useNavigate()

  const validate = (validator) => {
    if (validator.error) {
      return '';
    }
    return validator.value;
  };

  const getPropsValidate = (validator) => {
    const { value, setValue, ...validationProps } = validator;
    return validationProps;
  };

  useEffect(() => {
    setLoginData({
      email: validate(userEmail),
      password: validate(userPassword)
    });
  }, [userEmail.value, userPassword.value]);

  /**
   * Validate filled fields required to login
   * @returns boolean
   */
  const isInvalidLoginData = () => {
    const { email, password } = loginData;
    const validateRecaptcha = process.env.REACT_APP_MODE === 'production' ? isValidRecaptcha : true;
    if (!email || !password || !validateRecaptcha) return true;
    return false;
  };

  const onModal = () => {
    setOpen(!open);
  };

  const makeLogin = async () => {
    try {
      if (!isInvalidLoginData()) {
        const { email, password } = loginData;
        await loginFirebase(email, password);
      }
    } catch (error) {
      // TODO Notification error
      setLoginError(true);
    }
  };

  const loginGoogle = async () => {
    try {
      await loginWithGoogle();
      onModal();
    } catch (error) {
      if (
        error.toString() === 'FirebaseError: Firebase: Error (auth/popup-closed-by-user).' ||
        error.toString() === 'FirebaseError: Firebase: Error (auth/cancelled-popup-request).'
      ) {
        setOpen(false);
        return;
      }
      setOpen(false);
      setErrorSocialEmail(true);
    }
  };

  const loginFacebook = async () => {
    try {
      await loginWithFacebook();
      onModal();
    } catch (error) {
      if (
        error.toString() === 'FirebaseError: Firebase: Error (auth/popup-closed-by-user).' ||
        error.toString() === 'FirebaseError: Firebase: Error (auth/cancelled-popup-request).'
      ) {
        setOpen(false);
        return;
      }
      setOpen(false);
      setErrorSocialEmail(true);
    }
  };
  const handleChangeRecaptcha = (value) => setIsValidRecaptcha(value);

  return (
    <Container maxWidth="xl">
      <Grid container className={classes.container}>
        <Modal className={classes.modal} open={open}>
          <Box className={classes.boxModal}>
            <img src={images.iSanaMonochrome} alt="isana-logo" />
            <CircularProgress />
          </Box>
        </Modal>
        <Grid item md={6} sm={12} xs={12}>
          <div className={classes.box}>
            <div className={classes.form}>
              <Typography variant="h6" className={classes.title}>
                Iniciar sesión
              </Typography>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                size="small"
                type="email"
                className={classes.input}
                {...getPropsValidate(userEmail)}
              />
              <PasswordInput
                handlerField={getPropsValidate(userPassword)}
                helperText={helperText}
                error={error}
              />
              <Collapse in={loginError}>
                <Box className={`${classes.input} ${classes.boxWarning}`}>
                  <Typography className={classes.warningText}>
                    <ErrorOutline /> Email o contraseña incorrecto
                  </Typography>
                </Box>
              </Collapse>
              <Box className={classes.recaptcha}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA}
                  onChange={handleChangeRecaptcha}
                  className={classes.recaptcha}
                />
              </Box>
              <Button
                onClick={makeLogin}
                fullWidth
                variant="contained"
                disabled={isInvalidLoginData()}
                color="primary"
                className={`${classes.button} ${classes.input}`}
              >
                Iniciar sesión
              </Button>
              <div className={classes.controlBox}>
                <FormControlLabel control={<Checkbox defaultChecked />} label="recordarme" />
                <Button onClick={()=> {navigate('/signup')}} className={classes.button}>
                  Registrarme
                </Button>
              </div>
              <Typography variant="body2" className={classes.input}>
                ¿Olvidaste tu contraseña? Recuperala <Link href="/recover-password">aqui.</Link>
              </Typography>
              <Button
                onClick={loginGoogle}
                fullWidth
                variant="contained"
                className={`${classes.buttonGoogle} ${classes.input}`}
                startIcon={<img src={images.google} alt="" />}
              >
                Iniciar sesión con Google
              </Button>
              <Button
                onClick={loginFacebook}
                fullWidth
                variant="contained"
                className={`${classes.buttonFacebook} ${classes.input}`}
                startIcon={<img className={classes.svg} src={images.facebook} alt="" />}
              >
                Iniciar sesión con Facebook
              </Button>
              <Collapse in={errorSocialEmail}>
                <Box className={`${classes.input} ${classes.boxWarning}`}>
                  <Typography className={classes.warningText}>
                    <ErrorOutline /> El email está ligado a otro método de inicio de sesión
                  </Typography>
                </Box>
              </Collapse>
            </div>
          </div>
        </Grid>
        <Grid item md={6} sm={12}>
          <div className={classes.box}>
            <img src={images.docHeart} className={classes.image} alt="iSana" />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default noAuth(Login);
