import { SET_CUSTOMER_DATA, UPDATE_CUSTUMER_DATA } from './types';

const INITIAL_STATE = {
  customer: {},
  isGettingCustomerData: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CUSTOMER_DATA:
      return { ...state, customer: action.payload };
    case UPDATE_CUSTUMER_DATA:
      return { ...state, customer: action.payload };
    default:
      return state;
  }
};
