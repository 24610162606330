import { Box, Card, Grid, Typography } from '@material-ui/core';
import { ArrowForwardRounded } from '@material-ui/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles';
import TextNumber from '../../../components/TextNumber';
import { getDateFormat, getStatus, getStatusColor } from '../OrderDetail/helper';

const OrderCard = ({ order }) => {
  const classes = styles();
  const { _id, orderNumber, deliveryAddress, total, status, deliveryDate } = order;
  return (
    <Grid item xs={12} sm={12} md={6}>
      <Link to={`/orders/${_id}`} className={classes.link}>
        <Card className={classes.card}>
          <Box className={classes.info}>
            <Box className={classes.dateBox}>
              <Typography className={classes.dataText}>Pedido realizado el</Typography>
              <Typography className={classes.title}>{getDateFormat(deliveryDate)}</Typography>
            </Box>
            <Box className={classes.orderBox}>
              <Typography variant="subtitle2" className={classes.orderText}>
                Orden {orderNumber}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.address}>
            <Typography className={classes.dataText}>Destino</Typography>
            <Typography>
              {deliveryAddress.street} {deliveryAddress.exteriorNumber}{' '}
              {deliveryAddress.neighborhood} {deliveryAddress.postalCode} {deliveryAddress.city}{' '}
              {deliveryAddress.state}
            </Typography>
          </Box>
          <Box className={classes.info}>
            <Box className={classes.orderBox}>
              <Typography className={getStatusColor(status, classes)}>
                {getStatus(status)}
              </Typography>
              <Typography className={classes.dataText}>
                Total: <TextNumber number={total} />
              </Typography>
            </Box>
            <Box className={classes.dateBox}>
              <ArrowForwardRounded />
            </Box>
          </Box>
        </Card>
      </Link>
    </Grid>
  );
};

OrderCard.propTypes = {
  order: PropTypes.shape({
    orderNumber: PropTypes.string,
    deliveryAddress: PropTypes.shape({
      street: PropTypes.string,
      exteriorNumber: PropTypes.string,
      neighborhood: PropTypes.string,
      postalCode: PropTypes.number,
      city: PropTypes.string,
      state: PropTypes.string
    }),
    total: PropTypes.number,
    status: PropTypes.string,
    deliveryDate: PropTypes.string,
    _id: PropTypes.string
  })
};

OrderCard.defaultProps = {
  order: {
    orderNumber:'',
    deliveryAddress:{
      street: '',
      exteriorNumber:'',
      neighborhood: '',
      postalCode: 0,
      city: '',
      state: ''
    },
    total: 0,
    status: '',
    deliveryDate: '',
    _id: ''
  }
};
export default OrderCard;
