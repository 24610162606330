import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../theme';

const styles = makeStyles((theme) => ({
  box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  product: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  image: {
    width: '6rem',
    height: '6rem'
  },
  prices: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  coupon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '1rem'
  },
  separation: {
    width: '100%',
    color: Colors.blueHepatica,
    margin: '0.8rem'
  },
  chip: {
    background: Colors.electricViolet,
    color: Colors.simpleWhite,
    fontWeight: 'bold',
    marginLeft: '0.3rem'
  },
  circularProgress: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: '2rem'
  },
  link: {
    textDecoration: 'none',
    color: Colors.black
  },
  detail: {
    marginLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0rem'
    },
    color: Colors.gray
  }
}));
export default styles;
