import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CdsoProfile from './CdsoProfile';
import CdsoAboutUs from './CdsoProfile/CdsoAboutUs';

const LoyaltyProgram = ({ customer }) => (
  <div>{customer.cdsoCardNumber ? <CdsoProfile /> : <CdsoAboutUs />}</div>
);

LoyaltyProgram.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.string,
    cdsoCardNumber: PropTypes.string
  })
};

LoyaltyProgram.defaultProps = {
  customer: {
    cdsoCardNumber: '',
    name: ''
  }
};

const mapsStateToProps = (state) => {
  const { customer } = state.customer;
  return { customer };
};

export default connect(mapsStateToProps, {})(LoyaltyProgram);
