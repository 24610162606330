import { API_URL_PAYMENTS } from '../constants';
import axios from '../utils/axios';

/**
 * Function to confirm information of payment with current data in shopping cart
 * @param {string} paymentId  paytment mercado pago id
 * @param {string} shoppingCartId shopping cart id
 * @returns Promise
 */
export const confirmQuotePayment = async (paymentId, shoppingCartId) => {
  const response = await axios.get(`${API_URL_PAYMENTS}/${paymentId}/${shoppingCartId}`);
  return response.data;
};

/**
 * Function to update mercadopago payment
 * @param {string} paymentId mercadopago payment id
 * @param {string} shoppingCartId shoppingCart id
 * @returns Promise
 */
export const updateMercadoPagoPayment = async (paymentId, shoppingCartId) => {
  const response = await axios.put(`${API_URL_PAYMENTS}/${paymentId}/${shoppingCartId}`);
  return response.data;
};

/**
 * Function to create mercadopago preference by shopping cart id
 * @param {string} shoppingCartId shopping cart id
 * @returns
 */
export const createMercadoPagoPreferenceId = async (shoppingCartId) => {
  const response = await axios.get(`${API_URL_PAYMENTS}/${shoppingCartId}`);
  return response.data;
};

export const getOrderByMercadoRef = async (orderId) => {
  const response = await axios.get(`${API_URL_PAYMENTS}/order/${orderId}`);
  return response.data;
};
