import React from 'react';
import { Box } from '@material-ui/core';
import images from '../../assets/images';
import styles from './useStyles';

const LogosBox = () => {
  const classes = styles();

  return (
    <Box className={classes.logoBox}>
      <img className={classes.isanaPlus} src={images.isanaPlus} alt="isana+" />
      <img className={classes.cdsoLogo} src={images.cdsoLogo} alt="cdso" />
    </Box>
  );
};

export default LogosBox;