import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../theme';

const styles = makeStyles(() => ({
  box: {
    marginTop: '4rem',
    padding: '1rem',
    height: '7rem',
    margin: '1rem',
    background: Colors.simpleWhite,
    display: 'flex',
    alignItems: 'center'
  },
  infoClient: {
    display: 'flex',
    flexDirection: 'column'
  },
  name: {
    fontWeight: 'Bold',
    fontSize: '1.3rem'
  },
  cardNumber: {
    color: Colors.gray,
    cursor: 'pointer'
  },
  boxList: {
    margin: 'auto',
    height: '25rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  textList: {
    textAlign: 'center'
  }
}));
export default styles;
