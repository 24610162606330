import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5.26rem',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem',
      marginTop: '4rem'
    }
  },
  form: {
    maxWidth: '26rem'
  },
  input: {
    marginTop: '1rem',
    width: '100%'
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: Colors.electricViolet,
    marginTop: '3rem'
  },
  image: {
    maxWidth: '30rem',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  controlBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem'
  },
  button: {
    backgroundColor: Colors.blueHepatica,
    color: Colors.simpleWhite,
    textDecoration: 'none'
  },
  boxWarning: {
    padding: '0.6rem',
    borderRadius: '0.3rem'
  },
  warningText: {
    fontWeight: 'bold',
    color: Colors.blueHepatica,
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    [theme.breakpoints.up('xl')]: {
      paddingLeft: '12rem',
      paddingRight: '12rem'
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '10rem',
      paddingRight: '10rem'
    }
  }
}));
export default styles;
