import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Tooltip,
  Button
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './styles';
import images from '../../../assets/images';
import { STOCK_PARAMS  } from '../../../constants';
import SnackbarNote from '../../../components/SnackbarNote';
import ProductDescription from '../../../components/ProductDescription';
import PublicPrice from '../../../components/PublicPrice';
import getCurrencyFormat from '../../../utils/getCurrencyFormat';
import { handleTypeOfPromotion } from '../../../helper';
import AddToShoppingCartButton from '../../../components/AddToShoppingCartButton';

const ProductCard = ({ product }) => {
  const {
    imageUrl,
    promotion,
    name,
    requireRecipe,
    price,
    _id,
    stock,
    publicPrice
  } = product;
  const classes = styles();
  const [open, setOpen] = useState(false);
  const [success, setsuccess] = useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const isValidToSelling = () => price && stock > STOCK_PARAMS.EMPTY;

  /**
   * returns the discount percentage
   * @param {number} publicPrice 
   * @param {number} price 
   * @returns number
   */
  const getPercent = (publicPrice, price) => {
    const resultValue = parseFloat(publicPrice) - parseFloat(price);
    const resulPercent = parseInt((resultValue * 100) / publicPrice, 10);
    return resulPercent
  }

  /**
   * Validate correct discount to show
   * @param {number} publicPrice Public price
   * @param {number} price Price
   * @returns number
   */
  const isValidPublicPrice = (publicPrice, price) => {
    if (!publicPrice) {
      return false;
    }

    if (publicPrice < price) {
      return false;
    }

    const resulPercent = getPercent(publicPrice, price);
  
    if(resulPercent < 1) return false;

    return true;
  };

  /**
   * Get discount price with public price
   * @param {number} publicPrice Public price
   * @param {number} price Price
   * @returns number
   */
  const getDiscountPublicPrice = (publicPrice, price) => {
    if (!publicPrice || !price) {
      return '$0.00';
    }
    const result = parseFloat(publicPrice) - parseFloat(price);
    return getCurrencyFormat(result);
  };

  const getDiscountPublicPercentage = (publicPrice, price) => {
    if (!publicPrice || !price) {
      return '%';
    }

    const resulPercent = getPercent(publicPrice, price);

    return `- ${resulPercent} %`;
  };

  return (
    <div className={classes.cardBox}>
      <Card className={classes.cardFix}>
        <CardContent>
          <div className={classes.attachmentFix}>
            <Box className={classes.attachments}>
              {promotion ||
                (isValidPublicPrice(publicPrice, price) && (
                  <div className={classes.promotion}>
                    {getDiscountPublicPercentage(publicPrice, price)}
                  </div>
                ))}
              {requireRecipe && (
                <div className={classes.recipe}>
                  <Tooltip title="Requiere receta">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="32"
                      height="32"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm2 2h6v6H6V7zm2 2v2h2V9H8zm-2 6h12v2H6v-2zm8-8h4v2h-4V7zm0 4h4v2h-4v-2z"
                        fill="rgba(82,149,215,1)"
                      />
                    </svg>
                  </Tooltip>
                </div>
              )}
               {Boolean(product.offers.length) && (
                 <Tooltip title="Producto con promoción iSana+">
                <img src={images.plusIcon} className={classes.isanaPlus} alt="isanaplus" />
                </Tooltip>
                  )}
            </Box>
          </div>
          <Link to={`/productos/${_id}`} className={classes.link}>
            <div className={classes.imageBox}>
              <img
                className={classes.image}
                src={imageUrl || images.productDefault}
                alt="product"
              />
            </div>
            <div className={classes.nameBox}>
              <Typography className={classes.name}>{name}</Typography>
            </div>
            <div className={classes.description}>
              <ProductDescription classes={classes} productData={product} />
            </div>
            <div>
              {Boolean(price) && (
                <>
                  <div className={classes.prices}>
                    <PublicPrice publicPrice={publicPrice} price={price} />
                    <Typography className={classes.priceDetail}>{getCurrencyFormat(price)}</Typography>
                  </div>
                  <div className={classes.savingBox}>
                  {isValidPublicPrice(publicPrice, price) && (
                    <Typography className={classes.textDiscountPrice}>
                      Ahorras {getDiscountPublicPrice(publicPrice, price)}{' '}
                    </Typography>
                  )}
                  </div>
                </>
              )}
            </div>
          </Link>
          {!isValidToSelling() ? (
            <div className={classes.boxStock}>
              <Link to={`/productos/${_id}`} className={classes.noProductOnStock}>
                <Typography className={classes.onStockText}>Te lo conseguimos 😉</Typography>
              </Link>
            </div>
          ) : (
            <div className={classes.boxCenter}>
             <AddToShoppingCartButton 
             setsuccess={setsuccess} 
             onClickButton={handleClick} 
             product={product}
             />
              <AddToShoppingCartButton 
             setsuccess={setsuccess} 
             onClickButton={handleClick} 
             product={product}
             text='Comprar ahora'
             buyNow
             />
              {Boolean(product.offers.length) && (
                <Button size='small' variant='outlined' className={classes.offerBox} >
                    {handleTypeOfPromotion(product.offers[product.offers.length -1].name)}
                </Button>
              )}
            </div>
          )}
          {/* <div>
          {Boolean(product.offers.length) && (
                 <Typography className={classes.promoRule}>
                 *Las promociones se aplican en el proceso de compra
               </Typography>
              )}
            <Typography className={classes.rules}>
              *Precios y productos sujetos a disponibilidad
            </Typography>
          </div> */}
        </CardContent>
      </Card>
      <SnackbarNote onOpen={open} onClose={handleClose} success={success} />
    </div>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    imageUrl: PropTypes.string,
    promotion: PropTypes.bool,
    name: PropTypes.string,
    requireRecipe: PropTypes.bool,
    dose: PropTypes.string,
    content: PropTypes.string,
    price: PropTypes.number,
    ean: PropTypes.string,
    _id: PropTypes.string,
    stock: PropTypes.number,
    priceHistoryId: PropTypes.string,
    publicPrice: PropTypes.number,
    offers: PropTypes.arrayOf(PropTypes.shape({name:PropTypes.string}))
  })
};

ProductCard.defaultProps = {
  product: {
    imageUrl: '',
    promotion: false,
    name: '',
    requireRecipe: false,
    dose: '',
    content: '',
    price: 0,
    ean: '',
    _id: '',
    stock: 0,
    priceHistoryId: '',
    publicPrice: 0,
    offers: [
      {
        name: PropTypes.string
      }
    ]
  }
};

export default connect(null, { })(ProductCard);
