import React from 'react';
import {
  AccordionSummary, Typography, Accordion, AccordionDetails, Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import images from '../../assets/images';

const Questions = ({ question, answer, showButtons }) => {
  const classes = styles();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<img className={classes.arrowDown} src={images.arrowDown} alt="arrow" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Typography>
          {answer}
        </Typography>
        { showButtons && (
          <div className={classes.buttonsBox}>
            <Button>
              <img src={images.appStore} alt="app store" />
            </Button>
            <Button>
              <img src={images.googlePlay} alt="app store" />
            </Button>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

Questions.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
  showButtons: PropTypes.bool
};

Questions.defaultProps = {
  question: '',
  answer: '',
  showButtons: false
};

export default Questions;
