import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  container: {
    marginTop: '1rem',
    backgroundColor: Colors.simpleWhite
  },
  icon: {
    width: '1rem'
  },
  avatarRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem'
  },
  buttonsRow: {
    marginTop: '0.2rem'
  },
  input: {
    margin: '1rem'
  },
  form: {
    paddingTop: '1rem',
    marginBottom: '2rem',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'flex-start'
  },
  card: {
    padding: '1rem',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      maxWidth: '50rem'
    }
  },
  fixRow: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  textfield: {
    marginTop: '1rem',
    zIndex: 1
  },
  fixSmallField: {
    marginRight: '0.3rem',
    [theme.breakpoints.down('md')]: {
      marginRight: '0rem'
    }
  },
  centerRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '2rem'
  },
  gridFix: {
    display: 'flex',
    justifyContent: 'center'
  },
  circularBox: {
    height: '50rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  directions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    margin: '0.4rem'
  },
  link: {
    textDecoration: 'none'
  },
  breadcrumbBox: {
    display: 'flex',
    padding: '1.5rem',
    marginTop: '2rem'
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '20rem',
    maxWidth: '35rem',
    background: Colors.simpleWhite,
    borderRadius: '0.5rem',
    border: '2px  #000',
    boxShadow: '2rem',
    padding: '1rem'
  },
  textModal: {
    textAlign: 'center',
    marginTop: '1rem'
  },
  divModal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1rem'
  },
  buttonModal: {
    width: '8rem',
    marginTop: '3rem'
  },
  profileBanner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  nameUser: {
    marginLeft: '1rem',
    fontSize: '2rem',
    fontWeight: 'bold'
  },
  profileContainer: {
    marginTop: '3rem',
    padding: '2rem',
    backgroundColor: Colors.simpleWhite
  },
  profileFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textProfile: {
    fontSize: '1rem',
    color: Colors.solidGray,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0.8rem'
  },
  directonRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '2rem'
  }
}));
export default styles;
