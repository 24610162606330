import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
/* eslint-disable react/jsx-props-no-spreading */

const CustomLink = ({ isExternal, children, to, ...props }) => {
  if (isExternal) {
    return (
      <a href={to} target="_blank" rel="noreferrer" {...props}>
        {children}
      </a>
    );
  }
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  isExternal: PropTypes.bool,
  children: PropTypes.element.isRequired,
  to: PropTypes.string
};

CustomLink.defaultProps = {
  isExternal: false,
  to: ''
};

export default CustomLink;
