import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Modal,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PaymentMethodsBanner from '../../components/PaymentMethodsBanner';
import Cart from './Cart';
import Delivery from './Delivery';
import Detail from './Detail';
import styles from './styles';
import Approve from './Approve';
import {
  updateCheckoutShoppingCart,
  getShoppingCartBySomeIntegrationAction,
  getBonusInProducts,
  setBonusRecentlyUpdatedValidation,
  setChangesDisabled
} from '../../reducers/shoppingCart/actions';
import { SHOPPING_CART_ACTIONS, OAUTH_CODE, SHOP_ID_STORAGE } from '../../constants';
import CheckoutMercado from './CheckoutMercado';
import { scrollTop } from '../../helper';
import images from '../../assets/images';
import { SET_CDSO_BONUS } from '../../reducers/shoppingCart/types';
import { createOrder } from '../../services/orders';
import useAgreementCondition from '../../customHooks/useEgreementCondition';

const Checkout = ({
  updateCheckoutShoppingCart,
  shoppingCartData,
  getShoppingCartBySomeIntegrationAction,
  customer,
  getBonusInProducts,
  bonusProducts,
  setBonusRecentlyUpdatedValidation,
  setChangesDisabled
}) => {
  const classes = styles();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isButtonActive, setIsButtonActive] = useState(true);
  const [isValidDeliverAddress, setIsValidDeliveryAddress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { DELIVERY_DATA_WEBSITE_SET } = SHOPPING_CART_ACTIONS;
  const [loadingShippingCost, setLoadingShippingCost] = useState(false);
  const [infoData, setInfoData] = useState({
    email: '',
    name: '',
    phoneNumber: '',
    street: '',
    interiorNumber: '',
    exteriorNumber: '',
    city: '',
    addressNote: '',
    state: '',
    postalCode: '',
    search: '',
    neighborhood: ''
  });
  const [promotionApplied, setPromotionApplied] = useState(false);
  const [loadingBonus, setLoadingBonus] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);
  const [creating, setCreating] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(true);
  const isNotAgreement = useAgreementCondition({ shoppingCartData });
  const [orderIdAgreement, setOrderIdAgreement] = useState();
  const [agreemntSuccess, setAgreemntSuccess]  = useState(false);
  const [recordWithErrors, setRecordWithErrors] = useState([])
  const nextStep = () => {
    if (activeStep < 3) {
      setActiveStep(activeStep + 1);
    }
    scrollTop();
  };
  const previousStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
    if (activeStep === 0 && promotionApplied) {
      setChangesDisabled(false);
      setPromotionApplied(false);
    }
    scrollTop();
  };

  /**
   * close Modal to checkout by mercado pago
   */
  const closeModalCheckoutMercadoPago = () => setShowModal(false);

  const validateDeliveryDataRequired = (data) => {
    const { phoneNumber, street, city, postalCode, state, exteriorNumber, name, neighborhood } =
      data;
    if (
      !name ||
      !phoneNumber ||
      !street ||
      !city ||
      !postalCode ||
      !state ||
      !exteriorNumber ||
      !neighborhood
    ) {
      return false;
    }
    return true;
  };

  const handleClickNextStep = () => {
    if (activeStep === 1) {
      setLoadingShippingCost(true);
      if (validateDeliveryDataRequired(infoData)) {
        updateCheckoutShoppingCart(infoData, DELIVERY_DATA_WEBSITE_SET).then(() => {
          setLoadingShippingCost(false);
        });
      }
    }
    setActiveStep(activeStep + 1);
    scrollTop();
  };

  const getTextNextButton = () => {
    if (activeStep === 0) return 'Envío';
    if (activeStep === 1) return 'Confirmar';
    return 'Realizar el pago';
  };

  const isDisableNextStep = () => {
    if (activeStep === 0 || isValidDeliverAddress) return false;
    return true;
  };

  const handleOnSetAddressData = (values) => {
    const isValidRequired = validateDeliveryDataRequired(values);
    setIsValidDeliveryAddress(isValidRequired);
    setInfoData(values);
  };

  const validateShoppingCartData = () => {
    const { deliveryAddress } = shoppingCartData;
    const { deliveryContact } = shoppingCartData;
    if (
      deliveryContact.name ||
      deliveryContact.phoneNumber ||
      deliveryAddress.street ||
      deliveryAddress.city ||
      deliveryAddress.postalCode ||
      deliveryAddress.state ||
      deliveryAddress.exteriorNumber ||
      deliveryAddress.neighborhood
    ) {
      setInfoData({
        email: deliveryContact.email,
        name: deliveryContact.name,
        street: deliveryAddress.street,
        interiorNumber: deliveryAddress.interiorNumber,
        exteriorNumber: deliveryAddress.exteriorNumber,
        city: deliveryAddress.city,
        addressNote: deliveryAddress.addressNote,
        state: deliveryAddress.state,
        postalCode: deliveryAddress.postalCode,
        phoneNumber: deliveryContact.phoneNumber,
        neighborhood: deliveryAddress.neighborhood
      });
    }
  };

  const discountValidation = async () => {
    const { data } = bonusProducts;
    if (data.length > 0) {
      const updatedData = data
      await updateCheckoutShoppingCart(updatedData, SET_CDSO_BONUS);
      setBonusRecentlyUpdatedValidation(true);
      setLoadingBonus(false);
    }
  };

  useEffect(() => {
    const { _id, deliveryContact, deliveryAddress } = shoppingCartData;

    if (!_id) {
      const url = new URL(window.location.href);
      const paymentId = url.searchParams.get('payment');
      if (paymentId) {
        getShoppingCartBySomeIntegrationAction(paymentId).then(() => {
          sessionStorage.setItem(SHOP_ID_STORAGE, paymentId);
          setActiveStep(3);
        });
      } else {
        navigate('/home');
        return;
      }
    }
    if (deliveryContact && deliveryAddress) {
      validateShoppingCartData();
    }
  }, [shoppingCartData]);

  useEffect(() => {
    if (shoppingCartData.oauthId) {
      setIsButtonActive(shoppingCartData.oauthId.internalCode === OAUTH_CODE);
    }
  }, [shoppingCartData.oauthId]);

  useEffect(() => {
    discountValidation();
  }, [bonusProducts]);

  useEffect(() => {
    setChangesDisabled(false);
    scrollTop();
  }, []);

  const checkPromotions = async () => {
    setChangesDisabled(true);
    setLoadingBonus(true);
    if (customer.cdsoCardNumber) {
      await getBonusInProducts(customer.cdsoCardNumber, shoppingCartData.items);
      setPromotionApplied(true);
      return;
    }
    setPromotionApplied(true);
    setLoadingBonus(false);
  };

  const createAgreementOrder = async () => {
    try {
      setCreating(true);
      const response = await createOrder(shoppingCartData._id);
      if (response.data.metadata.recordWithErrors && response.data.metadata.recordWithErrors.length) {
        setRecordWithErrors(response.data.metadata.recordWithErrors)
      }
      setOrderIdAgreement(response.data.orderNumber)
      setAgreemntSuccess(true);
      setCreating(false);
      setAgreementModal(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setAgreemntSuccess(false)
      setCreating(false);
      setAgreementModal(true);
    }
  };

  const agreementCreated = () => {
    navigate(`/checkout/response?agreement=true&order_id=${orderIdAgreement}`);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ButtonsFlow = () => (
    <div className={classes.buttonsFlow}>
    {!promotionApplied ? (
      <div className={classes.buttonGroup}>
        <Button
          variant="contained"
          size="small"
          disableElevation
          className={classes.button}
          style={{ marginRight: '1rem' }}
          onClick={checkPromotions}
          disabled={loadingBonus}
        >
         {loadingBonus ? 'Verificando': 'Verificar Promociones'}
        </Button>
      </div>
    ) :(
        <div className={activeStep <= 1 ? classes.buttonGroup : classes.rowGroup}>
          {isButtonActive && activeStep <= 1 && (
            <Button
              variant="contained"
              size="small"
              disableElevation
              className={classes.button}
              style={{ marginRight: '1rem' }}
              onClick={previousStep}
            >
              Regresar
            </Button>
          )}
          {activeStep >= 2 ? (
            <div>
              {!isNotAgreement ? (
                <div>
                  {creating ? (
                    <CircularProgress size="2rem" />
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      disableElevation
                      className={classes.button}
                      onClick={createAgreementOrder}
                      disabled={loadingShippingCost || agreementModal}
                    >
                      Surtir receta
                    </Button>
                  )}
                </div>
              ) : (
                <CheckoutMercado />
              )}
            </div>
          ) : (
            <Button
              variant="contained"
              size="small"
              disabled={isDisableNextStep()}
              className={classes.button}
              onClick={handleClickNextStep}
              disableElevation
              endIcon={activeStep >= 2 ? <div /> : <ArrowForward />}
            >
              {getTextNextButton()}
            </Button>
          )}
        </div>
      )}
    </div>
  );
  return (
    <Container maxWidth="xl">
      <Grid container className={classes.bar}>
        <Grid item md={12} xs={12}>
          <div className={classes.spacer} />
        </Grid>
        <Grid item md={8} xs={12}>
          <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
            <Step>
              <StepLabel>Verifica tu carrito de compras</StepLabel>
            </Step>
            <Step>
              <StepLabel>Información del envío</StepLabel>
            </Step>
            <Step>
              <StepLabel>Confirma tu pedido</StepLabel>
            </Step>
          </Stepper>
          <div className={classes.spacerDiv} />

          {isButtonActive && activeStep >= 2 && (
            <Tooltip title="Regresar">
              <IconButton
                variant="contained"
                size="small"
                disableElevation
                className={classes.buttonIconBack}
                onClick={previousStep}
              >
                <ArrowBack />
              </IconButton>
            </Tooltip>
          )}
          {activeStep === 0 && <Cart />}
          {activeStep === 1 && (
            <Delivery
              addressNoteData={infoData}
              onSetAddressData={handleOnSetAddressData}
              isValidDeliverAddress={isValidDeliverAddress}
            />
          )}
          {activeStep >= 2 && <Approve infoData={infoData} />}
          <Modal open={showModal} onClose={closeModalCheckoutMercadoPago}>
            <CheckoutMercado />
          </Modal>
          {activeStep >= 2 && (
            <div className={classes.detailSmall}>
              <PaymentMethodsBanner />
            </div>
          )}
          <Dialog open={agreementModal}>
          {agreemntSuccess ? (
  <DialogContent>
  <Grid container>
    <Grid item md={6} xs={12}>
      <Box className={classes.boxCdso}>
        <Box className={classes.boxCdso}>
          <Typography variant="h6" className={classes.title}>
            Tu orden ha sido generada {recordWithErrors.length ? 'parcialmente' : ''}
          </Typography>
        </Box>
        <Box>
        {recordWithErrors.length ? (
          <Box>
            <Typography>
             Los siguientes NUR no están vigente para surtimiento
            </Typography>
            {recordWithErrors.map((nur)=> (
              <Typography>
                {nur}
              </Typography>
            ))}
             </Box>
        ) : null}
        <Typography variant="body1" align="center">
           Envíamos los detalles a tu correo.
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" align="center" className={classes.questions}>
            ¿Dudas o aclaraciones?
          </Typography>
        </Box>
        <Box className={classes.contactBox}>
          <Typography variant="body2" align="center" className={classes.contactText}>
          (55) 1329 9099
          </Typography>
          <Typography variant="body2" align="center" className={classes.contactText}>
           hola@isana.mx
          </Typography>
        </Box>
        <Box className={classes.boxCdso}>
          <Button
            variant="contained"
            size="small"
            disableElevation
            className={classes.button}
            onClick={agreementCreated}
          >
            Listo
          </Button>
        </Box>
      </Box>
    </Grid>
    <Grid item md={6} xs={12}>
      <Box className={classes.imagebox}>
        <img src={images.bolsas} className={classes.imageBag} alt="isana" />
      </Box>
    </Grid>
  </Grid>
</DialogContent>
          ) : (
            <DialogContent>
            <Grid container>
              <Grid item md={6} xs={12}>
                <Box className={classes.boxCdso}>
                  <Box className={classes.boxCdso}>
                    <Typography variant="h6" className={classes.title}>
                     Hubo un error al generar tu orden. 
                    </Typography>
                  </Box>
                  <Box>
                  <Typography variant="body1" align="center">
                    Al parecer tu orden ya no está vigente para el surtiemiento
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" align="center" className={classes.questions}>
                      Contatca a tu aseguradora.
                    </Typography>
                  </Box>
                  <Box className={classes.boxCdso}>
                    <Button
                      variant="contained"
                      size="small"
                      disableElevation
                      className={classes.button}
                      onClick={() => {
                        navigate('/home')
                      }}
                    >
                      ok
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box className={classes.imagebox}>
                  <img src={images.bolsas} className={classes.imageBag} alt="isana" />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          )}
          </Dialog>
          <Dialog open={!isNotAgreement && welcomeModal}>
            <DialogContent>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <Box className={classes.boxCdso}>
                    <Box className={classes.boxCdso}>
                      <Typography variant="h6" className={classes.title}>
                        Hola,{' '}
                        {shoppingCartData.metadata && shoppingCartData.metadata.axaUser
                          ? shoppingCartData.metadata.axaUser
                          : ''}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1" align="center">
                        Este es el proceso de surtimiento de tu receta.
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" align="center">
                        Por favor agrega tu información de contacto y dirección de envío para
                        concretar el proceso.
                      </Typography>
                    </Box>
                    <Box className={classes.boxCdso}>
                      <Button
                        variant="contained"
                        size="small"
                        disableElevation
                        className={classes.button}
                        onClick={() => {
                          setPromotionApplied(true);
                          setWelcomeModal(false);
                          setActiveStep(1);
                        }}
                      >
                        Continuar
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box className={classes.imagebox}>
                    <img src={images.bannerOk} className={classes.imageIsanaWomen} alt="isana" />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className={classes.spacerDiv} />
          <div className={classes.box}>
            <Detail
              action={nextStep}
              buttonText={getTextNextButton()}
              linkName="/order/info"
              lastStep={activeStep >= 2}
              loadingShippingCost={loadingShippingCost}
              ButtonsFlow={<ButtonsFlow />}
            />
            <PaymentMethodsBanner />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { shoppingCartData, bonusProducts, metadata } = state.shoppingCart;
  const { customer } = state.customer;
  const { bonusRecentlyUpdated } = metadata;
  return { shoppingCartData, customer, bonusProducts, bonusRecentlyUpdated };
};

Checkout.propTypes = {
  updateCheckoutShoppingCart: PropTypes.func,
  shoppingCartData: PropTypes.shape({
    deliveryAddress: PropTypes.shape({
      street: PropTypes.string,
      exteriorNumber: PropTypes.any, // eslint-disable-line
      interiorNumber: PropTypes.any, // eslint-disable-line
      city: PropTypes.string,
      state: PropTypes.string,
      postalCode: PropTypes.any, // eslint-disable-line
      neighborhood: PropTypes.string,
      addressNote: PropTypes.string
    }),
    deliveryContact: PropTypes.shape({
      name: PropTypes.string,
      phoneNumber: PropTypes.any, // eslint-disable-line
      email: PropTypes.string
    }),
    _id: PropTypes.string,
    oauthId: PropTypes.shape({ internalCode: PropTypes.string }),
    items: PropTypes.arrayOf(PropTypes.shape({ product: PropTypes.shape({}) })),
    metadata: PropTypes.shape({
      originServices: PropTypes.string,
      paymentIndicator: PropTypes.number,
      axaUser: PropTypes.string
    })
  }),
  getShoppingCartBySomeIntegrationAction: PropTypes.func,
  getBonusInProducts: PropTypes.func,
  bonusProducts: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape()) }),
  customer: PropTypes.shape({ cdsoCardNumber: PropTypes.string }),
  setBonusRecentlyUpdatedValidation: PropTypes.func,
  setChangesDisabled: PropTypes.func
};

Checkout.defaultProps = {
  updateCheckoutShoppingCart: () => {},
  shoppingCartData: {
    deliveryAddress: {},
    deliveryContact: {},
    items: [],
    metadata: { originServices: '', paymentIndicator: 0, axaUser: '' }
  },
  getShoppingCartBySomeIntegrationAction: () => {},
  getBonusInProducts: () => {},
  bonusProducts: { data: [] },
  customer: { cdsoCardNumber: '' },
  setBonusRecentlyUpdatedValidation: () => {},
  setChangesDisabled: () => {}
};

export default connect(mapStateToProps, {
  updateCheckoutShoppingCart,
  getShoppingCartBySomeIntegrationAction,
  getBonusInProducts,
  setBonusRecentlyUpdatedValidation,
  setChangesDisabled
})(Checkout);
