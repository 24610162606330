import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles(() => ({
    svg: {
        filter: 'invert(92%) sepia(93%) saturate(0%) hue-rotate(202deg) brightness(106%) contrast(106%)'
      },
      modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        flexDirection: 'column',
        background: `linear-gradient(to bottom right, ${Colors.electricViolet}, ${Colors.heliotrope})`
      },
      boxModal:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        flexDirection: 'column'
      }
}))

export default styles
