import React from 'react';
import { Typography, Grid, Grow } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

const ItemDescription = ({ service, checked }) => {
  const classes = styles();

  return (

    <Grid item xs={12} sm={12} md={4}>
      <div className={classes.centerBox}>
        <div className={classes.container}>
          <Grow
            in={checked}
            style={{ transformOrigin: '0 0 0' }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(checked ? { timeout: 1000 } : {})}
          >
            <img className={classes.image} src={service.image} alt="" />
          </Grow>
          <Typography align="center" variant="h6" className={classes.name}>{service.name}</Typography>
          <Typography align="center" className={classes.description}>{service.description}</Typography>
        </div>
      </div>
    </Grid>
  );
};
ItemDescription.propTypes = {
  service: PropTypes.shape({
    image: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string
  }),
  checked: PropTypes.bool

};

ItemDescription.defaultProps = {
  service: {},
  checked: false

};

export default ItemDescription;
