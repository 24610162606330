import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import PropTypes from 'prop-types';
import { GOOGLE_MAPS_API_KEY } from '../../constants';
import styles from './styles';

const SearchPlaceInput = ({ onChange, value, onSelect }) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <GooglePlacesAutocomplete
        apiKey={GOOGLE_MAPS_API_KEY}
        selectProps={{
          value,
          onChange,
          onSelect,
          placeholder: 'Busca tu dirección con google...',
          styles: {
            container: (provided) => ({
              ...provided,
              zIndex: 5
            }),
            input: (provided) => ({
              ...provided,
              zIndex: 5
            }),
            menu: (provided) => ({
              ...provided,
              zIndex: 5
            })
          }
        }}
        apiOptions={{ language: 'es-419', region: 'MX' }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ['mx']
          },
          types: ['address']
        }}
      />
    </div>
  );
};
SearchPlaceInput.propTypes = {
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  value: PropTypes.shape({})
};

SearchPlaceInput.defaultProps = {
  onChange: () => {},
  onSelect: () => {},
  value: {}
};

export default SearchPlaceInput;
