/* eslint-disable array-callback-return */
import { Button, CircularProgress, Container, Drawer, Grid, Typography } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Search } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import ProductCard from './ProductCard';
import styles from './styles';
import Pagination from '../../components/Pagination';
import { getProducts } from '../../reducers/products/actions';
import CategoryProducts from './CategoryProducts';
import { scrollTop } from '../../helper';
import BannerCdsoAd from '../../components/BannerCdsoAd';

const Products = ({
  getProducts,
  listproducts,
  categorySelected,
  isLoadingProducts,
  searchText
}) => {
  const selector = useSelector((state) => state.customer);
  const [drawState, setDrawState] = useState({
    right: false
  });
  const classes = styles();
  const [products, setProducts] = useState({
    docs: []
  });
  const [page, setPage] = useState(1);
  const limit = 8;

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawState({ ...drawState, [anchor]: open });
  };

  const nextPage = () => {
    setPage(page + 1);
    const {customer} = selector
    let referralCode = ''
    if (customer && customer.referralCode) {
      referralCode = customer.referralCode
    }
    getProducts({
      page: page + 1,
      search: searchText,
      limit,
      category: categorySelected.name || null,
      referralCode
    });
    scrollTop();
  };

  const previewPage = () => {
    setPage(page + 1);
    const {customer} = selector
    let referralCode = ''
    if (customer && customer.referralCode) {
      referralCode = customer.referralCode
    }
    getProducts({
      page: page - 1,
      search: searchText,
      limit,
      category: categorySelected.name || null,
      referralCode
    });
    scrollTop();
  };

  useEffect(() => {
    setProducts(listproducts);
  }, [listproducts]);

  useEffect(()=>{
    setPage(1);
  },[categorySelected])

  return (
    <Container maxWidth="xl">
      <Grid>
        <BannerCdsoAd/>
      </Grid>
      <Grid>
        <div className={classes.container}>
          <div className={classes.listBox}>
            <div className={classes.itemsFoundBox}>
              {Boolean(categorySelected.name) && (
                <div className={classes.boxTextItems}>
                  {isLoadingProducts ? (
                    <Typography className={classes.itemsFound} />
                  ) : (
                    <Typography className={classes.itemsFound}>
                      {`${products.totalDocs || 0} resultados encontrados`}
                    </Typography>
                  )}
                </div>
              )}
            </div>
            <Grid className={classes.gridProducts} container spacing={2}>
              <Grid item xs={12} md={12}>
                <div className={classes.smBoxFilter}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={toggleDrawer('right', true)}
                    startIcon={<Search />}
                  >
                    Buscar por categoría
                  </Button>
                  <Drawer
                    anchor="left"
                    open={drawState.right}
                    onClose={toggleDrawer('right', false)}
                  >
                    <CategoryProducts />
                  </Drawer>
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className={classes.centerBox}>
                  {isLoadingProducts ? (
                    <div className={classes.boxLoading}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className={classes.searchBox}>
                      {products.docs.length ? (
                        // eslint-disable-next-line consistent-return
                        products.docs.map((item) => <ProductCard key={item._id} product={item} />)
                      ) : (
                        <Typography>Lo sentimos, no se encontraron productos 🤕 </Typography>
                      )}
                    </div>
                  )}
                  {products.totalPages >= 2 && (
                    <Pagination
                      totalOfPages={products.totalPages}
                      page={products.page}
                      nextPage={nextPage}
                      previewPage={previewPage}
                      hasNextPage={products.hasNextPage}
                      hasPrevPage={products.hasPrevPage}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </Container>
  );
};

Products.propTypes = {
  listproducts: PropTypes.shape({
    docs: PropTypes.arrayOf(PropTypes.shape({})),
    page: PropTypes.string,
    totalDocs: PropTypes.number,
    totalPages: PropTypes.number,
    hasNextPage: PropTypes.bool,
    hasPrevPage: PropTypes.bool,
    limit: PropTypes.number
  }),
  getProducts: PropTypes.func,
  categorySelected: PropTypes.shape({ _id: PropTypes.string, name: PropTypes.string }),
  isLoadingProducts: PropTypes.bool,
  searchText: PropTypes.string
};

Products.defaultProps = {
  listproducts: { docs: [], page: 1, limit: 20 },
  getProducts: () => {},
  categorySelected: { _id: '', name: '' },
  isLoadingProducts: false,
  searchText: ''
};

const mapStateToProps = (state) => {
  const { products, categorySelected, isLoadingProducts, searchText } = state.product;
  const listproducts = products;
  return {
    listproducts,
    categorySelected,
    isLoadingProducts,
    searchText
  };
};

export default connect(mapStateToProps, { getProducts })(Products);
