import React from 'react';
import PropTypes from 'prop-types';
import { Radio, FormControlLabel } from '@material-ui/core';
import styles from './styles';

const LocationCheck = ({ value, onChange }) => {
  const classes = styles();
  return (
    <div className={classes.card}>
      <FormControlLabel
        value={value}
        control={<Radio color="primary" />}
        label={value}
        onChange={onChange}
      />
    </div>
  );
};
LocationCheck.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

LocationCheck.defaultProps = {
  value: 'Ubicacíon',
  onChange: () => {}
};
export default LocationCheck;
