import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

const ButtonCdsoInfo = ({variant}) =>{
    const classes = styles()
    return (
        <Link to="/programas-de-lealtad/circulo-salud-oro/que-es-cdso" className={classes.link}>
            <Button size='small' className={`${classes.button} ${variant ? classes.variant : classes.neutro}`}>Conoce más</Button>
        </Link>
    )
}

ButtonCdsoInfo.propTypes = {
    variant: PropTypes.bool
}

ButtonCdsoInfo.defaultProps = {
    variant: false
};

export default ButtonCdsoInfo;