import { Container } from '@material-ui/core';
import React from 'react';
import { scrollTop } from '../../helper';
import styles from './styles';

const Policies = () => {
  const classes = styles();
  scrollTop()
  return (
    <Container maxWidth="xl">
      <div className={classes.row}>
        <div className="mt-5 col-12">
          <h1 className={classes.title}>Términos y condiciones de envío</h1>
        </div>
        <div>
          <h3 className={classes.subtitle}>
            TÉRMINOS Y CONDICIONES DE USO DE PLATAFORMA “ISANA HEALTH TECH” LEA
            ESTAS CONDICIONES DETENIDAMENTE ANTES DE ACCEDER O USAR LOS SERVICIOS.
            RELACIÓN CONTRACTUAL:
          </h3>
          <h4 className={classes.textList}>
            Los presentes Términos y Condiciones de uso (en adelante los “Términos”
            y/o “Condiciones”, en su caso) regulan el acceso o uso que usted haga,
            como persona, desde los Estados Unidos Mexicanos de la plataforma
            virtual y servicios iSana, compuesta por una aplicación para
            dispositivos móviles y una página web (en adelante “el servicio” o “los
            servicios”), puestos a disposición por ISANA HEALTH TECH SAPI DE CV. (en
            lo sucesivo “ISANA”), la cual es una sociedad mercantil constituida bajo
            las leyes de los Estados Unidos Mexicanos, con domicilio social en LAVA
            208 INT 5, Jardines Del Moral 37160 León, Guanajuato, México, e inscrita
            en el Registro Público de la Propiedad y de Comercio.
          </h4>
        </div>
        <div>
          <div>
            <h4>
              NATURALEZA JURÍDICA
            </h4>
            <p className={classes.textList}>
              Los presentes Términos y Condiciones de uso regulan propiamente la
              relación contractual de carácter comercial que vincula a los Usuarios
              que acceden y/o usan la Plataforma virtual y a Isana, especialmente en
              la autorización de uso que otorga éste en favor de aquel como también
              el uso que los Usuarios dan a la Plataforma.
            </p>
          </div>
          <div>
            <h4>
              PRIVACIDAD
            </h4>
            <p className={classes.textList}>
              Para conocer el uso y manejo de los datos e Información Personal
              otorgada a ISANA para utilizar el servicio, tanto en la página web y
              en la plataforma, así como en la aplicación móvil y cualquier otro
              medio por el que sea prestado el servicio se deberá de revisar el
              Aviso de Privacidad ubicado en la página web www.isanate.com/AP.
            </p>
          </div>
          <div>
            <h4>
              CONSENTIMIENTO
            </h4>
            <p className={classes.textList}>
              Al momento de acceder y utilizar este servicio, usted acepta y
              consiente a estar obligado por los presentes términos y condiciones de
              este acuerdo. Asimismo, al utilizar estos servicios particulares,
              usted estará sujeto a toda regla, disposición, manual o guía de uso
              correspondiente que se haya publicado para dichos servicios. ISANA
              podrá modificar los Términos y Condiciones relativos a los Servicios
              cuando lo considere oportuno. Las modificaciones serán efectivas
              después de la publicación por parte de ISANA de dichos Términos y
              Condiciones actualizadas en esta ubicación o las políticas modificadas
              o condiciones suplementarias sobre el Servicio aplicable. Toda
              participación de este servicio, tanto de su página web, su aplicación
              móvil o cualquier otro medio por el cual sea prestado el servicio
              brindado por ISANA, constituirá la aceptación y consentimiento de este
              acuerdo y cualquiera de sus modificaciones publicadas. Si usted no
              acepta cumplir con lo anterior o no desea otorgar su consentimiento,
              por favor, absténgase de utilizarlo, de lo contrario estará
              otorgándolo, por lo que estará sujeto a los términos y condiciones del
              presente acuerdo.
            </p>
          </div>
          <div>
            <h4>
              DEFINICIONES
            </h4>
            <ul>
              <li className={classes.textList}>
                “ISANA» significará ISANA HEALTH TECH SAPI DE CV una Sociedad
                Anónima Promotora de Inversión constituida bajo las leyes de México.
              </li>
              <li className={classes.textList}>
                “Acuerdo” se refiere los presentes términos y condiciones que
                regularán la operatividad y manejo del servicio por parte de ISANA.
              </li>
              <li className={classes.textList}>
                “Isana” es el servicio tecnológico provisto por nuestro aplicativo,
                para la utilización de nuestros servicios de información, entrega de
                medicamentos a domicilio, búsqueda, comparación y cotización de
                medicamentos para ofrecer el precio más competitivo del mercado,
                personalización de pedidos recurrentes de productos de la salud y
                medicamentos, vinculación a médicos generales y especialistas para
                consultas médicas, curación y entrega de contenido especializado en
                salud, oferta de servicios y productos de salud de aliados
                estratégicos.
              </li>
              <li className={classes.textList}>
                “Caso Fortuito” se aplica durante eventos en los que existe la
                intervención humana como pudiesen ser, de manera enunciativa más no
                limitativa, vandalismo, uso ilegal o no autorizado de interferencias
                de radiofrecuencia, huelgas, cierres patronales u otras
                perturbaciones industriales, la falta de piezas de cualquier tipo de
                bienes proporcionados, actos de enemigos públicos, las guerras, los
                bloqueos, las insurrecciones, revueltas, restricciones o
                prohibiciones por cualquier tribunal, consejo, comisión,
                departamento o agencia de los Estados Unidos Mexicanos o cualquier
                Estado, detenciones y restricciones, los disturbios civiles y las
                explosiones
              </li>
              <li className={classes.textList}>
                «Comprador» se entenderá la parte que lleva a cabo la compra de
                Medicamentos o servicios conforme a las presentes Condiciones de
                venta.
              </li>
              <li className={classes.textList}>
                «Medicamentos» significa todos los fármacos, medicinas, tratamientos
                y servicios vendidos por arreglo en el presente Acuerdo.
              </li>
              <li className={classes.textList}>
                “Productos de la Salud”: Cualquier instrumento, dispositivo, equipo,
                programa informático, material u otro artículo, utilizado sólo o en
                combinación, incluidos los programas informáticos por su fabricante
                a las finalidades de diagnóstico y/o terapia y que intervengan en su
                buen funcionamiento, destinado por el fabricante a ser utilizado en
                seres humanos con fines de: A) Diagnóstico, prevención, control,
                tratamiento o alivio de una enfermedad. B) Diagnóstico, control,
                tratamiento, alivio o compensación de una lesión o de una
                deficiencia. C) Investigación, sustitución o modificación de la
                anatomía o de un proceso fisiológico. D) Regulación de la
                concepción. Y que no ejerza la acción principal que se desee obtener
                en el interior o en la superficie del cuerpo humano por medios
                farmacológicos, inmunológicos ni metabólicos, pero a cuya función
                puedan contribuir tales medios.
              </li>
              <li className={classes.textList}>
                “Recetas” significan las prescripciones médicas. La receta médica es
                una orden escrita emitida por el médico (prescripción) para que se
                surta o dispense uno o varios medicamentos al paciente.
              </li>
              <li className={classes.textList}>
                «Fuerza mayor» significa un evento más allá del control razonable de
                ISANA, incluidos los actos de Dios, huracanes, inundaciones,
                volcanes, tsunamis, tornados, tormentas, deslizamiento de tierras,
                epidemias, terremotos, incendios, entre otros.
              </li>
              <li className={classes.textList}>
                “Propiedad Intelectual” Se entenderá como todo lo que se refiere a
                Propiedad Industrial e Intelectual, como lo es: cualquier copyright,
                derechos de diseño, patentes, invenciones, logotipos, nombre
                comercial, marcas, nombres de dominio de Internet, derechos morales,
                derechos de bases de datos, datos, códigos, informes, dibujos,
                especificaciones, know how, métodos de negocio y secretos
                comerciales, por mencionar algunos.
              </li>
              <li className={classes.textList}>
                “Información Personal” son todos los datos y hechos que se
                relacionan con la persona, ya sea para la creación de un perfil, o
                bien, aun los obtenidos por el simple uso de cualquier servicio
                brindado por ISANA.
              </li>
              <li className={classes.textList}>
                “Terceros Asociados” serán empresas nacionales o internacionales,
                con quienes ISANA trabaje en conjunto para ofrecer algunos de los
                servicios que van incluidos en los servicios de suscripción o
                pedidos.
              </li>
              <li className={classes.textList}>
                «Términos Adicionales» serán todos los acuerdos o disposiciones a
                las que se vincule ISANA con terceros, los cuales podrán acarrear
                consigo obligaciones distintas al presente Acuerdo.
              </li>
              <li className={classes.textList}>
                “Usuario” es aquella persona que usa los servicios prestado por
                ISANA y/o Isana, y que puede o no contar con una cuenta en la
                aplicación móvil o página web.
              </li>
              <li className={classes.textList}>
                “Usuario Final» significa cualquier usuario final que paga por el
                consumo del servicio según corresponda.
              </li>
              <li className={classes.textList}>
                Plataforma: Aplicación web y móvil administrada por Isana que
                permite la intermediación en un mercado de Usuarios y Terceros
                Asociados para que por medio de contratos de mandato el Usuario
                solicite la compra o adquisición de un medicamento.
              </li>
              <li className={classes.textList}>
                Cookies: Cadenas de texto enviadas virtualmente que son almacenadas
                por el uso de la Plataforma por Isana, para la consulta de las
                actividades y preferencias de los usuarios.
              </li>
              <li className={classes.textList}>
                Comercio Electrónico: Comprende el envío, transmisión, recepción,
                almacenamiento de mensajes de datos por vía electrónica. Las dudas
                que surjan respecto de la eficacia y validez de los mensajes de
                datos y demás actividades vinculadas al comercio electrónico se
                interpretarán de conformidad con el Capítulo VIII BIS de la Ley
                Federal de Protección al Usuario.
              </li>
              <li className={classes.textList}>
                Repartidor: Persona física empleada o aliada a Isana que acepta
                realizar la gestión del encargo solicitado por el Usuario a través
                de la Plataforma.
              </li>
              <li className={classes.textList}>
                Usuario(es): Quien(es) accede(n) y/o usa(n) la Plataforma para
                solicitar por medio de ésta un mandato remunerado, cuyo encargo
                consiste en la celebración de un contrato de compraventa o cualquier
                otro tipo de contrato lícito, con el fin de adquirir bienes o
                servicios.
              </li>
              <li className={classes.textList}>
                Contrato de mandato remunerado celebrado por medios electrónicos:
                Aquel acuerdo de voluntades celebrado entre el Usuario e Isana, por
                medio del cual el Usuario solicita, a través de la Plataforma, la
                gestión de un encargo a Isana, obligándose este último a cumplir con
                dicho encargo por cuenta y riesgo del Usuario, a cambio de recibir
                una remuneración como contraprestación.
              </li>
              <li className={classes.textList}>
                Contrato por suscripción: Aquel acuerdo de voluntades celebrado
                entre el usuario y e Isana, por medio del cual el Usuario solicita,
                a través de la Plataforma, la gestión recurrente de un encargo a
                Isana, obligándose este último a cumplir con dicho encargo de manera
                recurrente por cuenta y riesgo del Usuario, a cambio de recibir una
                remunueración programada de forma mensual como contraprestación.
              </li>
              <li className={classes.textList}>
                Datos Personales: Es toda información que permite identificar o
                hacer identificable a una persona física.
              </li>
              <li className={classes.textList}>
                Datos Personales Sensibles: , aquellos datos personales íntimos o
                cuya utilización debida o indebida pudiera dar origen a
                discriminación o conlleve un riesgo grave para éste.
              </li>
              <li className={classes.textList}>
                Interacción en la Plataforma: Facultad de los Usuarios para conocer
                los productos exhibidos por Isana, así como la publicidad puesta a
                disposición en la Plataforma.
              </li>
              <li className={classes.textList}>
                Mayor de edad: Persona física mayor de dieciocho (18) años.
              </li>
              <li className={classes.textList}>
                Mensajes de Datos: La información generada, enviada, recibida,
                almacenada o comunicada por medios electrónicos, ópticos o
                similares, como pudieran ser, entre otros, el Intercambio
                Electrónico de Datos (EDI), Internet, el correo electrónico, el
                telegrama, el télex o el telefax.
              </li>
              <li className={classes.textList}>
                Isanate: Encargado de administrar operativa y funcionalmente la
                Plataforma representado para los efectos de los presentes Términos y
                Condiciones por ISANA o por la persona física o jurídica que ésta
                designe.
              </li>
              <li className={classes.textList}>
                Pasarela de Pagos: Servicio que permite la realización de pagos por
                parte de los Usuarios directamente a Isana a través de medios
                electrónicos utilizando plataformas tecnológicas (software).
              </li>
              <li className={classes.textList}>
                Publicidad: Es toda forma de comunicación realizada por Isana, con
                el fin de brindar información sobre productos, actividades
                comerciales y comunicar estrategias o campañas publicitarias o de
                mercadeo, propias o de terceros; realizada como mecanismo de
                referencia y no como oferta pública.
              </li>
              <li className={classes.textList}>
                Producto(s): Exclusivamente aquellos bien(es) de consumo exhibido(s)
                a través de la Plataforma.
              </li>
              <li className={classes.textList}>
                Ventanas Emergentes (Pop-Ups): Ventana o aviso de internet que
                emerge automáticamente en cualquier momento cuando se utiliza la
                Plataforma, especialmente utilizado para la formalización del
                contrato de mandato celebrado por medios electrónicos entre Usuarios
                e Isana.
              </li>
            </ul>
          </div>

          <div>
            <h4>
              CUENTA
            </h4>
            <p className={classes.textList}>
              Los Usuarios usan como referencia para su compra los Productos que se
              encuentran exhibidos en la Plataforma, teniendo como condición
              indispensable la creación de una cuenta de usuario (en adelante la
              “Cuenta de Usuario”), donde se solicitarán datos como nombre, fecha de
              nacimiento, teléfono, dirección, dirección de correo electrónico; esta
              información se utiliza para (i) la plena identificación de los
              Usuarios que pretenden adquirir los Productos, para (ii) el
              cumplimiento de la Compraventa, para (iii) la prevención de fraudes,
              para (iv) vincular al Usuario con el Repartidor y en general para los
              fines definidos en el capítulo correspondiente al Manejo de
              información.
            </p>
            <p className={classes.textList}>
              Los Usuarios en caso de tener cuentas en las redes sociales Facebook y
              Google +, podrán crear su Cuenta de Usuario con la utilización de las
              credenciales allí definidas, para lo cual la Plataforma comunicará
              esta opción. Una vez suministrada la información necesaria para el uso
              de la Plataforma, se validará por parte Isana el nombre de usuario y
              contraseña para acceder y/o usar la Plataforma.
            </p>
            <p className={classes.textList}>
              Podrán los Usuarios, además de la información obligatoria y
              facultativa requerida al momento de la creación de la cuenta,
              suministrar voluntariamente más datos relacionados con su
              individualización al momento en que cree su propio perfil dentro de la
              Plataforma.
            </p>
            <p className={classes.textList}>
              El uso de la Cuenta de Usuario es personal e intransferible, por lo
              cual los Usuarios no se encuentran facultados para ceder los datos de
              validación para el acceso a la Plataforma. En caso de olvido de los
              datos de validación o de usurpación de éstos, es obligación del
              Usuario informarlo al Isana a través de la opción “olvidó su
              contraseña” o a través de comunicación enviada al correo electrónico:
              ayuda@isana.mx
            </p>
            <p className={classes.textList}>
              Las cuentas de usuarios serán administradas por Isana a su discreción
              o por la persona que éste designe, teniendo plena facultad para la
              conservación o no de la cuenta, cuando la información suministrada por
              los Usuarios no sea veraz, completa o segura; o cuando se presente
              incumplimiento de las obligaciones de los Usuarios.
            </p>
            <p className={classes.textList}>
              En ningún momento Isana solicitará al Usuario información que NO
              resulte necesaria para su vinculación con el Repartidor y para la
              facilitación del pago, por lo que los datos de tarjetas débito o
              crédito, solo se solicitarán al momento de realizar el pago virtual si
              así lo determina el Usuario, éstos datos no serán almacenados por
              Isana, serán inscritos directamente en la Pasarela de Pagos
              correspondiente, y se utilizarán directamente por el Usuario en ésta,
              donde se informará las condiciones de seguridad y privacidad en que se
              efectúa el mismo.
            </p>
            <p className={classes.textList}>
              Con la creación de la Cuenta de Usuario, los Usuarios están
              manifestando su voluntad de aceptación expresa e inequívoca de los
              presentes Términos y Condiciones de uso de la Plataforma.
            </p>
            <p className={classes.textList}>
              Al momento de la aceptación de los presentes Términos y Condiciones,
              expresamente el Usuario autoriza el uso de Cookies por parte dIsana en
              toda actividad de uso que realice en la Plataforma.
            </p>
          </div>

          <div>
            <h4>
              PRODUCTOS
            </h4>
            <p>
              Los productos y servicios que ISANA ofrece son propiedad de ISANA o de
              sus Terceros Adicionales según sea el caso. Los productos se
              entenderán por la aplicación ISANA, los servicios de suscripción
              ISANA, los ChatBots de atención, el blog ISANA, la comunidad ISANA, el
              newsletter ISANA y el servicio de vinculación a médicos generales o
              especialistas, entre otros, los cuales estarán regidos por lo
              establecido en el presente Acuerdo, así como lo previsto en la página
              web, aplicación móvil y empaque de los productos.
            </p>
          </div>

          <div>
            <h4>
              DESCRIPCIÓN DE LOS SERVICIOS:
            </h4>
            <p className={classes.textList}>
              ISANA proporciona Aplicaciones móviles, Web, chatbots y whatsapp, que
              muestran la información publicada y/o enviada por ISANA, Servicios de
              información y Terceros Adicionales, como lo son: medicamentos y
              productos de la salud nuevos, ofertas, tratamientos, cupones y
              beneficios, servicios adicionales, entre otros.
            </p>

            <p className={classes.textList}>
              Para poder solicitar el mandato y de esta forma adquirir los bienes y
              servicios deseados, los Usuarios deben estar plenamente identificados
              en su Cuenta de Usuario y seguir el siguiente procedimiento:
            </p>
            <div className="p-2">
              <ul className={classes.numericList}>
                <li>
                  Ingresar a la Plataforma especificando su domicilio o ubicación
                  para determinar los Productos que se encuentran disponibles en el
                  sector correspondiente.
                </li>
                <li>
                  Seleccionar el lugar de entrega. Se debe suministrar por el
                  Usuario la dirección exacta donde se realizará la entrega del
                  (los) producto(s) seleccionado(s). Esta dirección debe encontrarse
                  en el rango de cobertura de entrega. En caso de no encontrarse en
                  dicho rango no se permitirá el cumplimiento del mandato.
                </li>
                <li>
                  Seleccionar el (los) Producto(s). Al seleccionar el (los)
                  Producto(s) se pone a disposición del Usuario las características
                  y valor total del (los) Producto(s) por medio de fotografías y
                  notas de referencias, que permiten la plena individualización del
                  (los) Producto(s) para el análisis detallado del Usuario.
                </li>
                <li>
                  Validación del (los) Producto(s). Cumplido el paso anterior, Isana
                  deberá exhibir al Usuario un resumen de (los) Producto(s) en
                  cuanto a sus condiciones generales tales como la marca y la
                  presentación suministrada. Con ésta información el Usuario podrá
                  validar el (los) Producto(s) seleccionado(s), para que sea usado
                  como referencia con relación al producto que pretende adquirir.
                </li>
                <li>
                  Ingreso del (los) Producto(s) al presupuesto. Este ingreso
                  corresponde a la voluntad inequívoca del Usuario de adquirir algún
                  Producto determinado, ya que se ha informado con suficiencia las
                  características del mismo, teniendo la posibilidad de adquirirlo o
                  no. Isana tiene total autonomía para limitar el ingreso del (los)
                  Producto(s) al presupeusto con ocasión a la cantidad.
                </li>
                <li>
                  Precio. Una vez se han agotados los pasos precedentes, se pone a
                  disposición del Usuario el Precio a pagar por el (los) Productos
                  seleccionado(s), el cual se encuentra reflejado unitariamente y en
                  conjunto por todos los Productos a adquirir. El valor
                  corresponderá al valor total del (los) Producto(s) incluyendo
                  costos de envío, de transacción e impuestos. En ese mismo sentido,
                  Isana se reserva el derecho de incrementar, hasta en un veinte por
                  ciento (20%), los precios de los productos exhibidos en las
                  tiendas físicas de sus aliados. El usuario reconoce y acepta la
                  anterior condición e igualmente tampoco realizará ningún tipo de
                  reclamación frente a Isana por cobros adicionales a los precios
                  que se vean reflejados en tickets de venta. Los valores
                  adicionales serán cobrados a título de uso de la plataforma
                  virtual.
                </li>
                <li>
                  Pago. El pago que realiza el Usuario lo realiza directamente a
                  ISana.
                </li>
                <li>
                  Forma de pago. El Usuario deberá seleccionar el medio de pago que
                  desea utilizar, teniendo como posibilidades el pago contra-entrega
                  a través de i) dinero en efectivo o ii) el pago electrónico a
                  través de la Pasarela de Pago contratada por Isana, siempre y
                  cuando ésta se encuentre disponible en la aplicación o iii) el
                  pago en físico a través de una terminal bancaria o iv) la
                  transferencia bancaria a la cuenta de banco de ISANA. En este
                  momento se configura la solicitud del encargo que realiza el
                  Usuario a Isana. El costo de la forma de pago será absorvido en su
                  totalidad por Isana.
                  <ul className={classes.numericList}>
                    <li>
                      Suscripción. Las ofertas y los precios de las suscripciones,
                      los servicios de suscripción, el alcance de los medicamentos y
                      productos de la salud disponibles a través de los servicios de
                      suscripción pueden cambiar con el tiempo y en función de la
                      ubicación sin previo aviso (salvo que lo requiera la
                      legislación aplicable). A menos que se indique de otro modo,
                      cualquier cambio de precio tendrá efecto a partir del inicio
                      del siguiente periodo de suscripción. En caso de
                      disconformidad con los cambios en su suscripción, los usuarios
                      podrán cancelar la misma de acuerdo con la cláusula de
                      Cancelación del presente Acuerdo. El precio de las
                      suscripciones incluirá el IVA y/o cualesquiera otros impuestos
                      aplicables. Cuando resulte aplicable, dichos impuestos serán
                      recaudados por la parte con la que tú realices la transacción
                      para acceder al Servicio, pudiendo ser Isana o un tercero.
                      Isana no ofrece garantía alguna en cuanto a la disponibilidad
                      de medicamentos y productos de la salud de Suscripción
                      concreto o la cantidad mínima de medicamentos y productos de
                      la salud de Suscripción disponible en cualquier suscripción.
                    </li>
                    <li>
                      Formas de pago suscripción: En caso de que Isana no pudiera
                      procesar un pago utilizando el método de pago elegido por el
                      usuario, se reservará el derecho a utilizar cualquier otro
                      método de pago que conste en sus archivos para el usuario en
                      cuestión. En caso de que un usuario compre una suscripción o
                      inicie una suscripción gratuita de prueba, dicha suscripción
                      se renovará automáticamente y los usuarios autorizarán a Isana
                      (sin previo aviso, a menos que así lo requiera la legislación
                      aplicable) a cobrar la cuota de suscripción periódica
                      aplicable en ese momento, incluidos cualesquiera impuestos,
                      utilizando cualquier forma de pago que Isana tenga registrada
                      en sus archivos. Isana seguirá facturando el servicio de
                      suscripción correspondiente hasta que sea cancelado. En caso
                      de que se rechacen todas las formas de pago que Isana tenga
                      registradas en sus archivos para el pago de la cuota de
                      suscripción, la suscripción será cancelada a menos que el
                      usuario proporcione una nueva forma de pago. De hacerlo y
                      poder efectuar el cargo correctamente antes de cancelar la
                      suscripción, el nuevo periodo de suscripción se basará en la
                      fecha de facturación original y no en la fecha en que haya
                      podido realizarse el cargo correctamente.
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className={classes.subNumericList}>
                <li>
                  Registro. Al momento de definir la forma de pago, el Usuario
                  deberá crear su cuenta personal en la cual se requerirán los datos
                  personales que permitan su identificación, más no su
                  individualización. Adicionalmente, se requerirán los datos para
                  poder realizar el pago de manera electrónica.
                </li>
                <li>
                  Resumen y Correo electrónico. Una vez completados los pasos
                  anteriores se le exhibirá a través de una ventana emergente al
                  Usuario, un resumen detallado de la transacción y la información
                  completa de Isana, que ha aceptado realizar la gestión del encargo
                  y con el cual se celebra el mandato. Dicho resumen será enviado
                  por correo electrónico al Usuario con ésta información de forma
                  detallada.
                </li>
                <li>
                  Recibo. En todo caso, sea que el pago realice en dinero en
                  efectivo o a través de la Pasarela de Pagos o la terminal bancaria
                  o la transferencia electrónica se enviará vía correo electrónico
                  un recibo al Usuario con el detalle de la transacción.
                </li>
                <li>
                  Entrega. Verificados todos los datos correspondientes a la
                  transacción y al ámbito de cobertura de entrega de los Productos,
                  Isana entregará en la dirección suministrada por el Usuario a
                  través de la Plataforma al finalizar la adquisición del (los)
                  Producto(s) comprado(s). En caso de no poderse realizar la entrega
                  por razones imputables al Usuario, deberá Isana o el Repartidor
                  dejar constancia de este hecho.
                </li>
              </ul>
            </div>
          </div>

          <div>
            <h4>
              OBLIGACIONES DEL USUARIO
            </h4>
            <p className={classes.textList}>
              Con la aceptación de los presentes Términos y Condiciones de uso de la
              Plataforma, el Usuario Final se obliga a: (1) Suministrar información
              veraz y fidedigna al momento de crear su propia Cuenta de Usuario; (2)
              Abstenerse de transferir a terceros los datos de validación (nombre de
              usuario y contraseña); (3) Abstenerse de acceder y/o usar la
              Plataforma para realizar actos contrarios a la moral, al orden
              público, a las buenas costumbres o actos o hechos ilícitos en contra
              dIsana, los Repartidors o de terceros; (4) Pagar oportunamente al
              Repartidor la contraprestación económica definida en el proceso de
              compra; (5) Informar inmediatamente al Isana en caso de olvido o
              usurpación de los datos de validación; (6) Abstenerse de realizar
              conductas atentatorias del funcionamiento de la Plataforma; (7)
              Abstenerse de requerir a los Repartidors cualquier otro producto cuya
              comercialización, venta o distribución por teléfono, correo, internet
              o cualquier otro medio de comunicación sea prohibida por la
              legislación aplicable; (8) Abstenerse de suplantar la identidad de
              otros Usuarios; (9) Abstenerse de descifrar, descompilar o
              desensamblar, apropiarse de cualquier elemento de la Plataforma o de
              cualquiera de sus partes; (10) Habilitar la utilización de ventanas
              emergentes durante la operación de la Plataforma; (11) En general,
              todas aquellas conductas necesarias para la ejecución del negocio
              jurídico, como i) la recepción de los productos solicitados, ii)
              exhibir la identificación en caso de compra de productos de uso
              restringido, iii) verificar al momento de la validación que los
              Productos seleccionados si corresponden a los necesitados, iv)
              informarse sobre las instrucciones de uso y consumo de los Productos,
              12) Sacar en paz y salvo a ISANA de cualquier reclamación de
              naturaleza jurídica en su contra y a asumir de manera personal, de
              manera enunciativa más no limitativa, las consecuencias relativas a la
              licitud, calidad, imagen, presentación, tamaño, color, precio, etc.,
              de aquel(los) producto(s) distintos al (los) Producto(s) exhibidos en
              la Plataforma y que sean requeridos por el Usuario Final a través de
              la Plataforma. V) En caso de requerir factura, debe informarle al
              Repartidor para que le proporcione la factura por el costo del (los)
              servicio(s) brindado (s). Si requiere factura del (los) Producto (s)
              que adquirió, debe informar al correo servicio@Isana.mx para que se le
              proporcione los datos de ticket de compra y pueda obtener la factura
              del establecimiento comercial del cual adquirió el/los producto(s),
              debiendo verificar y sujetarse a los requisitos de facturación que
              imponga el establecimiento comercial.
            </p>
          </div>

          <div>
            <h4>
              POLÍTICAS DE ENTREGAS Y ENVÍO
            </h4>
            <p className={classes.textList}>
              <ul className={classes.shippingList}>
                <li>
                  {' '}
                  Los precios de nuestros productos están sujetos a cambios sin previo aviso
                  y pueden variar en cualquier momento, esto debido a existencias y disponibilidad
                  de los productos con nuestros proveedores.

                </li>
                <li>
                  Los precios finales pueden variar contra los publicados
                  dependiendo de la localización
                  del cliente. Nuestra tecnología de cotización interna y
                  de uso en la aplicación digital
                  compara precios de distintos proveedores y puede dar
                  variaciones contra los precios publicados originalmente.
                </li>
                <li>
                  Los precios publicados en nuestros diferentes medios de
                  comunicación electrónicos y/o
                  escritos NO incluyen costos de envío cuando estos pedidos
                  se realicen fuera de las ciudades de León,
                  Guanajuato, Guadalajara, Jalisco y Zapopan, Jalisco, que
                  son donde la empresa cuenta con operaciones
                  fisicas actualmente.
                  {' '}
                </li>
                <li>
                  <strong>El Cliente</strong>
                  {' '}
                  se compromete a proporcionar una dirección válida y
                  localizable dentro de la República Mexicana. En caso de que
                  {' '}
                  <strong>El Cliente</strong>
                  {' '}
                  proporcione una dirección incorrecta, incompleta o que no sea
                  localizable para la empresa, cualquier costo adicional será cubierto por
                  {' '}
                  <strong>El Cliente</strong>
                  . En caso de que la dirección indicada sea considerada una zona
                  peligrosa o se encuentre fuera de los límites del área de entrega,
                  la empresa se encuentra en total derecho de solicitar una nueva dirección dentro
                  del rango de entrega o proceder a una cancelación de dicho pedido, haciendo un
                  reembolso total del dinero recibido en caso de haber realizado un pago anticipado.
                  {' '}
                </li>
                <li>
                  <strong>Cláusulas de entrega dentro y fuera de la ciudad </strong>
                  <ol className={classes.shoppingLetterList}>
                    <li>
                      El tiempo de entrega de los pedidos realizados dependerá totalmente de
                      las rutas programadas diariamente y de la cantidad de pedidos confirmados
                      de otros clientes con anterioridad, así mismo de las
                      condiciones climatológicas,
                      zonas de riesgo y acceso a cada localidad.
                    </li>
                    <li>
                      Se entregará en el domicilio indicado a la persona
                      que se encuentre en ese momento.
                      No necesariamente será al destinatario. De no encontrarse
                      alguna persona en el domicilio,
                      el repartidor de la empresa se deberá retirar del sitio
                      de entrega con el paquete asignado,
                      para posteriormente programar nuevamente la entrega.
                    </li>
                    <li>
                      En caso de un segundo intento fallido de entrega,
                      {' '}
                      <strong>El Cliente</strong>
                      {' '}
                      tendrá un plazo de 5 días naturales para
                      recoger el producto en las oficinas administrativas
                      de la empresa.
                      {' '}
                    </li>
                    <li>
                      El tiempo aproximado de entrega de medicamentos
                      solicitados será confirmado vía telefónica, electrónica o
                      mensaje de texto por parte del equipo de atención al cliente de la empresa.
                      {' '}

                    </li>
                    <li>
                      entrega, contará con 24 horas para reportar anomalías
                      por daño físico, faltantes o productos equivocados, despué
                      s de este plazo, no se procederá a ningún cambio o reclamación.
                      {' '}

                    </li>
                    <li>
                      El tiempo estimado de entrega y el costo de envío variará
                      según la ciudad destino a la que se necesite hacer llegar
                      la compra realizada.

                    </li>
                    <li>
                      todos nuestros pedidos son entregados en las localidades
                      anteriormente mencionadas en la cláusula 3 por el equipo i
                      nterno de logística y operaciones de la empresa.
                      En caso de realizarse un pedido fuera de las áreas designadas
                      para el servicio de envío a domicilio, se procederá a realizar
                      la entrega a través de una empresa de paquetería con la que la
                      farmacia posee alianzas.
                      {' '}

                    </li>
                    <li>
                      Una vez el pedido se encuentre en manos de los procesos
                      logísticos de la empresa de paquetería, la responsabilidad
                      total de la llegada a su destino de los
                      medicamentos y productos solicitados pasará directamente a dicha empresa.
                      {' '}

                    </li>
                    <li>
                      Se le proveerá toda la información necesaria
                      al Cliente para que este pueda rastrear y
                      conseguir su pedido a través de la empresa de paquetería
                      asignada. Será totalmente responsabilidad
                      {' '}
                      <strong>del Cliente</strong>
                      {' '}
                      proceder al seguimiento del mismo.
                      {' '}
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Cláusulas de entrega de medicamentos controlados o antibióticos.</strong>
                  {' '}
                </li>
                <ol className={classes.shoppingLetterList}>
                  <li>
                    <strong>El cliente</strong>
                    {' '}
                    se encuentra en la obligación de presentar una receta médica válida desde
                    el momento de la cotización, adicionalmente, dicha receta será validada por
                    la empresa proveedora para así confirmar la venta del medicamento.
                  </li>
                  <li>
                    Una vez confirmada la venta y programada la entrega, el repartidor deberá
                    solicitar dicha receta en original antes de
                    proporcionar el pedido solicitado por
                    {' '}
                    <strong>El Cliente</strong>
                    {' '}
                    para que este pueda verificar nuevamente su validez.
                    {' '}
                  </li>
                  <li>
                    En caso de que el repartidor considere que la receta médica posea un
                    detalle que afecte su validez o no sea la misma receta presentada al momento de
                    cotización de su pedido, este se encuentra en el total derecho de no entregar
                    el medicamento.
                  </li>
                  <li>
                    No será posible enviar medicamentos controlados
                    (TIPO I, II y III) ni antibióticos fuera de las ciudades donde
                    la empresa ejerce funciones, debido a la necesidad de poseer
                    la evidencia física recetada por el doctor o doctora
                    {' '}
                    <strong>del cliente</strong>
                    .
                    {' '}
                  </li>
                  <li>
                    En el caso de entrega de los medicamentos controlados (TIPO II y III)
                    en las ciudades de operación física de la empresa, el repartidor procederá
                    a solicitar el INE de la persona que se encuentre recibiendo el pedido
                    en el domicilio
                    de entrega, para anotar sus datos en la parte de atrás de la receta médica
                    y posteriormente
                    retirar dicha receta ya que la empresa tiene la obligación legal de tener
                    todo el sustento de
                    las recetas que respalden la venta de medicamentos cotnrolados.
                    {' '}

                  </li>
                  <li>
                    En el caso de los medicamentos Antibióticos (TIPO IV), el repartidor
                    procederá de las siguientes dos formas:
                    <li className={classes.subNumeric}>
                      En caso de que la farmacia esté surtiendo una parte proporcional del
                      tratamiento asignado, el repartidor tendrá que solicitar
                      la receta y sellar con
                      logo de la empresa en la parte de atrás indicando
                      la cantidad de medicamento surtido
                      al cliente y posteriormente devolver la receta.

                    </li>
                    <li className={classes.subNumeric}>
                      En el caso de que la farmacia esté surtiendo y entregando el final o
                      la totalidad del tratamiento, el repartidor deberá
                      solicitar al Cliente la receta
                      médica y retirarla.
                      {' '}

                    </li>
                  </li>

                </ol>
              </ul>
            </p>
          </div>

          <div>
            <h4>
              COMERCIO ELECTRÓNICO:
            </h4>
            <p className={classes.textList}>
              En cumplimiento de las disposiciones mexicanas sobre mensajes de datos
              según el Código Civil Federal, se comunica que la legislación mexicana
              reconoce validez a los mensajes por medios electrónicos y por tanto
              ellos adquieren carácter y entidad probatoria. En consecuencia,
              entienden los Usuarios Finales, que, mediante el cruce de mensajes por
              medios electrónicos, pueden dar lugar al nacimiento, modificación y
              extinción de obligaciones, siendo de su resorte exclusivo el
              contenido, consecuencias, responsabilidades y efectos de la
              información generada.
            </p>
          </div>

          <div>
            <h4>
              PRECIOS
            </h4>
            <p className={classes.textList}>
              Todos los precios están sujetos a cambios en función de los precios de
              venta ISANA vigente en la fecha de envío. Los precios para pedidos
              generales están sujetos a revisión y ajuste retroactivo, si es
              necesario, sobre la base de las cantidades enviadas.
            </p>
          </div>

          <div>
            <h4>
              IMPUESTOS
            </h4>
            <p className={classes.textList}>
              Todos los impuestos son exclusivos del gobierno federal, estatal y
              municipal. El comprador será responsable de todos los impuestos
              derivados de las ventas, uso y otros impuestos (ya sea local, estatal
              o federal) previstos en el presente Acuerdo o en los productos y/o
              servicios suministrados al comprador.
            </p>
          </div>

          <div>
            <h4>
              FUERZA MAYOR Y CASO FORTUITO
            </h4>
            <p className={classes.textList}>
              En el supuesto de que ISANA sea incapaz, en su totalidad o en parte,
              por un caso de fuerza mayor o caso fortuito, para cumplir sus
              obligaciones en virtud de este Acuerdo, las obligaciones afectadas por
              el evento se suspenderán en el plazo que se mantenga o persista dicha
              incapacidad. ISANA tomará medidas razonables para mitigar los casos de
              fuerza mayor o casos fortuitos.
            </p>
          </div>

          <div>
            <h4>
              CANCELACIÓN
            </h4>
            <p className={classes.textList}>
              Los servicios adquiridos de ISANA y/o sus Terceros Asociados no pueden
              ser cancelados o modificados, o hechos en entregas diferidas, por el
              Usuario Final, hasta concluir su vigencia; excepto con el
              consentimiento previo y por escrito de ISANA y sólo en términos
              aceptables por el mismo.
            </p>
            <p className={classes.textList}>
              En el caso de la suscripción,
            </p>
            <p className={classes.textList}>
              El usuario deberá solicitar la cancelación dentro de los primeros 5
              (cinco) días hábiles después de haberlo solicitado y/o en el lapso de
              3 días hábiles de la entrega de los productos con su empaque original,
              junto con los accesorios que en caso pudiera incluir. Se le pedirá al
              usuario enviar un correo electrónico a hola@isana.mx solicitando la
              cancelación y haciendo una breve explicación por el motivo que desea
              cancelar el pedido. Cumpliendo con lo anterior Isana abonará al
              usuario el pago total de los productos dentro de un máximo de 30 días
              contados a partir de que se devuelvan los productos adquiridos
              cancelados.
            </p>
            <p className={classes.textList}>
              El usuario tendrá la obligación de devolver el o los productos en las
              mismas condiciones en las que los recibió y sin haberlos utilizado.
              Deberá enviarse con el embalaje y etiquetado original y en la misma
              caja o sobre en el que fue enviado o en su defecto en algún formato
              similar que garantice la devolución en perfecto estado. Adicionalmente
              deberá incluir copia del comprobante de pago en el paquete.
            </p>
            <p className={classes.textList}>
              En todos los casos, los costos de envío y seguro correrán a cargo del
              usuario.
            </p>
          </div>

          <div>
            <h4>
              DEVOLUCIONES
            </h4>
            <p className={classes.textList}>
              Los servicios adquiridos de ISANA y/o sus Terceros Asociados, no
              pueden ser devueltos por el Usuario Final, hasta concluir su vigencia;
              excepto con el consentimiento previo y por escrito de ISANA y sólo en
              términos aceptables por el mismo.
            </p>
            <p className={classes.textList}>
              No se aceptarán cambios ni devoluciones en los siguientes casos:
            </p>
            <ul>
              <li className={classes.textList}>
                Sí el artículo fue manipulado por terceros;
              </li>
              <li className={classes.textList}>
                Uso inadecuado especificado en el manual;
              </li>
              <li className={classes.textList}>
                Daño ocasionado por conexión inadecuada;
              </li>
            </ul>

            <p className={classes.textList}>
              Isana acepta devoluciones de productos comprados en Isana siempre y
              cuando el producto se encuentre dañado o sea defectuoso, entendiéndose
              como tal lo siguiente:
            </p>
            <ul>
              <li className={classes.textList}>
                El contenido neto del producto o la cantidad entregada es menor a la
                indicada en el envase, recipiente o empaque, en su caso.
              </li>
              <li className={classes.textList}>
                Que el bien no corresponde a la calidad, marca, o especificaciones y
                demás elementos sustanciales bajo los cuales se haya ofrecido.
              </li>
            </ul>
            <p className={classes.textList}>
              Por salud y bienestar de los usuarios NO hay cambios ni devoluciones
              en: productos de la salud de uso único o cotidiano y sean de contacto
              físico con la piel o saliva.
            </p>
            <p className={classes.textList}>
              En este caso, Isana podrá efectuar la bonificación del pago por el
              producto mediante el mismo método de pago utilizado siempre y cuando
              los productos no hayan sido alterados a causa del usuario. Cumpliendo
              con lo anterior Isana abonará al usuario el pago total de la orden
              dentro de un máximo de 30 días.
            </p>
            <p className={classes.textList}>
              La solicitud para devolución o bonificación deberá efectuarse en
              principio por reclamo a través de un correo electrónico a
              hola@isana.mx dentro de un plazo máximo de 30 días, enviando evidencia
              del defecto en cuestión.
            </p>
            <p className={classes.textList}>
              Isana podrá realizar la bonificación del monto pagado por el mismo
              método de pago que se haya efectuado la compra.
            </p>
            <p className={classes.textList}>
              Isana, una vez resuelta la reclamación dentro del plazo de 15 días
              siguientes a su presentación, reemplazará el producto en un plazo
              razonable que no excederá de 30 días, en el entendido de la sujeción a
              factores independientes de Isana como son la entrega por parte de la
              empresa de paquetería, disponibilidad del producto a sustituir o
              disponibilidad de las piezas de este.
            </p>
            <p className={classes.textList}>
              En caso de que Isana no cuente con el mismo producto a remplazar,
              podrá proponer al usuario entregar un producto distinto, del mismo
              valor y que cumpla las mismas funciones y características que el
              solicitado.
            </p>
            <p className={classes.textList}>
              Isana podrá negarse a satisfacer la reclamación si ésta es
              extemporánea, cuando el producto haya sido usado en condiciones
              distintas a las recomendadas o propias de su naturaleza o destino o si
              ha sufrido un deterioro esencial, irreparable y grave por causas
              imputables al consumidor.
            </p>
            <p className={classes.textList}>
              Las disposiciones anteriores sólo se aplican a las compras realizadas
              en línea. Los productos comprados en una tienda estarán sujetos a la
              política de devolución de cada tienda. Las devoluciones o cambios para
              las compras en una tienda de Isana o lugar de venta asociado no pueden
              ser devueltos o intercambiados a Isanate.com. Las devoluciones para
              las compras realizadas en línea no serán aceptadas para su devolución
              o cambio en una tienda o mostrador.
            </p>
            <p className={classes.textList}>
              El usuario tendrá derecho a retornar los productos defectuosos
              comprados en línea, dentro de los 30 días siguientes a la recepción
              del pedido.
            </p>
          </div>

          <div>
            <h4>
              TERMINACIÓN DE SERVICIOS
            </h4>
            <p className={classes.textList}>
              En caso de que el Usuario Final infrinja el espíritu, la esencia o
              alguna disposición en el presente Acuerdo, cree riesgos de cualquier
              tipo para ISANA o exponga al mismo a posibles responsabilidades
              jurídicas, ISANA podrá impedir el acceso a sus servicios y productos
              de forma total o parcial. Se le notificará por correo electrónico o la
              próxima vez que intente acceder a su cuenta. De igual manera, el
              comprador o usuario podrá eliminar su cuenta o desactivar su
              aplicación en cualquier momento que así lo desee.
            </p>
          </div>

          <div>
            <h4>
              CONDICIONES GENERALES
            </h4>
            <h4>
              PROPIEDAD INTELECTUAL
            </h4>
            <p className={classes.textList}>
              La propiedad intelectual no está asignada al Comprador. ISANA poseerá
              o seguirá siendo el propietario de todos los derechos de propiedad
              intelectual utilizados o creados en el transcurso de la realización de
              este acuerdo. Cualquier desarrollo realizado a partir de alguna
              sugerencia por parte de un Comprador, no se transmitirá o compartirá
              la titularidad de los derechos provenientes de dicha Propiedad
              Intelectual, por lo que serán propiedad de ISANA. El comprador se
              compromete a no realizar ingeniería inversa de cualquier Medicamentos
              comprado o suministrado. «Propiedad intelectual» significa las
              patentes y solicitudes de patentes, las invenciones patentables
              (patentables o no), marcas registradas, marcas de servicio, imagen
              comercial, derechos de autor, secretos comerciales, know-how, datos
              humanos, especificaciones, dibujos, diseños, derechos mask work,
              derechos morales, derechos de autor, y otros derechos de propiedad
              intelectual, que puedan existir ahora o en lo sucesivo, y todas las
              renovaciones y prórrogas, independientemente de cualquiera de esos
              derechos nacen bajo las leyes de los Estados Unidos Mexicanos o de
              cualquier otro estado, país o jurisdicción, los registros o las
              aplicaciones de los mismos, y todos los fondos de comercio pertinentes
              a ellas.
            </p>
          </div>

          <div>
            <h4>
              CESIONES
            </h4>
            <p className={classes.textList}>
              El comprador no podrá ceder, transferir o delegar este contrato, así
              como cualquier parte de los derechos o deberes del comprador, sin
              previo aviso y consentimiento de ISANA. Cualquier intento de cesión en
              violación de esta sección ser nula y sin efecto.
            </p>
          </div>

          <div>
            <h4>
              ENLACES CON EL SITIO WEB
            </h4>
            <p className={classes.textList}>
              El comprador estará de acuerdo en que si se incluye un enlace (link)
              de cualquier otro sitio web al Sitio Web, dicho enlace se abrirá en
              una nueva ventana navegadora y se enlazará con la versión completa de
              una página formateada HTML de este Sitio Web. El comprador no tiene
              permitido enlazarse directamente a ninguna imagen almacenada en el
              Sitio Web o en nuestros servicios, como sería usar un método de enlace
              «en-línea» para provocar que la imagen almacenada por nosotros fuera
              expuesta en otro sitio web. Asimismo, se obliga a no descargar o usar
              imágenes almacenadas en este Sitio Web en otro sitio web, con
              cualquier propósito, incluyendo, sin limitación, publicar dichas
              imágenes en otro sitio web. De igual manera, se obliga a no enlazarse
              de cualquier otro sitio web a este Sitio Web de tal manera que el
              Sitio Web, o cualquier página del Sitio Web, esté «enmarcado», rodeado
              u ofuscado por los contenidos, materiales o posicionamientos de marca
              de cualquier tercero. Nos reservamos todos nuestros derechos bajo la
              ley para insistir en que cualquier enlace al Sitio Web sea
              descontinuado y a revocar su derecho a enlazarse al Sitio Web de
              cualquier otro sitio web, en cualquier momento en el que le mandemos
              notificación por escrito. ISANA se desvincula de cualquier
              información, problema, infracciones o inconveniente causado al
              Comprador o Usuario de páginas de terceros, por lo que en ningún
              momento podrán ejercer acción civil, mercantil, penal o cualquier otra
              en contra de ISANA por cuestiones de una página web de un tercer,
              inclusive si dicho enlace está en la Aplicación móvil o página Web.
            </p>
          </div>

          <div>
            <h4>
              EMPLEO DE TECNOLOGÍA “COOKIE”
            </h4>
            <p className={classes.textList}>
              Isana utiliza “cookies” cuando un Usuario navega por el sitio web
              correspondiente al Servicio. Las “cookies” se asocian únicamente con
              un Usuario anónimo y su ordenador, por lo que no proporcionan
              referencias que permitan deducir el nombre y apellidos del Usuario.
              Las “cookies” de Isana no pueden leer datos de su disco duro ni leer
              los archivos cookie creados por otros proveedores. El uso de la
              tecnología de las “cookies”, permite que Isana reconozca a los
              Usuarios registrados después de que éstos se hayan registrado por
              primera vez, sin que se tengan que registrar en cada visita para
              acceder a las áreas y servicios reservados exclusivamente a ellos. El
              Usuario tiene la posibilidad de configurar su navegador para ser
              avisado en pantalla de la recepción de “cookies” y para impedir la
              instalación de estas en su disco duro. Para utilizar el Servicio, no
              resulta necesario que el Usuario permita la instalación de las
              “cookies” enviadas Isana, sin perjuicio de que en tal caso será
              necesario que el Usuario se registre cada vez que acceda al Servicio.
            </p>
            <h4>
              LEY APLICABLE Y RESOLUCIÓN DE CONTROVERSIAS
            </h4>
            <p className={classes.textList}>
              Este Acuerdo se regirá, interpretará y aplicará de conformidad con las
              leyes de la CIUDAD DE MÉXICO, sin tener en cuenta conflictos de
              principios legales. Cualquier y todas las disputas derivadas de, o en
              relación con este acuerdo o su rendimiento («controversias») deberá
              ser resuelta por las Partes, al procurar la mediación en GUANAJUATO y
              conforme sus leyes o reglamentos. Si el conflicto no se resuelve
              transcurridos sesenta (60) días del comienzo de la mediación será
              litigado en los tribunales estatales o federales ubicados en el estado
              de GUANAJUATO. EN LA MÁXIMA MEDIDA PERMITIDA POR LA LEY, LAS PARTES
              CONVIENEN EN MEDIACIÓN Y QUE NO HABRÁ JUICIO EN CUALQUIER CONTROVERSIA
              A MENOS QUE SOBREPASE EL TIEMPO SEÑALADO EN EL PRESENTE PÁRRAFO. EL
              COMPRADOR RENUNCIARÁ A CUALQUIER OTRA JURISDICCIÓN O PROTECCIÓN, YA
              SEA DE MÉXICO O DE CUALQUIER OTRO PAÍS.
            </p>
            <h4>
              VALIDEZ DE DISPOSICIONES
            </h4>
            <p className={classes.textList}>
              En caso de que una o más de las disposiciones contenidas en este
              acuerdo o la aplicación de estas en cualquier circunstancia, sea
              declarada por cualquier autoridad competente como inválida, ilegal o
              no susceptible de ejecución, en cualquier aspecto o por cualquier
              motivo, la validez, legalidad y la obligatoriedad de cualquiera de
              dichas disposiciones en cualquier otro aspecto y de las disposiciones
              restantes de estos términos y condiciones, no serán limitadas o
              afectadas de forma alguna.
            </p>
            <h4>
              SIN RENUNCIA
            </h4>
            <p className={classes.textList}>
              El fallo o retraso de ISANA para ejercer un derecho o poder en virtud
              de este acuerdo no operará como una renuncia de éstos, ni ningún
              ejercicio individual o parcial de un derecho o poder excluirá
              cualquier otro ejercicio futuro del mismo.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Policies;
