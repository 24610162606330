import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Box, Typography, Container } from '@material-ui/core';
import styles from './styles';
import CdsoCard from '../../../components/CdsoCard';

const CdsoProfile = ({ customer }) => {
  const classes = styles();
  const [open, setOpen] = useState(false);

  const handleOpenCard = () =>{
    setOpen(!open)
  }
  return (
    <Container maxWidth="xl">
      <Grid item xs={12}>
        <Box className={classes.box}>
          <Box className={classes.infoClient}>
            <Typography className={classes.name}>{customer.name} {customer.lastName}</Typography>
              <Typography className={classes.cardNumber} onClick={handleOpenCard}>
                Ver mi número de usuario
              </Typography>
            <CdsoCard open={open} onClose={handleOpenCard} cdsoUserId={customer.cdsoCardNumber}/>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.boxList}>
          <Typography className={classes.textList}>
            Aquí verás la lista de productos de regalos que tienes disponible.
          </Typography>
        </Box>
      </Grid>
    </Container>
  );
};

CdsoProfile.propTypes = {
  customer: PropTypes.shape({
    name:PropTypes.string,
    lastName:PropTypes.string,
    cdsoCardNumber: PropTypes.string
  })
};

CdsoProfile.defaultProps = {
  customer: {
    name:'',
    lastName:'',
    cdsoCardNumber: ''
  }
};

const mapsStateToProps = (state) => {
  const { customer } = state.customer;
  return { customer };
};

export default connect(mapsStateToProps, {})(CdsoProfile);
