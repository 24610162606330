import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme';

const useStyles = makeStyles((theme) => ({
  categorieTitle: {
    paddingTop: '1rem',
    backgroundColor: Colors.lowGray
  },
  accordionFilters: {
    backgroundColor: Colors.lowGray
  },
  categoryName: {
    whiteSpace: 'break-spaces'
  },
  itemSelected: {
    backgroundColor: `${Colors.transparentBlue} !important`
  },
  colListCategories: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflowX: 'auto',
    width: '18rem',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      height: '60vh'
    }
  },
  circularProgressBox: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  textField: {
    margin: '0.3rem'
  }
}));

export default useStyles;
