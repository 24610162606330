import { Box, CircularProgress, Container, Grid, Zoom } from '@material-ui/core';
import { Error, VerifiedUser } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Title from '../../components/Title';
import useSearchQuery from '../../customHooks/useSearchQuery';
import { resendVerificationEmail, verifyEmail } from '../../services/auth';
import styles from './styles';

const Verification = ({ account }) => {
  const classes = styles();
  const navigate =  useNavigate();
  const [checked, setChecked] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [checkedEmailVerified, setCheckedEmailVerified] = useState(false);
  const [checkedError, setCheckedError] = useState(false);
  const query = useSearchQuery();

  useEffect(() => {
    const token = query.get('code');
    if (!token) {
      setRedirect(true);
    }
    const params = {
      token
    };
    verifyEmail(params)
      .then(() => {
        setChecked(false);
        setCheckedEmailVerified(true);
      })
      .catch(() => {
        setChecked(false);
        setCheckedError(true);
        resendVerificationEmail(account);
      });
  }, []);

  return (
    <Container maxWidth="xl">
      {redirect && navigate('/home')}
      <Grid className={classes.container}>
        <Zoom in={checked} style={{ transitionDelay: checked ? '500ms' : '0ms' }}>
          <Box className={checked ? classes.box : ''}>
            {checked && (
              <>
                <CircularProgress />
                <Title text="Verificando tu email" />
              </>
            )}
          </Box>
        </Zoom>
        <Zoom
          in={checkedEmailVerified}
          style={{ transitionDelay: checkedEmailVerified ? '500ms' : '0ms' }}
        >
          <div>
            {checkedEmailVerified && (
              <Box className={checkedEmailVerified ? classes.box : ''}>
                <VerifiedUser className={classes.icon} fontSize="large" />
                <Title text="¡Email verificado!" />
                <Link className={checkedEmailVerified ? classes.button : ''} to="/productos">
                  Ordenar productos
                </Link>
              </Box>
            )}
          </div>
        </Zoom>
        <Zoom in={checkedError} style={{ transitionDelay: checkedError ? '500ms' : '0ms' }}>
          <Box className={checkedError ? classes.box : ''}>
            <Error className={classes.icon} fontSize="large" />
            <Title text="Parece que hubo un error, te mandaremos un nuevo correo de verificación." />
          </Box>
        </Zoom>
      </Grid>
    </Container>
  );
};

const mapsStateToProps = (state) => {
  const { account } = state.auth;
  return { account };
};

Verification.propTypes = {
  account: PropTypes.shape({
    email: PropTypes.string
  })
};

Verification.defaultProps = {
  account: {
    email: ''
  }
};

export default connect(mapsStateToProps, {})(Verification);
