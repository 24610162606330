import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles(() => ({
    button: {
        borderRadius: '0.5rem',
        fontWeight: 'bold',
        padding: '0.4rem 2.7rem 0.4rem 2.7rem',
        fontSize: '0.75rem',
        marginTop: '0.3rem'
      },
      buttonLoader: {
        position: 'absolute',
        margin: 'auto'
      },
      buttonAdd: {
        background: Colors.blueViolet,
        color: Colors.simpleWhite,
        width: '19rem',
        marginLeft: '1rem',
        fontWeight: 'bold',
        '&:hover': {
          background: Colors.honeyFlower,
          color: 'white'
        }
      }
}));
export default styles;
