import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  boxFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '1rem'
    }
  },
  cardFix: {
    borderRadius: '0.8rem',
    width: '18rem',
    height: '26rem',
    [theme.breakpoints.up('lg')]: {
      width: '17rem'
    }
  },
  title: {
    fontSize: '1.1rem',
    fontWeight: 'bold'
  },
  subTitle: {
    fontSize: '0.9rem'
  },
  subText: {
    fontSize: '0.8rem'
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingTop: '2.3rem',
    width: '15rem'
  },
  promoBox: {
    background: Colors.softViolet,
    width: '5rem',
    borderRadius: '0.2rem',
    marginLeft: '-1rem'
  },
  promoText: {
    color: Colors.simpleWhite,
    paddingLeft: '1.5rem',
    fontWeight: 'bold'
  }
}));

export default styles;
