import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText
  } from '@material-ui/core';
  import React from 'react';
  import PropTypes from 'prop-types';
  import styles from './styles';
  
  const DialogModal = ({
    open, name, onClose
  }) => {
    const classes = styles();
    return (
      <div>
        <Dialog
          open={open}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          onClose={onClose}
  
        >
          <DialogContent className={classes.box}>
            <DialogContentText className={classes.name} id="alert-dialog-slide-description">
              {name}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} className={classes.buttonClose} variant="contained" color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  DialogModal.propTypes = {
    name: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func
  };
  
  DialogModal.defaultProps = {
    name: '',
    open: false,
    onClose: () => {}
  };

  export default DialogModal;
  