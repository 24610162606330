/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  Collapse,
  Container,
  Grid, Link, TextField, Typography
} from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import images from '../../assets/images';
import useValidField from '../../customHooks/useValidField';
import styles from './styles';
import { FirebaseContext } from '../../customContext/FirebaseContext';

const ResetPassword = () => {
  const classes = styles();
  const [showMessage, setShowMessage] = useState(false);
  const userEmail = useValidField({ type: 'email' });
  const [msgResponse, setMsgResponse] = useState('');
  const [emailField, setEmailField] = useState('');
  const { sendUserEmailResetPassword } = useContext(FirebaseContext);

  const validate = (validator) => {
    if (validator.error) {
      return '';
    }
    return validator.value;
  };

  useEffect(() => {
    setEmailField(validate(userEmail));
  }, [userEmail.value]);

  const getPropsValidate = (validator) => {
    const { value, setValue, ...validationProps } = validator;
    return validationProps;
  };

  const handleClickResetPassword = async () => {
    try {
      await sendUserEmailResetPassword(emailField);
      setMsgResponse('Te enviamos un correo de recuperación');
      setShowMessage(true);
    } catch (error) {
      setMsgResponse('Hubo un detalle al envíar el email ');
      setShowMessage(true);
    }
  };
  return (
    <Container maxWidth="xl">
      <Grid container className={classes.container}>
        <Grid item md={6} sm={12} xs={12}>
          <div className={classes.box}>
            <div className={classes.form}>
              <Typography variant="h6" className={classes.title}>
                Recupera tu contraseña
              </Typography>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                size="small"
                className={classes.input}
                {...getPropsValidate(userEmail)}
              />
              <Button
                onClick={handleClickResetPassword}
                fullWidth
                className={`${classes.button} ${classes.input}`}
                variant="contained"
                color="primary"
              >
                Recuperar contraseña
              </Button>
              <Collapse in={showMessage}>
                <Box className={`${classes.input}, ${classes.boxWarning}`}>
                  <Typography className={classes.warningText}>{msgResponse}</Typography>
                </Box>
              </Collapse>
              <Typography variant="body2" className={classes.input}>
                ¿Quieres intentar iniciar sesión de nuevo? Hazlo
                {' '}
                <Link href="/login">aqui.</Link>
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item md={6} sm={12}>
          <div className={classes.box}>
            <img src={images.bannerOk} className={classes.image} alt="iSana" />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
export default ResetPassword;
