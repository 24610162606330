/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Box, Card, Typography, TextField, Button, Dialog, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import SearchPlaceInput from '../../../components/SearchPlaceInput';
import useValidField from '../../../customHooks/useValidField';
import { updateCustomer } from '../../../services/customers';
import { geocodeLocation, parseGoogleAddress } from '../../../utils/googleMaps';
import { getPropsValidate } from '../../../helper';

const AddAdress = ({
  open,
  onClose,
  getUpdatedCustomer,
  handleSuccess,
  handleClickUpdate,
  customer
}) => {
  const classes = useStyles();
  const postalCode = useValidField({ type: 'number' });
  const state = useValidField({ type: 'text' });
  const city = useValidField({ type: 'text' });
  const street = useValidField({ type: 'text' });
  const neighborhood = useValidField({ type: 'text' });
  const exteriorNumber = useValidField({ type: 'text' });
  const interiorNumber = useValidField({ type: 'text', skip: true });
  const addressNote = useValidField({ type: 'text', skip: true });
  const alias = useValidField({ type: 'text' });
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  /**
   * handleSetValue
   * set a new value to a validator
   * @param {value}
   * @returns {string}
   */
  const handleSetValue = (validator, newValue) => {
    const { setValue } = validator;
    setValue(newValue || '');
  };

  /**
   * cleanInputValue
   * set a empty string value to a validator
   * @param {value}
   * @returns {string}
   */
  const cleanInputValue = (validator) => {
    const { setValue } = validator;
    setValue('');
  };

  /**
   * createNewLocation
   * Add a new location to customer locations and update data
   */
  const createNewLocation = () => {
    const location = {
      locations: [
        {
          city: city.value,
          neighborhood: neighborhood.value,
          postalCode: postalCode.value,
          state: state.value,
          street: street.value,
          alias: alias.value,
          exteriorNumber: exteriorNumber.value,
          interiorNumber: interiorNumber.value,
          deliveryInstructions: addressNote.value,
          lat: lat || 0,
          lng: lng || 0
        },
        ...customer.locations
      ],
      _id: customer._id
    };
    updateCustomer({ params: location })
      .then((response) => {
        getUpdatedCustomer(response);
        cleanInputValue(city);
        cleanInputValue(neighborhood);
        cleanInputValue(postalCode);
        cleanInputValue(exteriorNumber);
        cleanInputValue(interiorNumber);
        cleanInputValue(addressNote);
        cleanInputValue(state);
        cleanInputValue(street);
        cleanInputValue(alias);
        handleSuccess(true);
        handleClickUpdate();
        setSearchValue(null);
        onClose();
      })
      .catch(() => {
        handleSuccess(false);
        handleClickUpdate();
        onClose();
      });
  };

  /**
   * selectLocation
   * set the value over a searched location
   */
  const selectLocation = async () => {
    const result = await geocodeByAddress(searchValue.label);
    const latlng = await getLatLng(result[0]);
    const code = await geocodeLocation(latlng.lat, latlng.lng);
    const parseAddress = await parseGoogleAddress(code.data.results[0]);
    const pc = parseInt(parseAddress.postalCode, 10);
    handleSetValue(street, parseAddress.street);
    handleSetValue(state, parseAddress.state);
    handleSetValue(city, parseAddress.city);
    handleSetValue(neighborhood, parseAddress.neighborhood);
    handleSetValue(postalCode, pc);
    handleSetValue(exteriorNumber, parseAddress.streetNumber);
    setLat(parseAddress.lat);
    setLng(parseAddress.lng);
  };

  /**
   * validateEmptyValues
   *  check if a value is empty
   * @param {value}
   * @returns {boolean}
   */
  const validateEmptyValues = () => {
    if(!city.value||
      !street.value||
      !postalCode.value||
      !state.value||
      !neighborhood.value||
      !exteriorNumber.value||
      !alias.value){
        return true;
      } 
      return false;
  };

  useEffect(() => {
    if (searchValue) {
      selectLocation();
    }
  }, [searchValue]);

  return (
    <Dialog open={open}>
      <Box className={classes.gridFix}>
        <Card className={classes.card}>
          <Box className={classes.actionBox}>
            <Typography variant="h6">Agregar Dirección</Typography>
            <IconButton onClick={onClose}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
          <Box>
            <SearchPlaceInput
              onChange={setSearchValue}
              value={searchValue}
              onSelect={selectLocation}
            />
            <Box className={classes.fixRow}>
              <TextField
                size="small"
                className={`${classes.textfield} ${classes.fixSmallField}`}
                required
                label="Calle"
                variant="outlined"
                fullWidth
                value={street.value}
                {...getPropsValidate(street)}
              />
              <TextField
                size="small"
                className={classes.textfield}
                fullWidth
                value={exteriorNumber.value}
                required
                label="Número exterior"
                variant="outlined"
                {...getPropsValidate(exteriorNumber)}
              />
            </Box>
            <Box className={classes.fixRow}>
              <TextField
                size="small"
                className={`${classes.textfield} ${classes.fixSmallField}`}
                fullWidth
                value={interiorNumber.value}
                label="Número interior"
                variant="outlined"
                {...getPropsValidate(interiorNumber)}
              />
              <TextField
                size="small"
                className={classes.textfield}
                required
                label="Colonia"
                variant="outlined"
                fullWidth
                value={neighborhood.value}
                {...getPropsValidate(neighborhood)}
              />
            </Box>
            <TextField
              size="small"
              className={classes.textfield}
              required
              label="Ciudad"
              variant="outlined"
              fullWidth
              value={city.value}
              {...getPropsValidate(city)}
            />
            <div className={classes.fixRow}>
              <TextField
                size="small"
                className={`${classes.textfield} ${classes.fixSmallField}`}
                fullWidth
                required
                label="Codigo Postal"
                variant="outlined"
                value={postalCode.value}
                {...getPropsValidate(postalCode)}
              />
              <TextField
                size="small"
                className={classes.textfield}
                fullWidth
                value={state.value}
                required
                label="Estado"
                variant="outlined"
                {...getPropsValidate(state)}
              />
            </div>
            <TextField
              size="small"
              className={classes.textfield}
              label="Indicaciones adicionales"
              variant="outlined"
              fullWidth
              {...getPropsValidate(addressNote)}
            />
            <TextField
              size="small"
              className={classes.textfield}
              label="Alias (Mi casa, Oficina, etc.)"
              variant="outlined"
              fullWidth
              value={alias.value}
              required
              {...getPropsValidate(alias)}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            className={classes.textfield}
            onClick={createNewLocation}
            disabled={validateEmptyValues()}
          >
            Agregar
                  </Button>
        </Card>
      </Box>
    </Dialog>
  )
}


AddAdress.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  getUpdatedCustomer: PropTypes.func,
  handleSuccess: PropTypes.func,
  handleClickUpdate: PropTypes.func,
  customer: PropTypes.shape({
    _id: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

AddAdress.defaultProps = {
  open: false,
  onClose: () => { },
  getUpdatedCustomer: () => { },
  handleSuccess: () => { },
  handleClickUpdate: () => { },
  customer:{
    _id: '',
    locations:[]
  }
};

export default AddAdress;
