import React from 'react'
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core'
import useStyles from './useStyles'
import CustomLink from '../../../../../components/CustomLink';

const MenuLink = ({ isExternal, text, to, onClick }) => {

    const classes = useStyles();
    const { pathname } = window.location;

    return (
      <Grid item xs={12} md={2}  className={classes.gridFix}>
        <CustomLink isExternal={isExternal} to={to} className={classes.menuLink} onClick={onClick}>
          <Button
            size="small"
            fullWidth
            className={`${classes.buttonLink} ${pathname === to && classes.active}`}
          >
            {text}
          </Button>
        </CustomLink>
      </Grid>
    );
}

MenuLink.propTypes = {
    isExternal: PropTypes.bool,
    to: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string
}

MenuLink.defaultProps = {
    isExternal: false,
    to: '',
    onClick: () => {},
    text: ''
}

export default MenuLink;
