import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: '120rem',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  imageBanner: {
    width: '18rem',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '23rem',
      marginBottom: '2rem',
      marginTop: '2rem'
    },
    [theme.breakpoints.up('md')]: {
      width: '28rem',
      marginBottom: '2rem',
      marginTop: '2rem'
    },
    [theme.breakpoints.up('lg')]: {
      width: '30rem',
      marginBottom: '2rem',
      marginTop: '2rem'
    }
  },
  bannerSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  miniTitle: {
    fontSize: '0.7rem',
    color: Colors.gray,
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9rem'
    }
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold'
  },
  subTitle: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem'
    }
  },
  button: {
    background: Colors.electricViolet,
    borderRadius: '20rem',
    width: '13rem',
    color: Colors.simpleWhite,
    '&:hover': {
      background: Colors.honeyFlower,
      color: Colors.simpleWhite
    }
  },
  cardRow: {
    marginTop: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  cardIons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '20rem',
    padding: '2rem'
  },
  imageIcon: {
    width: '6rem',
    height: 'auto'
  },
  textIcon: {
    fontWeight: 'bold',
    marginTop: '0.3rem'
  },
  subTextIcon: {
    fontSize: '0.8rem',
    textAlign: 'center',
    marginTop: '1rem'
  },
  imageTeam: {
    width: '28rem',
    [theme.breakpoints.up('md')]: {
      width: '35rem'
    },
    [theme.breakpoints.up('xl')]: {
      width: '40rem'
    }
  },
  textVideoRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '15rem',
    width: '35rem',
    padding: '3rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '5rem'
    },
    [theme.breakpoints.up('md')]: {
      width: '48rem'
    }
  },
  textVideo: {
    fontSize: '0.9rem',
    padding: '0rem 2rem 0rem 2rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      padding: '0rem 2rem 0rem 2rem'
    }
  },
  teamRow: {
    marginTop: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  }
}));

export default styles;
