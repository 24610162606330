import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  container: {
    marginBottom: '2rem'
  },
  imageBanner: {
    width: '18rem',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '23rem',
      marginBottom: '2rem',
      marginTop: '2rem'
    },
    [theme.breakpoints.up('md')]: {
      width: '28rem',
      marginBottom: '2rem',
      marginTop: '2rem'
    },
    [theme.breakpoints.up('lg')]: {
      width: '30rem',
      marginBottom: '2rem',
      marginTop: '2rem'
    }
  },
  bannerSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'justify-between',
      flexDirection: 'row'
    }
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.up('md')]: {
      padding: '2rem'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '2rem 4rem 2rem 4rem'
    }
  },
  miniTitle: {
    fontSize: '0.7rem',
    color: Colors.lowGray,
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9rem'
    }
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold'
  },
  subTitle: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      width: '30rem',
      margin: '1rem 0rem 1rem 0rem'
    }
  },
  button: {
    background: 'none',
    borderRadius: '20rem',
    width: '13rem',
    color: Colors.black,
    fontSize: '0.5rem',
    '&:hover': {
      background: Colors.lowGray
    }
  },
  cardRow: {
    marginTop: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  cardIons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '20rem',
    padding: '2rem'
  },
  imageIcon: {
    width: '6rem',
    height: 'auto'
  },
  textIcon: {
    fontWeight: 'bold',
    marginTop: '0.3rem'
  },
  subTextIcon: {
    fontSize: '0.8rem',
    textAlign: 'center',
    marginTop: '1rem'
  },
  imageTeam: {
    width: '28rem',
    [theme.breakpoints.up('md')]: {
      width: '35rem'
    },
    [theme.breakpoints.up('xl')]: {
      width: '40rem'
    }
  },
  textVideo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '15rem',
    width: '35rem',
    [theme.breakpoints.up('md')]: {
      width: '48rem'
    }
  },
  teamRow: {
    marginTop: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  icon: {
    width: '1.5rem',
    paddingRight: '0.2rem'
  },
  rowQuestion: {
    padding: '0rem 2rem 0rem 2rem',
    [theme.breakpoints.up('md')]: {
      padding: '0rem 8rem 0rem 8rem'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0rem 16rem 0rem 16rem'
    }
  },
  buttonsMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  buttonsDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  }
}));

export default styles;
