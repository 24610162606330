import { API_URL_CDSO } from '../constants';
import axios from '../utils/axios';

/**
 * Get Bonus by products selected in shopping cart
 * @param {string} cardNumber CDSO card number
 * @param {array} products List of products with offers
 * @returns Promise<array>
 */
export const getBonusProducts = async (cardNumber, products) => {
  const response = await axios.post(`${API_URL_CDSO}/bonus-products`, { cardNumber, products });
  return response.data;
};

/**
 * Activate cdso card
 * @param {object} body data to activate cdso catd
 * @returns promise<string>
 */
export const activateCdsoCard = async (body) => {
  const response = await axios.post(`${API_URL_CDSO}/activate-card`, body);
  return response.data;
};

/**
 * Assign existing cdso card to customer
 * @param {string} clientId client id to assign cdso card
 * @param {string} cardNumber Cdso card number
 * @returns Promise
 */
export const assignCdsoCard = async ({ clientId, cardNumber }) => {
  const response = await axios.post(`${API_URL_CDSO}/assign-card`, { clientId, cardNumber });
  return response.data;
};
