/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Card, TextField, Typography
} from '@material-ui/core';
import styles from './styles';
import { getPropsValidate, validateInputValue } from '../../../../helper';

const AddressForm = ({
  street,
  exteriorNumber,
  interiorNumber,
  neighborhood,
  city,
  postalCode,
  state,
  addressNote,
  alias,
  onClick,
  buttonText,
  search,
  title,
  location
}) => {
  const classes = styles();

  const validateFormButtonDisabled = () => {
       if( validateInputValue(city.value, location.city)
           && validateInputValue(street.value, location.street)
           && validateInputValue(postalCode.value, location.postalCode)
           && validateInputValue(state.value, location.state)
           && validateInputValue(neighborhood.value, location.neighborhood)
           && validateInputValue(exteriorNumber.value, location.exteriorNumber)
           && validateInputValue(alias.value, location.alias)){
                        return true;
          }
          return false;
  }

  return (
    <Card className={classes.card}>
      <Box>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box>
        {search && (
        <TextField
          size="small"
          className={classes.textfield}
          label="Buscar dirección"
          variant="outlined"
          fullWidth
        />
        )}
        <Box className={classes.fixRow}>
          <TextField
            size="small"
            className={`${classes.textfield} ${classes.fixSmallField}`}
            required
            label="Calle"
            variant="outlined"
            fullWidth
            value={street.value}
            {...getPropsValidate(street)}
          />
          <TextField
            size="small"
            className={classes.textfield}
            fullWidth
            value={exteriorNumber.value}
            required
            label="Numero exterior"
            variant="outlined"
            {...getPropsValidate(exteriorNumber)}
          />
        </Box>
        <Box className={classes.fixRow}>
          <TextField
            size="small"
            className={`${classes.textfield} ${classes.fixSmallField}`}
            fullWidth
            value={interiorNumber.value}
            label="Numero interior"
            variant="outlined"
            {...getPropsValidate(interiorNumber)}
          />
          <TextField
            size="small"
            className={classes.textfield}
            required
            label="Colonia"
            variant="outlined"
            fullWidth
            value={neighborhood.value}
            {...getPropsValidate(neighborhood)}
          />
        </Box>
        <TextField
          size="small"
          className={classes.textfield}
          required
          label="Ciudad"
          variant="outlined"
          fullWidth
          value={city.value}
          {...getPropsValidate(city)}
        />
        <div className={classes.fixRow}>
          <TextField
            size="small"
            className={`${classes.textfield} ${classes.fixSmallField}`}
            fullWidth
            required
            label="Codigo Postal"
            variant="outlined"
            value={postalCode.value}
            {...getPropsValidate(postalCode)}
          />
          <TextField
            size="small"
            className={classes.textfield}
            fullWidth
            value={state.value}
            required
            label="Estado"
            variant="outlined"
            {...getPropsValidate(state)}
          />
        </div>
        <TextField
          size="small"
          className={classes.textfield}
          label="Indicaciones adicionales"
          variant="outlined"
          fullWidth
          {...getPropsValidate(addressNote)}
        />
        <TextField
          size="small"
          className={classes.textfield}
          label="Alias (Mi casa, Oficina, etc.)"
          variant="outlined"
          fullWidth
          value={alias.value}
          required
          {...getPropsValidate(alias)}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        className={classes.textfield}
        onClick={onClick}
        disabled={validateFormButtonDisabled()}
      >
        {buttonText}
      </Button>
    </Card>
  );
};

AddressForm.propTypes = {
  onClick: PropTypes.func,
  street: PropTypes.any,
  exteriorNumber: PropTypes.any,
  interiorNumber: PropTypes.any,
  neighborhood: PropTypes.any,
  city: PropTypes.any,
  postalCode: PropTypes.any,
  state: PropTypes.any,
  addressNote: PropTypes.any,
  alias: PropTypes.any,
  buttonText: PropTypes.string,
  search: PropTypes.bool,
  title: PropTypes.string,
  location: PropTypes.shape({
      street: PropTypes.string,
      exteriorNumber: PropTypes.string,
      interiorNumber: PropTypes.string,
      neighborhood: PropTypes.string,
      city: PropTypes.string,
      postalCode: PropTypes.any,
      state: PropTypes.string,
      addressNote: PropTypes.string,
      alias: PropTypes.string
  })

};

AddressForm.defaultProps = {
  onClick: () => {},
  street: {},
  exteriorNumber: {},
  interiorNumber: {},
  neighborhood: {},
  city: {},
  postalCode: {},
  state: {},
  addressNote: {},
  alias: {},
  buttonText: 'Agregar',
  search: false,
  title: '',
  location:{}
};
export default AddressForm;
