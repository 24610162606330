import React from 'react';
import { Box, Typography } from '@material-ui/core';
import images from '../../assets/images';
import styles from './styles';
import ButtonCdsoInfo from '../ButtonCdsoInfo';

const BannerCdsoAd = () => {
  const classes = styles();
  return (
    <Box className={classes.adbox}>
      <Box className={classes.adCdso}>
        <img src={images.isanaPlus} alt="cdso" className={classes.cdsoLogo} />
        <Box className={classes.adTextBox}>
          <Typography className={classes.titleAdd}>
            Encuentra las mejores ofertas con iSana+
          </Typography>
          <Typography className={classes.subTitle}>
            ¡Más de 700 medicamentos con piezas gratis!
          </Typography>
          <ButtonCdsoInfo />
        </Box>
        <img src={images.womenWithMask} alt="womenWithMask" className={classes.womenWithMask} />
      </Box>
    </Box>
  );
};

export default BannerCdsoAd;
