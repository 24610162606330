import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  pages: {
    margin: '1rem'
  }
}));

export default styles;
