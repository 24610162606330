/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  TableContainer,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CartTable from '../../../components/CartTable';

import styles from './styles';
import useValidField from '../../../customHooks/useValidField';
import { updateCheckoutShoppingCart } from '../../../reducers/shoppingCart/actions';
import { SHOPPING_CART_ACTIONS } from '../../../constants';

const Approve = ({ infoData, updateCheckoutShoppingCart }) => {
  const classes = styles();
  const { DELIVERY_DATA_WEBSITE_SET } = SHOPPING_CART_ACTIONS;
  const [deliveryInfo, setDeliveryInfo] = useState(infoData);
  const [open, setOpen] = useState(false);
  const emailValidator = useValidField({ type: 'email', noErrorEmpty: true });
  const [stateUpdateButton, setStateUpdateButton] = useState(false);
  const [validateScroll, setValidateScroll] = useState(true);
  const handleOnClose = () => {
    setOpen(false);
  };
  const getPropsValidate = (validator) => {
    const { setValue, ...validationProps } = validator;
    return validationProps;
  };

  const updateEmail = async() => {
    setStateUpdateButton('loading');
    const infoDataWithEmail = { 
      ...infoData,
      email: emailValidator.value
     
    };
    await updateCheckoutShoppingCart(infoDataWithEmail, DELIVERY_DATA_WEBSITE_SET);
    setDeliveryInfo(infoDataWithEmail);
    setStateUpdateButton('updated');
  };

  useEffect(() => {
    if (infoData) {
      setDeliveryInfo(infoData);
    }
  }, [infoData]);

  const handleEmailValidation = () => {
    if (document.documentElement.scrollTop > 100) {
        if(!infoData.email.length){
          setOpen(true);
          window.removeEventListener('scroll', handleEmailValidation);
        }
        setValidateScroll(false);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if(validateScroll) {
    window.addEventListener('scroll', handleEmailValidation);
    return () => window.removeEventListener('scroll', handleEmailValidation);
    }
    
  }, []);

  return (
    <Grid container>
      <Modal open={open} onClose={handleOnClose}>
        <Box className={classes.modal}>
          <Typography className={classes.titleModal}>
            Al parecer no contamos con tu Email
          </Typography>
          <Typography>Con tu email podemos notificarte sobre los detalles de tu orden.</Typography>
          <Typography className={classes.textFixed}>¿Deseas agregarlo?</Typography>
          <TextField
            size="small"
            className={classes.textfield}
            label="Email"
            variant="outlined"
            type="email"
            fullWidth
            {...getPropsValidate(emailValidator)}
          />
          <Box className={classes.buttonBox}>
            <Button variant="contained" onClick={handleOnClose}>
              No
            </Button>
            { stateUpdateButton === 'loading' ? (
              <CircularProgress/>
            ) :(
              <Button
              variant="contained"
              color="primary"
              onClick={stateUpdateButton === 'updated'  ? handleOnClose: updateEmail}
              disabled={emailValidator.value === '' || emailValidator.error}
            >
              {stateUpdateButton === 'updated' ? 'Listo': 'Agregar'}
            </Button>
            )}
          </Box>
        </Box>
      </Modal>
      <Grid item md={12} xs={12} className={classes.tableFix}>
        <Typography variant="h6" className={classes.title}>
          Confirma tu pedido
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.tableFix}>
        <div>
          <Typography variant="h6" className={classes.subtitle}>
            Carrito de compras
          </Typography>
          <TableContainer className={classes.TableContainer}>
            <CartTable isEditable={false} />
          </TableContainer>
        </div>
      </Grid>
      {infoData ? (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className={classes.infoRow}>
            <Typography variant="h6" className={classes.subtitle}>
              Información del contacto
            </Typography>
            <div className={classes.infoData}>
              <Typography className={classes.fixText} variant="subtitle2">
                <strong>Nombre</strong> <br /> {deliveryInfo.name}
              </Typography>
              <Typography className={classes.fixText} variant="subtitle2">
                <strong>Email</strong> <br /> {deliveryInfo.email}
              </Typography>
              <Typography className={classes.fixText} variant="subtitle2">
                <strong>Teléfono</strong> <br /> {deliveryInfo.phoneNumber}
              </Typography>
            </div>
            <Typography variant="h6" className={classes.subtitle}>
              Enviado a
            </Typography>
            <div className={classes.infoData}>
              <Typography className={classes.fixText} variant="subtitle2">
                <strong>Calle</strong> <br /> {deliveryInfo.street}
              </Typography>
              <Typography className={classes.fixText} variant="subtitle2">
                <strong>Número Exterior</strong> <br /> {deliveryInfo.exteriorNumber}
              </Typography>
              <Typography className={classes.fixText} variant="subtitle2">
                <strong>Número Interior</strong> <br />{' '}
                {deliveryInfo.interiorNumber.length === 0
                  ? 'Sin especificar'
                  : deliveryInfo.interiorNumber}
              </Typography>
              <Typography className={classes.fixText} variant="subtitle2">
                <strong>Estado</strong> <br /> {deliveryInfo.state}
              </Typography>
            </div>
            <div className={classes.infoData}>
              <Typography className={classes.fixText} variant="subtitle2">
                <strong>Codigo Postal</strong> <br /> {deliveryInfo.postalCode}
              </Typography>
              <Typography className={classes.fixText} variant="subtitle2">
                <strong>Ciudad</strong> <br /> {deliveryInfo.city}
              </Typography>
              <Typography className={classes.fixText} variant="subtitle2">
                <strong>Indicaciones</strong> <br />{' '}
                {deliveryInfo.addressNote.length === 0
                  ? 'Sin indicaciones'
                  : deliveryInfo.addressNote}
              </Typography>
            </div>
          </div>
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const { shoppingCartData } = state.shoppingCart;
  return { shoppingCartData };
};

Approve.propTypes = {
  updateCheckoutShoppingCart: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  infoData: PropTypes.any
};

Approve.defaultProps = {
  updateCheckoutShoppingCart: () => {},
  infoData: {
    email: '',
    name: '',
    address: '',
    street: '',
    interiorNumber: '',
    exteriorNumber: '',
    city: '',
    info: '',
    state: '',
    postalCode: '',
    phoneNumber: '',
    addressNote: ''
  }
};
export default connect(mapStateToProps, { updateCheckoutShoppingCart })(Approve);
