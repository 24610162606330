import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../../../theme';

export default makeStyles(() => ({
  menuLinksContainer: {
    marginBottom: '1rem',
    borderBottom: `solid 2px ${Colors.wildSand}`,
    marginTop: '1rem'
  },
  menuLinksContainerDesk: {
    background: Colors.softPurlple,
    marginTop: '.5rem'
  }
}));
