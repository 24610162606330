import axios from '../utils/axios';
import { API_URL_PRODUCTS } from '../constants';

/**
 * Get list products
 * @param {string} search Search param filter
 * @param {number} page Page number in pagination
 * @param {number} limit limit of records per page in pagination
 * @param {string} category Category param filter
 * @returns Promise<array>
 */
export const list = async ({ search, page, limit, category, referralCode }) => {
  let params = 'version=3';

  if (search) params += `&search=${search}`;

  if (page) params += `&page=${page}`;

  if (limit) params += `&limit=${limit}`;

  if (category) params += `&categories=${category}`;
  
  if (referralCode) params += `&referralCode=${referralCode}`;


  const response = await axios.get(`${API_URL_PRODUCTS}?${params}`);
  return response.data;
};

/**
 * Function to get product categories
 * @returns Promise<array>
 */
export const getCategories = async (search) => {
  let query = ''
  if (search && search.length > 0) {
    query = `?search=${search}`
  }
  const response = await axios.get(`${API_URL_PRODUCTS}/categories${query}`);
  return response.data;
};

/**
 * Get product data by id
 * @param {string} productId Product mongo id
 * @returns Promise<object>
 */
export const details = async (productId, referralCode) => {
  let query = ''
  if (referralCode) {
    query = `?referralCode=${referralCode}`
  }
  const response = await axios.get(`${API_URL_PRODUCTS}/${productId}${query}`);
  return response.data;
};

/**
 * Get similar products by categoryId
 * @param {string} categoryId Category id
 * @returns Promise<object>
 */
export const getSimilarProducts = async (categoryId) => {
  const response = await axios.get(
    `/productsApp/similarproducts/${categoryId.toString()}?version=3`
  );
  return response.data;
};

export const getProductByEan = async ean => {
  const response = await axios.get(`${API_URL_PRODUCTS}/by-ean/${ean}`);
  return response.data;
};

export const getProductsByNur = async nur => {
  const response = await axios.get(`${API_URL_PRODUCTS}/by-nurs/${nur}`);
  return response.data
};
