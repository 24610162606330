import {
  Box, Fab, Grid, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import CardSimilarProduct from './CardSimilarProduct';
import styles from './styles';
import { getSimilarProducts } from '../../../services/products';

const SimilarProducts = ({ categoryId }) => {
  const classes = styles();
  const [products, setProducts] = useState([]);
  const theme = useTheme();
  const isMdview = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isSmview = useMediaQuery(theme.breakpoints.down('sm'));
  const isLgView = useMediaQuery(theme.breakpoints.up('lg'));

  const [limits, setLimits] = useState({
    position: 0,
    items: 3
  });
  let control = 3;

  const getControlValue = () => {
    if (isMdview) {
      control = 2;
    }
    if (isSmview) {
      control = 1;
    }
    if (isLgView) {
      control = 3;
    }
  };
  useEffect(() => {
    if (categoryId) {
      getSimilarProducts(categoryId).then((response) => {
        setProducts(response);
      });
    }
  }, [categoryId]);

  useEffect(() => {
    if (isMdview) {
      setLimits({
        position: 0,
        items: 2
      });
    }
    if (isSmview) {
      setLimits({
        position: 0,
        items: 1
      });
    }
    if (isLgView) {
      setLimits({
        position: 0,
        items: 3
      });
    }
  }, [isMdview, isSmview, isLgView]);

  const next = () => {
    getControlValue();
    if (limits.items >= products.length) {
      return;
    }
    setLimits({
      position: limits.items,
      items: limits.items + control
    });
  };

  const back = () => {
    getControlValue();
    if (limits.items <= control) {
      return;
    }
    setLimits({
      position: limits.position - control,
      items: limits.items - control
    });
  };
  return (
    <>
    <div/>
      {products.length >= 1 ? (
        <Grid item xs={12} className={classes.iSanaWorkContainer}>
          <div className={classes.boxIconTitle}>
            <Typography className={classes.iconTitle}>Artículos relacionados</Typography>
          </div>
          <Box className={classes.list}>
            <Fab size="medium" onClick={back} className={classes.fabButton}>
              <ArrowBack className={classes.fabIcon} />
            </Fab>
            <div className={classes.list}>
              { products.slice(limits.position, limits.items).map((item) => (
                <CardSimilarProduct key={item._id} productData={item} />
              ))}
            </div>
            <Fab size="medium" onClick={next} className={classes.fabButton}>
              <ArrowForward className={classes.fabIcon} />
            </Fab>
          </Box>
        </Grid>
      ) : (
        <>
        </>
      )}
    <div/>
    </>

  );
};

SimilarProducts.propTypes = {
  categoryId: PropTypes.string
};

SimilarProducts.defaultProps = {
  categoryId: ''
};

export default SimilarProducts;
