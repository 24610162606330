import { Box, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles';

const InfoBox = ({ title, text }) => {
  const classes = styles();
  return (
    <Box className={classes.orderBox}>
      <Typography className={classes.textOrderBox}>
        {title}
        :
      </Typography>
      <Typography>
        {text}
      </Typography>
    </Box>
  );
};

InfoBox.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

InfoBox.defaultProps = {
  title: 'Titulo',
  text: 'Texto'
};
export default InfoBox;
