import {
  Grid,
  TableContainer, Typography
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CartTable from '../../../components/CartTable';
import styles from './styles';

const Cart = ({ changesDiabled, shoppingCartData }) => {
  const classes = styles();

  const isEditable = () => {
      let result = !changesDiabled
    if (shoppingCartData.metadata && shoppingCartData.metadata.originServices) {
      result = false
    }
    return result
  }
  return (

    <Grid item md={12} xs={12} className={classes.tableFix}>
      <Typography variant="h6" className={classes.title}>Verifica tu carrito de compras</Typography>
      <TableContainer className={classes.TableContainer}>
        <CartTable isEditable={isEditable()} />
      </TableContainer>
    </Grid>

  );
};

const mapStateToProps = (state) => {
  const { changesDiabled, shoppingCartData } = state.shoppingCart;
  return { changesDiabled, shoppingCartData };
};

Cart.propTypes = {
  changesDiabled: PropTypes.bool,
  shoppingCartData: PropTypes.shape({
    metadata: PropTypes.shape({
      originServices: PropTypes.string
    })
  })
};

Cart.defaultProps = {
  changesDiabled: false,
  shoppingCartData: {
    metadata: {originServices: ''}
  }
};
export default connect(mapStateToProps, {})(Cart);
