import { API_URL_ORDERS } from '../constants';
import axios from '../utils/axios';

/**
 * Function to create new order by shopping cart id
 * @param {string} shoppingCartId current shopping cart id
 * @returns Promise
 */
export const createOrder = async (shoppingCartId) => {
  const response = await axios.post(`${API_URL_ORDERS}/${shoppingCartId}`);
  return response.data;
};

/**
 * Get tracking by order number
 * @param {string} orderNumber Order number
 * @returns Promises<object>
 */
export const getTrackingByOrderNumber = async (orderNumber) => {
  const response = await axios.get(`${API_URL_ORDERS}/tracking/${orderNumber}`);
  return response.data;
};

/**
 * Get orders history by customer logged
 * @returns Promise<array>
 */
export const getOrdersByCustomer = async ({ status, page, limit, orderBy = 'desc' }) => {
  let params = '';

  if (page) {
    params += `&page=${page}`;
  }

  if (limit) {
    params += `&limit=${limit}`;
  }

  if (status) {
    params += `&status=${status}`;
  }

  if (orderBy) {
    params += `&orderBy=${orderBy}`;
  }

  const response = await axios.get(`${API_URL_ORDERS}/orders/history?${params}`);
  return response.data;
};

/**
 * Get order data by id
 * @param {string} id Order id
 * @returns Promise<object>
 */
export const getOrderById = async (id) => {
  const response = await axios.get(`${API_URL_ORDERS}/orders/${id}`);
  return response.data;
};

export const getOrderDetailsByMercadoRefId = async (refId) => {
  const response = await axios.get(`${API_URL_ORDERS}/orders/mercado/${refId}`);
  return response.data;
};

export const getOrderByOrderNumber = async (orderNumber) => {
  const response = await axios.get(`${API_URL_ORDERS}/orders/get-by-order-number/${orderNumber}`);
  return response.data;
};
