import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../../theme';

const styles = makeStyles((theme) => ({
  overFloFix: {
    position: 'relative !important',
    overflowX: 'hidden'
  },
  bannerBox: {
    display: 'flex',
    flexDirection: 'column'
  },
  iconShopping: {
    color: 'white'
  },
  linkPharm: {
    textDecoration: 'none',
    color: Colors.purpleBlue,
    fontSize: '1.2rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold'
  },
  shopButton: {
    backgroundColor: Colors.simpleWhite,
    color: Colors.purpleBlue,
    width: '29rem',
    borderRadius: '5rem',
    padding: '0.7rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '&:hover': {
      background: Colors.grayViolet
    },
    [theme.breakpoints.down('lg')]: {
      width: '26rem'
    },
    [theme.breakpoints.down('md')]: {
      width: '20.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      width: '17rem'
    }
  },
  boxFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    background: `linear-gradient(to bottom right, ${Colors.electricViolet}, ${Colors.heliotrope})`,
    height: '30rem',
    position: 'relative'
  },
  leftBox: {
    padding: '2rem',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '4rem',
      marginTop: '1rem'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '7rem',
      marginTop: '1rem'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '10rem',
      marginTop: '2rem'
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '13rem',
      marginTop: '2rem'
    }
  },
  title: {
    fontFamily: 'Quicksand',
    marginTop: '3rem',
    color: Colors.simpleWhite,
    fontWeight: 'bold',
    fontSize: '1.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.2rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.8rem',
      marginTop: '1rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '3rem'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '3.2rem'
    }
  },
  subTitle: {
    fontFamily: 'Quicksand',
    color: Colors.simpleWhite,
    fontSize: '1rem',
    maxWidth: '24rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.2rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4rem',
      maxWidth: '28rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.6rem',
      maxWidth: '33rem'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.8rem'
    }
  },
  containerSeccion: {
    marginTop: '2rem'
  },
  playBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  playButton: {
    background: Colors.simpleWhite,
    width: '30px',
    height: '30px',
    marginRight: '.4rem'
  },
  iconPlay: {
    fontSize: '1rem',
    color: Colors.electricViolet
  },
  textPlayButton: {
    fontSize: '.8rem',
    color: Colors.simpleWhite,
    fontWeight: 'bolder'
  },
  modalStyle: {
    position: 'absolute',
    width: '20rem',
    backgroundColor: Colors.simpleWhite,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.up('sm')]: {
      width: '30rem'
    },
    [theme.breakpoints.up('md')]: {
      width: '50rem'
    },
    [theme.breakpoints.up('lg')]: {
      width: '60rem'
    },
    [theme.breakpoints.up('xl')]: {
      width: '70rem'
    }
  },
  videoSize: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '11rem'
    },
    [theme.breakpoints.up('sm')]: {
      height: '20rem'
    },
    [theme.breakpoints.up('md')]: {
      height: '30rem'
    },
    [theme.breakpoints.up('md')]: {
      height: '40rem'
    }
  },
  rightBox: {
    overflowX: 'hidden'
  },
  bannerOk: {
    bottom: '0rem',
    position: 'absolute',
    width: '16rem',
    height: 'auto',
    left: '5rem',
    [theme.breakpoints.up('sm')]: {
      left: 'auto',
      right: '2rem',
      width: '25rem'
    },
    [theme.breakpoints.up('md')]: {
      right: '3rem',
      width: '30rem'
    },
    [theme.breakpoints.up('lg')]: {
      right: '5rem',
      width: '32rem'
    },
    [theme.breakpoints.up('xl')]: {
      right: '7rem',
      width: '35rem'
    }
  }
}));

export default styles;
