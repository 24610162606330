import {
  SET_CATEGORY,
  REMOVE_CATEGORY,
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  SET_SEARCH_TEXT
} from './types';

const INITIAL_STATE = {
  products: { docs: [] },
  categorySelected: {},
  isLoadingProducts: false,
  searchText: ''
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PRODUCTS_START:
      return { ...state, isLoadingProducts: true };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: payload,
        isLoadingProducts: false
      };
    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        isLoadingProducts: false
      };
    case SET_CATEGORY: {
      const category = action.payload._id === state.categorySelected._id ? {} : action.payload;
      return {
        ...state,
        categorySelected: category
      };
    }
    case REMOVE_CATEGORY:
      return {
        ...state,
        categorySelected: {}
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload
      };
    default:
      return state;
  }
};
