/* eslint-disable max-len */
import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import images from '../../../assets/images';
import ItemDescription from '../ItemDescription';
import TitleBlack from '../TitleBlack';

const AdvantagesTwo = ({ checked }) => {
  const services = [
    {
      id: 1,
      name: 'PASO 1',
      description: 'Busca tus medicamentos en nuestro sitio web o WhatsApp',
      image: images.iconApp
    },
    {
      id: 2,
      name: 'PASO 2',
      description: 'Encuentra mejores precios que en farmacias convencionales',
      image: images.iconPay
    },
    {
      id: 3,
      name: 'PASO 3',
      description: 'Recibe tus medicamentos donde quieras',
      image: images.iconBag
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBlack text="¿Cómo funciona nuestro servicio?" />
        <Grid container justifyContent="center" alignItems="center">
          {services.map((service) => (
            <ItemDescription key={service.id} service={service} checked={checked} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

AdvantagesTwo.propTypes = {
  checked: PropTypes.bool
};

AdvantagesTwo.defaultProps = {
  checked: false
};
export default AdvantagesTwo;
