import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonsRow: {
    marginTop: '0.2rem'
  },
  card: {
    padding: '1rem',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      maxWidth: '50rem'
    }
  },
  centerRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '2rem'
  },
  directions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    margin: '0.4rem'
  },
  link: {
    textDecoration: 'none'
  },
  actionBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));
export default useStyles;
