import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
    marginTop: '1rem'
  },
  cellTitle: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  title: {
    fontWeight: 'bold',
    color: Colors.electricViolet,
    fontSize: '1.5rem'
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: '0.9rem'
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  table: {
    border: 'none'
  },
  image: {
    width: '9rem',
    height: '9rem',
    [theme.breakpoints.down('md')]: {
      width: '8rem',
      height: '8rem'
    },
    [theme.breakpoints.down('sm')]: {
      width: '7rem',
      height: '7rem'
    },
    [theme.breakpoints.down('xs')]: {
      width: '8rem',
      height: '8rem'
    }
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.8rem'
  },
  buttonsCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.8rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem',
      justifyContent: 'center'
    }
  },
  nameProduct: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.85rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  subName: {
    fontSize: '0.9rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.85rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem'
    }
  },
  contentText: {
    fontSize: '0.9rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.85rem',
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
      textAlign: 'center'
    }
  },
  separation: {
    color: Colors.electricViolet,
    fontWeight: 'bold',
    marginLeft: '0.2rem',
    marginRight: '0.2rem',
    fontSize: '0.9rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.85rem'
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  deleteButton: {
    marginLeft: '1rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0rem',
      marginTop: '0.5rem'
    }
  },
  sameDayText: {
    fontSize: '1.3rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.95rem'
    }
  },
  programingDayText: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.95rem'
    }
  },
  buttonItems: {
    color: Colors.black,
    background: Colors.simpleWhite,
    textAlign: 'center',
    display: 'flex',
    width: '0.4rem',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none'
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '19rem',
    padding: '1rem',
    marginTop: '3rem',
    height: '35rem'
  },
  pricesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    background: Colors.lowGray,
    borderRadius: '0.5rem'
  },
  priceTitle: {
    fontSize: '0.8rem'
  },
  priceSameDay: {
    fontWeight: 'bold'
  },
  priceProgrammedDay: {
    fontWeight: 'bold',
    color: Colors.purpleBlue
  },
  programmedBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '16rem',
    padding: '0.6rem',
    borderRadius: '0.3rem',
    margin: '0.1rem',
    background: Colors.softPurlple
  },
  savedText: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: Colors.purpleBlue
  },
  shoppingButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  shoppingButton: {
    width: '19rem',
    marginTop: '0.5rem',
    background: Colors.purpleBlue,
    color: Colors.simpleWhite,
    fontWeight: 'bold',
    fontSize: '0.9rem',
    borderRadius: '0.5rem'
  },
  methodsTitle: {
    marginTop: '1rem',
    fontSize: '0.9rem',
    fontWeight: 'bold'
  },
  methodsBox: {
    display: 'flex',
    marginTop: '1rem',
    alignItems: 'center'
  },
  methodsSubtitle: {
    fontSize: '0.7rem',
    fontWeight: 'bold',
    marginLeft: '0.5rem'
  },
  methodsSubtitleNote: {
    fontSize: '0.6rem',
    marginLeft: '0.5rem'
  },
  TableContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  TableContainerSmall: {},
  boxTextSmall: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '1rem'
  },
  notFound: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem'
  },
  notFoundText: {
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem'
    }
  },
  paymentsImage: {
    width: '3.3rem',
    marginLeft: '1.5rem'
  },
  paymentsImageMasterCard: {
    width: '2.6rem',
    marginLeft: '1.5rem'
  },
  paymentsImagePayPal: {
    width: '4.6rem',
    marginLeft: '1.5rem'
  },
  boxPayment: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '0.3rem'
  }
}));

export default styles;
