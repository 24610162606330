import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../theme';

const styles = makeStyles((theme) => ({
  cardBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    marginBottom: '0.2rem',
    [theme.breakpoints.down('md')]: {
      padding: '0.5rem'
    }
  },
  cardFix: {
    borderRadius: '0.8rem',
    width: '18rem',
    height: '29rem',
    marginBottom: '0.3rem',
    border: `solid 1px ${Colors.lowGray}`,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '17rem',
      marginBottom: '1rem'
    },
    [theme.breakpoints.up('lg')]: {
      width: '18rem'
    }
  },
  warningBox: {
    position: 'absolute',
    zIndex: 5
  },
  promoBox: {
    background: Colors.radicalRed,
    width: '6rem',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '1.5rem',
    float: 'left',
    paddingLeft: '1rem',
    color: Colors.simpleWhite,
    fontWeight: 'bold'
  },
  noProductBox: {
    background: Colors.softViolet,
    width: '15rem',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '1.5rem',
    float: 'left',
    paddingLeft: '2rem'
  },
  noProductBoxText: {
    color: Colors.simpleWhite,
    fontWeight: 'bold'
  },
  attachmentBox: {
    background: Colors.softBlue,
    width: '6rem',
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '1.5rem',
    float: 'right',
    marginLeft: '8rem',
    paddingLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0.6rem'
    }
  },
  promoText: {
    color: Colors.simpleWhite,
    paddingLeft: '1.5rem',
    fontWeight: 'bold'
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '11rem',
    height: '9.5rem'
  },
  nameBox: {
    marginLeft: '1rem',
    height: '2.5rem'
  },
  name: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textOverflow: 'ellipsis'
  },
  description: {
    display: 'flex',
    marginLeft: '1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexDirection: 'row',
    marginTop: '0.2rem',
    height: '2.3rem'
  },
  verticalEffect: {
    borderLeft: `0.12rem solid ${Colors.softViolet}`,
    height: '1.1rem',
    marginTop: '0.4rem',
    marginLeft: '0.4rem',
    marginRight: '0.4rem'
  },
  descriptionText: {
    fontSize: '1.1rem'
  },
  rules: {
    textAlign: 'center',
    fontSize: '0.7rem',
    position: 'absolute',
    bottom: '0.8rem',
    width: '100%',
    left: 0
  },
  promoRule: {
    textAlign: 'center',
    fontSize: '0.7rem',
    position: 'absolute',
    bottom: '1.7rem',
    width: '100%',
    left: 0
  },
  boxCenter: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '0.2rem',
    marginBottom: '1rem'
  },
  button: {
    borderRadius: '0.5rem',
    fontWeight: 'bold',
    padding: '0.4rem 2.7rem 0.4rem 2.7rem',
    fontSize: '0.75rem',
    marginTop: '0.3rem'
  },
  prices: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0rem 0.5rem',
    alignItems: 'center'
  },
  priceDetail: {
    padding: '0.3rem',
    fontWeight: 'bold',
    color: Colors.radicalRed,
    fontSize: '1.4rem'
  },
  link: {
    textDecoration: 'none',
    color: Colors.black
  },
  buttonLoader: {
    position: 'absolute',
    margin: 'auto'
  },
  boxStock: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem'
  },
  noProductOnStock: {
    backgroundColor: Colors.lowPurple,
    padding: '0.5rem',
    borderRadius: '0.5rem',
    textDecoration: 'none',
    color: Colors.black,
    '&:hover': {
      background: Colors.softPurlple,
      transition: '1s'
    }
  },
  descriptionProduct: {
    fontSize: '.8rem',
    marginBottom: '.5rem'
  },
  removePrice: {
    color: Colors.gray,
    textDecoration: 'line-through'
  },
  textDiscountPrice: {
    fontSize: '.9rem',
    textAlign: 'center',
    color: Colors.gray,
    fontWeight: 'bold'
  },
  attachments: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  attachmentsRight: {
    display: 'flex',
    justifyContent: 'flex-left'
  },
  attachmentFix: {
    position: 'absolute',
    zIndex: 5,
    width: '90%'
  },
  promotion: {
    background: Colors.radicalRed,
    color: Colors.simpleWhite,
    width: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '0.3rem'
  },
  recipe: {
    background: Colors.softBlue,
    color: Colors.simpleWhite,
    width: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '0.3rem'
  },
  offerBox: {
   marginTop: '0.3rem',
   borderColor: Colors.radicalRed,
   borderRadius: '0.5rem',
   fontWeight: 'bold',
   padding: '0.4rem 2.7rem 0.4rem 2.7rem',
   fontSize: '0.75rem',
   color: Colors.radicalRed,
   cursor: 'inherit',
   '&:hover': {
    background: 'none'
   }
  },
  offerText: {
    color: Colors.simpleWhite,
    fontWeight: 'bold',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '0.8rem'
  },
  savingBox: {
    height: '1.2rem'
  },
  isanaPlus: {
    width: '1.7rem',
    height: '1.7rem'
    }
}));

export default styles;
