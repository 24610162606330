/* eslint-disable max-len */
import React from 'react';
// eslint-disable-next-line object-curly-newline
import { Typography, Modal, IconButton, Button, Slide } from '@material-ui/core';
import { PlayArrow, ShoppingCart } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles';
import images from '../../../assets/images';
import useToggle from '../../../customHooks/useToggle';

const Banner = ({ checked }) => {
  const classes = styles();
  const showBanner = false
  const { isToggleActive, handleToggle } = useToggle();

  return (
    <div className={classes.overFloFix}>
      <div className={classes.boxFlex}>
        <div className={classes.leftBox}>
          <div className={classes.bannerBox}>
            <Typography className={classes.title}>Tu farmacia a un click.</Typography>
            <Link className={classes.linkPharm} to="/productos">
              <Button className={classes.shopButton}>
                <Typography className={classes.linkPharm}> <ShoppingCart fontSize="medium" />¡Compra Aquí!</Typography>
              </Button>
            </Link>
          </div>
          <div className={classes.containerSeccion}>
            <Typography className={classes.subTitle}>Compra en línea todos tus medicamentos al mejor precio</Typography>
          </div>
          <div className={classes.containerSeccion}>
            {showBanner && (
            <div className={classes.playBox}>
              <IconButton className={classes.playButton} onClick={handleToggle} size="small">
                <PlayArrow className={classes.iconPlay} />
              </IconButton>
              <Typography className={classes.textPlayButton}>Conoce iSana</Typography>
              <Modal open={isToggleActive} onClose={handleToggle}>
                <div className={classes.modalStyle}>
                  <iframe
                    className={classes.videoSize}
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/CXnaEv9tBOM"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </Modal>
            </div>
            )}
          </div>
        </div>
        <div className={classes.rightBox}>
          <div>
            <Slide
              direction="left"
              in={checked}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(checked ? { timeout: 800 } : {})}
            >
              <img className={classes.bannerOk} src={images.bannerWoman} alt="circle" />
            </Slide>

          </div>
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  checked: PropTypes.bool
};

Banner.defaultProps = {
  checked: false
};
export default Banner;
