import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  container: {
    marginTop: '3rem',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '120rem',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'justify-between',
      padding: '0rem 2rem 0rem 2rem'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'justify-between',
      padding: '0rem 18rem 0rem 18rem'
    },
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: '0rem 18rem 0rem 18rem'
    }
  },
  promoBox: {
    display: 'flex',
    flexDirection: 'column',
    background: Colors.softViolet,
    margin: '1rem',
    borderRadius: '0.3rem',
    maxWidth: '100%',
    maxHeight: '28rem',
    [theme.breakpoints.up('md')]: {
      height: '26rem'
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      height: '26rem'
    },
    [theme.breakpoints.up('xl')]: {
      width: '70%',
      height: '26rem'
    }
  },
  image: {
    width: '12rem',
    height: '12rem'
  },
  infoPromo: {
    padding: '1rem 0rem 0rem 1rem',
    color: Colors.simpleWhite,
    fontSize: '0.9rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.3rem',
      padding: '2rem 0rem 0rem 2rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.4rem',
      padding: '3rem 0rem 0rem 2rem'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.8rem',
      padding: '3rem 0rem 0rem 2rem'
    }
  },
  mapBox: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start'
    }
  },
  boxArrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2rem'
  },
  buttonArrow: {
    background: 'none',
    color: Colors.electricViolet
  },
  arrowIconDown: {
    width: '1rem',
    marginRight: '0.3rem'
  },
  boxTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center'
  },
  infoTextBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column'
  }
}));

export default styles;
