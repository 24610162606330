import { Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const Pagination = ({
  page, totalOfPages, nextPage, previewPage,
  hasNextPage, hasPrevPage, isLoadingProducts
}) => {
  const classes = styles();
  return (
    <div className={classes.pagination}>
      { hasPrevPage && (
      <Button variant="contained" color="primary" size="small" disabled={isLoadingProducts} onClick={previewPage} type="button">Atrás</Button>
      )}
      <Typography className={classes.pages}>
        {page}
        {' '}
        de
        {' '}
        {totalOfPages}
      </Typography>
      { hasNextPage && (
      <Button variant="contained" color="primary" size="small" disabled={isLoadingProducts} onClick={nextPage} type="button">Siguiente</Button>
      )}
    </div>
  );
};

Pagination.propTypes = {
  totalOfPages: PropTypes.number,
  page: PropTypes.string,
  nextPage: PropTypes.func,
  previewPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  hasPrevPage: PropTypes.bool

};

Pagination.defaultProps = {
  totalOfPages: 0,
  page: '0',
  previewPage: () => {},
  nextPage: () => {},
  hasNextPage: false,
  hasPrevPage: false
};

Pagination.propTypes = {
  isLoadingProducts: PropTypes.bool
};

Pagination.defaultProps = {
  isLoadingProducts: false
};

const mapStateToProps = (state) => {
  const { isLoadingProducts } = state.product;
  return { isLoadingProducts };
};

export default connect(mapStateToProps, {})(Pagination);
