import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../theme';

const styles = makeStyles((theme) => ({
  adBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: '1rem',
    marginBottom: '-3rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      padding: '0rem',
      marginBottom: '2rem'
    }
  },
  infoBox: {
    width: '50rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '25rem'
    }
  },
  title: {
    fontFamily: 'Quicksand',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.8rem',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem'
    }
  },
  description: {
    textAlign: 'center',
    fontSize: '1rem',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem'
    }
  },
  button: {
    width: '20rem',
    margin: 'auto',
    color: Colors.simpleWhite,
    background: Colors.electricViolet,
    borderRadius: '1.5rem',
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      background: Colors.honeyFlower
    },
    [theme.breakpoints.down('sm')]: {
      width: '18rem',
      fontSize: '1rem'
    }
  },
  images: {
    [theme.breakpoints.down('sm')]: {
      width: '18rem'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '-5rem'

    },
    width: '18rem'
  },
  cdsoLogo: {
    [theme.breakpoints.down('sm')]: {
      width: '10rem'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '-9rem',
      width: '8rem',
      marginBottom: '2rem'
    },
    width: '10rem',
    marginTop: '-3rem'
  },
  boxLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  }
}));
export default styles;
