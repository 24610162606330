/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  createMercadoPagoPreferenceId,
  updateMercadoPagoPayment
} from '../../../services/payments';
import { updateCheckoutShoppingCart } from '../../../reducers/shoppingCart/actions';
import { SHOPPING_CART_UPDATE } from '../../../reducers/shoppingCart/types';
import styles from '../styles';

const CheckoutMercado = ({ updateCheckoutShoppingCart, shoppingCartData }) => {
  const FORM_ID = 'payment_form';
  const [preferenceId, setPreferenceId] = useState(null);
  const [showMessage, setShowMessage ] = useState(false)
  const colorElement = '#8e44ad';
  const classes = styles()


  const changeWord = () => {
    setInterval(() => {
      setShowMessage(!showMessage)
    }, 1000);
  }
  changeWord()
  useEffect(() => {
    // Do endpoint call
    const { _id, metadata = {} } = shoppingCartData;
    if (metadata && metadata.mercadoPagoRefId) {
      updateMercadoPagoPayment(metadata.mercadoPagoRefId, _id)
        .then((response) => {
          if (response.data.updated) setPreferenceId(metadata.mercadoPagoRefId);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('[Error update payment] ', error);
        });
    } else if (_id) {
      createMercadoPagoPreferenceId(_id)
        .then((response) => {
          setPreferenceId(response.data.preferenceId);
          if (!response.data.preferenceId) {
            // eslint-disable-next-line no-console
            console.error('Error to set preference id');
            return;
          }
          const data = {
            metadata: {
              ...metadata,
              mercadoPagoRefId: response.data.preferenceId
            }
          };
          updateCheckoutShoppingCart(data, SHOPPING_CART_UPDATE);
        })
        .catch(() => {
          // TODO notification error9
        });
    }
  }, [shoppingCartData.metadata, shoppingCartData.changes]);

  useEffect(() => {
    if (preferenceId) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://www.mercadopago.com.mx/integrations/v1/web-payment-checkout.js';
      script.setAttribute('data-preference-id', preferenceId);
      script.setAttribute('data-elements-color', colorElement);
      script.setAttribute('data-button-background', colorElement);
      script.setAttribute('data-button-label', 'Realizar pago');
      script.setAttribute('data-header-color', colorElement);
      const form = document.getElementById(FORM_ID);
      form.appendChild(script);
    }
  }, [preferenceId]);

  return (
    <div>
      {preferenceId ? (
        <div>
          {document.getElementsByName('script') ? (
            <form id={FORM_ID} method="GET" className={classes.form} />
          ) : (
            <Typography>Cargando...</Typography>
          )}
        </div>
      ) : (
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography style={{fontSize:'0.8rem'}}>{!showMessage? 'Ajustando los últimos detalles...' : 'Tu orden estará lista en unos segundos' }</Typography>
          <LinearProgress />
        </Box>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { shoppingCartData } = state.shoppingCart;
  return { shoppingCartData };
};

CheckoutMercado.propTypes = {
  updateCheckoutShoppingCart: PropTypes.func,
  shoppingCartData: PropTypes.shape({
    _id: PropTypes.string,
    changes: PropTypes.string,
    metadata: PropTypes.shape({ mercadoPagoRefId: PropTypes.string })
  })
};

CheckoutMercado.defaultProps = {
  updateCheckoutShoppingCart: () => {},
  shoppingCartData: { metadata: {} }
};

export default connect(mapStateToProps, { updateCheckoutShoppingCart })(CheckoutMercado);
