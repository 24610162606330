import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '1rem',
    [theme.breakpoints.up('md')]: {
      width: '35rem'
    },
    [theme.breakpoints.down('sm')]: {
      width: '20rem',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  fixRow: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  textfield: {
    marginTop: '1rem',
    zIndex: 1
  },
  fixSmallField: {
    marginRight: '0.3rem',
    [theme.breakpoints.down('md')]: {
      marginRight: '0rem'
    }
  },
  gridFix: {
    display: 'flex',
    justifyContent: 'center'
  },
  actionBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));
export default useStyles;
