/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Fab, Typography } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import PropTypes from 'prop-types';
import styles from './styles';

const DetailNumberItems = ({ quantity, handleOnSubstractItem, handleOnAddItem }) => {
  const classes = styles();

  return (
    <div className={classes.buttonsCell}>
      <Fab size="small" className={classes.fabButton} onClick={handleOnSubstractItem} disabled={quantity === 1}>
        <Remove className={classes.fabIcon} fontSize="small" />
      </Fab>
      <div className={classes.quantity}>
        <Typography>{quantity}</Typography>
      </div>
      <Fab size="small" className={classes.fabButton} onClick={handleOnAddItem}>
        <Add className={classes.fabIcon} fontSize="small" />
      </Fab>
    </div>
  );
};

DetailNumberItems.propTypes = {
  quantity: PropTypes.number,
  handleOnSubstractItem: PropTypes.func,
  handleOnAddItem: PropTypes.func
};

DetailNumberItems.defaultProps = {
  quantity: 1,
  handleOnSubstractItem: () => {},
  handleOnAddItem: () => {}
};

export default DetailNumberItems;
