import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../theme';

const styles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    color: Colors.radicalRed,
    fontSize: '1.5rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      textAlign: 'center'
    }
  },
  subtitle: {
    color: Colors.black,
    marginTop: '1.4rem',
    fontSize: '1.3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      textAlign: 'center',
      marginTop: '0.7rem'
    }
  },
  image: {
    maxWidth: '19rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  imageBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: '10%',
    marginTop: '10%'
  },
  container: {
    backgroundColor: Colors.simpleWhite,
    padding: '7rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '3rem'
    }
  },
  button: {
    backgroundColor: Colors.blueHepatica,
    color: Colors.simpleWhite,
    marginTop: '2rem',
    width: '100%'
  },
  warningBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  buttonDanger: {
    backgroundColor: Colors.blueHepatica,
    color: Colors.simpleWhite,
    maxWidth: '14rem',
    margin: 'auto'
  },
  boxInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center'
  },
  banner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconError: {
    fontSize: '4rem',
    color: Colors.radicalRed
  },
  boxBanner: {
    maxWidth: '25rem',
    boxShadow: Colors.lowGray,
    padding: '1.4rem',
    borderRadius: '0.5rem'
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1rem'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  font: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  }
}));
export default styles;
