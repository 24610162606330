import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  button: {
    width: '100%',
    maxWidth: '20rem',
    margin: 'auto',
    borderRadius: '1.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '18rem',
      fontSize: '1rem'
    }
  },
  neutro: {
    color: Colors.black,
    background: Colors.simpleWhite,
    '&:hover': {
      background: Colors.grayViolet
    }
  },
  variant: {
    color: Colors.simpleWhite,
    background: Colors.electricViolet,
    '&:hover': {
      background: Colors.honeyFlower
    }
  },
  link: {
    width: '100%',
    maxWidth: '20rem',
    margin: 'auto',
    marginTop: '0.4rem',
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '18rem',
      fontSize: '1rem'
    }
  }
}));

export default styles;
