import { useEffect } from 'react';
import { SHOP_ID_STORAGE } from '../constants';

const useGetShoppingCartData = ( getShoppingCartData ) => {
  let shopIdfromStorage = null;

  useEffect(() => {
    shopIdfromStorage = sessionStorage.getItem(SHOP_ID_STORAGE);
    if (shopIdfromStorage) {
      getShoppingCartData(shopIdfromStorage);
    }
  }, []);
  return {
    shopIdfromStorage
  };
};



export default useGetShoppingCartData;
