import { useState, useEffect } from 'react';

/**
 * useDebounce
 * Custom hook to get value debounced by delay selected
 * @param {any} value value to get debounced
 * @param {number} delay delay time selected
 * @returns any
 */
const useDebounce = (value, delay = 500) => {
  const [deboucedValue, setDeboucedValue] = useState(value);

  useEffect(() => {
    const handle = setTimeout(() => {
      setDeboucedValue(value);
    }, delay);

    return () => {
      clearTimeout(handle);
    };
  }, [value]);

  return deboucedValue;
};

export default useDebounce;
