import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    color: Colors.blueHepatica,
    marginTop: '8rem'
  },
  row: {
    marginTop: '2rem'
  },
  content: {
    maxWidth: '80rem',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));
export default styles;
