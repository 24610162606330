/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const existAccount = (customer) => customer && Object.keys(customer).length > 0;

export default (ChildComponent) => {
  const ComposedComponent = (otherProps) => {
    const navigate = useNavigate();
    const { customer } = otherProps;
    useEffect(() => {
      if (existAccount(customer)) {
        navigate('/');
      }
    }, [customer, navigate]);
    return !existAccount(customer) && <ChildComponent {...otherProps} />;
  };

  const mapStateToProps = (state) => {
    const { customer } = state.customer;
    return { customer };
  };

  ComposedComponent.propTypes = {
    customer: PropTypes.shape({})
  };

  ComposedComponent.defaultProps = {
    customer: null
  };

  return connect(mapStateToProps, null)(ComposedComponent);
};
