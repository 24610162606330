import {
  DELETE_ITEM_SHOPPING_CART,
  SET_COUPON_SHOPPING_CART,
  GET_SHOPPING_CART_DATA,
  SET_ITEM_SHOPPING_CART,
  UPDATE_QUANTITY_SHOPPING_CART,
  UPDATE_SHOPPING_CART_DATA,
  DELETE_SHOPPING_CART_DATA,
  SET_CLIENT_SHOPPING_CART,
  GET_BONUS_PRODUCTS_START,
  GET_BONUS_PRODUCTS_SUCCESS,
  GET_BONUS_PRODUCTS_FAIL,
  UPDATE_METADATA_ITEMS,
  SET_SHOPPINGCART_ERROR,
  SET_BONUS_RECENTLY_UPDATED,
  CHANGES_DISABLED,
  REMOVE_SHOPPING_CART_DATA
} from './types';

const INITIAL_STATE = {
  shoppingCartData: {
    items: [],
    metadata: {}
  },
  bonusProducts: {
    data: []
  },
  isGettingBonusProducts: false,
  metadata: {
    itemsToUpdate: [],
    action: null,
    bonusRecentlyUpdated: false
  },
  onCheckout: false,
  changesDiabled: false,
  error: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SHOPPING_CART_DATA:
      return {
        ...state,
        shoppingCartData: action.payload
      };
    case UPDATE_METADATA_ITEMS:
      return {
        ...state,
        metadata: action.payload
      };
    case SET_CLIENT_SHOPPING_CART:
      return {
        ...state,
        shoppingCartData: action.payload
      };
    case SET_SHOPPINGCART_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case GET_SHOPPING_CART_DATA:
      return {
        ...state,
        shoppingCartData: action.payload
      };
    case SET_ITEM_SHOPPING_CART: {
      const itemIsOnCart = state.orderList.find((item) => item.id === action.payload.id);

      const handleProductAlreadyInCart = (id) =>
        state.orderList.map((item) =>
          item.id === id
            ? {
                ...item,
                quantity: item.quantity + 1,
                productTotalPrice: item.productTotalPrice + item.product.price
              }
            : item
        );

      return itemIsOnCart
        ? {
            ...state,
            isShoppingCartDataBusy: false,
            orderList: handleProductAlreadyInCart(action.payload.id)
          }
        : {
            ...state,
            isShoppingCartDataBusy: false,
            orderList: [
              ...state.orderList,
              {
                product: { ...action.payload },
                quantity: 1,
                atthachment: 'no',
                id: action.payload.id,
                productTotalPrice: action.payload.price
              }
            ]
          };
    }
    case DELETE_ITEM_SHOPPING_CART: {
      return {
        ...state,
        orderList: state.orderList.filter((order) => order.id !== action.payload)
      };
    }
    case UPDATE_QUANTITY_SHOPPING_CART: {
      const { id, operation } = action.payload;
      const handleDecreaseQuantity = (id) =>
        state.orderList.map((item) =>
          item.id === id
            ? {
                ...item,
                quantity: item.quantity - 1,
                productTotalPrice: item.productTotalPrice - item.product.price
              }
            : item
        );

      const handleIncreaseQuantity = (id) =>
        state.orderList.map((item) =>
          item.id === id
            ? {
                ...item,
                quantity: item.quantity + 1,
                productTotalPrice: item.productTotalPrice + item.product.price
              }
            : item
        );

      return operation === 'Increase'
        ? {
            ...state,
            orderList: handleIncreaseQuantity(id)
          }
        : {
            ...state,
            orderList: handleDecreaseQuantity(id)
          };
    }
    case SET_COUPON_SHOPPING_CART:
      return {
        ...state,
        coupon: true
      };

    case DELETE_SHOPPING_CART_DATA:
      return {
        ...INITIAL_STATE
      };
    case REMOVE_SHOPPING_CART_DATA:
      return {
        shoppingCartData: {
          items: [],
          metadata: {}
        },
        bonusProducts: {
          data: []
        },
        isGettingBonusProducts: false,
        metadata: {
          itemsToUpdate: [],
          action: null,
          bonusRecentlyUpdated: false
        },
        onCheckout: false,
        changesDiabled: false,
        error: false
      };
    case GET_BONUS_PRODUCTS_START:
      return {
        ...state,
        isGettingBonusProducts: true
      };
    case GET_BONUS_PRODUCTS_SUCCESS:
      return {
        ...state,
        isGettingBonusProducts: false,
        bonusProducts: action.payload
      };
    case GET_BONUS_PRODUCTS_FAIL:
      return {
        ...state,
        isGettingBonusProducts: false
      };
    case SET_BONUS_RECENTLY_UPDATED: {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          bonusRecentlyUpdated: action.payload
        }
      };
    }
    case CHANGES_DISABLED: {
      return {
        ...state,
        changesDiabled: action.payload
      };
    }
    default:
      return state;
  }
};
