/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { DEBUG, API_TOKEN, API_URL } from '../constants';

const doLog = DEBUG;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: { 'access-token': API_TOKEN }
});

/**
 * setToken
 * Function for set Token in axios default headers
 * @param {string} authToken auth token
 */
export const setToken = (authToken) => {
  const token = `Bearer ${authToken}`;
  axiosInstance.defaults.headers.common.authorization = token;
  axiosInstance.defaults.headers.common.Version = 2;
};

const getToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  return user ? user.uid : '';
};

export const removeToken = () => {
  delete axiosInstance.defaults.headers.common.authorization;
};

/**
 * Function to get axios instance with header oauth token
 * @returns axios instance
 */
export const getAxiosInstanceByOauthToken = () => {
  const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: { 'access-token': API_TOKEN }
  });
  return axiosInstance;
};

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;
      const token = await getToken();
      if (token) {
        setToken(token);
        originalRequest.headers.authorization = `Bearer ${token}`;
        return axios(originalRequest);
      }

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

/**
 * getEmptyResponse
 * Function to return response on empty response case
 * @param {string} localTag
 * @returns object
 */
export const getEmptyResponse = (localTag) => {
  doLog && console.log(localTag, 'Empty response');
  return {
    data: { msg: 'Empty response', error: `${localTag} EMPTY_RESPONSE` },
    status: 201
  };
};

/**
 * getErrorResponse
 * Function to return response on error case
 * @param {string} localTag
 * @param {object} error
 * @returns object
 */
export const getErrorResponse = (localTag, error) => {
  doLog && console.log(localTag, error);
  const { response = { data: '', status: '' } } = error;
  const { data, status } = response;
  const errorData = { data, status };
  return errorData;
};

export default axiosInstance;
