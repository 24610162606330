import React, { useState } from 'react';
import { Box, Card, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from '../styles';
import CdsoCard from '../../../components/CdsoCard';

const MyLoyaltyPrograms = ({ customer }) => {
  const classes = styles();
  const [openCdsoCard, setopenCdsoCard] = useState(false);
  /**
   * onCloseCdsocARD
   *  handle close cdso card
   * @returns {boolean} true
   */
  const onCloseCdsoCard = () => {
    setopenCdsoCard(!openCdsoCard);
  };

  return (
    <Card className={classes.card}>
      <Box>
        <Typography variant="h6">Programas de lealtad</Typography>
      </Box>
      {customer.cdsoCardNumber ? (
        <Card className={classes.directions}>
          <CdsoCard
            open={openCdsoCard}
            onClose={onCloseCdsoCard}
            cdsoUserId={customer.cdsoCardNumber}
          />
          <Typography>iSana+</Typography>
          <Box className={classes.buttonsRow}>
            <Button variant="text" component="label" onClick={onCloseCdsoCard}>
              Ver
            </Button>
          </Box>
        </Card>
      ) : (
        <Box className={classes.centerRow}>
          <Typography align="center">Aún no tienes programas de lealtad registrados</Typography>
          <Typography align="center">
            <Link to="/programas-de-lealtad">Afiliate a uno aquí.</Link>
          </Typography>
        </Box>
      )}
    </Card>
  );
};

MyLoyaltyPrograms.propTypes = {
  customer: PropTypes.shape({
    cdsoCardNumber: PropTypes.string
  })
};

MyLoyaltyPrograms.defaultProps = {
  customer: {
    cdsoCardNumber: ''
  }
};

export default MyLoyaltyPrograms;
