import React, { useState } from 'react';
import { Fab, MenuItem, Select } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styles from './styles';
import { setBonusRecentlyUpdatedValidation, updateItemsInReduxState } from '../../reducers/shoppingCart/actions';
import { SHOPPING_CART_ACTIONS } from '../../constants';
import ModalDelete from '../ModalDelete';

const NumberItems = ({ item, updateItemsInReduxState, itemLength, setBonusRecentlyUpdatedValidation }) => {
  const [open, setOpen] = useState(false);
  const classes = styles();

  const { quantity, product, unitPrice, discount } = item;
  const { ITEM_SET, ITEM_DELETE } = SHOPPING_CART_ACTIONS;
  const navigate = useNavigate();
  const handleOnDelete = () => {
    setOpen(!open)
  }
  const [itemQuantity, setItemQuantity] = useState(quantity);

  /**
   * update item Quantity
   * @param {number} newQuantity new Quantity to update
   */
  const updateQuantity = (newQuantity) => {
    const bodyData = {
      productId: product._id,
      quantity: newQuantity,
      price: unitPrice,
      discount
    };
    updateItemsInReduxState(bodyData, ITEM_SET)
      .then(() => {
        setBonusRecentlyUpdatedValidation(false);
      })
      .catch(() => {
      });
  };

  const handleOnRemoveItem = () => {
    if(itemLength === 1){
      navigate('/home?remove=1')
      return;
    }
    const bodyData = {
      productId: product._id
    };
    updateItemsInReduxState(bodyData, ITEM_DELETE)
      .then(() => {
      })
      .catch(() => {
      });
  };

  const handleOnAddItem = (event) => {
    setItemQuantity(event.target.value)
    updateQuantity(event.target.value);
  };

  const onDelete = () => {
    setOpen(true)
  }

  return (
    <div className={classes.buttonsCell}>
      <ModalDelete
        name="¿Estás seguro de borrar este producto?"
        open={open}
        onClose={handleOnDelete}
        onAction={handleOnRemoveItem}
      />
        <Fab size="small" className={classes.fabButton} elevation={0} onClick={onDelete}>
          <Delete className={classes.fabIcon} fontSize="small" />
        </Fab>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={itemQuantity}
          label="Age"
          onChange={handleOnAddItem}
          variant='outlined'
          className={classes.select}
          >
        <MenuItem  value={1}>1</MenuItem>
        <MenuItem  value={2}>2</MenuItem>
        <MenuItem  value={3}>3</MenuItem>
        <MenuItem  value={4}>4</MenuItem>
        <MenuItem  value={5}>5</MenuItem>
        <MenuItem  value={6}>6</MenuItem>
        <MenuItem  value={7}>7</MenuItem>
        </Select>
    </div>
  );
};

NumberItems.propTypes = {
  item: PropTypes.shape({
    quantity: PropTypes.number,
    product: PropTypes.shape({ _id: PropTypes.string }),
    unitPrice: PropTypes.number,
    discount: PropTypes.number
  }),
  updateItemsInReduxState: PropTypes.func,
  itemLength: PropTypes.number,
  setBonusRecentlyUpdatedValidation: PropTypes.func
};

NumberItems.defaultProps = {
  item: {},
  updateItemsInReduxState: () => {},
  itemLength: 0,
  setBonusRecentlyUpdatedValidation: () => {}
};

export default connect(null, {
  updateItemsInReduxState,
  setBonusRecentlyUpdatedValidation
})(NumberItems);
