import { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../customContext/FirebaseContext';
import { signup } from '../services/auth';
import { removeToken, setToken } from '../utils/axios';

const useGetUser = (setAccountData, getCustomerData, setCustomerData, account, customer) => {
  const [active, setActive] = useState();
  const { auth, onAuthStateChanged } = useContext(FirebaseContext);

  const createNewUser = async (email, name, uid) => {
    removeToken();
    const params = {
      email,
      name,
      lastName: '',
      mothersLastName: '',
      uid,
      deliveryAddress: {},
      type: 'customer',
      originRecord: 'WEBSITE'
    };
    const newClient = await signup(params);
    setCustomerData(newClient.data);
  };
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (active) {
      const unsubscribeAuth = onAuthStateChanged(auth, async (authResponse) => {
        if (authResponse) {
          const {
            uid,
            email,
            emailVerified,
            isAnonymous,
            phoneNumber,
            photoUrl,
            displayName,
            providerData
          } = authResponse;
          try {
            const token = await authResponse.getIdToken(true);
            setToken(token);
            setAccountData({
              uid,
              email,
              emailVerified,
              isAnonymous,
              phoneNumber,
              photoUrl
            });
            const clientData = await getCustomerData(uid);
            if (!clientData) {
              await createNewUser(email, displayName, uid);
              return;
            }
            setCustomerData(clientData);
          } catch (error) {
            if (
              providerData[0].providerId === 'facebook.com' ||
              providerData[0].providerId === 'google.com'
            ) {
              await createNewUser(email, displayName, uid);
            }
          }
        } else {
          if (account) setAccountData({});
          if (customer) setCustomerData({});
          removeToken();
        }
      });
      return () => {
        unsubscribeAuth();
      };
    }
    setActive(false);
  }, [active]);
  return setActive;
};

export default useGetUser;
