import { makeStyles } from '@material-ui/core';
// eslint-disable-next-line import/named
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    overflowX: 'hidden',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '140rem',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  footerFixed: {
    width: '100%',
    background: Colors.honeyFlower,
    padding: '2rem 2rem',
    color: 'white'
  },
  fixContact: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%'
    }
  },
  topFooter: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  fixSection: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem'
    }
  },
  fixSectionActive: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  titleContact: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textDecoration: 'none',
    color: Colors.simpleWhite,
    display: 'flex',
    alignItems: 'center'
  },
  whatsAppIcon: {
    width: '1.5rem',
    marginRight: '0.4rem'
  },
  subtitleContact: {
    fontSize: '.9rem',
    textAlign: 'start',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  footerInput: {
    marginTop: '1rem',
    padding: '0.9rem 10.2rem 0.9rem 2rem',
    border: 'none',
    borderRadius: '2rem',
    fontSize: '0.9rem',
    outline: 'none'
  },
  suscribeButton: {
    marginLeft: '-8.7rem',
    marginTop: '1.2rem',
    position: 'absolute',
    padding: '0.6rem 2rem 0.6rem 2rem',
    border: 'none',
    borderRadius: '2rem',
    background: '#9E2FFB',
    color: 'white',
    cursor: 'pointer'
  },
  fixSecondSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  growFix: {
    color: Colors.simpleWhite,
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '.9rem'
  },
  fixActiveRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '2rem'
  },
  bottomFooter: {
    marginTop: '1rem',
    borderTop: `solid 1px ${Colors.lowGray}`,
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      paddingTop: '1rem'
    }
  },
  imagelogo: {
    width: '8rem',
    height: 'auto'
  },
  fixFont: {
    fontSize: '0.9rem',
    fontWeight: 'bold'
  },
  fixFontActive: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginTop: '1rem'
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.9rem'
  },
  inputBox: {
    display: 'none'
  },
  numberBox: {
    marginRight: '1rem'
  },
  input: {
    flex: 1,
    marginLeft: '1.3rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem'
    },
    '& ::placeholder': {
      color: Colors.scarpaFlow,
      fontSize: '.9rem'
    }
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.1rem 0.3rem',
    borderRadius: '2rem',
    width: '28rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '2rem',
      width: '25rem'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
      width: '18rem'
    }
  },
  buttonSuscribe: {
    textTransform: 'none',
    background: Colors.electricViolet,
    color: Colors.simpleWhite,
    borderRadius: '2rem',
    '&:hover': {
      background: Colors.honeyFlower,
      color: Colors.simpleWhite
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem'
    }
  },
  link: {
    fontSize: '.9rem',
    color: Colors.simpleWhite,
    textDecoration: 'none',
    fontWeight: '600'
  },
  secundaryContainer: {
    marginTop: '2rem'
  },
  centerDiv: { display: 'flex', flexDirection: 'column', margin: 'auto' }
}));

export default styles;
