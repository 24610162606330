import {
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Chip,
  CircularProgress,
  Grid,
  Box
} from '@material-ui/core';
import React from 'react';
import PropTypes, { arrayOf, shape } from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ProductDescription from '../../ProductDescription';
import NumberItems from '../../NumberItems';
import styles from '../styles';
import images from '../../../assets/images';
import TextNumber from '../../TextNumber';
import { handleTypeOfPromotion } from '../../../helper';
import { updateItemsInReduxState } from '../../../reducers/shoppingCart/actions';

const BonusInProduct = ({ offers, bonus, ean, isLoadingBonus }) => {
  const classes = styles();
  const { data } = bonus;
  const bonusDetails = data.find((bonus) => bonus.ean === ean);

  if (bonusDetails) {
    if (parseInt(bonusDetails.PiezasGratis, 10)) {
      const piezaWord = bonusDetails.PiezasGratis > 1 ? 'piezas' : 'pieza';
      return (
        <Chip
          size="small"
          className={classes.chipPromotion}
          label={`Tienes ${bonusDetails.PiezasGratis} ${piezaWord} gratis disponible!`}
        />
      );
    }
  }

  if (isLoadingBonus) {
    return (
      <Grid container alignItems="center">
        <CircularProgress size={15} className={classes.loaderPromotions} />
        <Typography variant="caption">Verificando promociones</Typography>
      </Grid>
    );
  }

  if (!isLoadingBonus && offers.length) {
    return (
      <Typography className={classes.promo}>{handleTypeOfPromotion(offers[offers.length -1].name)}</Typography>
    );
  }

  return null;
};

const ItemCartTable = ({
  item,
  isEditable,
  itemLength,
  bonusProducts,
  isGettingBonusProducts,
  showPromotion,
  showPrices
}) => {
  const classes = styles();
  const { product, quantity, unitPrice, discount } = item;
  return (
    <TableRow className={!quantity ? classes.rowDisabled : ''}>
      <TableCell>
        <div className={classes.cell}>
          <Link to={`/productos/${product._id}`}>
            <img
              className={isEditable ? classes.smimage : classes.image}
              src={product.imageUrl || images.productDefault}
              alt=""
            />
          </Link>
          <div className={classes.textProductContainer}>
            <Typography className={classes.nameProduct}>{product.name}</Typography>
            <ProductDescription classes={classes} productData={product} />
            {!quantity && (<div>{ product.metadata && product.metadata.assorted  ? (<Chip label='Este producto ya fue surtido.' />):(<Chip label='Lo sentimos, este producto no está disponible.' />) }</div>)}
            {showPromotion && (
              <BonusInProduct
                offers={product.offers}
                bonus={bonusProducts}
                ean={product.ean}
                isLoadingBonus={isGettingBonusProducts}
              />
            )}
          </div>
        </div>
        <div className={classes.boxHidden}>
          {isEditable ? (
            <NumberItems itemLength={itemLength} item={item} />
          ) : (
            <Typography align="center">{product.metadata && product.metadata.assorted ? '' : quantity}</Typography>
          )}
          {showPrices ? (
            <Typography variant="h6">
              <TextNumber number={unitPrice} />
            </Typography>
          ) : null}
          {product.requireRecipe && (
            <Tooltip title="Requiere receta">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm2 2h6v6H6V7zm2 2v2h2V9H8zm-2 6h12v2H6v-2zm8-8h4v2h-4V7zm0 4h4v2h-4v-2z"
                  fill="rgba(82,149,215,1)"
                />
              </svg>
            </Tooltip>
          )}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        {isEditable ? (
          <NumberItems itemLength={itemLength} item={item} />
        ) : (
          <Typography align="center">{quantity}</Typography>
        )}
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        {showPrices ? (
          <Typography>
            <TextNumber number={unitPrice} />
          </Typography>
        ) : null}
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        {showPrices ? (
          <Box>
            <Typography className={discount ? classes.discount : ''}>
              <TextNumber number={unitPrice * quantity} />
            </Typography>
            {Boolean(discount) && (
              <div>
                {
                  // eslint-disable-next-line no-restricted-globals
                  isNaN(item.subtotal) ? (
                    <CircularProgress size="1rem" />
                  ) : (
                    <Typography className={classes.pricewithdiscount}>
                      <TextNumber number={item.subtotal} />
                    </Typography>
                  )
                }
              </div>
            )}
          </Box>
        ) : null}
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        {product.requireRecipe && (
          <Tooltip title="Requiere receta">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm2 2h6v6H6V7zm2 2v2h2V9H8zm-2 6h12v2H6v-2zm8-8h4v2h-4V7zm0 4h4v2h-4v-2z"
                fill="rgba(82,149,215,1)"
              />
            </svg>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  const { bonusProducts, isGettingBonusProducts } = state.shoppingCart;
  return { bonusProducts, isGettingBonusProducts };
};

BonusInProduct.propTypes = {
  offers: arrayOf(PropTypes.shape({ name: PropTypes.string })),
  bonus: shape({ data: PropTypes.arrayOf(PropTypes.shape({ ean: PropTypes.string })) }),
  ean: PropTypes.string,
  isLoadingBonus: PropTypes.bool
};

BonusInProduct.defaultProps = {
  offers: [],
  bonus: { data: [] },
  ean: '',
  isLoadingBonus: false
};

ItemCartTable.propTypes = {
  item: PropTypes.shape({
    quantity: PropTypes.number,
    product: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      imageUrl: PropTypes.string,
      requireRecipe: PropTypes.bool,
      offers: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      ),
      ean: PropTypes.string,
      metadata: PropTypes.shape({
        assorted: PropTypes.bool
      })
    }),
    unitPrice: PropTypes.number,
    discount: PropTypes.number,
    subtotal: PropTypes.number
  }),
  isEditable: PropTypes.bool,
  itemLength: PropTypes.number,
  bonusProducts: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape()) }),
  isGettingBonusProducts: PropTypes.bool,
  showPromotion: PropTypes.bool,
  showPrices: PropTypes.bool
};

ItemCartTable.defaultProps = {
  item: { discount: 0, subtotal: 0 },
  isEditable: false,
  itemLength: 0,
  bonusProducts: { data: [] },
  isGettingBonusProducts: false,
  showPromotion: true,
  showPrices: true
};

export default connect(mapStateToProps, { updateItemsInReduxState })(ItemCartTable);
