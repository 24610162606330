import { useState } from 'react';

const useToggle = (toggle = false) => {
  const [isToggleActive, setIsToggleActive] = useState(toggle);

  const handleToggle = () => {
    setIsToggleActive(!isToggleActive);
  };

  return {
    isToggleActive,
    handleToggle
  };
};

export default useToggle;
