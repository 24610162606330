import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  containerFixed: {
    marginTop: '6rem'
  },
  input: {
    flex: 1,
    fontSize: '1.2rem',
    marginLeft: '1.3rem',
    '& input::-webkit-placeholder': {
      color: Colors.black
    },
    '& input::-ms-placeholder': {
      color: Colors.black
    },
    '& input::placeholder': {
      color: Colors.black
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem',
      fontSize: '1rem'
    }
  },
  root: {
    padding: '0.2rem 1rem',
    display: 'flex',
    alignItems: 'center',
    minWidth: '33rem',
    maxWidth: '33rem',
    borderRadius: '3rem',
    border: `solid 2px ${Colors.heliotrope}`,
    boxShadow: 'none',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      minWidth: '21rem',
      maxWidth: '33rem',
      padding: '0.3rem 0.6rem',
      margin: '0 0 .5rem 0'
    }
  },
  searchIcon: {
    color: Colors.simpleWhite
  },
  fabButton: {
    background: Colors.electricViolet,
    color: Colors.simpleWhite,
    '&:hover': {
      background: Colors.honeyFlower,
      color: 'white'
    }
  },
  infoUser: {
    display: 'flex',
    margin: '1rem',
    padding: '1rem',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
  },
  infoTitle: {
    fontWeight: 'bold',
    fontSize: '0.9rem'
  },
  info: {
    fontSize: '1rem'
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column'
  },
  cellTitle: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  tableCell: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  productsRow: {
    margin: 'auto',
    padding: '1.4rem',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '60rem',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '25rem'
    }
  },
  notInfo: {
    minHeight: '20rem',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  noAgreement: {
    display: 'flex',
    minHeight: '20rem',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems:'center '
  },
  paperContainer: {
    marginTop: '1rem'
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem'
  },
  buttonShopping: {
    width: '100%',
    maxWidth: '20rem',
    margin: 'auto',
    borderRadius: '1.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '18rem',
      fontSize: '1rem'
    },
    color: Colors.simpleWhite,
    background: Colors.electricViolet,
    '&:hover': {
      background: Colors.honeyFlower
    },
    marginBottom: '1rem'
  },
  searchBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  imagesBags: {
    width: '8rem'
  },
  assortedBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '4rem',
    marginBottom: '2rem'
  },
  assortedTitle: {
    textAlign: 'center'
  }
}));

export default styles;
