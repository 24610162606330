import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  fab: {
    zIndex: 1,
    display: 'flex',
    position: 'fixed',
    right: '4rem',
    bottom: '8rem',
    [theme.breakpoints.down('sm')]: {
      right: '1rem'
    }
  },
  whatsAppIcon: {
    width: '4rem',
    height: 'auto'
  }
}));
export default styles;
