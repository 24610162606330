/* eslint-disable no-underscore-dangle */
import {
  Fab,
  InputBase,
  LinearProgress,
  List,
  Paper,
  Typography,
  ListItem
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './styles';
import ProductDescription from '../../../../components/ProductDescription';
import useDebounce from '../../../../customHooks/useDebounce';
import { list } from '../../../../services/products';
import images from '../../../../assets/images';
import { getProducts, setSearchText, removeCategory } from '../../../../reducers/products/actions';
import { OAUTH_CODE } from '../../../../constants';

const SearchBar = ({
  getProducts,
  setSearchText,
  categorySelected,
  isLoadingProducts,
  shoppingCartData,
  removeCategory
}) => {
  const classes = styles();
  const navigate = useNavigate();
  const [isActivateSearchBar, setIsActivateSearchBar] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const delayedWordSearch = useDebounce(searchValue, 800);
  const [statusSearch, setStatusSearch] = useState('loading');
  const [productsData, setProductsData] = useState({ docs: [] });
  const [showDialogResults, setShowDialogResults] = useState(false);
  const { docs } = productsData;
  const [isDisplaySearchbar, setIsDisplaySearchbar] = useState(true);
  const [disableSearcbarToClean, setDisableSearcbarToClean] = useState(false);
  const selector = useSelector((state) => state.customer);


  useEffect(() => {
    if (shoppingCartData.oauthId) {
      setIsDisplaySearchbar(shoppingCartData.oauthId.internalCode === OAUTH_CODE);
    }
  }, [shoppingCartData.oauthId]);

  useEffect(() => {
    setIsActivateSearchBar(true);
    const url = new URL(window.location.href);
    const searchValue = url.searchParams.get('search');
    const {customer} = selector
    let referralCode = ''
    if (customer && customer.referralCode) {
      referralCode = customer.referralCode
    }
    if (searchValue) {
      setSearchText(searchValue);
      setSearchValue(searchValue);
      getProducts({ page: 1, search: searchValue, referralCode });
    } else {
      getProducts({ page: 1, referralCode});
    }

    document.addEventListener('click', () => {
      setShowDialogResults(false);
    });
  }, [selector.customer]);

  const handleSearchValue = (evt) => {
    if (disableSearcbarToClean) {
      setDisableSearcbarToClean(false);
      setIsActivateSearchBar(true);
    }
    setSearchValue(evt.target.value);
  }

  const getProductsLocal = async (params = {}) => {
    try {
      setStatusSearch('loading');
      const response = await list(params);
      if (response && response.totalDocs !== 0) {
        setStatusSearch(true);
      } else {
        setStatusSearch(false);
      }
      setProductsData(response);
    } catch (error) {
      setStatusSearch(false);
    }
  };

  const verifyInProductsSection = () => {
    const { pathname } = window.location;
    const slashNumber = pathname.split('/');
    return Boolean(pathname.includes('productos') && slashNumber.length === 2);
  };

  const setSearchParamInUrl = (word = '') => {
    const url = new URL(window.location.href);
    if (word) {
      url.searchParams.set('search', word);
      window.history.pushState({ path: url.href }, '', url.href);
      return;
    }

    if (url.searchParams.has('search')) {
      url.searchParams.delete('search');
      window.history.pushState({ path: url.href }, '', url.href);
    }
  };

  useEffect(() => {
    if (categorySelected && Object.keys(categorySelected).length) {
      setIsActivateSearchBar(false);
      setDisableSearcbarToClean(true);
      setSearchValue('');
    }
  }, [categorySelected])

  useEffect(() => {
    if (isActivateSearchBar) {
      if (verifyInProductsSection()) {
        if (categorySelected) {
          removeCategory();
        }
        getProducts({ page: 1, search: delayedWordSearch });
        setSearchParamInUrl(delayedWordSearch);
        setShowDialogResults(false);
        setSearchText(delayedWordSearch);
      } else {
        setShowDialogResults(true);
        getProductsLocal({ search: delayedWordSearch, limit: 16 });
      }
    }
  }, [delayedWordSearch]);

  /**
   * handleKeyPressTextField
   * Handler on key press text field message
   * @param {object} evt Object event key press
   */
  const handleKeyPressTextField = (evt) => {
    if (evt.key === 'Enter') {
      if (categorySelected) {
        removeCategory();
      }
      evt.preventDefault();
      setSearchValue(searchValue);
      getProducts({ search: searchValue });
      setShowDialogResults(false);
      navigate(`/productos?search=${searchValue}`);
    }
  };

  /**
   * Handler on click search button
   * @param {object} evt object click event
   */
  const handleClickSearch = (evt) => {
    if (!isLoadingProducts) {
      evt.preventDefault();
      if (categorySelected) {
        removeCategory();
      }
      setSearchText(searchValue);
      getProducts({ search: searchValue });
      navigate(`/productos?search=${searchValue}`);
    }
  };

  /**
   * handleSearchItems
   *  redirect to detail product
   *  restart searchValue
   * @param {string} newItem new item to search
   * @returns array
   */
  const handleSearchItems = (id) => {
    navigate(`/productos/${id}`);
    setSearchValue('');
  };

  const SearchList = () => docs.map((product) => (
    <ListItem
      onClick={() => handleSearchItems(product._id)}
      key={product._id}
      button
      className={classes.listItem}
    >
      <img
        src={product.imageUrl || images.productDefault}
        alt={product.name}
        className={classes.itemImage}
      />
      <Typography className={classes.nameProduct}>{product.name}</Typography>
      <ProductDescription classes={classes} productData={product} />
    </ListItem>
  ));

  return (
    <div>
      {isDisplaySearchbar && (
        <Paper component="form" className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="¿Qué necesitas comprar?"
            value={searchValue}
            inputProps={{ 'aria-label': '¿Qué necesitas comprar?' }}
            onChange={handleSearchValue}
            onKeyPress={handleKeyPressTextField}
          />
          <Fab
            size="small"
            aria-label="search"
            className={classes.fabButton}
            onClick={handleClickSearch}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"
                fill="rgba(255,255,255,1)"
              />
            </svg>
          </Fab>
        </Paper>
      )}
      {showDialogResults && (
        <div className={classes.modalBox}>
          {searchValue !== '' && (
            <Paper className={classes.modal}>
              <div>
                <Typography className={classes.serchText}>
                  Resultados para
                  {` "${searchValue}"`}
                </Typography>
              </div>

              {statusSearch === 'loading' ? (
                <div>
                  <Typography>Buscando resultados</Typography>
                  <LinearProgress color="primary" />
                </div>
              ) : (
                <div>
                  {docs.length >= 1 ? (
                    <div>
                      <List>
                        <SearchList />
                      </List>
                    </div>
                  ) : (
                    <div>
                      <Typography className={classes.sorryText}>
                        🤕 Lo sentimos. No se enontraron resultados
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </Paper>
          )}
        </div>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  getProducts: PropTypes.func,
  setSearchText: PropTypes.func,
  removeCategory: PropTypes.func,
  categorySelected: PropTypes.shape({}),
  isLoadingProducts: PropTypes.bool,
  shoppingCartData: PropTypes.shape({
    oauthId: PropTypes.shape({ internalCode: PropTypes.string })
  })
};

SearchBar.defaultProps = {
  getProducts: () => {},
  setSearchText: () => {},
  removeCategory: () => {},
  categorySelected: {},
  isLoadingProducts: false,
  shoppingCartData: { oauthId: { internalCode: OAUTH_CODE } }
};

const mapStateToProps = (state) => {
  const { categorySelected, isLoadingProducts } = state.product;
  const { shoppingCartData } = state.shoppingCart;
  return { categorySelected, isLoadingProducts, shoppingCartData };
};

export default connect(mapStateToProps, {
  getProducts,
  setSearchText,
  removeCategory
})(SearchBar);
