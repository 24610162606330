import React from 'react';
import PropTypes from 'prop-types';

const TextNumber = ({ number, ...otherProps }) => {
  const { className } = otherProps;
  return (
    <span className={className}>
      $
      {number.toFixed(2)}
    </span>
  );
};

TextNumber.propTypes = {
  number: PropTypes.number
};

TextNumber.defaultProps = {
  number: 0
};

export default TextNumber;
