import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../theme';

const styles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bar: {
    backgroundColor: Colors.simpleWhite
  },
  tableFix: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: Colors.blueHepatica,
    marginTop: '4rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5rem'
    }
  },
  subTitle: {
    fontWeight: 'bold',
    color: Colors.blueHepatica
  },
  boxField: {
    maxWidth: '32rem',
    padding: '2rem',
    backgroundColor: Colors.lowGray,
    marginTop: '2rem',
    margin: 'auto',
    marginBottom: '2rem'
  },
  textfield: {
    backgroundColor: Colors.simpleWhite,
    marginTop: '1rem'
  },
  fixRow: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  fixSmallField: {
    marginRight: '0.3rem',
    [theme.breakpoints.down('md')]: {
      marginRight: '0rem'
    }
  },
  button: {
    display: 'flex',
    backgroundColor: Colors.blueHepatica,
    color: Colors.simpleWhite,
    marginTop: '1.3rem'
  },
  address: {
    width: '91%',
    margin: 'auto'
  },
  boxDeliveryLoader: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alingContent: 'center',
    marginTop: '-13rem',
    marginLeft: '12.3rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '8.3rem',
      marginTop: '-20rem'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '12.3rem'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '9.3rem'
    }
  },
  fixDiv: {
    height: '3rem'
  }
}));

export default styles;
