import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import history from './utils/history';
import auth from './reducers/auth/reducers';
import customer from './reducers/customer/reducers';
import shoppingCart from './reducers/shoppingCart/reducers';
import product from './reducers/products/reducers';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    auth,
    customer,
    router: connectRouter(history),
    shoppingCart,
    product,
    ...injectedReducers
  });

  return rootReducer;
}
