import React from 'react';
import { Container, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuLink from './MenuLink';
import useStyles from './useStyles';

const MenuLinks = ({ onClickButton, isDeskScreen }) => {
  const handleClickButton = () => onClickButton();
  const classes = useStyles();

  const links = [
    { id: 1, text: 'Inicio', to: '/', external: false },
    { id: 2, text: 'Productos', to: '/productos', external: false },
    { id: 3, text: 'Programa de lealtad', to: '/programas-de-lealtad', external: false },
    { id: 5, text: 'Aseguradoras', to: '/aseguradoras', external: false }
  ];

  return (
    <Grid
      container
      className={isDeskScreen ? classes.menuLinksContainerDesk : classes.menuLinksContainer}
    >
      <Grid item xs={12}>
        <Container maxWidth="xl">
          <Grid container direction="row" justifyContent="center" alignItems="center">
            {links.map(({ id, text, to, external }) => (
              <MenuLink
                key={id}
                text={text}
                to={to}
                isExternal={external}
                onClick={handleClickButton}
              />
            ))}
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

MenuLinks.propTypes = {
  onClickButton: PropTypes.func,
  isDeskScreen: PropTypes.bool
};

MenuLinks.defaultProps = {
  onClickButton: () => {},
  isDeskScreen: false
};

export default MenuLinks;
