import { DialogContent, Dialog, DialogTitle, Typography, Button, Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './styles';
import { getCustomerData } from '../../../../../services/customers';
import { setCustomerData } from '../../../../../reducers/customer/actions';

const ResponseDialog = ({ result, open, onClose, uid, setCustomerData }) => {
  const classes = styles();

  const navigate = useNavigate();

  /**
   * Function to Redirect to cdso profile or home 
   * @returns 
   */
  const action = () => {
    if(result){
      navigate('/programas-de-lealtad/circulo-salud-oro/perfil');
      return;
    }
    navigate('/home')
  }

  useEffect(()=> {
    if(result){
      getCustomerData(uid).then((clientData)=>{
        setCustomerData(clientData);
      })
    }
  },[result])

  return (
    <Dialog
      className={classes.modal}
      open={open}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.text}>{result ? '¡Se ha creado tu tarjeta!' : '¡Oh Oh!'}</DialogTitle>
      <DialogContent>
        <Typography className={classes.text}>
          {result
            ? ' Ya puedes empezar a disfrutar de lo beneficios que iSana y el Círculo de la Salud Oro tienen para ti'
            : 'Estamos teniendo problemas para establecer conexión'}
        </Typography>
        <Box className={classes.boxButton}>
            <Button variant='contained' color='primary' onClick={result ? action : onClose}>{result
            ? ' Bien'
            : 'Intentar más tarde'}</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ResponseDialog.propTypes = {
  result: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  uid:PropTypes.string,
  setCustomerData: PropTypes.func
};
ResponseDialog.defaultProps = {
  result: false,
  open: false,
  onClose: () => {},
  uid:'',
  setCustomerData: ()=>{}
};

export default connect(null, { setCustomerData })(ResponseDialog);
