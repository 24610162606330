
import React from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import Main from './containers/Main';
import configureStore from './configureStore';
import history from './utils/history';
import { theme } from './theme';
import { FirebaseProvider } from './customContext/FirebaseContext';

// Create redux store with history
const initialState = {};
const store = configureStore(history, initialState);

const App = () => (
  <FirebaseProvider>
    <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Main />
        </ThemeProvider>
    </Provider>
  </FirebaseProvider>
);

export default App;
