import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  container: {
    marginTop: '1.5rem',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '120rem',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  banner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  bannerTitle: {
    marginTop: '5rem',
    marginBottom: '2rem',
    fontSize: '2rem',
    fontWeight: 'bold',
    color: Colors.electricViolet,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    }
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.up('xl')]: {
      padding: '0rem 0rem 0rem 4rem'
    }
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem',
    [theme.breakpoints.up('md')]: {
      marginLeft: '1rem'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '2rem'
    }
  },
  listBox: {
    background: Colors.simpleWhite,
    paddingTop: '1rem'
  },
  itemsFoundBox: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  itemsFound: {
    marginLeft: '4rem',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0rem',
      marginLeft: '0rem',
      fontSize: '1rem',
      textAlign: 'center'
    }
  },
  boxTextItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  menu: {
    display: 'flex',
    justifyContent: 'center'
  },
  menuList: {
    maxHeight: '30rem',
    overflow: 'scroll',
    overflowX: 'hidden',
    width: '100%'
  },
  gridProducts: {
    paddingLeft: '3rem',
    paddingRight: '3rem',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem'
    }
  },
  priceRange: {
    marginTop: '1rem',
    padding: '1rem',
    backgroundColor: Colors.lowGray
  },
  mdBoxFilter: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '4rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  smBoxFilter: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    },
    marginTop: '-1.3rem'
  },
  boxLoading: {
    height: '30rem',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  }
}));

export default styles;
