/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog, Typography, Box, Card, TextField, Button, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useStyles from './useStyles';
import { updateCustomer } from '../../../services/customers';
import useValidField from '../../../customHooks/useValidField';
import { getPropsValidate, validateInputValue } from '../../../helper';

const EditProfile = ({
  open,
  onClose,
  customer,
  getUpdatedCustomer,
  handleSuccess,
  handleClickUpdate
}) => {
  const classes = useStyles();
  const nameValidator = useValidField({ type: 'text' });
  const lastNameValidator = useValidField({ type: 'text' });
  const mothersLastNameValidator = useValidField({ type: 'text' });
  const phoneNumberValidator = useValidField({ type: 'text' });

  /**
   * validateChangesInValue
   *  check if a value changed from original
   * @param {newValue, oldValue}
   * @returns {String} old value or new value
   */
  const validateChangesInValue = (newValue, oldValue) => {
    if (newValue !== '' && newValue !== oldValue) {
      return newValue;
    }
    return oldValue;
  };


  /**
   * updateCustomerData 
   * set the new values and update state and info about customer
   */
  const updateCustomerData = () => {
    const userData = {
      name: validateChangesInValue(nameValidator.value, customer.name),
      lastName: validateChangesInValue(lastNameValidator.value, customer.lastName),
      mothersLastName: validateChangesInValue(mothersLastNameValidator.value, customer.mothersLastName),
      phoneNumber: validateChangesInValue(phoneNumberValidator.value, customer.phoneNumber),
      _id: customer._id
    };
    updateCustomer({ params: userData })
      .then((response) => {
        getUpdatedCustomer(response);
        handleSuccess(true);
        handleClickUpdate();
        onClose();
      })
      .catch(() => {
        handleSuccess(false);
      });
  };

  return (
    <Dialog open={open}>
      <Box className={classes.gridFix}>
        <Card className={classes.card}>
          <Box className={classes.actionBox}>
            <Typography variant="h6">Editar Perfil</Typography>
            <IconButton onClick={onClose}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
          <Box className={classes.fixRow}>
            <TextField
              label="Nombre"
              variant="outlined"
              size="small"
              type="email"
              className={`${classes.textfield} ${classes.fixSmallField}`}
              fullWidth
              defaultValue={customer.name}
              {...getPropsValidate(nameValidator)}
            />
            <TextField
              label="Primer apellido"
              variant="outlined"
              size="small"
              type="email"
              className={`${classes.textfield} ${classes.fixSmallField}`}
              fullWidth
              defaultValue={customer.lastName}
              {...getPropsValidate(lastNameValidator)}
            />
          </Box>
          <Box className={classes.fixRow}>
            <TextField
              label="Segundo apellido"
              variant="outlined"
              size="small"
              type="email"
              className={`${classes.textfield} ${classes.fixSmallField}`}
              fullWidth
              defaultValue={customer.mothersLastName}
              {...getPropsValidate(mothersLastNameValidator)}
            />
            <TextField
              label="Número de teléfono"
              variant="outlined"
              size="small"
              type="email"
              className={`${classes.textfield} ${classes.fixSmallField}`}
              fullWidth
              defaultValue={customer.phoneNumber}
              {...getPropsValidate(phoneNumberValidator)}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            className={classes.textfield}
            disabled={
              validateInputValue(nameValidator.value, customer.name) &&
              validateInputValue(lastNameValidator.value, customer.lastName) &&
              // eslint-disable-next-line max-len
              validateInputValue(
                mothersLastNameValidator.value,
                customer.mothersLastName
              ) &&
              // eslint-disable-next-line max-len
              validateInputValue(phoneNumberValidator.value, customer.phoneNumber)
            }
            onClick={updateCustomerData}
          >
            Guardar cambios
          </Button>
        </Card>
      </Box>
    </Dialog>
  );
};

EditProfile.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  customer: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    mothersLastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    _id: PropTypes.string
  }),
  getUpdatedCustomer: PropTypes.func,
  handleSuccess: PropTypes.func,
  handleClickUpdate: PropTypes.func
};

EditProfile.defaultProps = {
  open: false,
  onClose: () => {},
  customer: {
    name: '',
    lastName: '',
    mothersLastName: '',
    phoneNumber: '',
    _id: ''
  },
  getUpdatedCustomer: () => {},
  handleSuccess: () => {},
  handleClickUpdate: () => {}
};

export default connect(null, {})(EditProfile);
