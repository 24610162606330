/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import Nav from './Nav';
import Footer from '../../components/Footer';
import Home from '../Home';
import AskedQuestions from '../AskedQuestions';
import Services from '../Services';
import Promotions from '../Promotions';
import ProductDetail from '../ProductDetail';
import ShoppingCart from '../ShoppingCart';
import Products from '../Products';
import Login from '../Login';
import SignUp from '../SignUp';
import Checkout from '../Checkout';
import ResetPassword from '../ResetPassword';
import {
  getShoppingCartData,
  createShoppingCartData,
  updateShoppingCartData,
  setClientShoppingCart,
  setErrorShoppingCart
} from '../../reducers/shoppingCart/actions';
import { setAccountData } from '../../reducers/auth/actions';
import { setCustomerData } from '../../reducers/customer/actions';
import Response from '../Checkout/Response';
import { getCustomerData } from '../../services/customers';
import Policies from '../Policies';
import MyOrders from '../MyOrders';
import OrderDetail from '../MyOrders/OrderDetail';
import MyProfile from '../MyProfile';
import EditAddress from '../MyProfile/EditAddress';
import PrivacyPolicy from '../PrivacyPolicy';
import MyAccount from '../MyAccount';
import Verification from '../Verification';
import LoyaltyProgram from '../LoyaltyPrograms';
import CdsoProfile from '../LoyaltyPrograms/CdsoProfile';
import RegisterCdsoCard from '../LoyaltyPrograms/CdsoProfile/RegisterCdsoCard';
import CdsoAboutUs from '../LoyaltyPrograms/CdsoProfile/CdsoAboutUs';
import useGetShoppingCartData from '../../customHooks/useGetShoppingCartData';
import { SHOP_ID_STORAGE } from '../../constants';
import DialogModal from '../../components/DialogModal';
import useGetUser from '../../customHooks/useGetUser';
import Insurances from '../Insurances';
import ListenerAgreementsRoute from '../../components/ListenerAgreementRoute';

const Main = ({
  getShoppingCartData,
  setCustomerData,
  setAccountData,
  shoppingCartData,
  account,
  customer,
  setClientShoppingCart,
  createShoppingCartData,
  updateShoppingCartData,
  shoppingCartError, 
  setErrorShoppingCart
}) => {
  let shopId;
  const { shopIdfromStorage } = useGetShoppingCartData(getShoppingCartData, shoppingCartData);
  shopId = shopIdfromStorage;
  const [showErroModal, setShowErrorModal] = useState(shoppingCartError);
  const location = useLocation();
  
  const setActive = useGetUser(setAccountData, getCustomerData, setCustomerData, account, customer);

  const createShoppingCartAction = () => {
    const cdsoCard = customer.cdsoCardNumber || null;
    shopId = sessionStorage.getItem(SHOP_ID_STORAGE);
    if (!shopId) {
      createShoppingCartData(cdsoCard);
      return;
    }
    updateShoppingCartData();
  };

  const setClientInShoopingCart = async (clientData) => {
    if (shopId) {
      if (shoppingCartData && shoppingCartData._id) {
        if (!shoppingCartData.clientId) {
          await setClientShoppingCart(shopId, clientData._id);
        }
      } else {
        getShoppingCartData(shopId);
      }
    }
  };

  useEffect(() => {
    if (shoppingCartData.changes) {
      createShoppingCartAction(shoppingCartData);
    }
  }, [shoppingCartData.changes]);

  useEffect(() => {
    if (customer && Object.keys(customer).length) {
      setClientInShoopingCart(customer).then(() => {
        if (customer && customer.emailVerified) {
          if (account && !account.emailVerified) {
            const accountUpdated = { ...account, emailVerified: true };
            setAccountData(accountUpdated);
          }
        }
      });
    }
  }, [customer]);

  useEffect(() => {
    setActive(true);
  },[])

  const closeErrorModal = () => {
    setErrorShoppingCart(false)
  }

  useEffect(()=> {
    setShowErrorModal(shoppingCartError);
  }, [shoppingCartError])

  return (
    <div>
      <Nav />
      <DialogModal 
        name="Hubo un error al actualizar el carrito de compras"
        open={showErroModal}
        onClose={closeErrorModal}
        />
      {!location.pathname.includes('checkout') && (
      <ListenerAgreementsRoute routeName={location.pathname}/>
      )}
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/preguntasFrecuentes" element={<AskedQuestions />} />
        <Route path="/servicios" element={<Services />} />
        <Route path="/promociones" element={<Promotions />} />
        <Route path="/shoppingCart" element={<ShoppingCart />} />
        <Route path="/productos" element={<Products />} />
        <Route path="/productos/:_id" element={<ProductDetail />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/recover-password" element={<ResetPassword />} />
        <Route path="/checkout/response" element={<Response />} />
        <Route path="/account" element={<MyAccount />} />
        <Route path="/orders" element={<MyOrders />} />
        <Route path="/orders/:id" element={<OrderDetail />} />
        <Route path="/perfil" element={<MyProfile />} />
        <Route path="/perfil/direccion/:_id" element={<EditAddress />} />
        <Route path="/terminos-y-condiciones-de-envio" element={<Policies />} />
        <Route path="/aviso-de-privacidad" element={<PrivacyPolicy />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/programas-de-lealtad" element={<LoyaltyProgram />} />
        <Route
          path="/programas-de-lealtad/circulo-salud-oro/registrar-tarjeta"
          element={<RegisterCdsoCard />}
        />
        <Route path="/programas-de-lealtad/circulo-salud-oro/perfil" element={<CdsoProfile />} />
        <Route
          path="/programas-de-lealtad/circulo-salud-oro/que-es-cdso"
          element={<CdsoAboutUs />}
        />
        <Route path="/aseguradoras" element={<Insurances />}/>
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
};

Main.propTypes = {
  getShoppingCartData: PropTypes.func,
  setCustomerData: PropTypes.func,
  setAccountData: PropTypes.func,
  setClientShoppingCart: PropTypes.func,
  shoppingCartData: PropTypes.shape({
    _id: PropTypes.string,
    clientId: PropTypes.string,
    changes: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  account: PropTypes.shape({ emailVerified: PropTypes.bool }),
  customer: PropTypes.shape({ emailVerified: PropTypes.bool, cdsoCardNumber: PropTypes.string }),
  createShoppingCartData: PropTypes.func,
  updateShoppingCartData: PropTypes.func,
  shoppingCartError: PropTypes.bool,
  setErrorShoppingCart: PropTypes.func
};

Main.defaultProps = {
  getShoppingCartData: () => {},
  setCustomerData: () => {},
  setAccountData: () => {},
  setClientShoppingCart: () => {},
  createShoppingCartData: () => {},
  updateShoppingCartData: () => {},
  setErrorShoppingCart: () => {},
  shoppingCartData: {
    id: null,
    clientId: null,
    items: [],
    changes: false
  },
  account: { emailVerified: false },
  customer: { emailVerified: false, cdsoCardNumber: '' },
  shoppingCartError: false
};

const mapStateToProps = (state) => {
  const { shoppingCartData, error } = state.shoppingCart;
  const { customer } = state.customer;
  const { account } = state.auth;
  const shoppingCartError = error
  return { shoppingCartData, account, customer, shoppingCartError };
};

export default connect(mapStateToProps, {
  getShoppingCartData,
  setCustomerData,
  setAccountData,
  setClientShoppingCart,
  createShoppingCartData,
  updateShoppingCartData,
  setErrorShoppingCart
})(Main);
