import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

const ModalDelete = ({ open, name, onAction, onClose }) => {
  const classes = styles();

  const onDelete = () => {
    onAction();
    onClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onClose={onClose}
      >
        <DialogContent className={classes.box}>
          <DialogContentText className={classes.name} id="alert-dialog-slide-description">
            {name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDelete} className={classes.buttonDelete} color="primary">
            Si
          </Button>
          <Button
            onClick={onClose}
            className={classes.buttonClose}
            variant="contained"
            color="primary"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
ModalDelete.propTypes = {
  name: PropTypes.string,
  open: PropTypes.bool,
  onAction: PropTypes.func,
  onClose: PropTypes.func
};

ModalDelete.defaultProps = {
  name: '',
  open: false,
  onAction: () => {},
  onClose: () => {}
};
export default ModalDelete;
