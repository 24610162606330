import { Box, Typography } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import React from 'react';
import images from '../../assets/images';
import styles from './styles';

const PaymentMethodsBanner = () => {
  const classes = styles();
  return (
    <div className={classes.boxInfo}>
      <div className={classes.pricesInfo}>
        <Typography className={classes.pricesInfoTextTitle}>
          Compras protegidas por
          {' '}
          <Lock className={classes.lock} />
          {' '}
          SSL
        </Typography>
        <Typography className={classes.pricesInfoSubText}>
          Certificado que brinda seguridad mediante el cifrado de las conexiones.
        </Typography>
        <Typography className={classes.pricesInfoText}>
          *Precios y productos sujetos a disponibilidad.
        </Typography>
        <Box className={classes.mercadoPagoBox}>
          <img className={classes.mercadoPagoIcon} src={images.mercadoPagoIcon} alt="mercado pago" />
        </Box>
        <Typography className={classes.pricesInfoText}>
          *Pago seguro via Mercado Pago, iSana no procesa ni guarda la información de tu tarjeta.
        </Typography>
        <div className={classes.boxInfoMethods}>
          <Typography className={classes.methodsTitle}>Métodos de pago</Typography>
          <Typography className={classes.pricesInfoText}>
            <svg
              className={classes.carFix}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16"
              height="16"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M22 11v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-9h20zm0-4H2V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v3z" />
            </svg>
            Pago en línea
          </Typography>
          <div className={classes.boxPayment}>
            <img className={classes.paymentsImage} src={images.visa} alt="visa" />
            <img className={classes.paymentsImageMasterCard} src={images.masterCard} alt="masterd card" />
            <img className={classes.paymentsImageMasterCard} src={images.amex} alt="amex" />
          </div>
          <div className={classes.boxPayment}>
            <img className={classes.paymentsImage} src={images.mercadoPagoIcon} alt="mercado pago" />
            <img className={classes.paymentsImage} src={images.payPal} alt="mercado pago" />
          </div>
          <Typography className={classes.pricesInfoText}>
            Pago en efectivo
          </Typography>
          <Typography className={classes.pricesInfoText}>
          Más de 10,000 puntos físicos
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodsBanner;
