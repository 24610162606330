import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  arrowDown: {
    width: '1rem'
  },
  accordion: {
    opacity: '0.7',
    paddingTop: '0.4rem',
    [theme.breakpoints.up('md')]: {
      padding: '0rem 4rem 0rem 4rem'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0rem 8rem 0rem 8rem'
    }
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem'
  }
}));

export default styles;
