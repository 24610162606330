import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  MenuList,
  MenuItem,
  CircularProgress,
  Box,
  TextField
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import {
  getProducts,
  setCategory,
  removeCategory,
  setSearchText
} from '../../../reducers/products/actions';
import useStyles from './styles';
import useToggle from '../../../customHooks/useToggle';
import { getCategories } from '../../../services/products';
import { scrollTop } from '../../../helper';
import useFunctionDelay from '../../../customHooks/useFunctionDelay';

/**
 * CategoryList react component
 * @returns Node Element
 */
const CategoryList = ({ categories, filterProducts, categorySelected }) => {
  const classes = useStyles();

  /**
   * Function to add class to categories selected
   * @param {string} id category id
   * @returns string
   */
  const isFilterSelected = (id) => (categorySelected._id === id ? classes.itemSelected : '');

  return (
    <MenuList className={classes.menuList}>
      {categories.map((category) => (
        <MenuItem
          key={category._id}
          onClick={(e) => filterProducts(e, category)}
          className={`${isFilterSelected(category._id)} ${classes.categoryName}`}
        >
          {category.name}
        </MenuItem>
      ))}
    </MenuList>
  );
};

CategoryList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  filterProducts: PropTypes.func,
  categorySelected: PropTypes.shape({ _id: PropTypes.string })
};

CategoryList.defaultProps = {
  categories: [],
  filterProducts: () => {},
  categorySelected: { _id: '' }
};

const CategoryProducts = ({
  getProducts,
  products,
  setCategory,
  removeCategory,
  categorySelected,
  isLoadingProducts
}) => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const { isToggleActive, handleToggle } = useToggle(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [searchValue, setSearchValue] = useState()
  /**
   * Function to get category list
   */
  const getListCategories = async (search) => {
    try {
      const response = await getCategories(search);
      setCategories(response.docs);
    } catch (error) {
      // TODO error notification
    }
  };

  const setChanges = useFunctionDelay(() => {
    getListCategories(searchValue)
  }, 500)

  useEffect(() => {
    getListCategories();
    setInitialLoad(false);
  }, []);

  /**
   * Function to remove categories selected and query products
   */
  const removeCategoriesSelected = () => {
    const { limit } = products;
    removeCategory();
    getProducts({ page: 1, limit });
  };

  useEffect(() => {
    const { limit } = products;
    if (!initialLoad && !isLoadingProducts) {
      getProducts({
        category: categorySelected.name,
        page: 1,
        limit
      });
    }
    scrollTop();
  }, [categorySelected]);

  /**
   * Function to filter products by categories selected
   * @param {object} evt Click event
   * @param {object} category Category selected
   */
  const filterProducts = (evt, category) => {
    evt.preventDefault();
    setCategory(category);
  };

  return (
    <>
      <Accordion
        onChange={handleToggle}
        elevation={0}
        className={classes.accordionFilters}
        expanded={isToggleActive}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="category-products-accordion"
          id="category-products-accordion"
        >
          <Typography>Categorías</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.colListCategories}>
        <TextField placeholder='Buscar Categoría' variant='outlined' size='small' className={classes.textField} onChange={(event)=> {
          setSearchValue(event.target.value)
          setChanges(event.target.value)
        }}/>
          {categories.length ? (
            <CategoryList
              categories={categories}
              filterProducts={filterProducts}
              categorySelected={categorySelected}
            />
          ) : (
            <Box className={classes.circularProgressBox}>
            <CircularProgress />              
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
        <Button variant="contained" disabled={!categorySelected._id} color="primary" onClick={removeCategoriesSelected}>
          Limpiar filtro
        </Button>
    </>
  );
};

CategoryProducts.propTypes = {
  products: PropTypes.shape({ limit: PropTypes.number }),
  getProducts: PropTypes.func,
  setCategory: PropTypes.func,
  removeCategory: PropTypes.func,
  categorySelected: PropTypes.shape({ _id: PropTypes.string, name: PropTypes.string }),
  isLoadingProducts: PropTypes.bool
};

CategoryProducts.defaultProps = {
  products: { limit: 20 },
  getProducts: () => {},
  setCategory: () => {},
  removeCategory: () => {},
  categorySelected: { _id: '', name: '' },
  isLoadingProducts: false
};

const mapStateToProps = (state) => {
  const { products, categorySelected, isLoadingProducts } = state.product;
  return {
    products,
    categorySelected,
    isLoadingProducts
  };
};

export default connect(mapStateToProps, {
  getProducts,
  setCategory,
  removeCategory,
  setSearchText
})(CategoryProducts);
