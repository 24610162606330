/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable  no-unused-expressions */
import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Crumbs from '../../components/Crumbs';
import Pagination from '../../components/Pagination';
import Title from '../../components/Title';
import auth from '../../customHocs/auth';
import { getOrdersByCustomer } from '../../services/orders';
import styles from './styles';
import TabOrder from './TabOrder';

const MyOrders = () => {
  const classes = styles();
  const [orders, setOrders] = useState();
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({
    orderBy: 'desc',
    page: 1
  });

  const routes = [
    {
      link: 'account',
      name: 'Mi Cuenta'
    },
    {
      link: 'orders',
      name: 'Mis Pedidos'
    }
  ];

  const getOrders = async (page = searchParams.page, orderBy = searchParams.orderBy) => {
    const ordersData = await getOrdersByCustomer({ page, orderBy });
    setSearchParams({
      orderBy,
      page
    });
    setOrders(ordersData);
    setLoading(false);
  };

  const nextPage = async () => {
    setLoading(true);
    const page = searchParams.page + 1;
    await getOrders(page, searchParams.orderBy);
    setSearchParams({
      ...searchParams,
      page
    });
    setLoading(false);
  };

  const prevPage = async () => {
    setLoading(true);
    const page = searchParams.page - 1;
    await getOrders(page, searchParams.orderBy);
    setSearchParams({
      ...searchParams,
      page
    });
    setLoading(false);
  };

  useEffect(() => {
    async function getData(){ 
      await getOrders();
    }
    getData();
  }, []);

  const OrderByOnChange = async(e) => {
    setLoading(true);
    setSearchParams({
      ...searchParams,
      orderBy: e.target.value
    });
    await getOrders(searchParams.page, e.target.value);
  };

  return (
    <Container maxWidth="xl">
      <Grid container className={classes.container}>
        <Box className={classes.breadcrumbBox}>
          <Crumbs routes={routes} />
        </Box>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Title text="Mis Pedidos" />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.select}>
          <FormControl>
            <Select
              id="order-by-select"
              label="Ordenar"
              onChange={OrderByOnChange}
              className={classes.selectInput}
              disableUnderline
              value={searchParams.orderBy}
            >
              <MenuItem  value="desc">Pedido más reciente.</MenuItem>
              <MenuItem value="asc">Pedido más antiguo.</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Grid>
        {loading ? (
          <Box className={classes.circularProgress}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid className={classes.gridCards} container>
            <TabOrder orders={orders.data.docs} />
          </Grid>
        )}
        <Grid container justifyContent="center">
          {orders && (
            <Pagination
              page={orders.data.page}
              totalOfPages={orders.data.totalPages}
              nextPage={nextPage}
              hasNextPage={orders.data.hasNextPage}
              hasPrevPage={orders.data.hasPrevPage}
              prevPage={prevPage}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
export default auth(MyOrders);
