import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../../theme';

const styles = makeStyles(() => ({
  overFloFix: {
    position: 'relative !important',
    overflowX: 'hidden'
  },
  containerIcons: {
    margin: '0% 10%',
    paddingTop: '3rem',
    paddingBottom: '2rem'
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    verticalAlign: 'middle'
  },
  colorPhoneBanner: {
    color: Colors.balticSea
  },
  image: {
    display: 'block',
    margin: 'auto',
    width: '15rem'
  },
  name: {
    color: Colors.cornflowerBlue,
    marginTop: '2rem'
  },
  description: {
    width: '20rem'
  }
}));

export default styles;
