import { Box, Slide, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import images from '../../../assets/images';
import styles from './styles';
import ButtonCdsoInfo from '../../../components/ButtonCdsoInfo';

const CdsoBanner = ({ checked }) => {
  const classes = styles();
  return (
    <Box className={classes.adBox}>
      <Slide
        direction="right"
        in={checked}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(checked ? { timeout: 1500 } : {})}
      >
        <Box className={classes.boxLogo}>
        <img src={images.isanaPlus} alt="card" className={classes.images} />
        <img src={images.cdsoLogo} alt="card" className={classes.cdsoLogo} />
        </Box>
      </Slide>
      <Box className={classes.infoBox}>
        <Typography className={classes.title}>Obtén beneficios exclusivos</Typography>
        <Typography className={classes.title}>¡Más de 700 medicamentos con piezas gratis!</Typography>
        <Typography className={classes.description}>
          iSana une fuerzas con el programa de beneficios del Círculo de la Salud Oro para traerte la nueva tarjeta de lealtad iSana+
          para que disfrutes de las mejores ofertas y precios exclusivos.
        </Typography>
        <ButtonCdsoInfo variant/>
      </Box>
    </Box>
  );
};

CdsoBanner.propTypes = {
  checked: PropTypes.bool
};

CdsoBanner.defaultProps = {
  checked: false
};
export default CdsoBanner;
