import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from '../styles';


const InfoUser = ({title, info}) => {
    const classes = styles()
    return (
        <Box className={classes.infoBox}>
            <Typography className={classes.infoTitle}>{title}</Typography>
            <Typography className={classes.info}>{info}</Typography>
        </Box>
    )
}

InfoUser.propTypes = {
    title: PropTypes.string,
    info: PropTypes.string
}

InfoUser.defaultProps = {
    title: '',
    info: ''
}
export default InfoUser
