/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { WarningRounded } from '@material-ui/icons';
import { connect } from 'react-redux';
import useValidField from '../../../customHooks/useValidField';
import { sendProductRequestInfo } from '../../../services/shoppingCart';
import styles from './styles';

const WeGotItForYouForm = ({ productId, customer }) => {
  const classes = styles();
  const [notification, setNotification] = useState(false);
  const phone = useValidField({ type: 'number', phone: true, noErrorEmpty: true });
  const email = useValidField({ type: 'email', noErrorEmpty: true });
  const [error, setError] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const submit = () => {
    setLoadingRequest(true);
    const data = {
      phoneNumber: customer? customer.phoneNumber : phone.value,
      email: customer? customer.email : email.value,
      productId
    };
    sendProductRequestInfo(data)
      .then((response) => {
        if (!response.data._id) {
          setError(true);
          setLoadingRequest(false);
          return;
        }
        if (response) {
          setNotification(true);
          setLoadingRequest(false);
        }
      })
      .catch((error) => {
        console.error('Error to send product request: ', error);
        setError(true);
      });
  };

  const disabledButton = () => {

    if(Object.keys(customer).length){
        return false;
    }
    if (phone.value === '' && email.value === '') {
      return true;
    }

    if (loadingRequest) {
      return true;
    }

    if (!phone.error && !email.error) {
      return false;
    }

    return true;
  };
  const getPropsValidate = (validator) => {
    const { setValue, ...validationProps } = validator;
    return validationProps;
  };

  return (
    <div>
      <Typography className={classes.title}>Te lo conseguimos</Typography>
      {notification ? (
        <div>
          <Typography>¡Listo! Te avisaremos cuando el producto esté disponible. 😄</Typography>
        </div>
      ) : (
        <>
          {!Object.keys(customer).length && (
            <>
              <TextField
                size="small"
                className={classes.textfield}
                label="Email"
                variant="outlined"
                fullWidth
                {...getPropsValidate(email)}
              />
              <TextField
                size="small"
                className={classes.textfield}
                label="Teléfono"
                variant="outlined"
                fullWidth
                {...getPropsValidate(phone)}
              />
            </>
          )}

          { loadingRequest ? (
            <CircularProgress/>
          ): (
            <Button
            disabled={disabledButton()}
            size="small"
            variant="contained"
            fullWidth
            color="primary"
            onClick={submit}
          >
            Notificarme cuando esté disponible
          </Button>
          )}
          {error && (
            <Typography className={classes.warning}>
              <WarningRounded /> ¡Algo salió mal! 👀 Por favor, intenta más tarde.
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

WeGotItForYouForm.propTypes = {
  productId: PropTypes.string,
  customer: PropTypes.shape({
    phoneNumber: PropTypes.string,
    email: PropTypes.string
  })
};

WeGotItForYouForm.defaultProps = {
  productId: '',
  customer: {

  }
};

const mapsStateToProps = (state) => {
  const { customer } = state.customer;
  return { customer };
};

export default connect(mapsStateToProps, {})(WeGotItForYouForm);
