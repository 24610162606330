import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  carFix: {
    marginRight: '0.5rem'
  },
  pricesInfo: {
    width: '21rem',
    margin: '1rem auto 4rem auto',
    padding: '2rem',
    background: Colors.lowGray,
    borderRadius: '0.5rem',
    [theme.breakpoints.down('md')]: {
      width: '19rem',
      padding: '1rem',
      margin: '2rem'
    }
  },
  pricesInfoTextTitle: {
    fontSize: '1.1rem',
    marginTop: '0.8rem',
    fontWeight: 'bold',
    color: Colors.goodLuck
  },
  lock: {
    fontSize: '1rem'
  },
  pricesInfoSubText: {
    fontSize: '0.6rem'
  },
  pricesInfoText: {
    fontSize: '0.8rem',
    marginTop: '0.8rem',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  boxInfoMethods: {
    marginTop: '1rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '2rem'
    }
  },
  boxPoint: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2rem'
  },
  instructions: {
    fontSize: '0.7rem'
  },
  methodsTitle: {
    fontWeight: 'bold',
    fontSize: '0.99rem',
    textAlign: 'center'
  },
  boxInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paymentsImage: {
    width: '3.3rem',
    marginLeft: '1.5rem'
  },
  paymentsImageMasterCard: {
    width: '2.6rem',
    marginLeft: '1.5rem'
  },
  paymentsImagePayPal: {
    width: '4.6rem',
    marginLeft: '1.5rem'
  },
  boxPayment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '-2rem',
    flexDirection: 'row',
    marginTop: '0.4rem'
  },
  mercadoPagoIcon: {
    width: '6rem'
  },
  mercadoPagoBox: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default styles;
