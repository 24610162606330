import { Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

const Title = ({ text }) => {
  const classes = styles();
  return (
    <Typography variant="h6" className={classes.title}>
      {text}
    </Typography>
  );
};

Title.propTypes = {
  text: PropTypes.string
};

Title.defaultProps = {
  text: ''
};
export default Title;
