import React from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './useStyles';


const CdsoCard = ({ open, onClose, cdsoUserId}) => {
    const classes = useStyles();

    /**
     * formatCard
     * Make a card number format
     * @param {string} value 
     * @returns format 
     */
    const formatCard = value =>
    value
      .replace(/\s/g, '')
      .match(/.{1,3}/g)
      .join(' ')
      .substr(0, 16);

    return (
        <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={onClose}
        className={classes.dialogRoot}
        keepMounted
        aria-labelledby="dialog-store-dealer"
        aria-describedby="dialog-store-dealer"
      >
        <DialogContent className={classes.dialogContent}>
          <MuiDialogTitle disableTypography className={classes.dialogTitle}>
            <Typography className={classes.titleDialog}>
            Tarjeta iSana+ powered by Círculo de la salud oro
            </Typography>
            <IconButton aria-label="close" className={classes.modalCloseButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <Box className={classes.cardContent}>
            <Box className={classes.card}>
              <Box className={classes.info}>
                <Typography className={classes.cardTitle}>
                    Número de usuario
                </Typography>
              </Box>
              <Box className={classes.cardNumber}>
                <Typography className={classes.numbers}>{formatCard(cdsoUserId)} </Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
}


CdsoCard.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    cdsoUserId: PropTypes.string
  };
  
CdsoCard.defaultProps = {
    open: false,
    onClose: () => {},
    cdsoUserId: ''
  };

export default CdsoCard;
