import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import ReduxThunk from 'redux-thunk';
import createReducer from './reducers';

const configureStore = (history, initialState = {}) => {
  // Create the store with two middlewares
  // 1. ReduxThunk: Makes Redux Thunk work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  // 3. logger: redux-logger to debug the changes on the state
  let middlewares = [ReduxThunk, routerMiddleware(history)];

  if (process.env.REACT_APP_MODE !== 'production') {
    middlewares = [...middlewares, logger];
  }

  const store = createStore(createReducer(), initialState, applyMiddleware(...middlewares));

  // Extensions
  store.injectedReducers = {};

  // Make reducers hot reloadable, see http://mxs.is/googmo
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
};

export default configureStore;
