import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles(() => ({
  snackbarSuccess: {
    background: Colors.blueViolet
  },
  snackbarError: {
    background: Colors.radicalRed
  }
}));
export default styles;
