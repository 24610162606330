/* eslint-disable max-len */
import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import images from '../../../assets/images';
import ItemDescription from '../ItemDescription';
import TitleBlack from '../TitleBlack';

const Advantages = ({ checked }) => {
  const advantages = [
    {
      id: 1,
      name: 'Mejor precio ',
      image: images.iconBetterPrice
    },
    {
      id: 2,
      name: 'Más de 13,000 medicamentos y productos de salud ',
      image: images.iconMedicines
    },
    {
      id: 3,
      name: 'Envíos a domicilio',
      image: images.iconShipping
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleBlack text="Ventajas de comprar en iSana" />
        <Grid container>
          {advantages.map((advantage) => (
            <ItemDescription key={advantage.id} service={advantage} checked={checked} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
Advantages.propTypes = {
  checked: PropTypes.bool
};

Advantages.defaultProps = {
  checked: false
};
export default Advantages;
