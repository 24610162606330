import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ItemCart from './ItemCart';
import styles from './styles';

const CartList = ({ items }) => {
  const classes = styles();
  return (
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellTitle} align="center">
                Productos
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => (
              <TableRow key={row.productId}>
                <ItemCart item={row}  itemLength={items.length}/>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

CartList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, quantity: PropTypes.number }))
};

CartList.defaultProps = {
  items: []
};

export default CartList;
