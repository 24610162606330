import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../theme';

const useStyles = makeStyles((theme) => ({
  inputCard: {
    marginRight: '1rem',
    width: '30rem'
  },
  boxCard: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  },
  boxButtonCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: '7.3rem'
  },
  row: {
    marginTop: '2rem',
    marginBottom: '2rem',
    backgroundColor: Colors.simpleWhite
  },
  spaceFix: {
    marginTop: '1rem'
  },
  boxGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  gridContainer: {
    maxWidth: '40rem'
  },
  input: {
    margin: '1rem',
    width: '90%'
  },
  boxAddButtonCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  boxAddCartFix: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '3rem',
    marginTop: '1rem'
  },
  container: {
    marginTop: '3rem',
    backgroundColor: Colors.simpleWhite
  },
  breadcrumbBox: {
    display: 'flex',
    padding: '1.5rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '2rem'
    }
  },
  title: {
    marginTop: '3rem',
    marginBottom: '2rem'
  },
  button: {
    marginTop: '2rem',
    width: '100%',
    maxWidth: '20rem',
    margin: 'auto',
    borderRadius: '1.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '18rem',
      fontSize: '1rem'
    },
    color: Colors.simpleWhite,
    background: Colors.electricViolet,
    '&:hover': {
      background: Colors.honeyFlower
    }
  },
  form: {
    maxWidth: '50rem',
    margin: 'auto'
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    background: 'white',
    opacity: '80%'
  }
}));
export default useStyles;
