import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import OrderCard from '../OrderCard';
import styles from './styles';

const TabOrder = ({ orders }) => {

  const classes = styles();

  return (
  <>
  <div/>
    {!orders && (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    )}
    {orders && (
      <>
      <div/>
        {orders.length >= 1 ? (
          <>
            {orders.map((order) => (
              <OrderCard order={order} key={order._id} />
            ))}
          </>
        ) : (
          <Box className={classes.warningBox} s>
            <Typography
              align="center"
              variant="h6"
              className={classes.text}
            >
              No hay ordenes que mostrar.
            </Typography>
          </Box>
        )}
        <div/>
      </>
    )}
  <div/>
  </>
)};

TabOrder.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({}))
};

TabOrder.defaultProps = {
  orders: [{}]
};
export default TabOrder;
