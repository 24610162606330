import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const styles = makeStyles(() => ({
  title: {
    margin: '1rem auto'
  }
}));

const TitleBlack = ({ text }) => {
  const classes = styles();
  return (
    <Typography className={classes.title} align="center" variant="h4">
      {text}
    </Typography>
  );
};

TitleBlack.propTypes = {
  text: PropTypes.string
};

TitleBlack.defaultProps = {
  text: ''
};

export default TitleBlack;
