/*  eslint-disable no-unused-expressions, no-sequences  */

import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider
} from 'firebase/auth';
import { getFirestore, collection, onSnapshot, doc } from 'firebase/firestore';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
  const firebaseApp = initializeApp(firebaseConfig);
  const db = getFirestore(firebaseApp);

  const auth = getAuth();
  /**
   * Function to login by firebase auth
   * @param {string} email email user
   * @param {string} password password user
   * @returns Promise
   */
  const loginFirebase = async (email, password) => {
    const response = await signInWithEmailAndPassword(auth, email, password);
    return response;
  };

  /**
   * Function to send email reset password from firebase
   * @param {string} email email user firebase
   * @returns Promise
   */
  const sendUserEmailResetPassword = async (email) => {
    const response = await sendPasswordResetEmail(auth, email);
    return response;
  };

  /**
   * Function to send email verification from firebase
   * @returns Promise
   */
  const sendUserEmailVerification = async () => {
    const response = await sendEmailVerification(auth.currentUser);
    return response;
  };

  /**
   * Function to login by google auth
   * @param {string} email email user
   * @param {string} password password user
   * @returns Promise
   */
  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('email');
    const result = await signInWithPopup(auth, provider);
    return result;
  };

  const loginWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    provider.addScope('email');
    const result = await signInWithPopup(auth, provider);
    return result;
  };

  const values = useMemo(
    () => ({
      db,
      collection,
      onSnapshot,
      doc,
      loginFirebase,
      sendUserEmailResetPassword,
      sendUserEmailVerification,
      onAuthStateChanged,
      auth,
      signOut,
      loginWithGoogle,
      loginWithFacebook
    }),
    []
  );

  return <FirebaseContext.Provider value={values}>{children}</FirebaseContext.Provider>;
};

FirebaseProvider.propTypes = {
  children: PropTypes.element
};

FirebaseProvider.defaultProps = {
  children: null
};
