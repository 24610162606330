export const API_CHAT_URL =
  process.env.REACT_APP_MODE === 'development'
    ? 'https://us-central1-isana-services.cloudfunctions.net/chatWebsiteApp'
    : '';
export const WEB_CHAT_COLLECTION =
  process.env.REACT_APP_MODE === 'development' ? 'chatWebsite-dev' : 'chatWebsite';
export const DEBUG = process.env.REACT_APP_MODE === 'development';
// API URL segment by module
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_PRODUCTS = '/productsApp/products';
export const API_URL_SHOPPING_CART = '/shoppingCartApp';
export const API_URL_PAYMENTS = '/paymentsApp';
export const API_URL_ORDERS = '/ordersApp';
export const API_URL_CLIENTS = '/clientApp';
export const API_URL_COUPONS = '/couponsApp';
export const API_URL_CDSO = '/cdsoApp';

export const API_TOKEN = process.env.REACT_APP_API_KEY;
export const MERCADO_PAGO_KEY = process.env.REACT_APP_MERCADOPAGO_KEY;
export const API_URL_OAUTH = '/oauthApp';
export const VERSION = 2;
export const STORAGE_TOKEN_NAME = 'api-token';
export const STORAGE_REFRESH_TOKEN_NAME = 'api-refresh-token';
export const SHOPPING_CART_ACTIONS = {
  ITEM_SET: 'ITEM_SET',
  ITEM_DELETE: 'ITEM_DELETE',
  COUPON_SET: 'COUPON_SET',
  COUPON_DELETE: 'COUPON_DELETE',
  SHOPPING_CART_UPDATE: 'SHOPPING_CART_UPDATE',
  DELIVERY_DATA_SET: 'DELIVERY_DATA_SET',
  DELIVERY_ADDRESS_SET: 'DELIVERY_ADDRESS_SET',
  DELIVERY_DATA_WEBSITE_SET: 'DELIVERY_DATA_WEBSITE_SET',
  SET_CLIENT: 'SET_CLIENT'
};
export const OAUTH_CODE = 'isana';

export const NUR_NOT_CURRENT = 'NUR no vigente para surtimiento'

export const STOCK_PARAMS = {
  AVAILABLE: 90,
  LOW_STOCK: 50,
  EMPTY: 10
};

export const SHOP_ID_STORAGE = 'shopId';
