import { Button, Collapse, Paper, Typography, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './styles';
import TextNumber from '../../../components/TextNumber';
import SubtotalPrice from '../../../components/SubtotalPrice';
import LogosBox from '../../../components/LogosBox';
import useAgreementCondition from '../../../customHooks/useEgreementCondition';

const Detail = ({
  buttonText,
  action,
  lastStep,
  shoppingCartData,
  loadingShippingCost,
  metadata,
  ButtonsFlow
}) => {
  const classes = styles();
  const disable = false;
  const [totalPrice, setTotalPrice] = useState(0);
  const isAgreement  = useAgreementCondition({shoppingCartData})

  useEffect(() => {
    if (shoppingCartData && !lastStep) {
      let prices = 0;
      shoppingCartData.items.forEach((item) => {
        prices += item.subtotal;
      });
      setTotalPrice(prices);
      return;
    }

    if (shoppingCartData) {
      setTotalPrice(metadata.action ? 0 : shoppingCartData.total);
    }
  }, [shoppingCartData]);

  return (
    <div className={classes.container}>
      {isAgreement ? (
        <Paper className={classes.box} elevation={0}>
          <Typography variant="h6" className={classes.title}>
            Detalles del pedido
          </Typography>
          <Collapse orientation="horizontal" in={lastStep}>
            <div className={classes.finalPrice}>
              <Typography variant="body2" align="right">
                Costo unitario: <SubtotalPrice />
              </Typography>
              <Typography variant="body2" align="right">
                Costo de envío:{' '}
                {loadingShippingCost ? (
                  'Calculando...'
                ) : (
                  <TextNumber number={shoppingCartData.shippingCost} />
                )}
              </Typography>
            </div>
          </Collapse>
          <div className={classes.priceBox}>
            <Typography variant="body2" align="center">
              Total del pedido
            </Typography>
            <Typography variant="h6" align="center" className={classes.totalPrice}>
              {
                // eslint-disable-next-line no-nested-ternary
                totalPrice === 0 || loadingShippingCost || (lastStep && loadingShippingCost) ? (
                  <CircularProgress size="1rem" />
                ) : lastStep ? (
                  <TextNumber number={shoppingCartData.total} />
                ) : (
                  <SubtotalPrice />
                )
              }
            </Typography>
          </div>
          {!lastStep && (
            <div className={classes.shippingPrice}>
              <Typography variant="body2">Costo de envío:</Typography>
              <Typography className={classes.calculatePrice} variant="body2">
                Por calcular
              </Typography>
            </div>
          )}
          {lastStep && (
            <div className={classes.fixMargin}>
              {shoppingCartData.deliveryTimeRange &&
                (loadingShippingCost ? (
                  <CircularProgress size="1rem" />
                ) : (
                  <Typography variant="body2" className={classes.totalPrice} align="center">
                    Entrega estimada: {shoppingCartData.deliveryTimeRange}
                  </Typography>
                ))}
            </div>
          )}
          {ButtonsFlow}
          <div>
          <Typography align="center" className={classes.note}>
            <svg
              className={classes.carFix}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="19"
              height="19"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M17 8h3l3 4.056V18h-2.035a3.5 3.5 0 0 1-6.93 0h-5.07a3.5 3.5 0 0 1-6.93 0H1V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2zm0 2v3h4v-.285L18.992 10H17z"
                fill="rgba(117,77,252,1)"
              />
            </svg> &nbsp;Envíos gratis a partir de
          </Typography>
          <Typography className={classes.note}>
           Nacionales $1499.00
          </Typography>
          <Typography className={classes.note}>
           CDMX o León $500.00
          </Typography>
          <LogosBox />
          </div>
        </Paper>
      ) : (
        <Paper className={classes.box} elevation={0}>
          <Typography variant="h6" className={classes.title}>
            Detalles del pedido
          </Typography>
          <div className={classes.priceBox}>
            {lastStep ? (
              <Typography variant="body2" align="center">
                Verifica los datos de tu pedido, si todo esta bien, da click en el botón de surtir
                receta para crear la orden
              </Typography>
            ) : (
              <Typography variant="body2" align="center">
                Una vez lista tu información de contacto y dirección de envío da click en confirmar para verificar los datos. 😄
              </Typography>
            )}
          </div>
          {lastStep && (
            <div className={classes.fixMargin}>
              {
                (loadingShippingCost ? (
                  <CircularProgress size="1rem" />
                ) : (
                  <Typography variant="body2" className={classes.totalPrice} align="center">
                    Entrega estimada: {shoppingCartData.deliveryTimeRange || 'calculando'}
                  </Typography>
                ))}
            </div>
          )}
          {ButtonsFlow}
          <div>
          <Typography align="center">
            <svg
              className={classes.carFix}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="19"
              height="19"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M17 8h3l3 4.056V18h-2.035a3.5 3.5 0 0 1-6.93 0h-5.07a3.5 3.5 0 0 1-6.93 0H1V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2zm0 2v3h4v-.285L18.992 10H17z"
                fill="rgba(117,77,252,1)"
              />
            </svg>
          </Typography>
          <Typography className={classes.note}>
            Envíos nacionales gratis a partir de $1499.00
          </Typography>
          <Typography className={classes.note}>
            Envíos locales gratis con compras mayores a $500.00
          </Typography>
          <LogosBox />
          </div>
        </Paper>
        )}
      {disable && (
        <Button onClick={action} className={classes.button}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { shoppingCartData, metadata } = state.shoppingCart;
  return { shoppingCartData, metadata };
};

Detail.propTypes = {
  buttonText: PropTypes.string,
  action: PropTypes.func,
  lastStep: PropTypes.bool,
  shoppingCartData: PropTypes.shape({
    subTotal: PropTypes.number,
    total: PropTypes.number,
    shippingCost: PropTypes.number,
    deliveryTimeRange: PropTypes.string,
    change: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        quantity: PropTypes.number,
        unitPrice: PropTypes.number
      })
    ),
    metadata: {}
  }),
  loadingShippingCost: PropTypes.bool,
  metadata: PropTypes.shape({ action: PropTypes.string }),
  ButtonsFlow: PropTypes.shape({})
};

Detail.defaultProps = {
  buttonText: 'Continuar con la compra',
  action: () => {},
  lastStep: false,
  shoppingCartData: {
    items: [],
    subTotal: 0,
    total: 0,
    shippingCost: 0,
    deliveryTimeRange: '',
    change: false,
    metadata: {}
  },
  metadata: { action: null },
  loadingShippingCost: true,
  ButtonsFlow: () => {}
};
export default connect(mapStateToProps, {})(Detail);
