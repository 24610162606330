import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  REMOVE_CATEGORY,
  SET_CATEGORY,
  GET_PRODUCTS_START,
  SET_SEARCH_TEXT
} from './types';
import { list } from '../../services/products';

export const getProducts =
  ({ page, limit, search, category, referralCode }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_PRODUCTS_START });
      const response = await list({
        page,
        limit,
        category,
        search,
        referralCode
      });
      dispatch({ type: GET_PRODUCTS_SUCCESS, payload: response });
      return response.data;
    } catch (error) {
      dispatch({ type: GET_PRODUCTS_FAIL });
      throw error;
    }
  };

export const setCategory = (category) => (dispatch) => {
  dispatch({ type: SET_CATEGORY, payload: category });
};

export const removeCategory = () => (dispatch) => {
  dispatch({ type: REMOVE_CATEGORY });
};

/**
 * Function to set search text into redux state+
 * @param {string} text search value
 * @returns dispatch function
 */
export const setSearchText = (text) => (dispatch) => {
  dispatch({ type: SET_SEARCH_TEXT, payload: text });
};
