import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../../../../theme';

export default makeStyles((theme) => ({
  menuLink: {
    textDecoration: 'none',
    width: '100%'
  },
  buttonLink: {
    textTransform: 'capitalize',
    fontSize: '1rem',
    fontWeight: 'bold',
    marginTop: '.5rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0
    },
    width: '100%'
  },
  active: {
    color: Colors.blueViolet
  }
}));
