import { Button } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styles from './styles';
import { SHOPPING_CART_ACTIONS, SHOP_ID_STORAGE } from '../../constants';
import { setBonusRecentlyUpdatedValidation, setItemsInReduxState, updateItemsInReduxState } from '../../reducers/shoppingCart/actions';

const AddToShoppingCartButton = ({
  product,
  quantity,
  setsuccess,
  onClickButton,
  setItemsInReduxState,
  updateItemsInReduxState,
  buyNow,
  text,
  customer,
  detail,
  setBonusRecentlyUpdatedValidation,
  shoppingCartData
}) => {
  const classes = styles();
  const { ITEM_SET } = SHOPPING_CART_ACTIONS;
  const navigate = useNavigate();
  const handleClickAddToCart = () => {
    const data = {
      productId: product._id,
      quantity,
      price: product.price,
      imageUrl: product.imageUrl,
      description: product.description,
      name: product.name,
      priceHistoryId: product.priceHistoryId,
      metadata: {}
    };
    const shopId = sessionStorage.getItem(SHOP_ID_STORAGE);
    const { cdsoCardNumber } = customer;
    if (cdsoCardNumber) {
      data.metadata = {
        publicPrice: product.publicPrice
      };
    }
    if (!shopId) {
      setItemsInReduxState(data, cdsoCardNumber)
        .then(() => {
          setsuccess(true);
          setBonusRecentlyUpdatedValidation(false);
          onClickButton();
        })
        .then(() => {
          if (buyNow) {
            navigate('/checkout');
          }
        })
        .catch(() => {
          setsuccess(false);
          onClickButton();
        });
    } else {
      updateItemsInReduxState(data, ITEM_SET)
        .then(() => {
          setsuccess(true);
          setBonusRecentlyUpdatedValidation(false);
          onClickButton();
        })
        .then(() => {
          if (buyNow) {
            navigate('/checkout');
          }
        })
        .catch(() => {
          setsuccess(false);
          onClickButton();
        });
    }
  };


  
  return (
    <Button
      className={detail ? classes.buttonAdd : classes.button}
      onClick={handleClickAddToCart}
      variant={detail ? 'contained' : 'outlined'}
      type="button"
      color="primary"
      disabled={shoppingCartData.changes || !product.price}
    >
      {text}
    </Button>
  );
};

AddToShoppingCartButton.propTypes = {
  product: PropTypes.shape({
    imageUrl: PropTypes.string,
    promotion: PropTypes.bool,
    name: PropTypes.string,
    requireRecipe: PropTypes.bool,
    dose: PropTypes.string,
    content: PropTypes.string,
    price: PropTypes.number,
    ean: PropTypes.string,
    _id: PropTypes.string,
    description: PropTypes.string,
    stock: PropTypes.number,
    priceHistoryId: PropTypes.string,
    publicPrice: PropTypes.number,
    offers: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string }))
  }),
  quantity: PropTypes.number,
  setsuccess: PropTypes.func,
  onClickButton: PropTypes.func,
  setItemsInReduxState: PropTypes.func,
  updateItemsInReduxState: PropTypes.func,
  buyNow: PropTypes.bool,
  text: PropTypes.string,
  customer: PropTypes.shape({ cdsoCardNumber: PropTypes.string }),
  detail: PropTypes.bool,
  setBonusRecentlyUpdatedValidation: PropTypes.func,
  shoppingCartData:PropTypes.shape({ changes: PropTypes.bool })
};

AddToShoppingCartButton.defaultProps = {
  product: {
    imageUrl: '',
    promotion: false,
    name: '',
    requireRecipe: false,
    dose: '',
    content: '',
    price: 0,
    ean: '',
    _id: '',
    stock: 0,
    priceHistoryId: '',
    description: '',
    publicPrice: 0,
    offers: [
      {
        name: PropTypes.string
      }
    ]
  },
  quantity: 1,
  setsuccess: () => {},
  onClickButton: () => {},
  setItemsInReduxState: () => {},
  updateItemsInReduxState: () => {},
  buyNow: false,
  text: 'Agregar al carrito',
  customer: { cdsoCardNumber: '' },
  detail: false,
  setBonusRecentlyUpdatedValidation: () => {},
  shoppingCartData :{
    changes: false
  }
};

const mapStatesToProps = (state) => {
  const { customer } = state.customer;
  const { shoppingCartData  } = state.shoppingCart;


  return { customer, shoppingCartData };
};

export default connect(mapStatesToProps, { updateItemsInReduxState, setItemsInReduxState, setBonusRecentlyUpdatedValidation })(
  AddToShoppingCartButton
);
