import { Snackbar, SnackbarContent } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

const SnackbarNote = ({
  onOpen, onClose, success, errorMessage, successMessage
}) => {
  const classes = styles();
  return (
    <Snackbar
      open={onOpen}
      autoHideDuration={1000}
      onClose={onClose}
    >
      <SnackbarContent
        message={success ? successMessage : errorMessage}
        className={success ? classes.snackbarSuccess : classes.snackbarError}
      />
    </Snackbar>
  );
};
SnackbarNote.propTypes = {
  success: PropTypes.bool,
  onOpen: PropTypes.bool,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  onClose: PropTypes.func
};

SnackbarNote.defaultProps = {
  success: false,
  onOpen: false,
  errorMessage: 'Oh oh... algo salió mal',
  successMessage: 'Agregando producto 👍',
  onClose: () => {}
};
export default SnackbarNote;
