import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles(() => ({
  container: {},
  box: {
    height: '40rem',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  icon: {
    color: Colors.electricViolet
  },
  button: {
    textDecoration: 'none',
    backgroundColor: Colors.purpleBlue,
    color: Colors.simpleWhite,
    maxWidth: '11rem',
    borderRadius: '5rem',
    padding: '0.7rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '1rem',
    fontWeight: 'bold'
  }
}));
export default styles;
