/**
 * scrollTop
 * Scroll to the highest point of the page
 */
export const scrollTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

/**
 * handleTypeOfPromotion
 * valid promotion and create a text promotion or return text plane promotion
 * @param {string} promotion
 * @returns promotion
 */
export const handleTypeOfPromotion = (promotion) => {
  if (promotion.includes('%')){
    const validatationPromo = promotion.split('%')
    const newArrayPromo = validatationPromo[0].split(' ')
    const percent = newArrayPromo[newArrayPromo.length-1];
    return  ` ${percent}% de descuento`
  }
  if (promotion.includes('ASTRA')){
    const promo = promotion.split('ASTRA');
    const validatationPromo = promo[1].split('+');
    const itemOne = validatationPromo[0]
    const itemTwo = validatationPromo[1]
    return `Acumula ${itemOne} y llévate ${itemTwo} de regalo`;
  }
  if (promotion.includes('+')) {
    const validatationPromo = promotion.split('+');
    const itemOne = validatationPromo[0].slice(-1);
    const itemTwo = validatationPromo[1].slice(0);
    if (itemTwo.toString() !== '1') {
      return promotion.toString();
    }
    if (itemOne.toString() === '3') {
      return `Acumula ${itemOne} y llévate ${itemTwo} de regalo`;
    }
    if (itemOne.toString() === '4') {
      return `Acumula ${itemOne} y llévate ${itemTwo} de regalo`;
    }
    return promotion.toString();
  }
  if (promotion.includes('PRECIO FIJO')) {
    const validatationPromo = promotion.split('FIJO');
    return `Precio Fijo ${validatationPromo[validatationPromo.length - 1].toString()}`;
  }
  return promotion.toString();
};

/**
 * getPropsValidate
 * extract values that are not used and return the validator
 * @param {object} validator
 * @returns
 */
export const getPropsValidate = (validator) => {
  const { value, setValue, ...validationProps } = validator;
  return validationProps;
};

/**
 * validateInputValue
 *  check if a value changed from original
 * @param {newValue, oldValue}
 * @returns {boolean}
 */
export const validateInputValue = (newValue, oldValue) => {
  if (newValue !== '' && newValue !== oldValue) {
    return false;
  }
  return true;
};
