import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  image: {
    width: '13rem',
    height: '13rem',
    [theme.breakpoints.down('xs')]: {
      width: '9rem',
      height: '9rem'
    }
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    padding: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem'
    }
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  name: {
    fontWeight: 'bold',
    fontSize: '1.2rem'
  },
  buttonDelete: {
    fontWeight: 'bold'
  },
  buttonClose: {
    fontWeight: 'bold'
  }
}));

export default styles;
