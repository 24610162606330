import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  card: {
    maxWidth: '24rem',
    display: 'flex',
    padding: '2rem',
    alignItems: 'center',
    margin: '1rem',
    height: '13rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  image: {
    width: '3.3rem'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.7rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem'
    }
  },
  text: {
    fontSize: '1rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem'
    }
  },
  info: {
    paddingLeft: '2rem',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0rem'
    }
  },
  link: {
    textDecoration: 'none'
  }
}));
export default styles;
