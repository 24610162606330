import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles';
import { getPaymentStatus, getPaymentStatusColor } from './helper';
import { SHOP_ID_STORAGE } from '../../../../constants';
import ItemCartTable from '../../../../components/CartTable/ItemCartTable';
import FreePiece from '../../../../components/CartTable/FreePiece';
import LogosBox from '../../../../components/LogosBox';

const Success = ({ orderNumber, total, subTotal, shippingCost, items, status, metadata }) => {
  const classes = styles();
  const shoppingCartData = { items };

  const isAgreement = metadata && metadata.paymentIndicator;
  useEffect(() => {
    sessionStorage.removeItem(SHOP_ID_STORAGE);
  }, []);
  return (
    <Grid container className={classes.row} spacing={2}>
      <Grid item md={4} sm={12} xs={12}>
        <Box className={classes.boxInfo}>
          <Card className={classes.boxBanner}>
            <div className={classes.banner}>
              <Check className={classes.iconCheck} />
              <Typography className={classes.title}>¡Orden realizada con éxito!</Typography>
            </div>
            <div className={classes.details}>
              <div className={classes.info}>
                <Typography className={classes.font}>Número de pedido</Typography>
                <Typography className={classes.font}>
                  <strong>{orderNumber}</strong>
                </Typography>
              </div>
              <Divider />
              {!isAgreement && (
                <>
                  <div className={classes.info}>
                    <Typography className={classes.font}>Costo de envío</Typography>
                    <Typography className={classes.font}>${shippingCost} MXN</Typography>
                  </div>
                  <div className={classes.info}>
                    <Typography className={classes.font}>Subtotal</Typography>
                    <Typography className={classes.font}>${subTotal} MXN</Typography>
                  </div>
                  <div className={classes.info}>
                    <Typography className={classes.font}>Total</Typography>
                    <Typography className={classes.font}>
                      <strong>${total} MXN</strong>
                    </Typography>
                  </div>
                  <div className={classes.info}>
                    <Typography className={classes.font}>Pago</Typography>
                    <Typography className={classes.font}>
                      <strong className={getPaymentStatusColor(status, classes)}>
                        {getPaymentStatus(status)}
                      </strong>
                    </Typography>
                  </div>
                </>
              )}
            </div>
            <Typography
              style={{
                maxWidth: '21rem',
                fontSize: '0.9rem',
                marginTop: '0.6rem',
                fontWeight: 'bold'
              }}
              className={classes.fontSub}
            >
              * Conserva el número de pedido para poder rastrear tu paquete 🚚
            </Typography>
            <div className={classes.banner}>
              <Link to="/home" className={classes.link}>
                <Button className={classes.button} size="small">
                  Ok
                </Button>
              </Link>
            </div>
            <LogosBox />
          </Card>
        </Box>
      </Grid>

      <Grid item md={8} sm={12} xs={12}>
        <Card className={classes.productsRow}>
          <div className={classes.banner}>
            <Typography className={classes.title}>Detalles del pedido</Typography>
          </div>
          {shoppingCartData && (
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cellTitle} align="center">
                    Producto
                  </TableCell>
                  <TableCell className={`${classes.cellTitle} ${classes.tableCell}`} align="center">
                    Cantidad
                  </TableCell>
                  {!isAgreement && (
                    <TableCell
                      className={`${classes.cellTitle} ${classes.tableCell}`}
                      align="center"
                    >
                      Precio unitario
                    </TableCell>
                  )}
                  {!isAgreement && (
                    <TableCell
                      className={`${classes.cellTitle} ${classes.tableCell}`}
                      align="center"
                    >
                      Subtotal
                    </TableCell>
                  )}

                  <TableCell
                    className={`${classes.cellTitle} ${classes.tableCell}`}
                    align="center"
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {shoppingCartData.items.map((item) => (
                  <>
                    <ItemCartTable
                      itemLength={shoppingCartData.items.length}
                      key={item._id}
                      item={item}
                      isEditable={false}
                      showPrices={!isAgreement}
                    />
                    {item.metadata &&
                    item.metadata.bonusDetails &&
                    item.metadata.bonusDetails.PiezasGratis > 0 ? (
                      <FreePiece
                        itemLength={shoppingCartData.items.length}
                        key={item._id}
                        item={item}
                        bonus={item.metadata.bonusDetails}
                      />
                    ) : null}
                    {item.metadata && item.metadata.bonusDetails && item.metadata.giftAplusB ? (
                      <FreePiece
                        itemLength={shoppingCartData.items.length}
                        key={item._id}
                        item={{ product: item.metadata.giftAplusB }}
                        bonus={item.metadata.giftAplusB.metadata.bonusDetails}
                      />
                    ) : null}
                  </>
                ))}
              </TableBody>
            </Table>
          )}
          <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
          <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
          {/* {metadata.prescription_required && (
            <div className={classes.requireInstructionBox}>
            <Typography className={classes.requireInstructionText}>
              <Warning className={classes.warning} />
              Al parecer uno o más medicamentos requieren receta médica. Para que podamos realizar
              tu compra tendrás que mandarnos tu receta médica por WhatsApp al (55) 1329 9099 junto
              con el número de orden.
            </Typography>
          </div>
          )} */}
        </Card>
      </Grid>
    </Grid>
  );
};
Success.propTypes = {
  orderNumber: PropTypes.string,
  total: PropTypes.number,
  subTotal: PropTypes.number,
  shippingCost: PropTypes.number,
  status: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  metadata: PropTypes.arrayOf(PropTypes.shape({}))
};

Success.defaultProps = {
  orderNumber: '',
  total: 0,
  subTotal: 0,
  shippingCost: 0,
  items: [],
  status: 'pending',
  metadata: {}
};
export default Success;
