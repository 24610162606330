import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ClientsReview from './ClientsReview';
import Banner from './Banner';
import AdvantagesTwo from './AdvantagesTwo';
import Advantages from './Advantages';
import { scrollTop } from '../../helper';
import useSearchQuery from '../../customHooks/useSearchQuery';
import { deleteShoppingCartData } from '../../reducers/shoppingCart/actions';
import CdsoBanner from './CdsoBanner';
import { SHOP_ID_STORAGE } from '../../constants';

const Home = ({deleteShoppingCartData}) => {
  const [checkedAdvantages, setCheckedAdvantages] = useState(false);
  const [checkedClients, setCheckedClients] = useState(false);
  const query = useSearchQuery();
  const [checked, setChecked] = useState(false);

  const handleAnimation = () => {
    if (document.documentElement.scrollTop > 1000) {
      setCheckedAdvantages(true);
    }
    if (document.documentElement.scrollTop > 1200) {
      setCheckedClients(true);
    }
  };
  useEffect(()=>{
    if(query){
      if(query.get('remove')){
        deleteShoppingCartData(sessionStorage.getItem(SHOP_ID_STORAGE));
      }
    }
  },[])
  useEffect(() => {
    scrollTop();
    setChecked(true);
    window.addEventListener('scroll', handleAnimation);
    return () => window.removeEventListener('scroll', handleAnimation);
  }, []);

  return (
    <Container maxWidth="xl">
      <Grid xl>
        <Banner checked={checked} />
        <CdsoBanner checked={checked}/>
        <AdvantagesTwo checked={checked} />
        <Advantages checked={checkedAdvantages} />
        <ClientsReview checked={checkedClients} />
      </Grid>
    </Container>
  );
};
Home.propTypes = {
  deleteShoppingCartData:PropTypes.func
}

  Home.defaultProps = {
    deleteShoppingCartData:()=>{}
  }
export default connect(null, {deleteShoppingCartData})(Home);
