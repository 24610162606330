import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles(() => ({
  logoBox: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection:'row'
  },
  isanaPlus: {
    width: '7rem',
    height:'9rem',
    marginTop:'-0.5rem'
  },
  cdsoLogo: {
    width: '4.5rem',
    height:'4.5rem',
    marginTop:'1.2rem'
  },
  textLogos: {
    color: Colors.blueHepatica,
    marginTop:'1rem'
  }
}));
export default styles;
