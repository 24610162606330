import React from 'react'
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { getCurrencyFormat } from '../../containers/MyOrders/OrderDetail/helper';
import styles from './styles';

const PublicPrice = ({publicPrice, price}) => {
const classes = styles()

const isValidPublicPrice = () => {

  if(!publicPrice){
    return false;
  }

  if(publicPrice < price  && price === publicPrice){
    return false;
  }
  
  if(price === publicPrice){
    return false;
  }

  return true;
}

return (
  isValidPublicPrice() && (
    <Typography className={classes.removePrice}>{getCurrencyFormat(publicPrice)}</Typography>
  )
);
}

PublicPrice.propTypes = {
    publicPrice: PropTypes.number,
    price: PropTypes.number
  };
  
  PublicPrice.defaultProps = {
    publicPrice: 0,
    price: 0
  };
  
export default PublicPrice;
