import { CircularProgress, Container, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSearchQuery from '../../../customHooks/useSearchQuery';
import { getOrderByOrderNumber, getOrderDetailsByMercadoRefId } from '../../../services/orders';
import { getOrderByMercadoRef } from '../../../services/payments';
import Failure from './Failure';
import styles from './styles';
import Success from './Success';

const Response = () => {
  const classes = styles();
  const query = useSearchQuery();
  const [status, setStatus] = useState();
  const [redirect, setRedirect] = useState(false);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fixItems = (items) => {
    const itemsfixed = items.map((itemData) => ({
      ...itemData,
      product: {
        ...itemData.product,
        offers: []
      }
    }));
    return itemsfixed
  }

  const normalFlow = async () => {
    const shoppingCartIdQuery = query.get('external_reference');
    const paymentStatus = query.get('status');
    const orderId = query.get('payment_id');
    if (!shoppingCartIdQuery && !paymentStatus) {
      setRedirect(true);
    }
    const response = await getOrderByMercadoRef(orderId);
    if (!response.data.body.id) {
      setStatus('failure');
      setLoading(false);
      return;
    }
    const orderDetailMercado = await getOrderDetailsByMercadoRefId(
      response.data.response.external_reference
    );
    const { items, orderNumber, total, subTotal, shippingCost, metadata } = orderDetailMercado.data;
    const itemsfixed = fixItems(items)
    setOrder({ orderNumber, total, subTotal, shippingCost, metadata, items: itemsfixed });
    setStatus(paymentStatus);
    setLoading(false);
  };


  const agreementFlow = async () => {
     const orderId = query.get('order_id')
     const response = await getOrderByOrderNumber(orderId)
     const { items, orderNumber, total, subTotal, shippingCost, metadata } = response.data;
     const itemsfixed = fixItems(items)
    setOrder({ orderNumber, total, subTotal, shippingCost, metadata, items: itemsfixed });
    setStatus('success')
    setLoading(false);

  }
  /**
   * Handler on payment success to create order and delete shopping cart
   * @returns Promise
   */
  const handleStatusOrder = async () => {
    try {
      if (query) {
        const isAgreement = query.get('agreement');
        if (!isAgreement) {
          await normalFlow();
        } else {
          await agreementFlow()
        }
        return
      }
    } catch (error) {
      setStatus('failure');
    }
  };

  useEffect(() => {
    if (!order) {
      handleStatusOrder();
    }
  }, []);

  return (
    <Container maxWidth="xl" className={classes.box}>
      {loading ? (
        <div className={classes.boxSpinner}>
          <CircularProgress color="primary" />
          <Typography className={classes.text}>
            Un momento... Estamos recuperando los datos del pedido
          </Typography>
        </div>
      ) : (
        <>
          {redirect && navigate('/home')}
          {status === 'failure' ? (
            <Failure />
          ) : (
            order && (
              <Success
                orderNumber={order.orderNumber}
                total={order.total}
                subTotal={order.subTotal}
                shippingCost={order.shippingCost}
                items={order.items}
                status={status}
                metadata={order.metadata}
              />
            )
          )}
        </>
      )}
    </Container>
  );
};

export default Response;
