import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import images from '../../../../../../assets/images';
import styles from '../styles';
import ProductDescription from '../../../../../../components/ProductDescription';
import NumberItems from '../../../../../../components/NumberItems';
import TexNumber from '../../../../../../components/TextNumber';
import { handleTypeOfPromotion } from '../../../../../../helper';

const ItemCart = ({ item, itemLength }) => {
  const classes = styles();
  const { product, unitPrice } = item;
  return (
    <TableCell>
      <div className={classes.cell}>
      <Link to={`/productos/${product._id}`}>
        <img className={classes.image} src={product.imageUrl || images.productDefault} alt="" />
      </Link>
        <div>
          <Typography className={classes.nameProduct}>{product.name}</Typography>
          <ProductDescription classes={classes} productData={product} />
          {Boolean(product.offers.length >= 1) && (
            <div>
              <Typography className={classes.promo}>{handleTypeOfPromotion(product.offers[product.offers.length -1].name)}</Typography>
            </div>
          )}
        </div>
      </div>
      <NumberItems item={item} itemLength={itemLength} />
      <div className={classes.boxTextSmall}>
        <TexNumber number={unitPrice} className={classes.programingDayText} />
      </div>
    </TableCell>
  );
};

ItemCart.propTypes = {
  item: PropTypes.shape({
    product: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      imageUrl: PropTypes.string,
      offers: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      )
    }),
    quantity: PropTypes.number,
    unitPrice: PropTypes.number
  }),
  itemLength: PropTypes.number
};

ItemCart.defaultProps = {
  item: {
    product: {
      name: '',
      imageUrl: '',
      offers: [
        {
          name: ''
        }
      ]
    },
    quantity: 0,
    unitPrice: 0
  },
  itemLength: 0
};

export default ItemCart;
