import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  warningBox: {
    width: '100%',
    height: '15rem'
  },
  text: {
    marginTop: '3rem',
    fontSize: '1.2rem'
  }
}));
export default styles;
