import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5.26rem',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem',
      marginTop: '4rem'
    }
  },
  form: {
    width: '100%'
  },
  input: {
    marginTop: '1rem',
    width: '100%'
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: Colors.electricViolet,
    marginTop: '6rem',
    marginBottom: '2rem'
  },
  image: {
    marginTop: '9.5rem',
    maxWidth: '23rem',
    marginLeft: '19rem',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      marginTop: '13.5rem',
      marginLeft: '9rem',
      maxWidth: '17rem'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  controlBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  button: {
    backgroundColor: Colors.blueHepatica,
    color: Colors.simpleWhite,
    textDecoration: 'none',
    width: '100%',
    marginBottom: '1.1rem'
  },
  linkButton: {
    '&:hover': {
      textDecoration: 'none'
    }
  },
  loadingIndicator: {
    position: 'absolute',
    margin: 'auto'
  },
  boxWarning: {
    padding: '0.6rem',
    borderRadius: '0.3rem'
  },
  warningText: {
    fontWeight: 'bold',
    color: Colors.radicalRed,
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    paddingBottom: '2.18rem',
    margin: 'auto'
  },
  link: {
    textDecoration: 'none',
    color: Colors.blueHepatica
  },
  term: {
    marginBottom: '0.8rem',
    fontSize: '0.8rem',
    marginTop: '0.3rem'
  },
  banner: {
    width: '100%'
  },
  boxFix: {
    maxWidth: '34rem',
    margin: 'auto'
  },
  buttonGoogle: {
    background: Colors.simpleWhite,
    boxShadow: 'none',
    width: '100%',
    border: 'solid'
  },
  buttonFacebook: {
    background: Colors.facebookColor,
    boxShadow: 'none',
    width: '100%',
    color: 'white',
    minHeight: '2.6em',
    border: 'solid',
    borderColor: Colors.simpleWhite,
    '&:hover': {
      background: Colors.facebookColor,
      color: Colors.simpleWhite,
      opacity: '92%'
    }
  },
  svg: {
    filter: 'invert(92%) sepia(93%) saturate(0%) hue-rotate(202deg) brightness(106%) contrast(106%)'
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    flexDirection: 'column',
    background: `linear-gradient(to bottom right, ${Colors.electricViolet}, ${Colors.heliotrope})`
  },
  boxModal:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    flexDirection: 'column'
  },
  text: {
    fontSize: 'bold',
    color: Colors.simpleWhite
  },
  recaptcha: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '0.2rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  fixSeparator: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1rem'
  },
  lines: {
    height: '8rem'
  },
  textLine: {
    height: '0.12rem',
    width: '94%',
    marginTop: '1.2rem',
    background: Colors.electricViolet
  },
  errorEmailBox: {
    display: 'flex',
    justifyContent: 'center'
  }
}));
export default styles;
