/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const existAccount = (account) => account && Object.keys(account).length > 0;

export default (ChildComponent) => {
  const ComposedComponent = (otherProps) => {
    const navigate = useNavigate();
    const { account } = otherProps;
    useEffect(() => {
      if (!existAccount(account)) {
        navigate('/login');
      }
    }, [account, navigate]);
    return existAccount(account) && <ChildComponent {...otherProps} />;
  };

  const mapStateToProps = (state) => {
    const { account } = state.auth;
    return { account };
  };

  ComposedComponent.propTypes = {
    account: PropTypes.shape({})
  };

  ComposedComponent.defaultProps = {
    account: null
  };

  return connect(mapStateToProps)(ComposedComponent);
};
