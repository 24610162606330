/*  eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }]  */
import { Box, Card, CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Title from '../../../components/Title';
import { getCurrencyFormat, getDateFormat, getPaymentMethod, getStatus, getStatusColor } from './helper';
import InfoBox from './InfoBox';
import ProductBox from './ProductBox';
import styles from './styles';
import { getOrderById } from '../../../services/orders';
import { scrollTop } from '../../../helper';
import auth from '../../../customHocs/auth';
import Crumbs from '../../../components/Crumbs';

const OrderDetail = () => {
  const classes = styles();
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (id) {
      getOrderById(id).then((response) => {
        setOrder(response.data);
        setLoading(false);
      });
    }
    scrollTop();
  }, [id]);

  const getNumberOfItems = (item) => {
    let numberOfItems = 0;
    for (let i = 0; i < item.length; i++) {
      numberOfItems += item[i].quantity;
    }
    return numberOfItems;
  };

  const routes = [
    {
      link: 'account',
      name: 'Mi Cuenta'
    },
    {
      link: 'orders',
      name: 'Mis Pedidos'
    },
    {
      link: `orders/${id}`,
      name: 'Detalle del pedido'
    }
  ]


  return (
    <Container maxWidth="xl">
      <Grid container className={classes.container}>
        <Box className={classes.breadcrumbBox}>
          <Crumbs routes={routes}/>
        </Box>
      </Grid>
      {!loading ? (
        <Grid container className={classes.container}>
          <Grid item xs={12} style={{ marginBottom: '1rem' }}>
            <Title text="Detalle del pedido" />
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.row}>
              <Box>
                <Typography className={classes.orderText}>Destino</Typography>
                <Typography>{order.deliveryAddress.street}</Typography>
                <Typography>{order.deliveryAddress.exteriorNumber}</Typography>
                <Typography>{order.deliveryAddress.city}</Typography>
                <Typography>{order.deliveryAddress.state}</Typography>
                <Typography>{order.deliveryAddress.postalCode}</Typography>
              </Box>
              <Box>
                <Typography className={classes.orderText}>Método de pago</Typography>
                <Typography>{getPaymentMethod(order.paymentType)}</Typography>
              </Box>
              <Box>
                <Typography className={classes.orderText}>Resumen</Typography>
                <InfoBox title="Orden" text={order.orderNumber.toString()} />
                <InfoBox title="Realizado el" text={getDateFormat(order.deliveryDate).toString()} />
                <InfoBox title="Artículos" text={getNumberOfItems(order.items).toString()} />
                <InfoBox title="Subtotal" text={getCurrencyFormat(order.subTotal)} />
                <InfoBox title="Costo de envío" text={getCurrencyFormat(order.shippingCost)}/>
                <InfoBox title="Total" text={getCurrencyFormat(order.total)}/>
                <Typography className={getStatusColor(order.status, classes)}>
                  {getStatus(order.status)}
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Card className={classes.productsRow}>
              {order.items.map((item) => (
                <ProductBox item={item} key={item._id} />
              ))}
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Box className={classes.circularProgress}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default auth(OrderDetail);
