import { Box, Chip, CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles';
import { details } from '../../../../services/products';
import TextNumber from '../../../../components/TextNumber';
import { getCoupon } from '../../../../services/coupons';
import images from '../../../../assets/images';

const ProductBox = ({ item }) => {
  const { productId, discount, unitPrice, quantity, subtotal, couponId } = item;
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const classes = styles();
  const getProductById = async (productId) => {
    try {
      const response = await details(productId);
      setProduct(response);
    } catch (error) {
      // TODO error notification
    }
  };

  useEffect(() => {
    getProductById(productId);
  }, [productId]);

  useEffect(() => {
    if (product) {
      setLoading(false);
    }
  }, [product]);

  const getCouponCode = async (id) => {
    const response = await getCoupon(id);
    return response.code;
  };

  return (
    <div>
      {loading ? (
        <div className={classes.circularProgress}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box className={classes.box}>
            <Link className={classes.link} to={`/productos/${productId}`}>
              <Box className={classes.product}>
                <img
                  className={classes.image}
                  src={product.imageUrl ? product.imageUrl : images.iconRecipe}
                  alt={product.name}
                />
                <Typography variant="subtitle1"> {product ? product.name : ''}</Typography>
                <Typography className={classes.detail} variant="subtitle1"> {product ? product.description : ''}</Typography>
              </Box>
            </Link>
            <Box className={classes.prices}>
              <Typography align="right">
                {quantity} x <TextNumber number={unitPrice} />
              </Typography>
              {discount !== 0 && (
                <Typography align="right">
                  - <TextNumber number={discount} />
                </Typography>
              )}
              <Typography align="right">
                Total: <TextNumber number={subtotal} />{' '}
              </Typography>
            </Box>
          </Box>
          {couponId && (
              <Box className={classes.coupon}>
                <Typography align="left">Descuento aplicado:</Typography>
                <Chip className={classes.chip} label={couponId ? getCouponCode(couponId) : ''} />
              </Box>
            )}
          <hr className={classes.separation} />
        </>
      )}
    </div>
  );
};

ProductBox.propTypes = {
  item: PropTypes.shape({
    productId: PropTypes.string,
    quantity: PropTypes.number,
    unitPrice: PropTypes.number,
    subtotal: PropTypes.number,
    discount: PropTypes.number,
    couponId: PropTypes.string
  })
};

ProductBox.defaultProps = {
  item: {
    productId: '',
    quantity: 0,
    unitPrice: 0,
    subtotal: 0,
    discount: 0,
    couponId: ''
  }
};

export default ProductBox;
