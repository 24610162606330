import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  table: {
    border: 'none'
  },
  cellTitle: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  chipPromotion: {
    background: Colors.redBackIsana,
    color: Colors.simpleWhite,
    marginTop: '.5rem'
  },
  image: {
    width: '5rem',
    height: '5rem',
    [theme.breakpoints.down('md')]: {
      width: '4rem',
      height: '4rem'
    },
    [theme.breakpoints.down('sm')]: {
      width: '3.5rem',
      height: '3.5rem'
    },
    [theme.breakpoints.down('xs')]: {
      width: '5rem',
      height: '5rem'
    }
  },
  nameProduct: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.85rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  subName: {
    fontSize: '0.9rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.85rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem'
    }
  },
  buttonsCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
    justifyContent: 'center'
  },
  fabButton: {
    boxShadow: 'none',
    width: '2.2rem',
    height: '1.7rem'
  },
  fabIcon: {
    fontSize: '1rem'
  },
  tableCell: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  boxHidden: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  price: {
    fontWeight: 'bold'
  },
  smimage: {
    width: '5rem',
    height: '5rem'
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    margin: '8rem'
  },
  descriptionProduct: {
    fontSize: '.9rem'
  },
  textProductContainer: {
    marginLeft: '.5rem'
  },
  promo: {
    color: Colors.redIsana,
    fontSize: '0.8rem'
  },
  loaderPromotions: {
    marginRight: '.5rem'
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '20rem',
    background: Colors.simpleWhite,
    borderRadius: '0.5rem',
    border: '2px  #000',
    boxShadow: '2rem',
    padding: '1rem'
  },
  buttonsModal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  buttonModal: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '1rem'
    }
  },
  discount: {
    color: Colors.gray,
    textDecoration: 'line-through;'
  },
  pricewithdiscount: {
    color: Colors.radicalRed
  },
  rowDisabled: {
    background: Colors.athensGray
  }
}));
export default styles;
