import { useEffect, useState } from 'react';

const useFunctionDelay = (action, delay = 1000) => {
  const [change, setChange] = useState();

  useEffect(() => {
    const handle = setTimeout(() => {
        action();
    }, delay);
    return () => clearTimeout(handle);
  }, [change]);

  return setChange;
};

export default useFunctionDelay;
