import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../theme';

const styles = makeStyles((theme) => ({
  input: {
    flex: 1,
    fontSize: '1.2rem',
    marginLeft: '1.3rem',
    '& input::-webkit-placeholder': {
      color: Colors.black
    },
    '& input::-ms-placeholder': {
      color: Colors.black
    },
    '& input::placeholder': {
      color: Colors.black
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem',
      fontSize: '1rem'
    }
  },
  root: {
    padding: '0.2rem 1rem',
    display: 'flex',
    alignItems: 'center',
    minWidth: '33rem',
    maxWidth: '33rem',
    borderRadius: '3rem',
    border: `solid 2px ${Colors.heliotrope}`,
    boxShadow: 'none',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      minWidth: '21rem',
      maxWidth: '33rem',
      padding: '0.3rem 0.6rem',
      margin: '0 0 .5rem 0'
    }
  },
  searchIcon: {
    color: Colors.simpleWhite
  },
  fabButton: {
    background: Colors.electricViolet,
    color: Colors.simpleWhite,
    '&:hover': {
      background: Colors.honeyFlower,
      color: 'white'
    }
  },
  selectField: {
    width: '10rem',
    outline: 'none',
    border: 'none',
    background: 'none',
    fontSize: '0.9rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  selectFieldSmall: {
    width: '10rem',
    outline: 'none',
    border: 'none',
    background: 'none',
    fontSize: '0.9rem',
    display: 'flex'
  },
  rootSelect: {
    padding: '1rem 3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '21rem',
    borderRadius: '3rem',
    margin: '1rem',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  textSelect: {
    fontSize: '1rem'
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: '0rem 0rem 0rem 8rem'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: '0rem 0rem 0rem 8rem'
    },
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: '0rem 0rem 0rem 4rem'
    }
  },
  centerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  listBox: {
    background: Colors.simpleWhite,
    paddingTop: '1rem'
  },
  itemsFoundBox: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  itemsFound: {
    marginLeft: '4rem',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0rem',
      marginLeft: '0rem',
      fontSize: '1rem',
      textAlign: 'center'
    }
  },
  boxTextItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  chip: {
    marginLeft: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.3rem'
    }
  },
  svgHandle: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  modalBox: {
    position: 'relative'
  },
  modal: {
    position: 'absolute',
    zIndex: 10,
    marginTop: '-0.7rem',
    left: 0,
    right: 0,
    padding: '1rem',
    maxHeight: '26rem',
    paddingBottom: '1rem',
    overflow: 'scroll',
    overflowX: 'hidden',
    top: '1rem',
    border: `solid 2px ${Colors.heliotrope}`,
    [theme.breakpoints.down('sm')]: {
      width: '23rem',
      marginTop: '0.3rem',
      marginLeft: '-1rem'
    }
  },
  listImage: {
    width: '5.5rem',
    height: '5.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '3rem',
      height: '3rem'
    }
  },
  listTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem'
    }
  },
  listSubtitle: {
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.47rem'
    }
  },
  serchText: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem'
    }
  },
  sorryText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem'
    }
  },
  searchButton: {
    color: Colors.electricViolet,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    }
  },
  listItem: {
    '&:hover': {
      background: Colors.simpleWhite,
      '& $nameProduct': {
        color: Colors.blueHepatica
      },
      '& $descriptionProduct': {
        color: Colors.blueHepatica
      }
    }
  },
  itemImage: {
    width: '4rem',
    [theme.breakpoints.down('sm')]: {
      width: '3rem'
    }
  },
  nameProduct: {
    fontSize: '1rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    marginLeft: '.5rem'
  },
  descriptionProduct: {
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: '.5rem'
  }
}));

export default styles;
