import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../../theme';

const styles = makeStyles((theme) => ({
  cellTitle: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  table: {
    border: 'none'
  },
  image: {
    width: '6rem',
    height: '6rem'
  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttonsCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
    justifyContent: 'center'
  },
  nameProduct: {
    fontWeight: 'bold',
    textAlign: 'center'
  },
  subName: {
    fontSize: '0.8rem',
    textAlign: 'center'
  },
  contentText: {
    fontSize: '0.7rem',
    textAlign: 'center'
  },
  separation: {
    color: Colors.electricViolet,
    fontWeight: 'bold',
    marginLeft: '0.2rem',
    marginRight: '0.2rem',
    fontSize: '0.85rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  deleteButton: {
    marginLeft: '1rem'
  },
  sameDayText: {
    textAlign: 'center'
  },
  programingDayText: {
    textAlign: 'center',
    fontWeight: 'bold',
    display: 'block'
  },
  inputItems: {
    color: Colors.black,
    background: Colors.simpleWhite,
    textAlign: 'center',
    display: 'flex',
    width: '0.4rem',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none'
  },
  buttonGroup: {
    height: '1.6rem'
  },
  fabButton: {
    boxShadow: 'none',
    width: '2.2rem',
    height: '1.7rem'
  },
  fabIcon: {
    fontSize: '1rem'
  },
  quantity: {
    width: '2.3rem',
    height: '2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10rem',
    margin: '1rem'
  },
  descriptionProduct: {
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: '.5rem',
    width: '15rem'
  },
  promo: {
    color: Colors.redIsana,
    fontSize: '0.8rem',
    textAlign: 'center'
  }
}));

export default styles;
