import { Fab } from '@material-ui/core';
import React from 'react';
import images from '../../../assets/images';
import styles from './styles';

const WhatsAppFloatingButton = () => {
  const classes = styles();
  return (
    <Fab
      href="https://wa.me/525513299099?text=¡iSana Online me cuida y por eso quiero surtir mi receta contigo!"
      target="_blank"
      className={classes.fab}
      color="primary"
      aria-label="add"
    >
      <img src={images.whatsAppIcon} alt="whatsapp" className={classes.whatsAppIcon} />
    </Fab>
  );
};
export default WhatsAppFloatingButton;
