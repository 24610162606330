import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useAgreementCondition from '../../customHooks/useEgreementCondition';
import { removeShoppingCartData } from '../../reducers/shoppingCart/actions';

const ListenerAgreementsRoute = ({ routeName, removeShoppingCartData }) => {
  const routeIdentifier = 'checkout';
  const isIsanaCart = useAgreementCondition();
  const [controlLoop, setControlLoop] = useState(true);
  const deleteShoppingCartDataAgreement = () => {
    removeShoppingCartData();
    setControlLoop(false);
    return true;
  };
  return (
    <Box>
      {!isIsanaCart && !routeName.includes(routeIdentifier) && controlLoop ? (
        <Box>{deleteShoppingCartDataAgreement() && <Box />}</Box>
      ) : (
        <Box />
      )}
    </Box>
  );
};

ListenerAgreementsRoute.propTypes = {
  routeName: PropTypes.string,
  removeShoppingCartData: PropTypes.func
};

ListenerAgreementsRoute.defaultProps = {
  routeName: '',
  removeShoppingCartData: () => {}
};
export default connect(null, { removeShoppingCartData })(ListenerAgreementsRoute);
