import { SET_CUSTOMER_DATA, UPDATE_CUSTUMER_DATA } from './types';

/**
 * Set customer data in redux state
 * @param {object} data customer data
 */
export const setCustomerData = (data) => (dispatch) =>
  dispatch({ type: SET_CUSTOMER_DATA, payload: data });

/**
 * Update customer data in redux state
 * @param {object} value customer data
 */
export const updateCustumerData = (value) => (dispatch) =>
  dispatch({ type: UPDATE_CUSTUMER_DATA, payload: value });
