import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AppBar,
  Button,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Container,
  Typography,
  useMediaQuery,
  Box
} from '@material-ui/core';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import styles from './styles';
import images from '../../../assets/images';
import CartWindow from './CartWindow';
import MenuLinks from './MenuLinks';
import { FirebaseContext } from '../../../customContext/FirebaseContext';
import { OAUTH_CODE } from '../../../constants';
import useAgreementCondition from '../../../customHooks/useEgreementCondition';

const UserActionsMobile = ({ customer = {}, isWindowMobile, handleClickLogout }) => {
  const classes = styles();
  const { name = '', lastName = '' } = customer;
  const fullName = `${name} ${lastName}`;
  const [modalCartHanlder, setCartModalHandler] = useState(false);
  /**
   * handlerCartView
   *  open and close the cart view modal
   * @param {object} e prevents the searhList from closing
   */
  const handlerCartView = (e) => {
    e.stopPropagation();
    return modalCartHanlder ? setCartModalHandler(false) : setCartModalHandler(true);
  };

  return (
    <div
      className={
        isWindowMobile ? classes.containerMobileCustomerLinks : classes.containerDeskCustomerLinks
      }
    >
      {isWindowMobile && <Typography>{fullName}</Typography>}
      <Link className={classes.link} to="/account">
        <Button variant="contained" color="primary" className={classes.buttonMyAccount}>
          Mi cuenta
        </Button>
      </Link>
      <Button
        onClick={handleClickLogout}
        className={`${classes.linkLogin} ${classes.buttonLogout}`}
      >
        Cerrar sesión
      </Button>
      {!isWindowMobile && <CartWindow handler={handlerCartView} modalHanlder={modalCartHanlder} />}
    </div>
  );
};

UserActionsMobile.propTypes = {
  customer: PropTypes.shape({ name: PropTypes.string, lastName: PropTypes.string }),
  isWindowMobile: PropTypes.bool,
  handleClickLogout: PropTypes.func
};

UserActionsMobile.defaultProps = {
  customer: {
    name: '',
    lastName: ''
  },
  isWindowMobile: false,
  handleClickLogout: () => {}
};

const ActionsMobile = ({ isActiveButtons, onClickButton, isWindowMobile }) => {
  const classes = styles();
  const [modalCartHanlder, setCartModalHandler] = useState(false);
  /**
   * handlerCartView
   *  open and close the cart view modal
   * @param {object} e prevents the searhList from closing
   */
  const handlerCartView = (e) => {
    e.stopPropagation();
    return modalCartHanlder ? setCartModalHandler(false) : setCartModalHandler(true);
  };
  return (
    <div>
      {isActiveButtons && (
        <div
          className={
            isWindowMobile
              ? classes.containerMobileCustomerLinks
              : classes.containerDeskCustomerLinks
          }
        >
          <Box>
            <Button
              fullWidth
              onClick={onClickButton}
              className={classes.fixLinkDrawer}
              color="inherit"
            >
              <NavLink
                className={({ isActive }) => (isActive ? classes.linkActive : classes.linkLogin)}
                to="/login"
              >
                Entrar
              </NavLink>
            </Button>
          </Box>
          <Box>
            <Button fullWidth onClick={onClickButton} className={classes.buttonNavDrawer}>
              <Link className={classes.linkButton} to="/signup">
                Registrarme
              </Link>
            </Button>
          </Box>
          <Box>
            {!isWindowMobile && (
              <CartWindow handler={handlerCartView} modalHanlder={modalCartHanlder} />
            )}
          </Box>
        </div>
      )}
    </div>
  );
};

ActionsMobile.propTypes = {
  isActiveButtons: PropTypes.bool,
  onClickButton: PropTypes.func,
  isWindowMobile: PropTypes.bool
};

ActionsMobile.defaultProps = {
  isActiveButtons: true,
  onClickButton: () => {},
  isWindowMobile: false
};

const HeaderLinksDesk = ({
  customer,
  account,
  isActiveButtons,
  isWindowMobile,
  handleClickLogout
}) =>
  account && Object.keys(account).length ? (
    <UserActionsMobile
      isWindowMobile={isWindowMobile}
      handleClickLogout={handleClickLogout}
      customer={customer}
    />
  ) : (
    <ActionsMobile isActiveButtons={isActiveButtons} isWindowMobile={isWindowMobile} />
  );

HeaderLinksDesk.propTypes = {
  account: PropTypes.shape({ email: PropTypes.string }),
  customer: PropTypes.shape({ name: PropTypes.string, lastName: PropTypes.string }),
  isWindowMobile: PropTypes.bool,
  handleClickLogout: PropTypes.func,
  isActiveButtons: PropTypes.bool
};

HeaderLinksDesk.defaultProps = {
  account: { email: '' },
  customer: {
    name: '',
    lastName: ''
  },
  isWindowMobile: false,
  handleClickLogout: () => {},
  isActiveButtons: false
};

const Nav = ({ customer, shoppingCartData, account }) => {
  const classes = styles();
  const isWindowMobile = useMediaQuery('(max-width: 1164px)');
  const [menuState, setMenuState] = useState({ left: false });
  const [modalCartHanlder, setCartModalHandler] = useState(false);
  const { auth, signOut } = useContext(FirebaseContext);
  const [isActiveButtons, setIsActiveButtons] = useState(true);
  const isNotAgreement = useAgreementCondition()
  const navigate = useNavigate();
  useEffect(() => {
    if (shoppingCartData.oauthId) {
      setIsActiveButtons(shoppingCartData.oauthId.internalCode === OAUTH_CODE);
    }
  }, [shoppingCartData.oauthId]);

  /**
   * handlerCartView
   *  open and close the cart view modal
   * @param {object} e prevents the searhList from closing
   */
  const handlerCartView = (e) => {
    e.stopPropagation();
    return modalCartHanlder ? setCartModalHandler(false) : setCartModalHandler(true);
  };

  /**
   * handleDrawerClick
   *  function of toggle the navbar
   * @param {string} anchor gets the side of deployment
   * @param {boolean} open it is to know if it is open or closed
   * @returns state
   */
  const handleDrawerClick = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMenuState({ ...menuState, [anchor]: open });
  };

  const handleClickLogout = async () => {
    try {
      await signOut(auth);
      navigate('/home');
    } catch (error) {
      // eslint-disable-next-line
      console.error('Signout error: ', error);
      // TODO error notification
    }
  };

  /*
   * Handler on click button to close menu mobile
   * @returns
   */
  const handleClickMobileButton = () => setMenuState({ ...menuState, left: false });

  return (
    <>
      <AppBar color="inherit" className={classes.appBar}>
        {/* <Container maxWidth="xl">  */}
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={8} sm={10} md={2} lg={2}>
              <Link to="/home">
                <img className={classes.logo} src={images.iSanaLogo} alt="logo" />
              </Link>
            </Grid>
            {!isWindowMobile && isNotAgreement && (
              <Grid item lg={6} md={6}>
                <SearchBar />
              </Grid>
            )}
            {!isWindowMobile && isNotAgreement &&  (
              <Grid item xs={1} md={4}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                  className={classes.headerLinksContainer}
                >
                  <HeaderLinksDesk
                    customer={customer}
                    account={account}
                    handleClickLogout={handleClickLogout}
                    isActiveButtons={isActiveButtons}
                    isWindowMobile={isWindowMobile}
                  />
                </Grid>
              </Grid>
            )}
            {isWindowMobile && isNotAgreement &&  (
              <Grid item xs={4} sm={2} lg={3}>
                <Box className={classes.menuItemsBox}>
                  <CartWindow handler={handlerCartView} modalHanlder={modalCartHanlder} />
                  <IconButton
                    onClick={handleDrawerClick('left', true)}
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
                    </svg>
                  </IconButton>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
        {!isWindowMobile && isNotAgreement &&  <MenuLinks onClickButton={handleClickMobileButton} isDeskScreen />}
        {isWindowMobile && isNotAgreement &&  (
          <Drawer
            anchor="left"
            open={menuState.left}
            onClose={handleDrawerClick('left', false)}
            className={classes.menuDrawerMobile}
          >
            <MenuLinks onClickButton={handleClickMobileButton} />
            {customer.name ? (
              <UserActionsMobile
                customer={customer}
                isWindowMobile={isWindowMobile}
                handleClickLogout={handleClickLogout}
              />
            ) : (
              <ActionsMobile
                isActiveButtons={isActiveButtons}
                onClickButton={handleClickMobileButton}
                isWindowMobile={isWindowMobile}
              />
            )}
          </Drawer>
        )}

        {isWindowMobile && isNotAgreement && (
          <div className={classes.searchSmall}>
            <SearchBar />
          </div>
        )}
        {/* </Container> */}
        {modalCartHanlder && (
          <Paper elevation={0} className={classes.handlerCloseDiv} onClick={handlerCartView} />
        )}
      </AppBar>

      <div className={classes.offset} />
    </>
  );
};

Nav.propTypes = {
  customer: PropTypes.shape({ name: PropTypes.string }),
  shoppingCartData: PropTypes.shape({
    oauthId: PropTypes.shape({ internalCode: PropTypes.string })
  }),
  account: PropTypes.shape({})
};

Nav.defaultProps = {
  account: {},
  customer: {
    name: ''
  },
  shoppingCartData: {
    oauthId: { internalCode: OAUTH_CODE }
  }
};

const mapsStateToProps = (state) => {
  const { account } = state.auth;
  const { customer } = state.customer;
  const { shoppingCartData } = state.shoppingCart;
  return { customer, shoppingCartData, account };
};

export default connect(mapsStateToProps, null)(Nav);
