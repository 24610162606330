import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import images from '../../assets/images';
import PromoCard from '../../components/PromoCard';
import styles from './styles';

const Promotions = () => {
  const classes = styles();
  const mapState = [1, 2, 3, 4, 5, 6, 7];

  // TODO: Refactor on integration
  const promoMap = mapState.map((i) => (
    <PromoCard
      key={i}
      image={images.medicinale}
      promotion="15%"
      name="Sensor FreeStyle libre"
      validity="Vigencia al 23 de Julio del 2021"
      exception="*Hasta agotar existencia"
    />
  ));

  // TODO: Refactor on integration
  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <div className={classes.promoBox}>
          <Box className={classes.boxTitle}>
          <img className={classes.image} src={images.cdsoLogo} alt="" />
          <Typography className={classes.infoPromo}>
            Los mejores descuentos y las mejores promociones las encuentras con iSana y el Círculo de la Salud Oro
          </Typography>
          </Box>
          <Box className={classes.infoTextBox}>
            <Typography>¡Aprovecha todas estas promociones!</Typography>
            <Button>¡Unete aqui!</Button>
          </Box>
        </div>
        {promoMap}
      </div>
      <div className={classes.boxArrow}>
        <Button
          variant="extended"
          size="small"
          className={classes.buttonArrow}
        >
          <img className={classes.arrowIconDown} src={images.arrowDown} alt="arrow" />
          Mostrar más promociones
        </Button>
      </div>
    </div>
  );
};

export default Promotions;
