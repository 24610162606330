/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import React from 'react';
import { Typography, IconButton, useMediaQuery, Icon } from '@material-ui/core';

import { Link } from 'react-router-dom';
import styles from './styles';
import images from '../../assets/images';
import WhatsAppFloatingButton from './WhatsAppFloatingButton';
import CdsoFloatingBanner from './CdsoFloatingBanner';

const Footer = () => {
  const isActive = useMediaQuery('(max-width: 975px)');
  const classes = styles();

  return (
    <div className={classes.footerFixed}>
      <CdsoFloatingBanner />
      <WhatsAppFloatingButton />
      <div className={classes.container}>
        <div className={classes.topFooter}>
          {!isActive && (
            <div className={classes.fixSection}>
              <img className={classes.imagelogo} src={images.iSanaMonochrome} alt="logo" />
              <div className={classes.secundaryContainer}>
                <Typography className={classes.link}>Horarios laborales</Typography>
                <Typography className={classes.link}>Lunes a viernes: 9am-9pm</Typography>
                <Typography className={classes.link}>Sábados: 10am-6pm</Typography>
              </div>
            </div>
          )}
          <div className={classes.fixSection}>
            {!isActive && (
              <div className={classes.fixContact}>
                <div className={classes.numberBox}>
                  <Typography className={classes.subtitleContact}>ESCRÍBENOS</Typography>
                  <a
                    target="_blank"
                    className={classes.titleContact}
                    href="https://wa.me/525513299099?text=¡Hola! Tengo unas dudas sobre como surtir mi receta en el *sitio web de iSana*. ¿Me podrías apoyar por favor?"
                    rel="noreferrer"
                  >
                    <img
                      src={images.whatsAppIcon}
                      alt="whatsapp"
                      className={classes.whatsAppIcon}
                    />
                    <Typography className={classes.titleContact}>(55) 1329 9099</Typography>
                  </a>
                </div>
                <div>
                  <Typography className={classes.subtitleContact}>CONTÁCTANOS</Typography>
                  <a
                    className={classes.titleContact}
                    target="_blank"
                    href="mailto:hola@isana.mx"
                    rel="noreferrer"
                  >
                    <Typography className={classes.titleContact}>hola@isana.mx</Typography>
                  </a>
                </div>
              </div>
            )}
          </div>
          {!isActive && (
            <div className={classes.fixSection}>
              <div className={classes.buttonsBox}>
                {/* <a
                  target="_blank"
                  href="https://apps.apple.com/mx/app/isana-app/id1557020175"
                  rel="noreferrer"
                >
                  <img src={images.appStore} alt="app store" />
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.isana.isanaapp"
                  rel="noreferrer"
                >
                  <img src={images.googlePlay} alt="app store" />
                </a> */}
              </div>
              <div className={classes.secundaryContainer}>
                <div className={classes.fixSection}>
                  <Link to="/preguntasFrecuentes" className={classes.link}>
                    Preguntas frecuentes
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        {isActive && (
          <div className={classes.fixActiveRow}>
            <div className={classes.fixSectionActive}>
              <div className={classes.buttonsBox}>
                {/* <a
                  target="_blank"
                  href="https://apps.apple.com/mx/app/isana-app/id1557020175"
                  rel="noreferrer"
                >
                  <img src={images.appStore} alt="app store" />
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.isana.isanaapp"
                  rel="noreferrer"
                >
                  <img src={images.googlePlay} alt="app store" />
                </a> */}
              </div>
              <div className={classes.fixSectionActive}>
                {/* <a className={classes.link}>Blog</a> */}
                <Link to="/preguntasFrecuentes" className={classes.link}>
                  Preguntas frecuentes
                </Link>
                <Link to="/terminos-y-condiciones-de-envio" className={classes.link}>
                  Políticas de envío gratis
                </Link>
                <Link to="/contacto" className={classes.link}>
                  Contacto
                </Link>
              </div>
            </div>
            <div className={classes.fixSectionActive}>
              <Typography className={classes.fixFontActive}>Horarios laborales</Typography>
              <Typography className={classes.fixFont}>Lunes a viernes: 9am-9pm</Typography>
              <Typography className={classes.fixFont}>Sábados: 10am-6pm</Typography>
            </div>
          </div>
        )}
        {isActive && (
          <div className={classes.fixContact}>
            <div className={classes.centerDiv}>
              <Typography align="center" className={classes.subtitleContact}>
                ESCRÍBENOS
              </Typography>
              <a
                className={classes.titleContact}
                target="_blank"
                href="https://wa.me/525513299099?text=¡Hola! Tengo unas dudas sobre como surtir mi receta en el *sitio web de iSana*. ¿Me podrías apoyar por favor?!"
                rel="noreferrer"
              >
                <img src={images.whatsAppIcon} alt="whatsapp" className={classes.whatsAppIcon} />
                <Typography className={classes.titleContact}>(55) 1329 9099</Typography>
              </a>
            </div>
            <div className={classes.centerDiv}>
              <Typography align="center" className={classes.subtitleContact}>
                CONTÁCTANOS
              </Typography>
              <a
                className={classes.titleContact}
                target="_blank"
                href="mailto:hola@isana.mx"
                rel="noreferrer"
              >
                <Typography className={classes.titleContact}>hola@isana.mx</Typography>
              </a>
            </div>
          </div>
        )}
        <div className={classes.bottomFooter}>
          <div className={classes.fixSecondSection}>
            <Link to="/terminos-y-condiciones-de-envio" className={classes.growFix}>
              Términos y condiciones de envío
            </Link>
          </div>
          <div className={classes.fixSecondSection}>
            <Link to="/aviso-de-privacidad" className={classes.growFix}>
              Aviso de privacidad
            </Link>
          </div>
          <div className={classes.fixSecondSection}>
            <a href="https://www.facebook.com/isanamexico" target="_blank" rel="noreferrer">
              <IconButton color="inherit" aria-label="menu">
                <Icon>
                  <img className={classes.icon} src={images.facebookIcon} alt="" />
                </Icon>
              </IconButton>
            </a>
            <a href="https://www.instagram.com/isanamexico/" target="_blank" rel="noreferrer">
              <IconButton color="inherit" aria-label="menu">
                <Icon>
                  <img className={classes.icon} src={images.instagramIcon} alt="" />
                </Icon>
              </IconButton>
            </a>
            <a href="https://www.linkedin.com/company/isana-health/" target="_blank" rel="noreferrer">
              <IconButton color="inherit" aria-label="menu">
                <Icon>
                  <img className={classes.icon} src={images.linkedinIcon} alt="" />
                </Icon>
              </IconButton>
            </a>
          </div>
        </div>
        <div>
          <div className={classes.fixSecondSection}>
            <Typography className={classes.growFix}>
              iSana ® - iSana Health Tech SAPI de CV. Todos los derechos reservados 2023.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
