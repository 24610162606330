/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { Box, Typography, Collapse, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './styles';
import ButtonCdsoInfo from '../../ButtonCdsoInfo';

const CdsoFloatingBanner = ({ customer }) => {
  const [customerData, setCustomerData] = useState();
  const [showText, setShowText] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [delay, setDelay] = useState(false);
  const classes = styles();

  /**
   * show subtext from modal
   */
  const onHandleShowText = () => {
    setShowText(true);
  };

  /**
   * onCloseBox
   * Close modal
   */
  const onCloseBox = () => {
    setShowBox(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setDelay(true);
    }, 5000);
  }, []);

  useEffect(()=>{
    if(customer){
      setCustomerData(customer)
    }
  },[customer])
  return (
    <>
      {delay && (
        <>
          {!customerData.cdsoCardNumber && (
            <Box className={!showBox ? classes.floater : classes.hiddenBox}>
              <Box
                className={classes.box}
                onMouseEnter={onHandleShowText}
                onMouseLeave={onHandleShowText}
              >
                <Collapse in={showText}>
                  <Box className={classes.closeBox}>
                    <Button onClick={onCloseBox}>
                      <CloseIcon className={classes.icon} />
                    </Button>
                  </Box>
                </Collapse>
                <Typography className={classes.title}>
                  ¡OBTÉN MEDICAMENTOS GRATIS Y MÁS DESCUENTOS!
                </Typography>
                <Collapse in={showText}>
                  <Typography className={classes.text}>
                    Con el programa de beneficios de iSana+ podrás obtener
                    beneficios exclusivos como descuentos y medicamentos GRATIS.
                  </Typography>
                  <Box className={classes.boxButton}>
                    <ButtonCdsoInfo />
                  </Box>
                </Collapse>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

CdsoFloatingBanner.propTypes = {
  customer: PropTypes.shape({ cdsoCardNumber: PropTypes.string })
};

CdsoFloatingBanner.defaultProps = {
  customer: {cdsoCardNumber:''}
};

const mapsStateToProps = (state) => {
  const { customer } = state.customer;
  return { customer };
};
export default connect(mapsStateToProps, {})(CdsoFloatingBanner);
