import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  bar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '3rem',
    padding: '1rem 1rem 1rem 3rem',
    marginBottom: '0.5rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '5rem'
    },
    background: Colors.simpleWhite
  },
  backButton: {
    color: Colors.electricViolet,
    fontWeight: 'bold',
    marginRight: '2rem'
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold'
  },
  boxCart: {
    background: Colors.simpleWhite,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-around',
      flexDirection: 'row'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'cener',
      alingContent: 'center'
    }
  },
  detail: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '4rem',
    padding: '1rem',
    [theme.breakpoints.up('lg')]: {
      marginTop: '5rem'
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'cener',
      alingContent: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'cener',
      alingContent: 'center'
    }
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'center'
  },
  informationBox: {
    width: '28rem',
    [theme.breakpoints.down('md')]: {
      width: '23rem'
    },
    [theme.breakpoints.down('sm')]: {
      width: '21rem'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: 'auto',
      padding: '2rem'
    }
  },
  textField: {
    width: '5rem',
    textAlign: 'right'
  },
  imageProduct: {
    width: '18rem',
    height: '18rem',
    margin: '1rem',
    [theme.breakpoints.up('lg')]: {
      width: '26rem',
      height: '25rem'
    },
    [theme.breakpoints.down('sm')]: {
      width: '15rem',
      height: '14rem'
    },
    [theme.breakpoints.down('xs')]: {
      width: '18rem',
      height: '17rem'
    }
  },
  productName: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.6rem'
    }
  },
  subtitle: {
    fontSize: '1.1rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem'
    }
  },
  subtitleSpacing: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    color: Colors.electricViolet,
    marginLeft: '0.3rem',
    marginRight: '0.3rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem'
    }
  },
  subtitleBox: {
    display: 'flex'
  },
  carFix: {
    marginRight: '0.5rem'
  },
  note: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    color: Colors.blueViolet,
    fontSize: '0.8rem'
  },
  buttonAdd: {
    background: Colors.blueViolet,
    color: Colors.simpleWhite,
    width: '19rem',
    marginLeft: '1rem',
    fontWeight: 'bold',
    '&:hover': {
      background: Colors.honeyFlower,
      color: 'white'
    }
  },
  boxFix: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.5rem',
    [theme.breakpoints.up('lg')]: {
      marginTop: '1rem'
    }
  },
  others: {
    fontSize: '0.9rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem'
    }
  },
  othersPrice: {
    fontSize: '0.9rem',
    textDecoration: 'line-through',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem'
    }
  },
  sameDay: {
    fontSize: '0.95rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.1rem'
    }
  },
  sameDayPrice: {
    fontSize: '0.95rem',
    fontWeight: 'bold',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.1rem'
    }
  },
  boxProgrammedFix: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
    padding: '0.3rem',
    borderRadius: '0.3rem',
    background: Colors.grayViolet,
    marginBottom: '0.5rem',
    [theme.breakpoints.up('lg')]: {
      marginTop: '6rem'
    }
  },
  programmed: {
    fontSize: '1rem',
    fontWeight: 'bold',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.12rem'
    }
  },
  programmedNote: {
    fontSize: '0.8rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.9rem'
    }
  },
  programmedPrice: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: Colors.redIsana,
    marginRight: '1rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.8rem',
      marginLeft: '0.4rem'
    }
  },
  pricesInfo: {
    width: '21rem',
    margin: '4rem',
    padding: '2rem',
    background: Colors.lowGray,
    borderRadius: '0.5rem',
    [theme.breakpoints.down('md')]: {
      width: '19rem',
      padding: '1rem',
      margin: '2rem'
    }
  },
  pricesInfoTextTitle: {
    fontSize: '1.1rem',
    marginTop: '0.8rem',
    fontWeight: 'bold',
    color: Colors.goodLuck
  },
  lock: {
    fontSize: '1rem'
  },
  pricesInfoSubText: {
    fontSize: '0.6rem'
  },
  pricesInfoText: {
    fontSize: '0.8rem',
    marginTop: '0.8rem',
    fontWeight: 'bold'
  },
  boxInfoMethods: {
    marginTop: '8rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '7rem'
    }
  },
  boxPoint: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2rem'
  },
  instructions: {
    fontSize: '0.7rem'
  },
  methodsTitle: {
    fontWeight: 'bold',
    fontSize: '0.99rem'
  },
  boxInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iSanaWorkContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '4rem',
    background: Colors.simpleWhite,
    marginTop: '.5rem'
  },
  iconTitle: {
    fontWeight: 'bold'
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  boxIcon: {
    display: 'flex',
    marginTop: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '2rem'
    }
  },
  textIcon: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    textAlign: 'center',
    width: '18rem'
  },
  icon: {
    width: '3rem',
    height: '3rem'
  },
  input: {
    color: Colors.black,
    background: Colors.simpleWhite,
    textAlign: 'center',
    width: '0.4rem',
    height: '2rem',
    border: 'none'
  },
  paymentsImage: {
    width: '3.3rem',
    marginLeft: '1.5rem'
  },
  paymentsImageMasterCard: {
    width: '2.6rem',
    marginLeft: '1.5rem'
  },
  paymentsImagePayPal: {
    width: '4.6rem',
    marginLeft: '1.5rem'
  },
  boxPayment: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '0.3rem'
  },
  productOnStock: {
    backgroundColor: Colors.mintZest,
    maxWidth: '9rem',
    padding: '0.28rem',
    borderRadius: '1rem',
    marginTop: '0.7rem'
  },
  onStockText: {
    fontSize: '0.8rem',
    textAlign: 'center'
  },
  fewProductOnStock: {
    backgroundColor: Colors.iceLemon,
    maxWidth: '12rem',
    padding: '0.28rem',
    borderRadius: '1rem',
    marginTop: '0.7rem'
  },
  noProductOnStock: {
    backgroundColor: Colors.fancyFlamingo,
    maxWidth: '12rem',
    padding: '0.28rem',
    borderRadius: '1rem',
    marginTop: '0.7rem'
  },
  progress: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '20rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0rem'
    }
  },
  removePrice: {
    color: Colors.gray,
    textDecoration: 'line-through;'
  },
  textDiscountPrice: {
    fontSize: '.9rem',
    color: Colors.gray,
    fontWeight: 'bold'
  },
  requireRecipeBox: {
    backgroundColor: Colors.softBlue,
    padding: '0.28rem',
    borderRadius: '1rem',
    marginTop: '0.7rem'
  },
  requireRecipeText: {
    fontWeight: 'bold',
    textAlign: 'center'
  },
  requireInstructionBox: {
    border: 'solid',
    borderColor: Colors.redBackIsana,
    padding: '0.4rem',
    borderRadius: '0.3rem',
    marginTop: '1rem'
  },
  requireInstructionText: {
    fontSize: '0.9rem'
  },
  warning: {
    marginBottom: '-0.13rem',
    fontSize: '0.9rem',
    marginRight: '0.2rem'
  },
  promo: {
    fontSize: '1rem',
    marginTop: '1rem',
    fontWeight: 'bold',
    color: Colors.redIsana,
    textTransform: 'capitalize'
  },
  nameBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.3rem'
  },
  promotion: {
    background: Colors.radicalRed,
    color: Colors.simpleWhite,
    width: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '0.3rem'
  },
  promotions: {
    fontSize: '0.7rem',
    marginTop: '0.8rem'
  },
  plusText: {
    color: Colors.redIsana,
    fontWeight: 'bold',
    fontSize: '1rem',
    marginTop: '1rem'
  },
  isanaText: {
    color: Colors.honeyFlower,
    fontWeight: 'bold',
    fontSize: '1rem',
    marginTop: '1rem'
  }
}));

export default styles;
