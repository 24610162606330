import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../theme';

const styles = makeStyles((theme) => ({
  floater: {
    zIndex: 10,
    display: 'flex',
    position: 'fixed',
    left: '4rem',
    bottom: '0.2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    cursor: 'pointer'
  },
  box: {
    width: '28rem',
    background: Colors.blueHepatica,
    padding: '1rem',
    borderRadius: '0.15rem'
  },
  boxButton: {
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.2rem'
  },
  text: {
    textAlign: 'center',
    fontSize: '1rem'
  },
  button: {
    borderRadius: '2rem',
    background: Colors.simpleWhite,
    width: '12rem',
    marginTop: '1rem',
    '&:hover': {
      background: Colors.grayViolet
    }
  },
  closeBox: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  hiddenBox: {
    display: 'none'
  },
  icon: {
    color: Colors.simpleWhite
  }
}));
export default styles;
