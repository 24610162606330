import { SET_ACCOUNT_DATA } from './types';

// eslint-disable-next-line import/prefer-default-export
/**
 * set account authenticated data
 * @param {object} value account data
 */
// eslint-disable-next-line import/prefer-default-export
export const setAccountData = (value) => (dispatch) =>
  dispatch({ type: SET_ACCOUNT_DATA, payload: value });
