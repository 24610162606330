import {
  Box,
  Button,
  Card,
  Dialog,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Add, CheckCircle, Close } from '@material-ui/icons';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SnackbarNote from '../../../components/SnackbarNote';
import { updateCustumerData } from '../../../reducers/customer/actions';
import assignCodeReferred from '../../../services/clients';
import styles from './styles';

const ReferralCode = ({ updateCustumerData }) => {
  const classes = styles();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorOnAssign, setErrorOnAssign] = useState(false);
  const [codeText, setCodeText] = useState('');
  const [loading, setLoading] = useState(false)
  const selector = useSelector((state) => state.customer);

  const onClose = () => {
    setOpen(!open);
    setSuccess(true);
  };

  const handleDiaog = () => {
    setOpenDialog(!openDialog);
  };

  const onAssign = async () => {
    try {
      if (errorOnAssign) {
        setErrorOnAssign(false);
      }
      setLoading(true)
      const data = {
        code: codeText,
        clientId: selector.customer._id
      };
      const response = await assignCodeReferred(data);
      const customer = {
        ...selector.customer,
        referralCode: response.data.referralCode
      };
      updateCustumerData(customer);
      setOpen(true);
      setSuccess(true);
      setLoading(false)
      handleDiaog()
      return;
    } catch (error) {
      setErrorOnAssign(error);
      setLoading(false)
    }
  };

  const isButtonDisabled = () => !!(loading || codeText.length === 0)

  return (
    <Box>
      <Card className={classes.card}>
        <Box className={classes.actionBox}>
          <Typography variant="h6">Código de referido</Typography>
          {selector && selector.customer.referralCode ? (
            <Tooltip title="Código asignado">
              <CheckCircle />
            </Tooltip>
          ) : (
            <Tooltip title="Agregar código de referido">
              <IconButton onClick={handleDiaog}>
                <Add fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Card>
      <Dialog open={openDialog}>
        <Box className={classes.gridFix}>
          <Card className={classes.cardModal}>
            <Box className={classes.actionBox}>
              <Typography variant="h6">Agregar Código</Typography>
              <IconButton onClick={handleDiaog}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
            <Box className={classes.fixRow}>
              <TextField
                size="small"
                className={`${classes.textfield} ${classes.fixSmallField}`}
                required
                label="Código de referido"
                placeholder="Añade el código que te dio tu empresa"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setCodeText(e.target.value);
                }}
              />
            </Box>
            <Box>
              {errorOnAssign && (
                <Typography>Error al intentar asignar el código de referido</Typography>
              )}
            </Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.textfield}
              onClick={onAssign}
              disabled={isButtonDisabled()}
            >
              Agregar
            </Button>
          </Card>
        </Box>
      </Dialog>
      <SnackbarNote
        successMessage="Código de referido agregado 👍"
        onOpen={open}
        onClose={onClose}
        success={success}
      />
    </Box>
  );
};

ReferralCode.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    mothersLastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    _id: PropTypes.string,
    emailVerified: PropTypes.bool,
    cdsoCardNumber: PropTypes.string
  }),
  updateCustumerData: PropTypes.func
};

ReferralCode.defaultProps = {
  customer: {
    name: '',
    lastName: '',
    mothersLastName: '',
    phoneNumber: '',
    _id: '',
    emailVerified: false,
    cdsoCardNumber: ''
  },
  updateCustumerData: () => {}
};

export default connect(null, { updateCustumerData })(ReferralCode);
