import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const useStyles = makeStyles(() => ({
  dialogRoot: {
    '& .MuiPaper-root': {
      borderRadius: '.62rem'
    },
    marginTop: '3rem'
  },
  dialogContent: {
    padding: '3rem',
    '&::-webkit-scrollbar': {
      width: '6px !important',
      height: '6px !important'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)'
    },
    '&::-webkit-scrollbar-track': {
      background: 'hsla(0,0%,100%,.1)'
    }
  },
  dialogTitle: {
    padding: '1rem 0rem'
  },
  titleDialog: {
    fontSize: '1.2rem',
    color: Colors.affair,
    fontWeight: 'bold'
  },
  modalCloseButton: {
    position: 'absolute',
    color: Colors.wildBlueYonder,
    padding: '0',
    '&:hover': {
      background: 'transparent',
      '& svg': {
        fill: Colors.mulledWine
      }
    },
    right: '1rem',
    top: '1rem'
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  card: {
    width: '25rem',
    height: '15rem',
    background: `linear-gradient(to bottom right, ${Colors.goldCdso}, ${Colors.goldGradiantCdso})`,
    padding: '2rem',
    borderRadius: '0.8rem',
    boxShadow: Colors.doveGray
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardNumber: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem'
  },
  numbers: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: Colors.black
  },
  cardTitle: {
    color: Colors.athensGray,
    fontWeight: 'bold'
  },
  id: {
    color: Colors.black
  },
  textDates: {
    fontSize: '1rem',
    marginRight: '1rem'
  },
  dates: {
    display: 'flex',
    flexDirection: 'row'
  },
  idBox: {
    display: 'flex',
    marginTop: '2.3rem'
  }
}));
export default useStyles;
