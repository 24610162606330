import axios from '../utils/axios';
import { API_URL_OAUTH } from '../constants';

/**
 * Verify Email
 * @param {string} token Token to authenticate verification
 * @returns
 */
export const verifyEmail = async ({ token }) => {
  const response = await axios.post(`${API_URL_OAUTH}/verify-email`, { token });
  return response.data;
};

/**
 * Resend email to verifacte email
 * @param {string} token Token to authenticate verification
 * @returns
 */
export const resendVerificationEmail = async (params) => {
  const response = await axios.post(`${API_URL_OAUTH}/resend-verify-email`, params);
  return response.data;
};

/**
 * signup new customer
 * @param {object} data customer data to register
 * @returns promise<object>
 */
export const signup = async (data) => {
  const response = await axios.post(`${API_URL_OAUTH}/signup`, { ...data, type: 'customer' });
  return response.data;
};
