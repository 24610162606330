import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../theme';

const styles = makeStyles((theme) => ({
  container: {
    marginTop: '3rem',
    backgroundColor: Colors.simpleWhite
  },
  row: {
    maxWidth: '65rem',
    margin: 'auto',
    padding: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  productsRow: {
    maxWidth: '65rem',
    margin: 'auto',
    padding: '3rem',
    display: 'flex',
    flexDirection: 'column'
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '2rem'
  },
  orderText: {
    color: Colors.blueHepatica,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem'
    }
  },
  dataText: {
    fontWeight: 'bold'
  },
  delivery: {
    color: Colors.goodLuck,
    fontWeight: 'bold'
  },
  orderBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  textOrderBox: {
    maxWidth: '8rem',
    marginRight: '3rem'
  },
  canceled: {
    color: Colors.radicalRed,
    fontWeight: 'bold'
  },
  defaultStatus: {
    color: Colors.purpleHeart,
    fontWeight: 'bold'
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: '43rem',
    width: '100%'
  },
  breadcrumbBox: {
    display: 'flex',
    padding: '1.5rem'
  },
  link: {
    textDecoration: 'none'
  }
}));
export default styles;
