// SET Shopping DATA
export const SET_ITEM_SHOPPING_CART = 'SET_ITEM_SHOPPING_CART';
export const DELETE_ITEM_SHOPPING_CART = 'DELETE_ITEM_SHOPPING_CART';
export const SET_COUPON_SHOPPING_CART = 'SET_COUPON_SHOPPING_CART';
export const UPDATE_QUANTITY_SHOPPING_CART = 'UPDATE_QUANTITY_SHOPPING_CART';

export const CREATE_SHOPPING_CART_DATA = 'CREATE_SHOPPING_CART_DATA';
export const UPDATE_SHOPPING_CART_DATA = 'UPDATE_SHOPPING_CART_DATA';
export const UPDATE_METADATA_ITEMS = 'UPDATE_METADATA_ITEMS';
export const GET_SHOPPING_CART_DATA = 'GET_SHOPPING_CART_DATA';
export const DELIVERY_DATA_WEBSITE_SET = 'DELIVERY_DATA_WEBSITE_SET';
export const SHOPPING_CART_UPDATE = 'SHOPPING_CART_UPDATE';
export const SET_CLIENT_SHOPPING_CART = 'SET_CLIENT_SHOPPING_CART';

export const DELETE_SHOPPING_CART_DATA = 'DELETE_SHOPPING_CART_DATA';
export const REMOVE_SHOPPING_CART_DATA = 'REMOVE_SHOPPING_CART_DATA';


export const GET_BONUS_PRODUCTS_START = 'GET_BONUS_PRODUCTS_START';
export const GET_BONUS_PRODUCTS_SUCCESS = 'GET_BONUS_PRODUCTS_SUCCESS';
export const GET_BONUS_PRODUCTS_FAIL = 'GET_BONUS_PRODUCTS_FAIL';

export const SET_SHOPPINGCART_ERROR = 'SET_SHOPPINGCART_ERROR';
export const SET_BONUS_RECENTLY_UPDATED = 'SET_BONUS_RECENTLY_UPDATED';
export const ON_CHECKOUT_PROCCESS = 'ON_CHECKOUT_PROCCESS';
export const CHANGES_DISABLED = 'CHANGES_DISABLED';
export const SET_CDSO_BONUS = 'SET_CDSO_BONUS';



