/* eslint-disable import/prefer-default-export */
export const getStatus = (status) => {
  switch (status) {
    case 'delivered':
      return 'Entregado';
    case 'canceled':
      return 'Cancelado';
    case 'toDeliver':
      return 'En camino';
    case 'preparing':
      return 'Preparando pedido';
    default:
      return 'Pendiente';
  }
};
export const getPaymentMethod = (method) => {
  switch (method) {
    case 'mercadoPago':
      return 'Mercado Pago';
    case 'cash':
      return 'Efectivo';
    case 'terminal':
      return 'Terminal';
    case 'creditCardStripe':
      return 'Tarjeta de crédito';
    case 'debitCardStripe':
      return 'Tarjeta de débito';
    case 'paypal':
      return 'Paypal';
    default:
      return 'Mercado Pago';
  }
};

export const getStatusColor = (status, classes) => {
  switch (status) {
    case 'delivered':
      return classes.delivery;
    case 'canceled':
      return classes.canceled;
    default:
      return classes.defaultStatus;
  }
};

export const getDateFormat = (date) => {
  const dateForm = date.slice(0, 10);
  return dateForm;
};

export const getCurrencyFormat = (value) => {
  if (!value) {
    return '';
  }
  return new Intl.NumberFormat('es-MX', { currency: 'MXN', style: 'currency' }).format(value);
};
