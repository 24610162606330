import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles(() => ({
  title: {
    marginTop: '5rem',
    textAlign: 'center',
    color: Colors.blueHepatica,
    fontWeight: 'bold'
  },
  container: {
    backgroundColor: Colors.simpleWhite
  },
  gridCards: {
    padding: '4rem',
    marginBottom: '10rem',
    marginTop: '4rem'
  },
  nameUser: {
    fontWeight: 'bold',
    marginLeft: '1rem'
  },
  avatar: {
    display: 'flex',
    paddingLeft: '4rem',
    paddingRight: '4rem',
    alignItems: 'center',
    marginTop: '6rem'
  }
}));
export default styles;
