import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  container: {
    marginTop: '3rem',
    backgroundColor: Colors.simpleWhite
  },
  gridCards: {
    padding: '4rem',
    marginBottom: '5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.1rem'
    }
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: '33rem',
    width: '100%'
  },
  breadcrumbBox: {
    display: 'flex',
    padding: '1.5rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '2rem'
    }
  },
  link: {
    textDecoration: 'none'
  },
  select: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem'
  },
  selectInput: {
    border: 'none',
    borderBottom: 'none'
  }
}));
export default styles;
