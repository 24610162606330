import React from 'react';
import { Typography } from '@material-ui/core';
import Questions from '../../components/Questions';
import styles from './styles';

const AskedQuestions = () => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <div className={classes.bannerSection}>
        <div className={classes.titleRow}>
          <Typography className={classes.miniTitle}>SERVICIO ISANA</Typography>
          <Typography className={classes.title}>Preguntas frecuentes</Typography>
        </div>
      </div>
      <div className={classes.rowQuestion}>
        <Questions
          question="¿iSana es una farmacia?"
          answer="Somos una farmacia digital que se encarga de buscar todos tus medicamentos al instante y llevarlos a tu domicilio, gracias a nuestra tecnología te podemos garantizar un excelente precio.
          "
        />
        <Questions
          question="¿Puedo agendar mis entregas?"
          answer="¡Claro! iSana te garantiza la entrega de tus medicamentos ya sea con entrega el mismo día o entregas programadas. Nuestros horarios son de lunes a viernes 9:00 hrs a 21:00 hrs y sábados de 9:00 am a 18:00 hrs"
        />
        <Questions
          question="¿Puedo hacer un pedido recurrente?"
          answer="Por supuesto ☺, en iSana amamos llevar salud a la puerta de tu casa y por eso contamos
          con la tecnología que te permitirá agendar tus pedidos y así la familia iSana se encargará de buscar
          el mejor precio con anticipación. "
        />
        <Questions
          question="¿Cómo puedo pagar mis pedidos?"
          answer="en iSana contamos con diferentes formas de pago, puede ser tarjeta de débito o
          crédito ya sea pagos en línea o con la terminal en la puerta de tu casa, también puedes hacer
          transferencia o depósito bancario así como pagos en efectivo al momento de recibir tu pedido completo!
          Puedes elegir la forma que desees"
        />
        <Questions
          question="¿Cuánto cuesta el servicio de iSana?"
          answer="Para entregas en CDMX y León el costo depende del valor de tu pedido. Si el valor de tu pedido es mayor a $350 MXN ¡el costo de envío es gratis! En caso contrario el costo del envío es de $29 MXN. Para entregas en otras ciudades cotizamos cada pedido al momento de atenderte."
        />
        <Questions
          question="¿Me consiguen todos mis medicamentos?"
          answer="Si, nos encargamos de surtir todo lo que necesites.
          Cuando hay un medicamento difícil de encontrar, no descansamos hasta poder entregártelo.
          Llevar salud es lo que más amamos hacer ☺"
        />
        <Questions
          question="¿Y si tengo más preguntas?"
          answer="Puedes enviarnos un mensaje directo por WhatsApp al número (55) 1329 9099 o dando clic en el ícono de WhatsApp de nuestra página."
        />
      </div>
    </div>
  );
};

export default AskedQuestions;
