import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  adbox: {
    display: 'flex',
    justifyContent: 'center'
  },
  adCdso: {
    marginTop: '3.3rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80rem',
    alignItems: 'center',
    background: `linear-gradient(to bottom right, ${Colors.simpleWhite}, ${Colors.grayViolet})`,
    borderRadius: '0.2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1.7rem',
      paddingBottom: '0.7rem'
    }
  },
  cdsoLogo: {
    maxWidth: '10rem',
    height: 'auto',
    marginLeft: '4rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0rem',
      maxWidth: '8rem'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '8rem'
    }
  },
  adTextBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginLeft: '-3rem'
  },
  titleAdd: {
    fontWeight: 'bold',
    color: Colors.black,
    textAlign: 'center',
    fontSize: '1.43rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
      marginBottom: '0.8rem'
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0.2rem'
    }
  },
  subTitle: {
    fontWeight: 'bold',
    color: Colors.black,
    textAlign: 'center',
    fontSize: '1.33rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  womenWithMask: {
    width: 'auto',
    height: '12rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export default styles;
