import {
  Box, Button, Card, Grid, Link, Typography
} from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import React from 'react';
import styles from './styles';

const Failure = () => {
  const classes = styles();
  return (
    <Grid item xs={12} className={classes.row}>
      <Box className={classes.boxInfo}>
        <Card className={classes.boxBanner}>
          <div className={classes.banner}>
            <ErrorOutline className={classes.iconError} />
            <Typography className={classes.title}>¡Oh Oh!</Typography>
          </div>
          <div className={classes.details}>
            <Typography className={classes.subtitle}>
              <strong>Algo salió mal 🤔</strong>
            </Typography>
          </div>
          <Typography
            style={{
              maxWidth: '21rem',
              fontSize: '1.2rem',
              marginTop: '0.6rem',
              textAlign: 'center'
            }}
            className={classes.font}
          >
            Revisa que tus datos sean correctos o intenta más tarde
          </Typography>
          <div className={classes.banner}>
            <Link component={Button} to="/checkout" className={classes.button} size="small">
              Ok
            </Link>
          </div>
        </Card>
      </Box>
    </Grid>
  );
};

export default Failure;
