/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Modal
} from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useState, useEffect, useContext } from 'react';
import PasswordInput from '../../components/PasswordInput';
import useValidField from '../../customHooks/useValidField';
import { FirebaseContext } from '../../customContext/FirebaseContext';
import images from '../../assets/images';
import styles from './styles';
import { signup } from '../../services/auth';
import noAuth from '../../customHocs/noAuth';

const SignUp = () => {
  const classes = styles();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mediaEmailError, setMediaEmailError] = useState(false);
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    lastName: '',
    password: '',
    mothersLastName: '',
    postalCode: '',
    dateOfBirth: '',
    gender: ''
  });
  const emailValidator = useValidField({ type: 'email' });
  const nameValidator = useValidField({ type: 'text' });
  const passwordValidator = useValidField({ type: 'password' });
  const confirmPasswordValidator = useValidField({ type: 'password' });
  const [isValidRecaptcha, setIsValidRecaptcha] = useState(true);
  const { type, helperText: passHelperText, error: passError, ...userPassword } = passwordValidator;
  const {
    type: confirmPass,
    helperText: confirmHelperText,
    error: confirmError,
    ...userConfirmPassword
  } = confirmPasswordValidator;
  const { loginFirebase, loginWithGoogle, loginWithFacebook } = useContext(FirebaseContext);
  const validate = (validator) => {
    if (validator.error) {
      return '';
    }
    return validator.value;
  };

  useEffect(() => {
    setUserData({
      ...userData,
      email: validate(emailValidator),
      password: validate(passwordValidator),
      name: validate(nameValidator)
    });
  }, [emailValidator.value, passwordValidator.value, nameValidator.value]);

  const makeSignUp = async () => {
    try {
      setIsLoading(true);
      if (userPassword.value !== userConfirmPassword.value) {
        setPasswordError(true);
        setEmailError(true);
        return;
      }
      await signup(userData);
      setIsLoading(false);
      const { email, password } = userData;
      await loginFirebase(email, password);

      // TODO signIn process and redirect to client dashboard
    } catch (error) {
      setIsLoading(false);
      // TODO error notification
    }
  };
  const isIncompleteData = () => {
    const { email, password, name } = userData;
    if (
      !name ||
      !email ||
      !password ||
      (password !== confirmPasswordValidator.value, !isValidRecaptcha)
    ) {
      return true;
    }

    return false;
  };

  const getPropsValidate = (validator) => {
    const { value, setValue, ...validationProps } = validator;
    return validationProps;
  };

  const onModal = () => {
    setOpen(!open);
  };

  const signUpGoogle = async () => {
    try {
      await loginWithGoogle();
      onModal();
    } catch (error) {
      if (
        error.toString() === 'FirebaseError: Firebase: Error (auth/popup-closed-by-user).' ||
        error.toString() === 'FirebaseError: Firebase: Error (auth/cancelled-popup-request).'
      ) {
        setOpen(false);
        return;
      }
      setOpen(false);
      setMediaEmailError(true);
    }
  };

  const signUpFacebook = async () => {
    try {
      await loginWithFacebook();
      onModal();
    } catch (error) {
      if (
        error.toString() === 'FirebaseError: Firebase: Error (auth/popup-closed-by-user).' ||
        error.toString() === 'FirebaseError: Firebase: Error (auth/cancelled-popup-request).'
      ) {
        setOpen(false);
        return;
      }
      setOpen(false);
      setMediaEmailError(true);
    }
  };
  const handleChangeRecaptcha = (value) => setIsValidRecaptcha(value);

  return (
    <Container maxWidth="xl" className={classes.fixContainer}>
      <Grid container className={classes.container}>
        <Modal className={classes.modal} open={open}>
          <Box className={classes.boxModal}>
            <img src={images.iSanaMonochrome} alt="isana-logo" />
            <CircularProgress />
          </Box>
        </Modal>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          <Grid container spacing={1} justifyContent="center" className={classes.form}>
            <Grid item md={12} sm={12} className={classes.boxFix}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  Registrarme
                </Typography>
              </Grid>
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12} md={6}>
                  <Button
                    className={classes.buttonGoogle}
                    onClick={signUpGoogle}
                    startIcon={<img src={images.google} alt="" />}
                  >
                    <Typography>Google</Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    className={classes.buttonFacebook}
                    onClick={signUpFacebook}
                    startIcon={<img className={classes.svg} src={images.facebook} alt="" />}
                  >
                    <Typography>Facebook</Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={12} lg={12}>
                <Collapse in={mediaEmailError} className={classes.errorEmailBox}>
                  <Box className={`${classes.input} ${classes.boxWarning}`}>
                    <Typography variant="body2" className={classes.warningText}>
                      <ErrorOutline />
                      Este correo ya está asociado a una cuenta
                    </Typography>
                  </Box>
                </Collapse>
                <Box className={classes.fixSeparator}>
                  <hr className={classes.textLine} />
                </Box>
              </Grid>
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Nombre"
                    variant="outlined"
                    size="small"
                    type="text"
                    className={classes.input}
                    {...getPropsValidate(nameValidator)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    size="small"
                    type="email"
                    className={classes.input}
                    {...getPropsValidate(emailValidator)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12} md={6}>
                  <PasswordInput
                    handlerField={getPropsValidate(userPassword)}
                    error={passError}
                    helperText={passHelperText}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PasswordInput
                    handlerField={getPropsValidate(userConfirmPassword)}
                    name="Confirmar contraseña"
                    error={confirmError}
                    helperText={confirmHelperText}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={passwordError} className={classes.errorEmailBox}>
                  <Box className={`${classes.input} ${classes.boxWarning}`}>
                    <Typography variant="body2" className={classes.warningText}>
                      <ErrorOutline />
                      Las contraseñas no coinciden
                    </Typography>
                  </Box>
                </Collapse>
                <Collapse in={emailError} className={classes.errorEmailBox}>
                  <Box className={`${classes.input} ${classes.boxWarning}`}>
                    <Typography variant="body2" className={classes.warningText}>
                      <ErrorOutline />
                      Este correo ya está asociado a una cuenta
                    </Typography>
                  </Box>
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.recaptcha}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA}
                    className={classes.recaptcha}
                    onChange={handleChangeRecaptcha}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.term}>
                  Al verificar aceptas los{' '}
                  <a
                    className={classes.link}
                    href="/terminos-y-condiciones-de-envío"
                    target="_blank"
                  >
                    términos y condiciones de envío
                  </a>{' '}
                  y estar de acuerdo con el{' '}
                  <a className={classes.link} href="/aviso-de-privacidad" target="_blank">
                    aviso de privacidad
                  </a>
                  .
                </Typography>
              </Grid>
              <Grid item xs={12} hidden>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Quiero poder recibir miles de artículos gratis con el círculo de la salud"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={makeSignUp}
                  disabled={isIncompleteData()}
                  className={classes.button}
                >
                  {isLoading && <CircularProgress size={25} className={classes.loadingIndicator} />}
                  Registrarme
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item sm={12} className={classes.banner}>
              <img src={images.QuestionsAnsawersbanner} alt="bag" className={classes.image} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default noAuth(SignUp);
