import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../theme';

const styles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bar: {
    backgroundColor: Colors.simpleWhite
  },
  tableFix: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  TableContainer: {
    marginTop: '1rem',
    maxWidth: '60rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  table: {
    border: 'none'
  },
  cellModal: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0.8rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  image: {
    width: '9rem',
    height: '9rem',
    [theme.breakpoints.down('md')]: {
      width: '8rem',
      height: '8rem'
    },
    [theme.breakpoints.down('sm')]: {
      width: '7rem',
      height: '7rem'
    },
    [theme.breakpoints.down('xs')]: {
      width: '6rem',
      height: '6rem'
    }
  },
  nameProduct: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.85rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  subName: {
    fontSize: '0.9rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.85rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem'
    }
  },
  buttonsCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
    justifyContent: 'center'
  },
  fabButton: {
    boxShadow: 'none',
    width: '2.2rem',
    height: '1.7rem'
  },
  fabIcon: {
    fontSize: '1rem'
  },
  quantity: {
    width: '2.3rem',
    height: '2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10rem',
    margin: '1rem'
  },
  tableCell: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  boxHidden: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  price: {
    fontWeight: 'bold'
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: Colors.blueHepatica,
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem'
    }
  },
  subTitle: {
    fontWeight: 'bold',
    textAlign: 'start',
    color: Colors.blueHepatica
  },
  info: {
    marginTop: '1rem'
  },
  fixText: {
    marginTop: '0.5rem'
  },
  boxInfo: {
    margin: '1rem'
  },
  subtitle: {
    marginTop: '3rem',
    fontWeight: 'bold',
    color: Colors.blueHepatica,
    fontSize: '1.2rem',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  infoData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  infoRow: {
    maxWidth: '53rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto'
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '20rem',
    background: Colors.simpleWhite,
    borderRadius: '0.5rem',
    border: '2px  #000',
    boxShadow: '2rem',
    padding: '1rem'
  },
  titleModal: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    padding: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem'
    }
  },
  textfield: {
    backgroundColor: Colors.simpleWhite,
    marginTop: '1rem'
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem'
  },
  textFixed: {
    marginTop: '1rem'
  }
}));

export default styles;
