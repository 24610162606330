import {
  Container, Grid
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import images from '../../assets/images';
import SectionCard from './SectionCard';
import styles from './styles';

const MyAccount = ({ customer }) => {
  const classes = styles();
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl">
      { !Object.keys(customer).length && navigate('/') }
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Grid
            className={classes.gridCards}
            container
          >
            <SectionCard
              title="Mis Pedidos"
              text="Revisa tus pedidos realizados y los que están en proceso."
              linkSection="/orders"
            />
            <SectionCard
              title="Mi Perfil"
              text="Configura tu perfil y editalo cuando quieras."
              image={images.phoneIcon}
              linkSection="/perfil"
            />
              <SectionCard
              title="Programas de lealtad"
              text="Consigue puntos, promociones y productos gratis."
              image={images.phoneIcon}
              linkSection="/programas-de-lealtad"
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

MyAccount.propTypes = {
  customer: PropTypes.shape({ name: PropTypes.string, lastName: PropTypes.string })
};

MyAccount.defaultProps = {
  customer: {}
};

const mapsStateToProps = (state) => {
  const { customer } = state.customer;
  return { customer };
};

export default connect(mapsStateToProps, null)(MyAccount);
