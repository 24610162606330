import { makeStyles } from '@material-ui/styles';
// eslint-disable-next-line import/named
import { Colors } from '../../../theme';

const styles = makeStyles((theme) => ({
  navSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  searchSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    width: '9rem',
    height: 'auto'
  },
  buttonNav: {
    background: Colors.honeyFlower,
    fontWeight: 'bold',
    color: Colors.simpleWhite,
    paddingRight: '1.2rem',
    paddingLeft: '1.2rem',
    borderRadius: '2rem',
    marginRight: '1rem',
    '&:hover': {
      background: Colors.electricViolet,
      color: 'white'
    },
    [theme.breakpoints.down('md')]: {
      marginRight: '0.5rem'
    }
  },
  fixLink: {
    borderRadius: 50,
    marginRight: '1.5rem',
    [theme.breakpoints.down('md')]: {
      marginRight: '1rem'
    }
  },
  link: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
    color: Colors.gray,
    textDecoration: 'none'
  },
  linkActive: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
    color: Colors.electricViolet,
    textDecoration: 'none',
    textTransform: 'none'
  },
  fixLinkLogin: {
    borderRadius: '1rem',
    marginLeft: '4rem',
    marginRight: '1.5rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1rem',
      marginRight: '1rem'
    }
  },
  linkLogin: {
    fontFamily: 'Quicksand',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    color: Colors.honeyFlower,
    textDecoration: 'none',
    textTransform: 'none'
  },
  fixLinkDrawer: {
    borderRadius: '2rem',
    marginTop: '1rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0
    }
  },
  buttonNavDrawer: {
    background: Colors.honeyFlower,
    color: Colors.simpleWhite,
    borderRadius: '2rem',
    marginTop: '1rem',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    '&:hover': {
      background: Colors.electricViolet,
      color: Colors.simpleWhite
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 0
    }
  },
  headerLinksContainer: {
    width: 'auto'
  },
  buttonMyAccount: {
    background: Colors.honeyFlower.length,
    borderRadius: '2rem',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    [theme.breakpoints.up('sm')]: {
      margin: '0 0.5rem'
    }
  },
  linkButton: {
    fontFamily: 'Quicksand',
    textTransform: 'none',
    color: Colors.simpleWhite,
    textDecoration: 'none',
    fontSize: '0.9rem'
  },
  buttonCar: {
    background: Colors.wildSand,
    margin: '.5rem'
  },
  badgeItems: {
    '& .MuiBadge-badge': {
      color: Colors.simpleWhite,
      background: Colors.radicalRed
    }
  },
  searchSmall: {
    marginBottom: '0.3rem',
    display: 'flex',
    justifyContent: 'center'
  },
  handlerCloseDiv: {
    backgroundColor: 'transparent',
    width: '100%',
    height: '70rem',
    position: 'absolute',
    zIndex: 5
  },
  menuDrawerMobile: {
    '& .MuiPaper-root': {
      padding: '0 .5rem'
    }
  },
  itemText: {
    fontSize: '0.8rem',
    marginLeft: '0.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
      marginLeft: '0.1rem'
    }
  },
  offset: theme.mixins.toolbar,
  containerMobileCustomerLinks: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem .5rem .5rem .5rem',
    alignItems: 'center'
  },
  containerDeskCustomerLinks: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0'
  },
  buttonLogout: {
    marginTop: '1rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: '0'
    }
  },
  menuLinksLink: {
    textDecoration: 'none'
  },
  menuButton: {
    margin: 0
  },
  menuItemsBox: {
    display: 'flex'
  },
  appBar: {
    minHeight: '5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

export default styles;
