import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../../theme';

const styles = makeStyles((theme) => ({
  containerClients: {
    background: Colors.lowGray,
    padding: '1rem'
  },
  boxFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflowX: 'hidden',
    marginTop: '2rem'
  },
  title: {
    fontFamily: 'Quicksand',
    fontSize: '1.4rem',
    textAlign: 'center',
    fontWeight: '600',
    paddingBottom: '1rem',
    color: Colors.balticSea
  },
  subTitle: {
    fontSize: '.8rem',
    color: Colors.scarpaFlow,
    textAlign: 'center',
    paddingBottom: '1rem',
    maxWidth: '50rem'
  },
  cardBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      padding: '3rem',
      overflowX: 'hidden',
      background: Colors.lowGray
    }
  },
  isanaButton: {
    backgroundColor: Colors.blueHepatica,
    color: Colors.simpleWhite,
    width: '29rem',
    borderRadius: '5rem',
    padding: '0.7rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    textDecoration: 'none',
    fontWeight: 'bold',
    fontSize: 'large',
    '&:hover': {
      background: Colors.purpleHeart
    },
    [theme.breakpoints.down('lg')]: {
      width: '26rem'
    },
    [theme.breakpoints.down('md')]: {
      width: '20.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      width: '17rem'
    }
  }
}));

export default styles;
