import axios from '../utils/axios';
import { API_URL_SHOPPING_CART } from '../constants';

/**
 * Function to update shopping cart
 * @param {object} id shopping cart Id selected
 * @param {object} data shopping cart data to upload
 * @param {object} action action to alter in shopping cart
 * @returns Promise<object>
 */
export async function updateShoppingCart(id, data, action) {
  const response = await axios.put(`${API_URL_SHOPPING_CART}/${id}`, { ...data, action });
  return response.data;
}

/**
 * Function to destroy shopping cart data
 * @param {string} id shopping cart mongo id
 * @returns Promise<boolean>
 */
export async function destroyShoppingCart(id) {
  const response = await axios.delete(`${API_URL_SHOPPING_CART}/${id}`);
  return response.data;
}

/**
 * Get shopping cart data by id
 * @param {string} id shopping cart id
 * @returns Promise<object>
 */
export async function getShoppingCartById(id) {
  const response = await axios.get(`${API_URL_SHOPPING_CART}/${id}`);
  return response.data;
}

/**
 * Function to get shopping cart by some integration
 * @param {string} id shopping cart id
 * @returns Promise
 */
export async function getShoppingCartBySomeIntegration(id) {
  const response = await axios.get(`${API_URL_SHOPPING_CART}/integration/${id}`);
  return response.data;
}

/**
 * Function to create shopping cart
 * @param {object} data shopping cart data
 * @returns Promise
 */
export async function createShoppingCart(data) {
  const response = await axios.post(API_URL_SHOPPING_CART, { ...data });
  return response.data;
}

/**
 * Send product request by customer
 * @param {object} data customer information and product id to request
 * @returns Promise<object>
 */
export const sendProductRequestInfo = async (data) => {
  const response = await axios.post(`${API_URL_SHOPPING_CART}/product-requests`, { ...data });
  return response.data;
};
