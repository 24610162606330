/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
import axios from 'axios';
import { GOOGLE_MAPS_API_KEY } from '../constants';

const logTag = '[geocodeLocation]';
const doLog = true;

// eslint-disable-next-line arrow-body-style
const geocodeLocation = (latitude, longitude) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`
        )
        .then((response) => {
          doLog && console.log(logTag, 'Success', response);
          resolve(response);
        })
        .catch((error) => {
          doLog && console.log(logTag, 'Fail!', error);
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
};

const geocodePlace = async (placeId) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=${GOOGLE_MAPS_API_KEY}`
    );
    if (!response) {
      doLog && console.log(logTag, 'Fail!');
    }
    return response.data;
  } catch (error) {
    doLog && console.log(logTag, 'Fail!', error);
  }
};

const parseGoogleAddress = (googleAddress) => {
  doLog && console.log(logTag, '[parseGoogleAddress]', googleAddress);

  const response = {};

  if (googleAddress.address_components && googleAddress.address_components.length > 0) {
    for (let i = 0, len = googleAddress.address_components.length; i < len; i++) {
      const item = googleAddress.address_components[i];

      for (let j = 0; j < item.types.length; j++) {
        switch (item.types[j]) {
          case 'street_number':
            response.streetNumber = item.long_name;
            break;
          case 'route':
            response.street = item.short_name;
            break;
          case 'sublocality':
            response.neighborhood = item.short_name;
            break;
          case 'locality':
            response.city = item.short_name;
            break;
          case 'administrative_area_level_1':
            response.state = item.long_name;
            break;
          case 'country':
            response.country = item.long_name;
            break;
          case 'postal_code':
            response.postalCode = item.long_name;
            break;
          default:
            break;
        }
      }
    }
  }
  response.lat = googleAddress.geometry.location.lat;
  response.lng = googleAddress.geometry.location.lng;
  return response;
};

const addressValidation = (address = {}) => {
  let addressString = 'Ubicación no disponible';
  let addressString2 = '';
  const isValid = false;

  if (!address || !Object.keys(address).length) {
    return { addressString, addressString2, isValid };
  }
  const {
    street,
    streetNumber,
    exteriorNumber = '',
    interior = '',
    neighborhood,
    postalCode = '',
    city = '',
    state = ''
  } = address;

  if (!street) {
    return { addressString, addressString2, isValid };
  }
  addressString = `${street} ${streetNumber || exteriorNumber}`;
  addressString =
    interior !== 'null' || !interior
      ? `${addressString}${interior ? `- Int. ${interior}` : ''}`
      : addressString;

  if (neighborhood) {
    addressString2 = `${addressString.trim()}, ${neighborhood}, ${postalCode} ${city} ${state} `;
  }

  return { addressString, addressString2, isValid: true };
};

const formatAddress = (address = {}) => {
  const {
    street = '',
    postalCode = '',
    exteriorNumber = '',
    streetNumber = '',
    neighborhood = '',
    city = '',
    state = '',
    interiorNumber = '',
    interior = ''
  } = address;
  let number = exteriorNumber || streetNumber;
  let numInterior = interiorNumber || interior;

  if (numInterior === 'null') {
    numInterior = '';
  }
  if (number === 'null') {
    number = 's/n';
  }

  const addressTemp = `${street} ${number}`;
  const addressMain = numInterior ? `${addressTemp} - Int. ${numInterior}` : addressTemp;
  const addressState = [neighborhood, city, state, postalCode].filter((e) => e).join(', ');
  const addressFull = `${addressMain}, ${addressState}`;

  return { addressMain, addressState, addressFull };
};

const formatAddressComponents = (response) => {
  if (!Array.isArray(response)) {
    return {};
  }
  return response.reduce((seed, { long_name, types }) => {
    types.forEach((t) => {
      // eslint-disable-next-line no-param-reassign
      seed[t] = long_name;
    });

    return seed;
  }, {});
};

const getDeliveryAddressInfo = (position) => {
  const { latitude = 0, longitude = 0, address = {} } = position;
  const {
    city = '',
    country = '',
    neighborhood = '',
    postalCode = 0,
    street = '',
    streetNumber = '',
    interior = ''
  } = address;

  return {
    city,
    country,
    neighborhood,
    postalCode,
    state: address.state,
    street,
    latitude,
    longitude,
    exteriorNumber: streetNumber,
    interiorNumber: interior
  };
};

export {
  geocodeLocation,
  geocodePlace,
  parseGoogleAddress,
  addressValidation,
  formatAddress,
  formatAddressComponents,
  getDeliveryAddressInfo
};
