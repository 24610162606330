import {  Grow, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import images from '../../../assets/images';
import ClientCard from '../../../components/ClientCard';
import styles from './styles';

const ClientsReview = ({ checked }) => {
  const classes = styles();
  return (
    <div className={classes.containerClients}>
      <div className={classes.boxFlex}>
        <Typography className={classes.title}>Lo que nuestros clientes dicen</Typography>
      </div>
      <Grow in={checked}>
        <div className={classes.cardBox}>
          <ClientCard
            image={images.mariana}
            name="Mariana"
            comment="Recomiendo iSana al 100%, siempre ahorro y recibo
            el mejor servicio. A mí me ha ido excelente con los
            medicamentos de mi hijo, siempre me ayudan a
            conseguir uno que es muy difícil, a un súper precio.
            ¡Hagan la comparación!"
          />
          <ClientCard
            image={images.marilu}
            name="Marilú"
            comment="Estoy encantada con iSana, ha sido una maravilla.
            Me consiguen los mejores precios en medicamentos
           y me evitan ir a varias tiendas, además su personal
           es muy eficiente y todos son muy amables.
           ¡Ha sido un gran cambio para mí!"
          />
          <ClientCard
            image={images.alejandro}
            name="Alejandro"
            comment="Estoy muy satisfecho con iSana. Su servicio es una gran
            opción para poder surtir una lista sin la complicación de
            visitar tantas tiendas. Se los recomiendo mucho.
            ¡Hace una gran diferencia!"
          />
        </div>
      </Grow>
    </div>
  );
};
ClientsReview.propTypes = {
  checked: PropTypes.bool
};

ClientsReview.defaultProps = {
  checked: false
};
export default ClientsReview;
