import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5.26rem',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem',
      marginTop: '4rem'
    }
  },
  form: {
    maxWidth: '24rem'
  },
  input: {
    marginTop: '1rem',
    width: '100%'
  },
  recaptcha: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '0.4rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: Colors.electricViolet,
    marginTop: '3rem'
  },
  image: {
    maxWidth: '30rem',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  controlBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem'
  },
  button: {
    backgroundColor: Colors.blueHepatica,
    color: Colors.simpleWhite,
    textDecoration: 'none'
  },
  boxWarning: {
    padding: '0.6rem',
    borderRadius: '0.3rem'
  },
  warningText: {
    fontWeight: 'bold',
    color: Colors.radicalRed,
    display: 'flex',
    alignItems: 'center'
  },
  buttonGoogle: {
    background: Colors.simpleWhite,
    boxShadow: 'none',
    width: '100%',
    border: 'solid',
    marginTop: '2rem'
  },
  buttonFacebook: {
    background: Colors.facebookColor,
    boxShadow: 'none',
    width: '100%',
    color: 'white',
    minHeight: '2.6em'
  },
  svg: {
    filter: 'invert(92%) sepia(93%) saturate(0%) hue-rotate(202deg) brightness(106%) contrast(106%)'
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    flexDirection: 'column',
    background: `linear-gradient(to bottom right, ${Colors.electricViolet}, ${Colors.heliotrope})`
  },
  boxModal:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    flexDirection: 'column'
  }
}));
export default styles;
