import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../theme';

const styles = makeStyles((theme) => ({
  icon: {
    color: Colors.cornflowerBlue
  },
  empty: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '7rem',
    padding: '0.3rem'
  },
  emptyText: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: Colors.cornflowerBlue
  },
  fixLinkDrawer: {
    borderRadius: '2rem',
    marginLeft: '3rem',
    marginRight: '3rem',
    marginTop: '1rem'
  },
  buttonCar: {
    margin: '0.5rem'
  },
  badgeItems: {
    '& .MuiBadge-badge': {
      color: Colors.simpleWhite,
      background: Colors.radicalRed
    }
  },
  cartView: {
    position: 'relative'
  },
  modal: {
    position: 'absolute',
    zIndex: 10,
    marginLeft: '6rem',
    width: '20rem',
    left: '-25vw',
    top: '2rem',
    padding: '1rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0rem'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '3.2rem'
    },
    [theme.breakpoints.down('xs')]: {
      left: '-16rem'
    }
  },
  cardBox: {
    height:'auto',
    overflow: 'auto',
    marginBottom: '1rem'
  },
  button: {
    borderRadius: '5rem',
    marginTop: '0.5rem',
    padding: '0.4rem 0rem'
  },
  buttonLinkCheckout: {
    textDecoration: 'none'
  },
  cart: {
    padding: '0.3rem',
    height:'100%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between'
  },
  infoTotalBox: {
    display:'flex',
    flexDirection:'column'
  }
}));

export default styles;
