import { Container, Typography } from '@material-ui/core';
import React from 'react';
import { scrollTop } from '../../helper';
import styles from './styles';

const PrivacyPolicy = () => {
  const hello = 'Aviso de privacidad';
  const classes = styles();
  scrollTop()
  return (
    <Container maxWidth="xl">
      <div className="row">
        <div className={classes.content}>
          <Typography className={classes.title} variant="h6" align="center">{hello}</Typography>
          <Typography align="justify" className={classes.row}>
            <strong>
              Aviso de Privacidad Simple (APS)
            </strong>
            {' '}
            <br />
            iSana Health Tech SAPI de CV con domicilio en CALLE LAVA 208 INT 5,
            Jardines Del Moral 37160 León, Guanajuato, México, en su cumplimiento
            con lo dispuesto por la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares, hace de su conocimiento que es responsable
            de recabar sus datos personales, del uso que se dé a los mismos y de su
            protección, y que sus datos personales, incluso los sensibles y los
            patrimoniales o financieros que se recaben o generados con motivo de la
            relación jurídica que tengamos celebrada, o que en su caso, se celebre,
            se tratarán para todos los fines vinculados con dicha relación, tales
            como; identificación, operación, administración, análisis, ofrecimiento
            y promoción. Si requiere más información puede acceder a nuestro aviso
            de completo a través de WWW.ISANATE.COM/AP.
          </Typography>
          <Typography align="justify" className={classes.row}>
            <strong>
              Aviso de Privacidad Completo (APC)
            </strong>

            {' '}
            <br />
            El presente documento constituye el Aviso de Privacidad Completo, en lo
            sucesivo APC, de iSana Health Tech SAPI de CV así como sus subsidiarias,
            afiliadas, controlador y demás empresas relacionadas, (en lo sucesivo
            “EL RESPONSABLE”), con domicilio para oír y recibir notificaciones y
            solicitudes en calle con domicilio en CALLE LAVA 208 INT 5, Jardines Del
            Moral 37160 León, Guanajuato, México, mismo que se pone a disposición de
            Usted (“EL TITULAR”) previo a la obtención y tratamiento de sus datos
            personales; de igual forma se pone a su disposición el correo
            electrónico siguiente: Ayuda@isana.mx
          </Typography>
          <Typography align="justify">
            Los términos que no cuenten con una definición específica en este APC
            tendrán los significados que para tal efecto se establezcan en la Ley
            Federal de Protección de Datos Personales en Posesión de los
            Particulares (”LA LEY”) y su Reglamento.
          </Typography>
          <Typography align="justify" className={classes.row}>
            <strong>I.OBJETO.</strong>
            {' '}
            Este APC regula la forma, términos y condiciones conforme a
            los cuales EL RESPONSABLE incluyendo a sus empresas subsidiarias y
            empresas relacionadas, están facultadas y autorizadas por EL TITULAR
            para obtener, tratar y transferir los datos personales (en lo sucesivo
            los “Datos”) del EL TITULAR.
          </Typography>
          <Typography align="justify" className={classes.row}>
            <strong> I.EXCLUSIONES.</strong>
            {' '}
            El sitio web del EL RESPONSABLE puede incluir enlaces a
            sitios web de terceras personal (en lo sucesivo “TERCEROS”), los cuales
            no tienen relación alguna con EL RESPONSABLE. En caso de que EL TITULAR
            obtenga acceso a dichos enlaces, abandonará el sitio web de EL
            RESPONSABLE. Por lo cual EL RESPONSABLE. no asume ninguna
            responsabilidad en relación con esos sitios web de TERCEROS.
          </Typography>
          <Typography align="justify">
            Si publica, comenta o comparte información o datos personales, incluidas
            fotografías, en cualquier foro público, red social, blog u otro foro de
            este tipo, debe tener en cuenta que cualquier información personal que
            publique la podrán leer, ver, recabar o utilizar otros usuarios de
            dichos foros, y se podría utilizar para ponerse en contacto con usted,
            enviarle mensajes no solicitados o con fines que ni EL TITULAR ni EL
            RESPONSABLE pueden controlar. EL RESPONSABLE no lo será respecto de la
            información personal y/o datos que EL TITULAR decida enviar a estos
            foros o redes sociales.
          </Typography>
          <Typography align="justify" className={classes.row}>
            <strong> III.CONSENTIMIENTO.</strong>
            {' '}
            EL TITULAR en este acto manifiesta bajo protesta de
            decir vedad que a través de la Aplicación electrónica o sitio web
            www.isanate.com/AP se percató del Aviso de Privacidad Simplificado
            (“APS”) y que al entrar a la aplicación y dar “clic” en “Aceptar los
            Términos y Condiciones” y el correspondiente APS, fue remitido al
            presente APC, posterior a esto, se le pidió a EL TITULAR el
            consentimiento expreso, una vez obtenida la autentificación de EL
            TITULAR por firma autógrafa, firma electrónica o por medio de
            vinculación a una cuenta de correo específica o de una red social que
            permita su veraz identidad, para que EL RESPONSABLE realice el
            tratamiento de sus “Datos”, de conformidad con este APC. EL TITULAR en
            todo momento tiene derecho de acceder, rectificar y cancelar sus
            “Datos”, así como de oponerse al tratamiento de estos o revocar el
            consentimiento que para tal fin nos haya otorgado, a través de los
            procedimientos que se establecen en este APC.
          </Typography>
          <Typography align="justify" className={classes.row}>
            <strong> IV.DATOS SENSIBLES.</strong>
            {' '}
            EL TITULAR, por la naturaleza del servicio a recibir
            por parte de EL RESPONSABLE, deberá proporcionar a EL RESPONSABLE de
            ciertos datos personales sensibles (En lo sucesivo DPS), es decir,
            aquellos datos personales íntimos o cuya utilización debida o indebida
            pudiera dar origen a discriminación o conlleve un riesgo grave para
            éste, los cuales son en particular: (i) origen racial o étnico; (ii)
            estado de salud presente y futuro; e (iii) información genética. No
            obstante lo anterior, y como regla general, EL RESPONSABLE se obliga a
            no solicitarle a EL TITULAR ningún otro DPS a los anteriores y que no
            vayan acorde a la finalidad de los servicios a prestar por parte de EL
            RESPONSABLE hacia EL TITULAR como lo pudiesen ser: creencias religiosas,
            filosóficas y morales, afiliación sindical, opiniones políticas y/o
            preferencia sexual. Asimismo, EL RESPONSABLE declara que deberán de
            crearse una base de datos sobre los DPS de EL TITULAR, el cual servirá
            para tener un registro de salud, así como de las enfermedades que EL
            TITULAR pudiera tener, o bien, de los medicamentos que éste necesita,
            con el fin último de brindarle un mejor servicio, procurar la salud de
            EL TITULAR y estar a la vanguardia de nuevos medicamentos o tratamientos
            que pudieren beneficiar a EL TITULAR. EL TITULAR podrá revocar en
            cualquier momento su consentimiento acerca del tratamiento de DPS, al
            notificarlo al área prevista en el apartado X) del presente APC.
          </Typography>
          <Typography align="justify" className={classes.row}>
            <strong>V.OBTENCIÓN DE DATOS. </strong>
            EL RESPONSABLE podrá obtener los “Datos” del EL
            TITULAR mediante su ingreso directo a la Aplicación de Internet de EL
            RESPONSABLE o mediante el envío de archivos electrónicos o de cualquier
            otra naturaleza, incluyendo de forma enunciativa mas no limitativa,
            entrega directa de información, acceso a la Aplicación de Internet de EL
            RESPONSABLE. En caso de uso de, o acceso a nuestros sitios de Internet u
            otros medios electrónicos, le informamos que es posible que nuestros
            sistemas recopilen “Datos” de EL TITULAR tales como tipo de navegador,
            sistema operativo, páginas de Internet visitadas, dirección IP, etc., a
            través “cookies” o “web-beacons”, entre otros. Las cookies son archivos
            de texto que son descargados automáticamente y almacenados en el disco
            duro del equipo de cómputo del usuario al navegar en una página de
            Internet específica, que permiten recordar al servidor de Internet
            algunos “Datos” sobre este usuario, entre ellos, sus preferencias para
            la visualización de las páginas en ese servidor, nombre y contraseña.
            Por su parte, las web-beacons son imágenes insertadas en una página de
            Internet o correo electrónico, que puede ser utilizado para monitorear
            el comportamiento de un visitante, como almacenar información sobre la
            dirección IP del usuario, duración del tiempo de interacción en dicha
            página y el tipo de navegador utilizado, entre otros. EL TITULAR acepta
            que EL RESPONSABLE también podrá obtener “Datos” del EL TITULAR de otros
            medios, como información que se obtenga de otras fuentes comercialmente
            disponibles, como por ejemplo directorios telefónicos, directorios
            laborales, tarjetas de presentación, bases de datos públicas, etc., en
            cuyo caso, y en caso de no estar de acuerdo con lo anterior, tendrá la
            posibilidad de comunicarse con EL RESPONSABLE para manifestar su
            oposición e inconformidad con el tratamiento de sus “Datos”. Toda la
            información y “Datos” que recopilamos sobre EL TITULAR pueden combinarse
            para los fines permitidos conforme a este “APC”.
          </Typography>
          <Typography align="justify" className={classes.row}>
            <strong>VI.USO DE DATOS. </strong>
            {' '}
            EL TITULAR entiende, acepta y reconoce que los “Datos”
            que se recabarán y los fines para los que serán usados son los
            siguientes: (i) proveer a EL TITULAR de servicios de salud relacionados
            con su tratamiento o medicamentos; (ii) compartirlos con doctores,
            enfermeras, laboratorios farmacéuticos, distribuidores, técnicos, u otro
            personal, para conseguir los medicamentos o servicios que EL TITULAR
            requiera; (iii) para realizar facturas de los pagos por medicamentos o
            tratamientos, así como compartir el plan de salud necesario con las
            aseguradoras para recibir pagos por cuestiones de pólizas de seguro;
            (iv) para enviar recordatorios de que EL TITULAR tiene prescripciones
            pendientes con EL RESPONSABLE, (v) en caso de que EL TITULAR no pueda
            valerse por sí mismo, o dependa económica o completamente de otra
            persona, compartirle la información pertinente a dicha persona que se
            registre como responsable “Cuidador” de EL TITULAR o paciente; e (vi)
            investigación para poder comprobar la eficacia de medicamentos o
            tratamientos siguiendo la legislación o procedimientos institucionales
            adecuados para dicha investigación.
          </Typography>
          <Typography align="justify">
            De manera enunciativa, más no limitativa, los “Datos” que se podrían
            recabar y en su caso tratar son los siguientes:
          </Typography>
          <ul>
            <li>A.Nombre Completo / Razón Social</li>

            <li>B.Correo electrónico.</li>

            <li>C.Domicilio Particular</li>

            <li>D.Domicilio Fiscal</li>

            <li>E.Registro Federal de Contribuyentes (RFC)</li>

            <li>F.Teléfono, celular y/o fax.</li>

            <li>G.Fotografía (en su caso)</li>

            <li>
              H.Información sobre grados académicos, lo relativo a historial
              académico y laboral (empleos anteriores) y datos relativos a interés
              en puestos de trabajo, incluyendo aptitudes y capacidades, datos de
              contacto, nivel socioeconómico y pretensiones laborales (en su caso).
            </li>
            <li>
              I.Número de cuenta bancaria e información patrimonial (en su caso)
            </li>

            <li>J.Ingresos (en su caso)</li>

            <li>K. Cuenta de Facebook (en su caso)</li>

            <li>L. Estado Civil</li>

            <li>M. Estado de Salud</li>

            <li>N. Fecha de Nacimiento</li>

            <li>O. Raza o etnia</li>

            <li>P. Condiciones Médicas</li>

            <li>Q. Enfermedades Temporales, Crónicas y/o Terminales</li>

            <li>R. Información Genética</li>

            <li>S. Embarazo</li>

            <li>T. Alergias</li>
          </ul>
          <Typography align="justify">
            En caso de no contar con esta información no estaríamos en posibilidad
            de proporcionarle los servicios que se ofrecen. Los fines para los que
            se usará su información, además de los mencionados en el primer párrafo,
            incluyen:
          </Typography>
          <ul>
            <li>
              Mejora del proceso de facturación. Lo cual incluye (i) Procurar un
              servicio eficiente y proveer una mejor atención al usuario, así como
              para mejorar su experiencia en el proceso de facturación; (ii)
              Enviarle y presentarle bienes y/o servicios que puedan resultar
              relevantes o atractivos, incluyendo su participación en promociones,
              ofertas y campañas de publicidad; (iii) Informar sobre cambios o
              nuevos productos o servicios que estén relacionados con el contratado
              o adquirido por el cliente; (iv) Dar cumplimiento a obligaciones
              contraídas con nuestros clientes; (v) Evaluar la calidad del servicio,
              o realizar estudios internos sobre hábitos de consumo. (vi) Mandar
              facturas de los datos proporcionados a una base de datos que manejará
              EL RESPONSABLE (vii) Capturar, administrar y organizar la información
              de la manera que EL RESPONSABLE esté especulando.
            </li>
            <li>
              Información de contacto o laboral. Lo cual incluye (i) Promoción de
              oferta laboral; Procesos de contratación laboral o de prestación de
              servicios independientes; (iii) Para efecto de proporcionar
              referencias en caso de que otra persona o empresa solicite informes
              sobre posibles candidatos a un puesto, trabajo o comisión; (iv) Para
              efecto de publicarlos en caso de que se requiera como parte de una
              estrategia de oferta de recursos humanos. •Seguimiento a una solicitud
              de crédito. Lo cual incluye (i) la verificación de cierta información
              tendiente al análisis y viabilidad del otorgamiento del crédito a
              favor del EL TITULAR (ii) Verificación de sus antecedentes
              crediticios. (iii) Verificación de ciertas referencias personales o
              con diversas instituciones de crédito. Etc.
            </li>
          </ul>
          <Typography align="justify" className={classes.row}>
            <strong>VII. ALMACENAMIENTO, DIVULGACIÓN Y TRANSFERENCIA DE LOS DATOS.</strong>
            Los
            “Datos” pueden transferirse, almacenarse y procesarse en un país
            distinto de donde se proporcionaron, en cuyo caso transferimos la
            información de conformidad con las leyes de protección de la información
            aplicables. EL TITULAR entiende y acepta que EL RESPONSABLE está
            autorizado para usar, almacenar, divulgar, transferir, así como para
            utilizar o de cualquier otra forma comercializar o transferir los
            “Datos”. En este caso EL RESPONSABLE celebrará contratos con “Empresas
            de Facturación, Publicidad” a los que proporcione los “Datos”, en los
            que se incorporarán las restricciones y demás términos y condiciones de
            este APC. Si usted no acepta que sus datos estén sujetos a
            transferencia, entonces debe omitir entregar cualquier “Dato”. Si usted
            no manifiesta su oposición o negativa para que sus “Datos” sean
            transferidos, se entenderá que ha otorgado su consentimiento para ello.
            Lo anterior no aplica para los DPS, para dichos datos personales
            sensibles, EL TITULAR deberá dar su consentimiento expreso a EL
            RESPONSABLE para que éste pueda compartirlo. Independientemente de lo
            mencionado, EL RESPONSABLE también podrá proporcionar parte de los
            “Datos” a proveedores de bienes o servicios que requieren conocer esta
            información. Entre esos proveedores se incluyen, de forma enunciativa
            más no limitativa, los procesadores de tarjetas de crédito, los
            proveedores de servicio de asistencia al cliente y de soporte
            proporcionado en tiempo real, marketing, los proveedores de servicios de
            correo electrónico, procesadores de datos automatizados y
            transportistas. Dichos proveedores de servicios están obligados, por
            contrato, a mantener la confidencialidad de los “Datos” conforme a este
            APC.
          </Typography>
          <Typography align="justify" className={classes.row}>
            <strong> VIII. ALMACENAMIENTO DE DATOS. </strong>
            {' '}
            Una vez recibidos los “Datos”, éstos
            serán procesados y almacenados en una base de datos que puede ser
            administrada por EL RESPONSABLE o por un TERCERO, dentro o fuera del
            país donde fueron proporcionados los “Datos” con el cual EL RESPONSABLE
            tenga un contrato para dichos efectos.
          </Typography>
          <Typography align="justify">
            EL RESPONSABLE incorporará mecanismos de protección a fin de evitar (en
            la medida de lo posible) desviaciones, adulteración, pérdida, consulta o
            tratamiento no autorizado de los “Datos”, y en caso de detectar alguna
            situación de las referidas en este párrafo, lo hará del conocimiento de
            EL TITULAR de la información que haya sido vulnerada, a efecto que éste
            último tome las medidas precautorias que estime necesarias. En el caso
            de los DPS, EL RESPONSABLE se obliga a tomar medidas más rigurosas para
            la protección y salvaguarda de dichos datos personales sensibles.
          </Typography>
          <Typography align="justify" className={classes.row}>
            <strong>  IX.CONFIDENCIALIDAD. </strong>
            EL RESPONSABLE no revelará los “Datos” a terceros
            no autorizados salvo cuando dicha divulgación sea requerida por ley o
            mandamiento de autoridad, o por el propio TITULAR.
          </Typography>
          <Typography align="justify" className={classes.row}>
            <strong> X.ACCESO, RECTIFICACIÓN. </strong>
            EL TITULAR tendrá derecho para solicitar a EL
            RESPONSABLE en cualquier momento el acceso, rectificación, cancelación u
            oposición respecto de sus “Datos”, para lo cual deberá hacerlo mediante
            alguno de los siguientes:
          </Typography>
          <ul>
            <li>a. La aplicación móvil y/o el sitio web.</li>
            <li>
              b. Correo postal o electrónico al Departamento Especializado de Datos
              Personales, dependiente del área de Recursos Humanos ubicado en CALLE
              LAVA 208 INT 5, Jardines Del Moral 37160 León, Guanajuato, México.
            </li>
            <li>c. Al correo electrónico Ayuda@isana.mx</li>
          </ul>
          <Typography align="justify">
            La solicitud de acceso, rectificación, cancelación u oposición deberá
            contener y acompañar lo siguiente:
          </Typography>
          <ul>
            <li>
              El nombre del EL TITULAR y domicilio u otro medio para comunicarle la
              respuesta a su solicitud.
            </li>
            <li>
              Los documentos que acrediten la identidad o, en su caso, la
              representación legal de EL TITULAR
            </li>
            <li>
              La descripción clara y precisa de los datos personales respecto de los
              que se busca ejercer alguno de los derechos antes mencionados, y
              cualquier otro elemento o documento que facilite la localización de
              los datos personales de EL TITULAR.
            </li>
          </ul>
          <Typography align="justify">
            EL RESPONSABLE tendrá un plazo máximo de 7 días hábiles para atender su
            petición y se le informará a EL TITULAR sobre la procedencia de esta a
            través la misma aplicación móvil, del sitio web o por el correo
            previamente señalado.
          </Typography>
          <Typography align="justify" className={classes.row}>
            <strong> XI.CANCELACIÓN O REVOCACIÓN. </strong>
            En caso de solicitudes de cancelación total
            de “Datos” o revocación del consentimiento, las mismas deberán
            realizarse de conformidad con lo señalado en el apartado anterior. La
            cancelación de “Datos” dará lugar a un periodo de bloqueo tras el cual
            se procederá a la supresión del Dato. EL RESPONSABLE podrá conservar los
            “Datos” exclusivamente para efectos de las responsabilidades nacidas del
            tratamiento. Cuando los “Datos” hubiesen sido transmitidos con
            anterioridad a la fecha de rectificación o cancelación y sigan siendo
            tratados por terceros, E L RESPONSABLE” deberá hacer de su conocimiento
            dicha solicitud de rectificación o cancelación, sin ninguna otra
            obligación adicional de su parte. EL RESPONSABLE no estará obligado a
            cancelar los “Datos” cuando:
          </Typography>
          <ul>
            <li>Deban ser tratados por disposición legal;</li>
            <li>
              Obstaculice actuaciones judiciales o administrativas vinculadas a
              obligaciones fiscales, la investigación y persecución de delitos o la
              actualización de sanciones administrativas;
            </li>
            <li>
              Sean necesarios para proteger los intereses jurídicamente tutelados
              del EL TITULAR
            </li>
            <li>
              Sean necesarios para realizar una acción en función del interés
              público;
            </li>
            <li>
              Sean necesarios para cumplir con una obligación legalmente adquirida.
            </li>
          </ul>
          <Typography align="justify" className={classes.row}>
            <strong> XII.MODIFICACIONES. </strong>
            EL RESPONSABLE se reserva el derecho de efectuar
            en cualquier momento, modificaciones en las presentes políticas de
            privacidad y adaptarla a novedades legislativas, jurisprudenciales,
            así como a prácticas del mercado. EL TITULAR está de acuerdo y
            conforme en que cualquier cambio a este APC o a las políticas de
            privacidad se notifique mediante su publicación en la página de
            www.isanate.com/AP. Es obligación de EL TITULAR visitar periódicamente
            dicho sitio a fin de verificar la versión más actual del Aviso de
            Privacidad Completo.
          </Typography>
          <Typography align="justify" className={classes.row} style={{ marginBottom: '2rem' }}>
            La política de privacidad y los Términos y condiciones están sujetos a
            cambios sin previo aviso.
          </Typography>
        </div>
      </div>
    </Container>

  );
};
export default PrivacyPolicy;
