import { Box, CircularProgress, Modal } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import images from '../../assets/images';
import styles from './styles';

const ModalLogoIsana = ({ open }) => {
  const classes = styles();

  return (
    <Modal className={classes.modal} open={open}>
      <Box className={classes.boxModal}>
        <img src={images.iSanaMonochrome} alt="isana-logo" />
        <CircularProgress />
      </Box>
    </Modal>
  );
};

ModalLogoIsana.propTypes = {
  open: PropTypes.bool
};

ModalLogoIsana.defaultProps = {
  open: false
};

export default ModalLogoIsana;
