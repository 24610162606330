import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../theme';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '21rem',
    alignItems: 'center'
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: '21rem',
    margin: '1rem auto 1rem auto',
    padding: '2rem',
    background: Colors.lowGray,
    borderRadius: '0.5rem',
    [theme.breakpoints.down('md')]: {
      width: '19rem',
      padding: '1rem',
      margin: '2rem'
    }
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: Colors.blueHepatica
  },
  priceBox: {
    backgroundColor: Colors.carteBlanche,
    padding: '1rem',
    borderRadius: '0.5rem'
  },
  totalPrice: {
    color: Colors.blueHepatica,
    fontWeight: 'bold'
  },
  shippingPrice: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '0.6rem'
  },
  note: {
    marginTop: '-0.2rem',
    marginBottom: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: Colors.blueViolet,
    fontSize: '0.8rem'
  },
  calculatePrice: {
    color: Colors.blueHepatica,
    marginLeft: '0.2rem'
  },
  button: {
    width: '21rem',
    maxWidth: '21rem',
    backgroundColor: Colors.blueHepatica,
    color: Colors.simpleWhite,
    marginTop: '-3rem',
    [theme.breakpoints.down('md')]: {
      width: '19rem'
    }
  },
  finalPrice: {
    padding: '0.3rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem'
  },
  fixMargin: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center'
  },
  circularProgress: {
    width: '1rem',
    height: '1rem'
  }
}));
export default styles;
