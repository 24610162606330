import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  buttonsCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
    justifyContent: 'center'
  },
  fabButton: {
    boxShadow: 'none',
    width: '2.2rem',
    height: '1.7rem'
  },
  fabIcon: {
    fontSize: '1rem'
  },
  quantity: {
    width: '2.3rem',
    height: '2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10rem',
    margin: '1rem'
  }
}));
export default styles;
