import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useAgreementCondition = () => {
    const [indicator, setIndicator] = useState(true)
    const selector = useSelector(state =>  state.shoppingCart)
    useEffect(() => {
        const {shoppingCartData} = selector
        if (shoppingCartData) {
            const { metadata } = shoppingCartData
            if (!metadata) {
                setIndicator(true)
                return 
            }
            const isValid = metadata && metadata.originServices === 'medikit' && metadata.paymentIndicator === 2
            setIndicator(!isValid)
        }
    }, [selector])

    return indicator
}


export default useAgreementCondition
