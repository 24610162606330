import {
  Card, Grid, Typography
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles';
import images from '../../../assets/images';

const SectionCard = ({
  title, text, image, linkSection
}) => {
  const classes = styles();
  return (
    <Grid item xs={12} sm={12} md={4}>
      <Link to={linkSection} className={classes.link}>
        <Card className={classes.card}>
          <div className={classes.imageBox}>
            <img className={classes.image} src={image || images.packageIcon} alt="" />
          </div>
          <div className={classes.info}>
            <Typography className={classes.title}>
              {title}
            </Typography>
            <Typography className={classes.text}>
              {text}
            </Typography>
          </div>
        </Card>
      </Link>
    </Grid>
  );
};
SectionCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  linkSection: PropTypes.string,

  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.any
};

SectionCard.defaultProps = {
  title: 'Título',
  text: 'Texto',
  linkSection: '/account',
  image: null
};
export default SectionCard;
