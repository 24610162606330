import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableFix: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bar: {
    backgroundColor: Colors.simpleWhite
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: Colors.blueHepatica,
    marginTop: '2rem'
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    padding: '2rem',
    justifyContent: 'center'
  },
  rowGroup: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2rem',
    justifyContent: 'center'
  },
  button: {
    backgroundColor: Colors.blueHepatica,
    color: Colors.simpleWhite,
    height: '2.3rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.3rem'
    },
    '&:hover': {
      backgroundColor: Colors.electricViolet
    },
    fontSize: '0.8rem'
  },
  buttonIconBack: {
    backgroundColor: Colors.blueHepatica,
    color: Colors.simpleWhite,
    marginLeft: '1rem',
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.3rem',
      marginTop: '6rem'
    },
    '&:hover': {
      backgroundColor: Colors.electricViolet
    }
  },
  stepper: {
    position: 'fixed',
    zIndex: '10',
    top: '4rem',
    width: '97%',
    maxWidth: '115rem',
    paddingTop: '5rem',
    borderBottom: 'solid 1px #F1F1F1',
    [theme.breakpoints.down('md')]: {
      width: '93%',
      paddingTop: '3rem',
      marginTop: '1.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4rem'
    },
    [theme.breakpoints.down('xs')]: {
      width: '93%',
      paddingTop: '4rem',
      marginTop: '1rem'
    }
  },
  spacer: {
    marginTop: '5rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '5rem'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '5rem'
    }
  },
  spacerDiv: {
    marginTop: '5rem'
  },
  detailSmall: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  imageIcon: {
    width: '8rem',
    height: '10rem',
    marginBottom: '-2rem'
  },
  imageCdso: {
    width: '4rem',
    height: '4rem'
  },
  boxCdso: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center'
  },
  dialogCdso: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    height: '100%',
    width: '100%',
    background: 'white'
  },
  loadingCdso: {
    marginTop: '-8rem'
  },
  buttonText: {
    fontSize: '1rem'
  },
  buttonsFlow: {
    display: 'flex',
    justifyContent: 'center'
  },
  boxBack: {
    marginLeft: '1rem',
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    flexDirection: 'row'
  },
  form: {
    '& button': {
      background: Colors.blueHepatica,
      textTransform: 'uppercase',
      fontSize: '0.8rem'
    }
  },
  imageIsanaWomen: {
    width: '18rem',
    height: '18rem'
  },
  imagebox: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      alignContent: 'center'
    }
  },
  imageBag: {
    marginLeft: '8rem',
    marginTop: '2rem',
    marginBottom: '3rem',
    width: '12rem',
    height: '12rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0rem',
      marginTop: '2rem'
    }
  },
  contactBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  contactText: {
    margin: '1rem',
    fontWeight: 'bold'
  },
  questions: {
    marginTop: '0.5rem'
  }
}));

export default styles;
