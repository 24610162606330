import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  box: {
    marginTop: '5rem'
  },
  boxSpinner: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '35rem',
    flexDirection: 'column'
  },
  text: {
    marginTop: '1rem'
  }
}));
export default styles;
