/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import styles from './styles';
import useValidField from '../../../customHooks/useValidField';
import LocationCheck from './LocationCheck';
import SearchPlaceInput from '../../../components/SearchPlaceInput';
import { parseGoogleAddress } from '../../../utils/googleMaps';

const Delivery = ({ onSetAddressData, shoppingCartData, customer }) => {
  const classes = styles();
  const emailValidator = useValidField({ type: 'email', skip: true });
  const nameValidator = useValidField({ type: 'text' });
  const phoneNumber = useValidField({ type: 'number', phone: true });
  const postalCode = useValidField({ type: 'number' });
  const state = useValidField({ type: 'text' });
  const city = useValidField({ type: 'text' });
  const street = useValidField({ type: 'text' });
  const neighborhood = useValidField({ type: 'text' });
  const exteriorNumber = useValidField({ type: 'text' });
  const interiorNumber = useValidField({ type: 'text', skip: true });
  const addressNote = useValidField({ type: 'text', skip: true });
  const [searchValue, setSearchValue] = useState(null);
  const [loadingDeliveryValues, setLoadingDeliveryValues] = useState(false);
  const validate = (validator) => validator.value ?? '';

  const getPropsValidate = (validator) => {
    const { setValue, ...validationProps } = validator;
    return validationProps;
  };

  const putValueData = (validator, value) => {
    const { setValue } = validator;
    setValue(value ?? '');
  };

  const checkButtonOnChanges = (id) => {
    if (id) {
      const location = customer.locations.filter((item) => item._id === id);
      putValueData(emailValidator, customer.email);
      putValueData(nameValidator, customer.name);
      putValueData(phoneNumber, customer.phoneNumber);
      putValueData(street, location[0].street);
      putValueData(state, location[0].state);
      putValueData(interiorNumber, location[0].interiorNumber);
      putValueData(exteriorNumber, location[0].exteriorNumber);
      putValueData(city, location[0].city);
      putValueData(neighborhood, location[0].neighborhood);
      putValueData(addressNote, location[0].locationIndications);
      putValueData(postalCode, location[0].postalCode);
    }
  };

  useEffect(() => {
    onSetAddressData({
      email: validate(emailValidator),
      name: validate(nameValidator),
      street: validate(street),
      interiorNumber: validate(interiorNumber),
      exteriorNumber: validate(exteriorNumber),
      city: validate(city),
      addressNote: validate(addressNote),
      state: validate(state),
      postalCode: validate(postalCode),
      phoneNumber: validate(phoneNumber),
      neighborhood: validate(neighborhood)
    });
  }, [
    emailValidator.value,
    nameValidator.value,
    street.value,
    interiorNumber.value,
    exteriorNumber.value,
    city.value,
    addressNote.value,
    state.value,
    postalCode.value,
    phoneNumber.value,
    neighborhood.value
  ]);

  const setExistingShoppingCartData = () => {
    const { deliveryAddress } = shoppingCartData;
    const { deliveryContact } = shoppingCartData;

    if (
      deliveryContact.name ||
      deliveryContact.phoneNumber ||
      deliveryAddress.street ||
      deliveryAddress.city ||
      deliveryAddress.postalCode ||
      deliveryAddress.state ||
      deliveryAddress.exteriorNumber ||
      deliveryAddress.neighborhood
    ) {
      putValueData(emailValidator, deliveryContact.email);
      putValueData(nameValidator, deliveryContact.name);
      putValueData(phoneNumber, deliveryContact.phoneNumber);
      putValueData(street, deliveryAddress.street);
      putValueData(state, deliveryAddress.state);
      putValueData(interiorNumber, deliveryAddress.interiorNumber);
      putValueData(exteriorNumber, deliveryAddress.exteriorNumber);
      putValueData(city, deliveryAddress.city);
      putValueData(neighborhood, deliveryAddress.neighborhood);
      putValueData(addressNote, deliveryAddress.addressNote);
      putValueData(postalCode, deliveryAddress.postalCode);
    }
  };

  useEffect(() => {
    if (shoppingCartData.deliveryContact && shoppingCartData.deliveryAddress) {
      setExistingShoppingCartData();
    }
  }, [shoppingCartData]);

  const selectLocation = async () => {
    setLoadingDeliveryValues(true);
    const result = await geocodeByPlaceId(searchValue.value.place_id);
    const parseAddress = await parseGoogleAddress(result[0]);
    const pc = parseInt(parseAddress.postalCode, 10);
    putValueData(street, parseAddress.street);
    putValueData(state, parseAddress.state);
    putValueData(city, parseAddress.city);
    putValueData(neighborhood, parseAddress.neighborhood);
    putValueData(postalCode, pc);
    putValueData(exteriorNumber, parseAddress.streetNumber);
    setLoadingDeliveryValues(false);
  };;;

  useEffect(() => {
    if (searchValue) {
      selectLocation();
    }
  }, [searchValue]);

  const existsCustomer = () => customer && Object.keys(customer).length > 0

  return (
    <Grid item xs={12} className={classes.tableFix}>
      <Typography variant="h6" className={classes.title}>
        Información del pedido
      </Typography>
      <Grid container>
        {existsCustomer() && (
          <Grid item xs={12}>
            {customer.locations.length >= 1 && (
              <Box className={classes.address}>
                <FormControl style={{ width: '100%', margin: '1rem' }}>
                  <FormLabel
                    className={classes.subTitle}
                    style={{ marginBottom: '1rem' }}
                    id="checked-group"
                  >
                    Tus Direcciones
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="checked-group"
                    name="controlled-checked-buttons-group"
                  >
                    {customer.locations.map((item) => (
                      <LocationCheck
                        key={item._id}
                        value={item.alias}
                        onChange={() => {
                          checkButtonOnChanges(item._id);
                        }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
          </Grid>
        )}

        <Grid item md={6} xs={12}>
          <div className={classes.boxField}>
            <Typography className={classes.subTitle}>Información de contacto</Typography>
            <TextField
              size="small"
              className={classes.textfield}
              label="Email"
              variant="outlined"
              type="email"
              fullWidth
              {...getPropsValidate(emailValidator)}
            />
            <TextField
              size="small"
              className={classes.textfield}
              required
              label="Nombre Completo"
              variant="outlined"
              fullWidth
              {...getPropsValidate(nameValidator)}
            />
            <TextField
              size="small"
              className={classes.textfield}
              required
              label="Teléfono"
              variant="outlined"
              fullWidth
              {...getPropsValidate(phoneNumber)}
            />
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className={classes.boxField}>
            <Typography className={classes.subTitle}>Agrega los datos de la dirección</Typography>
            <SearchPlaceInput
              onChange={setSearchValue}
              value={searchValue}
              onSelect={selectLocation}
            />

            <div className={classes.fixRow}>
              <TextField
                size="small"
                className={`${classes.textfield} ${classes.fixSmallField}`}
                required
                label="Calle"
                variant="outlined"
                fullWidth
                {...getPropsValidate(street)}
                disabled={loadingDeliveryValues}
              />
              <TextField
                size="small"
                className={classes.textfield}
                fullWidth
                required
                label="Numero exterior"
                variant="outlined"
                {...getPropsValidate(exteriorNumber)}
                disabled={loadingDeliveryValues}
              />
            </div>
            <div className={classes.fixRow}>
              <TextField
                size="small"
                className={`${classes.textfield} ${classes.fixSmallField}`}
                fullWidth
                label="Numero interior"
                variant="outlined"
                {...getPropsValidate(interiorNumber)}
                disabled={loadingDeliveryValues}
              />
              <TextField
                size="small"
                className={classes.textfield}
                required
                label="Colonia"
                variant="outlined"
                fullWidth
                {...getPropsValidate(neighborhood)}
                disabled={loadingDeliveryValues}
              />
            </div>
            <TextField
              size="small"
              className={classes.textfield}
              required
              label="Ciudad"
              variant="outlined"
              fullWidth
              {...getPropsValidate(city)}
              disabled={loadingDeliveryValues}
            />
            <div className={classes.fixRow}>
              <TextField
                size="small"
                className={`${classes.textfield} ${classes.fixSmallField}`}
                fullWidth
                required
                label="Codigo Postal"
                variant="outlined"
                {...getPropsValidate(postalCode)}
                disabled={loadingDeliveryValues}
              />
              <TextField
                size="small"
                className={classes.textfield}
                fullWidth
                required
                label="Estado"
                variant="outlined"
                {...getPropsValidate(state)}
                disabled={loadingDeliveryValues}
              />
            </div>
            <TextField
              size="small"
              className={classes.textfield}
              label="Indicaciones adicionales"
              variant="outlined"
              fullWidth
              {...getPropsValidate(addressNote)}
              disabled={loadingDeliveryValues}
            />
            {loadingDeliveryValues && (
                <Box className={classes.boxDeliveryLoader}>
                  <CircularProgress />
                </Box>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const { shoppingCartData } = state.shoppingCart;
  const { customer } = state.customer;
  return { shoppingCartData, customer };
};

Delivery.propTypes = {
  onSetAddressData: PropTypes.func,
  shoppingCartData: PropTypes.shape({
    deliveryAddress: PropTypes.shape({
      street: PropTypes.string,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      exteriorNumber: PropTypes.string,
      interiorNumber: PropTypes.string,
      state: PropTypes.string,
      neighborhood: PropTypes.string,
      addressNote: PropTypes.string
    }),
    deliveryContact: PropTypes.shape({
      name: PropTypes.string,
      phoneNumber: PropTypes.string,
      email: PropTypes.string
    })
  }),
  customer: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

Delivery.defaultProps = {
  onSetAddressData: () => {},
  shoppingCartData: {
    deliveryAddress: {},
    deliveryContact: {}
  },
  customer: {
    name: '',
    email: '',
    phoneNumber: '',
    locations: []
  }
};

export default connect(mapStateToProps, {})(Delivery);
