import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

const ClientCard = ({ image, name, comment }) => {
  const classes = styles();

  return (
    <div className={classes.boxFlex}>
      <Card className={classes.cardFix}>
        <CardContent>
          <div className={classes.image}>
            <img className={classes.circleImg} src={image} alt="" />
          </div>
          <Typography className={classes.title}>{name}</Typography>
          <Typography className={classes.subTitle}>{comment}</Typography>
        </CardContent>
      </Card>
    </div>
  );
};

ClientCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  comment: PropTypes.string
};

ClientCard.defaultProps = {
  image: '',
  name: '',
  comment: ''
};
export default ClientCard;
