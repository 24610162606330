import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import images from '../assets/images';

/**
 * ProductDescription
 * component to display product description
 * @param {object} classes Object style classes
 * @param {object} productData product data
 * @param {boolean} showRequireRecipe flag to indicate if it will be shown if it requires a recipe
 */
const ProductDescription = ({ classes, productData, showRequireRecipe = true }) => {
  const {
    description, quantity, presentation, unit, dose, requireRecipe
  } = productData;
  return (
    <Typography className={classes.descriptionProduct}>
      <span>{description || ''}</span>
      {quantity ? (
        <span>
          {`con ${quantity} ${presentation}`}
        </span>
      ) : (
        ''
      )}
      {unit ? (
        <span>
          {`de ${dose} ${unit}`}
        </span>
      ) : (
        ''
      )}
      {requireRecipe && showRequireRecipe && (
      <span className={classes.labelRecipe}>
            &nbsp;
        <img className={classes.iconRecipe} src={images.iconRecipe} alt="icon recipe" />
        Requiere receta
      </span>
      )}
    </Typography>
  );
};

ProductDescription.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  productData: PropTypes.shape({
    description: PropTypes.string,
    quantity: PropTypes.number,
    presentation: PropTypes.string,
    unit: PropTypes.string,
    dose: PropTypes.string,
    requireRecipe: PropTypes.bool
  }),
  showRequireRecipe: PropTypes.bool
};

ProductDescription.defaultProps = {
  classes: {},
  productData: {},
  showRequireRecipe: false
};

export default ProductDescription;
