import React, { useState } from 'react';
import { Box, Card, Typography, Button, IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Edit, LocationCity, Delete, Add } from '@material-ui/icons';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import ModalDelete from '../../../components/ModalDelete';
import SnackbarNote from '../../../components/SnackbarNote';
import { updateCustomer } from '../../../services/customers';
import AddAdress from '../AddAddress';

const MyAddresses = ({ customer, getUpdatedCustomer }) => {
  const classes = useStyles();
  const [success, setsuccess] = useState(false);
  const [modalIsOpen, setModaIsOpen] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  const [idLocation, setIdLocation] = useState();
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [addLocation, setAddLocation] = useState(false);

  /**
   * handleClickDelete
   *  open the snackbar note when delete a location
   * @returns {boolean} true
   */
  const handleClickDelete = () => {
    setLocationOpen(true);
  };

  /**
   * handleClickAddAddress
   *  open the snackbar note when add a location
   * @returns {boolean} true
   */
  const handleClickAddAddress = () => {
    setAddLocation(true);
  };

  /**
   * handleCloseDelete
   *  close the snackbar note when delete a location
   * @returns {boolean} false
   */
  const handleCloseDelete = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setLocationOpen(false);
  };

  /**
   * handleCloseAdd
   *  close the snackbar note when add a location
   * @returns {boolean} false
   */
  const handleCloseAdd = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAddLocation(false);
  };


  /**
   * handleModalDelete
   * Opens a modal that asks if you are sure to delete the address
   * @param {string} id id location
   */
  const handleModalDelete = (id) => {
    setIdLocation(id);
    setModaIsOpen(!modalIsOpen);
  };

  /**
   * deleteLocation
   * delete a location and update customer locations state
   */
  const deleteLocation = () => {
    const locationsSaved = customer.locations.filter((item) => item._id !== idLocation);
    const location = {
      locations: [...locationsSaved],
      _id: customer._id
    };
    updateCustomer({ params: location })
      .then((response) => {
        getUpdatedCustomer(response);
        setsuccess(true);
        handleClickDelete();
      })
      .catch(() => {
        setsuccess(false);
        handleClickDelete();
      });
  };

  /**
   * HandleShowAddAddress
   * Opens or close the add address modal 
   */
  const HandleShowAddAddress = () => {
    setShowAddAddress(!showAddAddress);
  };

  /**
   * handleSuccess
   * put information if the response was successful or not
   * @param {boolean} value 
   */
  const handleSuccess = (value) => {
    setsuccess(value);
  };
  
  return (
    <Box>
      <Card className={classes.card}>
        <Box className={classes.actionBox}>
          <Typography variant="h6">Mis Direcciones</Typography>
          <Tooltip title="Agregar Nueva Dirección">
            <IconButton onClick={HandleShowAddAddress}>
              <Add fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        {customer.locations && customer.locations.length >= 1 ? (
          <>
            {customer.locations.map((item) => (
              <Card className={classes.directions} key={item._id}>
                <Box>
                  <Typography>{item.alias}</Typography>
                  <Typography variant="subtitle2">{`${item.street}, ${item.exteriorNumber}, ${item.city}, ${item.postalCode}.`}</Typography>
                </Box>
                <Box className={classes.buttonsRow}>
                  <Link to={`/perfil/direccion/${item._id}`} className={classes.link}>
                    <Button variant="text">
                      <Edit className={classes.icon} />
                    </Button>
                  </Link>
                  <Button
                    variant="text"
                    component="label"
                    onClick={() => {
                      handleModalDelete(item._id);
                    }}
                  >
                    <Delete className={classes.icon} />
                  </Button>
                </Box>
              </Card>
            ))}
            <ModalDelete
              name="¿Estás seguro de borrar esta dirección?"
              open={modalIsOpen}
              onClose={handleModalDelete}
              onAction={deleteLocation}
            />
          </>
        ) : (
          <Box className={classes.centerRow}>
            <LocationCity />
            <Typography>Aún no tienes direcciones agregadas</Typography>
          </Box>
        )}
      </Card>
      <AddAdress
        customer={customer}
        open={showAddAddress}
        onClose={HandleShowAddAddress}
        getUpdatedCustomer={getUpdatedCustomer}
        handleSuccess={handleSuccess}
        handleClickUpdate={handleClickAddAddress}
      />
      <SnackbarNote
        successMessage="Dirección eliminada correctamente ✅"
        onOpen={locationOpen}
        onClose={handleCloseDelete}
        success={success}
      />
      <SnackbarNote
        successMessage="Nueva Dirección Agregada 👍"
        onOpen={addLocation}
        onClose={handleCloseAdd}
        success={success}
      />
    </Box>
  );
};

MyAddresses.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    mothersLastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    _id: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  getUpdatedCustomer: PropTypes.func
};

MyAddresses.defaultProps = {
  customer: {
    name: '',
    lastName: '',
    mothersLastName: '',
    phoneNumber: '',
    _id: '',
    locations:[]
  },
  getUpdatedCustomer: () => {}
};

export default MyAddresses;
