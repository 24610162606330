import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    color: Colors.blueHepatica,
    marginTop: '8rem'
  },
  row: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '10rem',
      paddingRight: '10rem'
    }
  },
  subtitle: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '14rem',
      paddingRight: '14rem'
    },
    textAlign: 'justify'
  },
  numericList: {
    counterReset: 'item',
    '& li': {
      listStyleType: 'decimal !important',
      display: 'block',
      marginTop: '1rem'
    }
  },
  shippingList: {
    '& li': {
      listStyleType: 'decimal',
      marginTop: '1rem'
    }
  },
  subNumeric: {
    listStyleType: 'circle !important',
    marginLeft: '2rem',
    marginTop: '1rem'
  },
  textList: {
    textAlign: 'justify',
    marginTop: '1rem'
  }
}));
export default styles;
