import { Box, Card, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import images from '../../../../assets/images';
import ProductDescription from '../../../../components/ProductDescription';
import styles from '../styles';

const CardSimilarProduct = ({ productData }) => {
  const classes = styles();
  const { price, stock } = productData;

  const isValidToSelling = () => price && stock;
  return (
    <Link className={classes.link} to={`/productos/${productData._id}`}>
      <Card className={classes.card}>
        <Box className={classes.imageBox}>
          <img className={classes.image} src={productData.imageUrl || images.productDefault} alt="product" />
        </Box>
        <Typography align="center" className={classes.price}>
          {productData.name}
        </Typography>
        <Box className={classes.descriptionBox}>
          <ProductDescription classes={classes} productData={productData} />
        </Box>
        {!isValidToSelling() ? (
          <div className={classes.boxStock}>
            <div className={classes.noProductOnStock}>
              <Typography className={classes.textNoStock}>
                Te lo conseguimos 😉
              </Typography>
            </div>
          </div>
        ) : (
          <Typography align="center" className={classes.price}>
            $
            {' '}
            {productData.price}
          </Typography>
        ) }

      </Card>
    </Link>
  );
};

CardSimilarProduct.propTypes = {
  productData: PropTypes.shape({
    description: PropTypes.string,
    quantity: PropTypes.number,
    presentation: PropTypes.string,
    unit: PropTypes.string,
    dose: PropTypes.string,
    requireRecipe: PropTypes.bool,
    name: PropTypes.string,
    price: PropTypes.number,
    stock: PropTypes.number,
    imageUrl: PropTypes.string,
    _id: PropTypes.string
  })
};

CardSimilarProduct.defaultProps = {
  productData: {}
};
export default CardSimilarProduct;
