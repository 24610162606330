import React from 'react'
import { Breadcrumbs } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles';

const Crumbs = ({ routes }) => {
const classes = styles();

return(
<Breadcrumbs>
    { routes.map((crumb)=> (
        <Link to={`/${crumb.link}`} className={classes.link} key={crumb.name}>
            {crumb.name}
        </Link>
    ))}
</Breadcrumbs>
)
}

Crumbs.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.shape({link: PropTypes.string, name: PropTypes.string}))
}

Crumbs.defaultProps = {
    routes: [{link:'', name:''}]
}

export default Crumbs;
