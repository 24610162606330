import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextNumber from '../TextNumber';

const SubtotalPrice = ({ shoppingCartData }) => {
  /**
   * Function to get subtotal by items selected
   * @returns number
   */
  const getSubtotal = () => {
    let sumSubtotal = 0;
    shoppingCartData.items.forEach((item) => {
      sumSubtotal += item.subtotal;
    });
    return parseFloat(sumSubtotal.toFixed(2));
  };
  return <TextNumber number={getSubtotal()} />;
};

const mapStateToProps = (state) => {
  const { shoppingCartData } = state.shoppingCart;
  return { shoppingCartData };
};

SubtotalPrice.propTypes = {
  shoppingCartData: PropTypes.shape({
    oauthId: PropTypes.shape({ internalCode: PropTypes.string }),
    subTotal: PropTypes.number,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        quantity: PropTypes.number
      })
    )
  })
};

SubtotalPrice.defaultProps = {
  shoppingCartData: { items: [], subTotal: 0 }
};

export default connect(mapStateToProps)(SubtotalPrice);
