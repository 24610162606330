/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Crumbs from '../../../../components/Crumbs';
import Title from '../../../../components/Title';
import useValidField from '../../../../customHooks/useValidField';
import useStyles from './useStyles';
import { activateCdsoCard , assignCdsoCard } from '../../../../services/cdso';
import ResponseDialog from './ResponseDialog';

const RegisterCdsoCard = ({ customer, account }) => {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const [open, setOpen] = useState(false);
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const phoneNumberValidator = useValidField({ type: 'number', phone: true });
  const postalCode = useValidField({ type: 'number' });
  const name = useValidField({ type: 'text' });
  const lastName = useValidField({ type: 'text' });
  const mothersLastName = useValidField({ type: 'text' });
  const email = useValidField({ type: 'email' });
  const dateOfBirth = useValidField({ type: 'date' });
  const [gender, setGender] = useState('');

  /**
   * Function that Handle the form on view
   * Changes state between true or false
   */
  const handleForms = () => {
    setShowForm(!showForm);
    setShowCard(!showCard);
  };

  /**
   * Function to handle gender value
   * @param {object} event 
   */
  const handleGenderOnChange = (event) => {
    setGender(event.target.value);
  };

  /**
   * Function to Remove the state handler from the props
   * @param {*} validator 
   * @returns Validator without setState
   */
  const getPropsValidate = (validator) => {
    const { setValue, ...validationProps } = validator;
    return validationProps;
  };

  const routes = [
    {
      link: 'account',
      name: 'Mi Cuenta'
    },
    {
      link: 'programas-de-lealtad',
      name: 'Programas de lealtad'
    },
    {
      link: 'programas-de-lealtad/circulo-de-la-salud',
      name: 'Circulo de la salud'
    }
  ];

  /**
   * Put new value on validator state
   * @param {*} value 
   * @param {*} validator 
   */

  const setUserValues = (value, validator) => {
    const { setValue } = validator;
    setValue(value);
  };

  /**
   * Show or close the modal ResponseDialog
   */
  const handleModal = () => {
    setOpen(!open);
  };

  /**
   * Handle if cdso info is complete
   * @returns Boolean
   */
  const canRegister = () => {
    const body = {
      name: name.value,
      lastName: lastName.value,
      mothersLastName: mothersLastName.value,
      email: email.value,
      birthday: dateOfBirth.value,
      postalCode: postalCode.value,
      gender,
      phoneNumber: phoneNumberValidator.value
    };
    if (
      !body.name ||
      !body.lastName ||
      !body.mothersLastName ||
      !body.email ||
      !body.postalCode ||
      !body.birthday ||
      !body.gender ||
      !body.phoneNumber
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (customer) {
      setUserValues(customer.name, name);
      setUserValues(customer.lastName, lastName);
      setUserValues(customer.mothersLastName, mothersLastName);
      setUserValues(account.email, email);
    }
  }, [customer]);

  /**
   * Function to create cdso card
   * @returns boolean
   */
  const createCard = async () => {
    try {
      setLoading(true);
      const body = {
        name: name.value,
        lastName: lastName.value,
        mothersLastName: mothersLastName.value,
        email: email.value,
        birthday: dateOfBirth.value,
        postalCode: postalCode.value,
        gender,
        phoneNumber: phoneNumberValidator.value,
        clientId: customer._id
      };
      const response = await activateCdsoCard(body);
      setRes(true);
      setLoading(false);
      handleModal();
      return response;
    } catch (error) {
      setLoading(true);
      setRes(false);
      setLoading(false);
      handleModal();
      return false;   
    }
  };

  /**
   * Function to assign existing card to customer
   */
  const assignCard = async() => {
    try {
      setLoading(true);
      const body = {
        clientId: customer._id,
        cardNumber
      }
      const response = await assignCdsoCard(body)
      if(response) {
        setRes(true);
        setLoading(false);
        handleModal();
      }
    } catch (error) {
      setLoading(true);
      setRes(false);
      setLoading(false);
      handleModal();
    }
  }

  /**
   * Function to set value to client card number
   * @param {object} e 
   */
  const handleCardNumber = (e) =>{
    setCardNumber(e.target.value)
  }

  return (
    <Container maxWidth="xl">
      <Grid container className={classes.container}>
        <ResponseDialog open={open} onClose={handleModal} result={res} uid={customer.authId} />
        <Box className={classes.breadcrumbBox}>
          <Crumbs routes={routes} />
        </Box>
      </Grid>
      <Collapse in={showCard}>
        <Grid container className={classes.row}>
          <Grid item xs={12}>
            <Title text="Círculo de la Salud Oro" />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" className={classes.title}>
              ¿Cuentas con una tarjeta del Círculo de la Salud Oro? Ingrésala en el siguiente campo.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.boxCard}>
              <TextField
                placeholder="Escribe tu número de tarjeta"
                className={classes.inputCard}
                size="small"
                variant="outlined"
                onChange={handleCardNumber}
              />
              <Button variant="contained" color="primary" onClick={assignCard} 
                disabled={cardNumber.length < 13}>
                Agregar
              </Button>
            </Box>
            <Box className={classes.boxButtonCard}>
             
                <Button
                  className={classes.button}
                  color="primary"
                  onClick={handleForms}
                >
                  Ó adquiere una aquí
                </Button>
                <Typography>( Te toma menos de un minuto 😉 )</Typography>
               
            </Box>
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={showForm}>
        <Grid container className={classes.row}>
          <Grid item xs={12}>
            <Title text="Círculo de la Salud Oro" />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" className={classes.title}>
              Registrate en el siguiente formulario
            </Typography>
          </Grid>
          <Grid container justifyContent="center" className={classes.form}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nombre"
                variant="outlined"
                size="small"
                className={classes.input}
                {...getPropsValidate(name)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Apellido Paterno"
                variant="outlined"
                size="small"
                className={classes.input}
                {...getPropsValidate(lastName)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Apellido Materno"
                variant="outlined"
                size="small"
                className={classes.input}
                {...getPropsValidate(mothersLastName)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                size="small"
                className={classes.input}
                {...getPropsValidate(email)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Teléfono"
                variant="outlined"
                size="small"
                className={classes.input}
                {...getPropsValidate(phoneNumberValidator)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="date"
                label="Fecha de nacimiento"
                InputLabelProps={{
                  shrink: true
                }}
                variant="outlined"
                size="small"
                fullWidth
                className={classes.input}
                {...getPropsValidate(dateOfBirth)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                size="small"
                fullWidth
                variant="outlined"
                className={classes.input}
              >
                <InputLabel id="demo-select-small">Sexo</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={gender}
                  label="Sexo"
                  onChange={handleGenderOnChange}
                >
                  <MenuItem value="">
                    <em>Ninguno</em>
                  </MenuItem>
                  <MenuItem value="M">Masculino</MenuItem>
                  <MenuItem value="F">Femenino</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.input}
                size="small"
                variant="outlined"
                label="Código postal"
                {...getPropsValidate(postalCode)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Box className={classes.boxAddCartFix}>
                <Button variant="contained" color="primary" onClick={handleForms}>
                  Regresar
                </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={createCard}
                    disabled={canRegister()}
                  >
                    Registrarme
                  </Button>
              </Box>
              <Modal open={loading} className={classes.circularProgress} >
              <CircularProgress />
              </Modal>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </Container>
  );
};

RegisterCdsoCard.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    mothersLastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    _id: PropTypes.string,
    emailVerified: PropTypes.bool,
    authId: PropTypes.string
  }),
  account: PropTypes.shape({
    email: PropTypes.string
  })
};

RegisterCdsoCard.defaultProps = {
  customer: {
    name: '',
    lastName: '',
    mothersLastName: '',
    phoneNumber: '',
    _id: '',
    emailVerified: false,
    authId:''
  },
  account: {
    email: ''
  }
};

const mapsStateToProps = (state) => {
  const { customer } = state.customer;
  const { account } = state.auth;
  return { customer, account };
};

export default connect(mapsStateToProps, {})(RegisterCdsoCard);
