import { Box, TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from '../styles';
import TextNumber from '../../TextNumber';
import images from '../../../assets/images';

const FreePiece = ({ item, bonus }) => {
  const classes = styles();
  const { product } = item;
  const freeParts = parseInt(bonus.PiezasGratis, 10) + parseInt(bonus.PiezasPendientes, 10);
  return (
    <TableRow>
      <TableCell>
        <div className={classes.cell}>
          <Link to={`/productos/${product._id}`}>
            <img className={classes.image} src={product.imageUrl || images.productDefault} alt="" />
          </Link>
          <div className={classes.textProductContainer}>
            <Typography className={classes.nameProduct}>{product.name}</Typography>
          </div>
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Typography align="center">{freeParts}</Typography>
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <Typography>
          <TextNumber number={0} />
        </Typography>
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <Box>
          <Typography>
            <TextNumber number={0} />
          </Typography>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCell} align="center" />
    </TableRow>
  );
};

FreePiece.propTypes = {
  item: PropTypes.shape({
    product: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      imageUrl: PropTypes.string,
      requireRecipe: PropTypes.bool,
      ean: PropTypes.string
    })
  }),
  bonus: PropTypes.shape({
    PiezasGratis: PropTypes.string,
    PiezasPendientes: PropTypes.string
  })
};
FreePiece.defaultProps = {
  item: { discount: 0, subtotal: 0 },
  bonus: {}
};
export default FreePiece;
