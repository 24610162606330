import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Fab,
  Grid,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styles from './styles';
import { getProductsByNur } from '../../services/products';
import ItemCartTable from '../../components/CartTable/ItemCartTable';
import InfoUser from './InfoUser';
import { createShoppingCartByInsurancesData } from '../../reducers/shoppingCart/actions';
import ModalLogoIsana from '../../components/ModalLogoIsana';
import images from '../../assets/images';
import { NUR_NOT_CURRENT } from '../../constants';

const Insurances = ({ createShoppingCartByInsurancesData }) => {
  const classes = styles();
  const [dataInsurances, setDataInsurances] = useState({ products: [] });
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [isSuplied, setIsSuplied] = useState(false);
  const [nur, setNur] = useState();
  const [productError, setProductError] = useState(false);
  const [errorWarning, setErrorWarning] = useState(false);
  const getProducts = async () => {
    try {
      setLoading(true);
      const data = await getProductsByNur(nur);
      const { products, NURS } = data;
      const items = [];
      const supliedItem = []
      products.forEach((product) => {
        const nurProductData = NURS.find((nur) => product.ean === nur.EAN);
        if (nurProductData.Surtido) {
          supliedItem.push(product.ean)
        }
        items.push({
          product,
          quantity: product.stock && product.price ? nurProductData.Cantidad : 0,
          discount: 0,
          unitPrice: product.price,
          _id: product._id
        });
      });
      if (data.Error === NUR_NOT_CURRENT) {
         setErrorWarning(true);
         setLoading(false);
         setProductError(false);
         setDataInsurances({ ...data, products: items });
         return
      }
      if (items.length === supliedItem.length) {
        setIsSuplied(true)
        setErrorWarning(true);
      }
      setDataInsurances({ ...data, products: items });
      setProductError(false);
      setLoading(false);
      return;
    } catch (error) {
      setLoading(false);
      setProductError(true);
    }
  };
  const onChangeNur = (event) => {
    setNur(event.target.value);
  };
  const navigate = useNavigate();

  const proccesOrder = async () => {
    setCreating(true);
    const data = {
      ...dataInsurances,
      products: []
    };
    dataInsurances.products.forEach((item) => {
      if (item.quantity >= 1) {
        data.products.push(item);
      }
    });
    await createShoppingCartByInsurancesData(data);
    navigate('/checkout');
    setCreating(false);
  };

  const isAvailable = () => {
    let quantity = 0;
    dataInsurances.products.forEach((product) => {
      quantity = product.quantity + quantity;
    });
    return quantity;
  };

 
  // useEffect(() => {
    
  //   const listener = async event => {
  //     if (event.code === "Enter") {
  //       event.preventDefault();
  //       await getProducts()
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, [])

  // eslint-disable-next-line react/no-unstable-nested-components
  const TableInsurances = () => (
    <Box>
      <Box className={classes.infoUser}>
        <InfoUser title="Paciente" info={dataInsurances.NombrePaciente} />
        <InfoUser title="Médico" info={dataInsurances.NombreMedico} />
        <InfoUser title="Plan de salud" info={dataInsurances.PlanSalud} />
        <InfoUser title="Especialidad" info={dataInsurances.Especialidad} />
        <InfoUser title="Aseguradora" info={dataInsurances.Administradora} />

      </Box>
      {errorWarning ? (
        <Box className={classes.assortedBox}>
          <Box>
            {isSuplied ? (
          <Typography variant='h6' className={classes.assortedTitle}>Al parecer esta receta ya no está vigente para ser surtida</Typography>
            ) : (
          <Typography variant='h6' className={classes.assortedTitle}>La receta ya no está vigente.</Typography>
            )}
          <Typography className={classes.assortedTitle}>Para cualquier aclaración consulta a tu aseguradora.</Typography>
          </Box>
          <Box>
          <img className={classes.imagesBags} src={images.bolsas} alt="Bolsas" />
          </Box>
        </Box>
      ) : (
        <Box className={classes.productsRow}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellTitle} align="center">
                  Producto
                </TableCell>
                <TableCell className={`${classes.cellTitle} ${classes.tableCell}`} align="center">
                  Cantidad
                </TableCell>
                <TableCell className={`${classes.cellTitle} ${classes.tableCell}`} align="center" />
                <TableCell className={`${classes.cellTitle} ${classes.tableCell}`} align="center" />
                <TableCell className={`${classes.cellTitle} ${classes.tableCell}`} align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataInsurances.products.map((item) => (
                <ItemCartTable
                  itemLength={dataInsurances.products.length}
                  key={item._id}
                  item={item}
                  isEditable={false}
                  showPrices={false}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
  return (
    <Container maxWidth="xl" className={classes.containerFixed}>
      <Grid container>
        <Grid xs={12}>
          <ModalLogoIsana open={creating} />
          <Paper component="form" className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Introduce un código NUR de tu receta"
              inputProps={{ 'aria-label': 'Introduce un código NUR de tu receta' }}
              onChange={onChangeNur}
            />
            <Tooltip title="Buscar">
              <Fab
                size="small"
                aria-label="search"
                className={classes.fabButton}
                onClick={getProducts}
                disabled={loading}
                id='searchNur'
              >
                <ArrowForward />
              </Fab>
            </Tooltip>
          </Paper>
        </Grid>
        <Grid xs={12}>
          {dataInsurances.products.length && !loading && !productError ? (
            <Paper className={classes.paperContainer}>
              {dataInsurances.IndicadorDePago === 2 ? (
                <>
                  <TableInsurances />
                  <Box className={classes.buttonBox}>
                    {!isSuplied && (
                    <Button
                      className={classes.buttonShopping}
                      onClick={proccesOrder}
                      disabled={!isAvailable() || creating}
                    >
                      Ir al proceso de surtimiento
                    </Button>
                    )}
                  </Box>
                </>
              ) : (
                <Box className={classes.noAgreement}>
                  <Typography>Esta receta no tiene convenio. 🤔</Typography>
                </Box>
              )}
            </Paper>
          ) : (
            <Box className={classes.notInfo}>
              {loading ? (
                <Box className={classes.searchBox}>
                  <CircularProgress />
                  <Typography variant="body2">
                    Estamos recuperando la información de tu receta.
                  </Typography>
                </Box>
              ) : (
                <Typography>
                  {productError
                    ? 'No se encontraron resultados 🔍'
                    : 'No hay receta que mostrar 📝'}
                </Typography>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

Insurances.propTypes = {
  createShoppingCartByInsurancesData: PropTypes.func
};

Insurances.defaultProps = {
  createShoppingCartByInsurancesData: () => {}
};
export default connect(null, { createShoppingCartByInsurancesData })(Insurances);
