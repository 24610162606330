import { Box, CircularProgress, Container, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Title from '../../../components/Title';
import styles from '../styles';
import useValidField from '../../../customHooks/useValidField';
import { updateCustomer } from '../../../services/customers';
import SnackbarNote from '../../../components/SnackbarNote';
import Crumbs from '../../../components/Crumbs';
import { scrollTop } from '../../../helper';
import { updateCustumerData } from '../../../reducers/customer/actions';
import AddressForm from './AddressForm';

const EditAddress = ({ customer, updateCustumerData }) => {
  const classes = styles();
  const [addressInfo, setAddressInfo] = useState([{
    state: '',
    street: '',
    city: '',
    postalCode: '',
    neighborhood: '',
    exteriorNumber: '',
    interiorNumber: '',
    addressNote: '',
    alias: ''
  }]);
  const [open, setOpen] = useState(false);
  const [success, setsuccess] = useState(true);
  const { _id } = useParams();
  const postalCode = useValidField({ type: 'number' });
  const state = useValidField({ type: 'text' });
  const city = useValidField({ type: 'text' });
  const street = useValidField({ type: 'text' });
  const neighborhood = useValidField({ type: 'text' });
  const exteriorNumber = useValidField({ type: 'text' });
  const interiorNumber = useValidField({ type: 'text', skip: true });
  const addressNote = useValidField({ type: 'text', skip: true });
  const alias = useValidField({ type: 'text' });
  const [saved, setSaved] = useState(false);
  const navigate = useNavigate();
  const routes = [
    {
      link: 'account',
      name: 'Mi Cuenta'
    },
    {
      link: 'perfil',
      name: 'Mi Perfil'
    },
    {
      link: `perfil/direccion/${_id}`,
      name: 'Editar Dirección'
    }
  ];

  const handleClickUpdate = () => {
    setOpen(true);
  };

  const handleCloseUpdate = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (customer) {
      const location = customer.locations.filter((item) => item._id === _id);
      setAddressInfo(location);
    }
    scrollTop();
  }, [customer]);

  const putValueData = (validator, value) => {
    const { setValue } = validator;
    setValue(value);
  };

  useEffect(() => {
    if (addressInfo) {
      if(saved){
        return;
      }
      putValueData(state, addressInfo[0].state);
      putValueData(street, addressInfo[0].street);
      putValueData(city, addressInfo[0].city);
      putValueData(postalCode, addressInfo[0].postalCode);
      putValueData(neighborhood, addressInfo[0].neighborhood);
      putValueData(exteriorNumber, addressInfo[0].exteriorNumber);
      putValueData(interiorNumber, addressInfo[0].interiorNumber);
      putValueData(addressNote, addressInfo[0].addressNote);
      putValueData(alias, addressInfo[0].alias);
    }
  }, [addressInfo]);

  const updateAddress = () => {
    const oldData = customer.locations.filter((item) => item._id !== _id);
    const location = {
      locations: [
        {
          city: city.value,
          neighborhood: neighborhood.value,
          postalCode: postalCode.value,
          state: state.value,
          street: street.value,
          alias: alias.value,
          exteriorNumber: exteriorNumber.value,
          interiorNumber: interiorNumber.value,
          deliveryInstructions: addressNote.value
        },
        ...oldData
      ],
      _id: customer._id
    };
    updateCustomer({ params: location })
      .then((response) => {
        setSaved(true);
        updateCustumerData(response);
        setsuccess(true);
        handleClickUpdate();
        navigate('/perfil');
      })
      .catch(() => {
        setsuccess(false);
        handleClickUpdate();
      });
  };

  return (
    <Container maxWidth="xl">
      <Grid container className={classes.container}>
        <Box className={classes.breadcrumbBox}>
          <Crumbs routes={routes} />
        </Box>
      </Grid>
      {addressInfo ? (
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Title text="Editar Dirección" />
          </Grid>
          <SnackbarNote
            successMessage="Datos actualizados correctamente 👍"
            onOpen={open}
            onClose={handleCloseUpdate}
            success={success}
          />
          <Grid item xs={12}>
            <Box className={classes.gridFix} style={{ marginTop: '2rem', marginBottom: '5rem' }}>
              <AddressForm
                street={street}
                exteriorNumber={exteriorNumber}
                interiorNumber={interiorNumber}
                neighborhood={neighborhood}
                city={city}
                postalCode={postalCode}
                state={state}
                addressNote={addressNote}
                alias={alias}
                buttonText="Editar"
                onClick={updateAddress}
                location={addressInfo[0]}
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box className={classes.circularBox}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

EditAddress.propTypes = {
  customer: PropTypes.shape({
    _id: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  updateCustumerData: PropTypes.func
};

EditAddress.defaultProps = {
  customer: {
    _id: 'id',
    locations: []
  },
  updateCustumerData: () => {}
};

const mapsStateToProps = (state) => {
  const { customer } = state.customer;
  return { customer };
};
export default connect(mapsStateToProps, { updateCustumerData })(EditAddress);
