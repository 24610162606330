import React from 'react';
import { Button, Typography } from '@material-ui/core';
import images from '../../assets/images';
import styles from './styles';

const Services = () => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <div className={classes.bannerSection}>
        <Typography className={classes.miniTitle}>
          NUESTRO SERVICIO
        </Typography>
        <Typography className={classes.title}>
          Somos tu farmacia digital
        </Typography>
        <Typography className={classes.subTitle}>
          Buscamos por ti todos tus medicamentos y te los entregamos
          en la puerta de tu casa.
        </Typography>
        <img className={classes.imageBanner} src={images.bagServices} alt="" />
        <Button className={classes.button}>
          Hacer pedido
        </Button>
      </div>
      <div className={classes.cardRow}>
        <div className={classes.cardIons}>
          <img className={classes.imageIcon} src={images.iconhservices} alt="" />
          <Typography className={classes.textIcon}>Círculo de la salud</Typography>
          <Typography className={classes.subTextIcon}>
            Recompensamos y reconocemos tu lealtad.
            Te damos cientos de productos al 3x4, 4+2 y otras ofertas exclusivas.
          </Typography>
        </div>
        <div className={classes.cardIons}>
          <img className={classes.imageIcon} src={images.iconPhoneServices} alt="" />
          <Typography className={classes.textIcon}>Más de 25,000 productos</Typography>
          <Typography className={classes.subTextIcon}>
            Para tu comodidad tenemos una alta disponibilidad de
            productos para que encuentres tus medicamentos en un solo lugar.
          </Typography>
        </div>
        <div className={classes.cardIons}>
          <img className={classes.imageIcon} src={images.iconRewardsServices} alt="" />
          <Typography className={classes.textIcon}>Recompensas iSana</Typography>
          <Typography className={classes.subTextIcon}>
            Generas puntos con cada compra y utilízalos para comprar tus medicamentos
          </Typography>
        </div>
      </div>
      <div className={classes.teamRow}>
        <div>
          <img className={classes.imageTeam} src={images.team} alt="" />
        </div>
        <div className={classes.textVideoRow}>
          <Typography className={classes.textVideo}>
            En iSana somos una farmacia digital con capacidad de encontrar todos tus
            medicamentos a los mejores precios y llevarlos hasta las puertas de tu
            casa cuando tú lo necesites. Ahorra hasta en los medicamentos que requieren
            receta médica sólo envíanos una foto de tu receta médica para poder cotizar
            tus medicamentos.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Services;
