import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../theme';

const styles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    color: Colors.blueHepatica,
    fontSize: '1.5rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      textAlign: 'center'
    }
  },
  subtitle: {
    color: Colors.black,
    marginTop: '1.4rem',
    fontSize: '1.3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      textAlign: 'center',
      marginTop: '0.7rem'
    }
  },
  image: {
    maxWidth: '19rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  imageBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: '2rem'
  },
  container: {
    backgroundColor: Colors.simpleWhite,
    padding: '7rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '3rem'
    }
  },
  button: {
    backgroundColor: Colors.blueHepatica,
    color: Colors.simpleWhite,
    marginTop: '2rem',
    width: '100%'
  },
  warningBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  buttonDanger: {
    backgroundColor: Colors.blueHepatica,
    color: Colors.simpleWhite,
    maxWidth: '14rem',
    margin: 'auto'
  },
  boxInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignContent: 'center',
      alignSelf: 'center'
    }
  },
  banner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconCheck: {
    fontSize: '4rem',
    color: Colors.blueHepatica
  },
  boxBanner: {
    maxWidth: '25rem',
    boxShadow: Colors.lowGray,
    padding: '1.4rem',
    borderRadius: '0.5rem',
    minHeight: '33rem'
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1rem'
  },
  font: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  fontSub: {
    fontSize: '1rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem'
    }
  },
  descriptionProduct: {
    fontSize: '.9rem'
  },
  textPrice: {
    width: '45%',
    fontSize: '1rem',
    textAlign: 'right'
  },
  productsRow: {
    margin: 'auto',
    padding: '1.4rem',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '60rem',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '25rem'
    }
  },
  address: {
    display: 'flex',
    flexDirection: 'row'
  },
  addressText: {
    marginRight: '0.3rem'
  },
  addressTitle: {
    marginTop: '1rem',
    fontWeight: 'bold',
    color: Colors.blueHepatica
  },
  contactText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginRight: '0.4rem',
    fontSize: '1.3rem'
  },
  paid: {
    color: Colors.goodLuck
  },
  pending: {
    color: Colors.radicalRed
  },
  requireRecipeBox: {
    backgroundColor: Colors.softBlue,
    padding: '0.28rem',
    borderRadius: '1rem',
    marginTop: '0.7rem'
  },
  requireRecipeText: {
    fontWeight: 'bold',
    textAlign: 'center'
  },
  requireInstructionBox: {
    border: 'solid',
    borderColor: Colors.redBackIsana,
    padding: '0.4rem',
    borderRadius: '0.3rem'
  },
  requireInstructionText: {
    fontSize: '0.9rem'
  },
  warning: {
    marginBottom: '-0.13rem',
    fontSize: '0.9rem',
    marginRight: '0.2rem'
  },
  able: {
    border: 'none'
  },
  cellTitle: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  link: {
    textDecoration: 'none',
    width: '100%'
  },
  logoBox: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection:'row'
  },
  isanaPlus: {
    width: '7rem',
    height:'9rem',
    marginTop:'-0.5rem'
  },
  cdsoLogo: {
    width: '4rem',
    height:'4rem',
    marginTop:'1rem'
  },
  textLogos: {
    color: Colors.blueHepatica,
    marginTop:'1rem'
  },
  tableCell: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));
export default styles;
