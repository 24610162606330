import { makeStyles } from '@material-ui/core';
import { Colors } from '../../theme';

const styles = makeStyles(() => ({
  removePrice: {
    color: Colors.gray,
    textDecoration: 'line-through;'
  }
}));

export default styles;
