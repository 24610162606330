import {
  Avatar, CircularProgress
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './styles';

const AvatarUser = ({ customer, lg }) => {
  const classes = styles();
  const [name, setName] = useState(null);

  useEffect(() => {
    if (customer) {
      const { name = '', lastName = '' } = customer;
      setName(`${name} ${lastName}`);
    }
  }, [customer]);
  return !name ? (
    <CircularProgress />
  ) : (
    <Avatar
      className={lg ? classes.avatar : ''}
      src={`https://ui-avatars.com/api/?background=AD01D5&color=fff&name=${name}`}
    />
  );
};

AvatarUser.propTypes = {
  customer: PropTypes.shape({ name: PropTypes.string, lastName: PropTypes.string }),
  lg: PropTypes.bool
};

AvatarUser.defaultProps = {
  customer: {
    name: '',
    lastName: ''
  },
  lg: false
};

const mapsStateToProps = (state) => {
  const { customer } = state.customer;
  return { customer };
};

export default connect(mapsStateToProps, null)(AvatarUser);
